import { createSvgIcon } from "@mui/material";

const VisibleOn = createSvgIcon(
    <svg viewBox="-2 -2 22 22" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.733 8.8246C17.7129 8.7951 17.535 8.533 17.4391 8.4078C16.9912 7.8236 15.9424 6.4554 14.4964 5.2586C12.6856 3.7599 10.8364 3 9 3C7.16365 3 5.31443 3.7599 3.50367 5.2586C2.05766 6.4554 1.00881 7.8236 0.560883 8.4079C0.465012 8.533 0.287086 8.7951 0.267082 8.8246L0 9.2188L0.267012 9.6129C0.28698 9.6424 0.464941 9.9046 0.560883 10.0298C1.00877 10.6141 2.05766 11.9822 3.50364 13.1791C5.31439 14.6778 7.16365 15.4377 9 15.4377C10.8364 15.4377 12.6856 14.6777 14.4964 13.179C15.9424 11.9822 16.9912 10.614 17.4391 10.0298C17.535 9.9046 17.713 9.6424 17.733 9.6129L18 9.2187L17.733 8.8246ZM9 14.0322C6.62224 14.0322 4.16971 12.4127 1.71011 9.2188C4.16974 6.0248 6.62224 4.4054 9 4.4054C11.3778 4.4054 13.8303 6.0249 16.2899 9.2188C13.8303 12.4128 11.3778 14.0322 9 14.0322Z" />
        <path d="M9.00001 10.5891C9.75678 10.5891 10.3702 9.97563 10.3702 9.21893C10.3702 8.46213 9.75678 7.84863 9.00001 7.84863C8.24324 7.84863 7.62976 8.46213 7.62976 9.21893C7.62976 9.97563 8.24324 10.5891 9.00001 10.5891Z"  />
        <path d="M8.99998 5.21338C6.79143 5.21338 4.99463 7.01008 4.99463 9.21868C4.99463 11.4273 6.79139 13.2241 8.99998 13.2241C11.2086 13.2241 13.0053 11.4273 13.0053 9.21868C13.0053 7.01008 11.2085 5.21338 8.99998 5.21338ZM8.99998 11.8187C7.56634 11.8187 6.40004 10.6523 6.40004 9.21868C6.40004 7.78508 7.56634 6.61878 8.99998 6.61878C10.4336 6.61878 11.5999 7.78508 11.5999 9.21868C11.5999 10.6523 10.4336 11.8187 8.99998 11.8187Z" />
    </svg>,
    "VisibleOn"
)

export default VisibleOn