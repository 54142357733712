import { createSlice } from "@reduxjs/toolkit";
import { entrantFormService } from "../../services/entrantService";
import { createCustomAsyncThunk } from "../utils";
import { getCurrentISODate } from "../../utils";

export const getPayerInfo = createCustomAsyncThunk(
    "entrantForm/payerInfo/get",
    async userId => await entrantFormService.getPayerInfo(userId)
)

export const createPayerInfo = createCustomAsyncThunk(
    "entrantForm/payerInfo/create",
    async data => await entrantFormService.postPayerInfo(data)
)

export const updatePayerInfo = createCustomAsyncThunk(
    "entrantForm/payerInfo/update",
    async data => await entrantFormService.patchPayerInfo(data)
)

export const deletePayerInfo = createCustomAsyncThunk(
    "entrantForm/payerInfo/delete",
    async data => await entrantFormService.deletePayerInfo(data)
)

export const getPayerOrgInfo = createCustomAsyncThunk(
    "entrantForm/payerOrgInfo/get",
    async userId => await entrantFormService.getPayerOrgInfo(userId)
)

export const createPayerOrgInfo = createCustomAsyncThunk(
    "entrantForm/payerOrgInfo/create",
    async data => await entrantFormService.postPayerOrgInfo(data)
)

export const updatePayerOrgInfo = createCustomAsyncThunk(
    "entrantForm/payerOrgInfo/update",
    async data => await entrantFormService.patchPayerOrgInfo(data)
)

export const deletePayerOrgInfo = createCustomAsyncThunk(
    "entrantForm/payerOrgInfo/delete",
    async data => await entrantFormService.deletePayerOrgInfo(data)
)

const payerInitialValues = {
    id: null,
    surname: "",
    name: "",
    patronymic: "",
    passport_series: "",
    passport_number: "",
    passport_issue_dep: "",
    passport_issue_date: getCurrentISODate(),
    passport_issue_dep_code: "",
    address: null,
    email: "",
    phone: "",
    doc_type: parseInt(window._env_?.DEFAULT_PASSPORT) ?? null,
    birthday: getCurrentISODate(),
    birth_place: "",
    gender: 1,
    inn: "",
    snils_number: "",
    user: null,
    citizen: parseInt(window._env_?.DEFAULT_CITIZENSHIP) ?? null,
    is_mat_capital: false,
    can_edit_and_delete: true,
}

const payerOrgInitialValues = {
    id: null,
    organization: {
        name: "",
        sname: "",
        ogrn: "",
        kpp: "",
        inn: "",
        address: "",
        phone: "",
        email: "",
        head_name: "",
    },
    can_edit_and_delete: true,
}

const payerInfoSlice = createSlice({
    name: "entrantForm/payerInfo",
    initialState: {
        payer: payerInitialValues,
        payerIsLoading: false,

        payerOrg: payerOrgInitialValues,
        payerOrgIsLoading: false,

        payerType: "person",

        error: null,
    },
    reducers: {
        setPayerType: (state, action) => { state.payerType = action.payload }
    },
    extraReducers: builder => {
        builder
            .addCase(getPayerInfo.pending, state => { state.payerIsLoading = true })
            .addCase(getPayerInfo.fulfilled, (state, action) => {
                state.payerIsLoading = false
                state.payer = action.payload.length > 0 ? action.payload[0] : payerInitialValues
            })
            .addCase(getPayerInfo.rejected, (state, action) => {
                state.payerIsLoading = false
                state.error = action.error
            })

            .addCase(createPayerInfo.pending, state => { state.payerIsLoading = true })
            .addCase(createPayerInfo.fulfilled, (state, action) => {
                state.payerIsLoading = false
                state.payer = action.payload
            })
            .addCase(createPayerInfo.rejected, (state, action) => {
                state.payerIsLoading = false
                state.error = action.error
            })

            .addCase(updatePayerInfo.pending, state => { state.payerIsLoading = true })
            .addCase(updatePayerInfo.fulfilled, (state, action) => {
                state.payerIsLoading = false
                state.payer = action.payload
            })
            .addCase(updatePayerInfo.rejected, (state, action) => {
                state.payerIsLoading = false
                state.error = action.error
            })

            .addCase(deletePayerInfo.pending, state => { state.payerIsLoading = true })
            .addCase(deletePayerInfo.fulfilled, (state, action) => {
                state.payerIsLoading = false
                state.payer = payerInitialValues
            })
            .addCase(deletePayerInfo.rejected, (state, action) => {
                state.payerIsLoading = false
                state.error = action.error
            })

            .addCase(getPayerOrgInfo.pending, state => { state.payerOrgIsLoading = true })
            .addCase(getPayerOrgInfo.fulfilled, (state, action) => {
                state.payerOrgIsLoading = false
                state.payerOrg = action.payload.length > 0 ? action.payload[0] : payerOrgInitialValues
            })
            .addCase(getPayerOrgInfo.rejected, (state, action) => {
                state.payerOrgIsLoading = false
                state.error = action.error
            })

            .addCase(createPayerOrgInfo.pending, state => { state.payerOrgIsLoading = true })
            .addCase(createPayerOrgInfo.fulfilled, (state, action) => {
                state.payerOrgIsLoading = false
                state.payerOrg = action.payload
            })
            .addCase(createPayerOrgInfo.rejected, (state, action) => {
                state.payerOrgIsLoading = false
                state.error = action.error
            })

            .addCase(updatePayerOrgInfo.pending, state => { state.payerOrgIsLoading = true })
            .addCase(updatePayerOrgInfo.fulfilled, (state, action) => {
                state.payerOrgIsLoading = false
                state.payerOrg = action.payload
            })
            .addCase(updatePayerOrgInfo.rejected, (state, action) => {
                state.payerOrgIsLoading = false
                state.error = action.error
            })


            .addCase(deletePayerOrgInfo.pending, state => { state.payerOrgIsLoading = true })
            .addCase(deletePayerOrgInfo.fulfilled, state => {
                state.payerOrgIsLoading = false
                state.payerOrg = payerOrgInitialValues
            })
            .addCase(deletePayerOrgInfo.rejected, (state, action) => {
                state.payerOrgIsLoading = false
                state.error = action.error
            })
    }
})

export const { setPayerType } = payerInfoSlice.actions

export default payerInfoSlice.reducer