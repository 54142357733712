import React from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { getEntrant, getEntrantAllInfo } from "../../redux/entrantAllInfoSlice"


const useEntrantAllInfoData = (userId) => {

    const dispatch = useDispatch()

    const { open, data, user, app, userIsLoading, dataIsLoading } = useSelector(x => x.entrantAllInfo)

    const isLoading = userIsLoading || dataIsLoading

    React.useEffect(() => {
        if (userId && Number(userId) !== user?.id) dispatch(getEntrant(userId))
    }, [dispatch, user, userId])

    React.useEffect(() => {
        if (user?.id) { dispatch(getEntrantAllInfo(user.id)) }
    }, [dispatch, user])

    return { user, app, open, data, isLoading }
}

export default useEntrantAllInfoData