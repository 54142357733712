import { combineReducers } from "@reduxjs/toolkit";
import achievementTypesReducer from "./achievementTypesSlice";
import citizenshipsReducer from "./citizenshipsSlice";
import eduDocTypesReducer from "./eduDocTypesSlice";
import languagesReducer from "./languagesSlice";
import levelsReducer from "./levelsSlice";
import oksoSpecsReducer from "./oksoSpecsSlice";
import personalDocTypesReducer from "./personalDocTypesSlice";
import prevEduInstitutionsReducer from "./prevEduInstitutionsSlice";
import subjectsReducer from "./subjectsSlice";
import specsReducer from "./specsSlice";
import exemptionDocsReducer from "./exemptionDocsSlice";
import exemptionDocCategoriesReducer from "./exemptionDocCategoriesSlice";
import regionsReducer from "./regionsSlice";
import departmentsReducer from "./departmentsSlice";
import universitiesReducer from "./universitiesSlice";
import cgSubspecReducer from "./cgSubspecsSlice";
import cgSpecsReducer from "./cgSpecsSlice";
import specialConditionsReducer from "./specialConditionsSlice";
import noticeTypesReducer from "./noticeTypesSlice";
import subspecsReducer from "./subspecsSlice";


const dictsReducer = combineReducers({
    achievementTypes: achievementTypesReducer,
    citizenships: citizenshipsReducer,
    regions: regionsReducer,
    cgSpecs: cgSpecsReducer,
    cgSubspecs: cgSubspecReducer,
    departments: departmentsReducer,
    // edu forms
    // edu types
    eduDocTypes: eduDocTypesReducer,
    exemptionDocs: exemptionDocsReducer,
    exemptionDocCategories: exemptionDocCategoriesReducer,
    languages: languagesReducer,
    levels: levelsReducer,
    noticeTypes: noticeTypesReducer,
    oksoSpecs: oksoSpecsReducer,
    personalDocTypes: personalDocTypesReducer,
    prevEduInstitutions: prevEduInstitutionsReducer,
    specs: specsReducer,
    subspecs: subspecsReducer,
    subjects: subjectsReducer,
    universities: universitiesReducer,
    specialConditions: specialConditionsReducer,
})

export default dictsReducer