import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import routes from '.'
import EntrantLayout from '../layouts/EntrantLayout'
import OperatorLayout from '../layouts/OperatorLayout'
import Spinner from '../Spinner'


const PrivateRoute = ({ operatorRoleNeeded = false, isCommon = false, children, requiredRoles }) => {

    const { userInfo, isLoading, isAuthenticated } = useSelector(x => x.user)

    const PermittedLayout = React.useMemo(() => userInfo?.is_operator ? OperatorLayout : EntrantLayout, [userInfo])

    if (isLoading) {
        return <Spinner height="100vh" />
    }

    if (!isLoading && !isAuthenticated) {
        return <Navigate to={routes.login.path} />
    }

    const userHasRequiredRole = userInfo && userInfo.is_operator === operatorRoleNeeded

    if (requiredRoles) {
        const hasAccess = Boolean(userInfo?.accesses?.find(el => requiredRoles.includes(el.role.id)))
        if (!hasAccess) return <Navigate to={routes.profile.path} />
    }

    if (isAuthenticated && !userHasRequiredRole && !isCommon) {
        return <Navigate to={routes.profile.path} />
    }

    return <PermittedLayout>
        {children}
    </PermittedLayout>

}

export default PrivateRoute