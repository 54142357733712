import { useDispatch } from "react-redux"
import routes from "../../components/routes"
import toastWrapper from "../../components/toastWrapper"
import { dialogModes, dialogTypes } from "../../constants"
import { setDisplayedUserInfo } from "../../redux/userSlice"
import { entrantFormService } from "../../services/entrantService"
import { operatorService } from "../../services/operatorService"

const userInitialValues = {
    id: null,
    surname: "",
    name: "",
    patronymic: "",
    phone: "",
    email: "",
}

const useUserListActionHandlers = (tableRef, userInfo, onMenuClick, onMenuClose, onOpenDialog, onCloseDialog, onOpenApps, onCloseApps) => {

    const dispatch = useDispatch()

    const handleAction = async (f, data, successMessage, errorMessage, onAfterAction = null) => f(data).then(() => {
        toastWrapper(successMessage, "success")
        tableRef.current.onQueryChange()
        if (onAfterAction) { onAfterAction() }
    }).catch(e => {
        console.error(e)
        toastWrapper(errorMessage, "error")
        tableRef.current.onQueryChange()
    })


    const handleOpenUserMenu = (e, row) => {
        onMenuClick(e)
        dispatch(setDisplayedUserInfo(row))
    }

    const handleCloseUserMenu = () => {
        onMenuClose()
        dispatch(setDisplayedUserInfo(userInfo))
    }

    const handleCreateUserIntent = () => {
        onOpenDialog({
            dialogType: dialogTypes.USER_FORM,
            dialogMode: dialogModes.CREATE,
            dialogData: userInitialValues
        })
    }

    const handleCreateUser = values => handleAction(
        operatorService.createUser,
        values,
        "Пользователь добавлен",
        "Не удалось добавить пользователя",
        handleCloseUserForm
    )

    const handleUpdateUser = values => handleAction(
        operatorService.updateUser,
        values,
        "Информация о пользователе обновлена",
        "Не удалось обновить информацию о пользователе",
        handleCloseUserForm
    )

    const handleCloseUserForm = () => {
        onCloseDialog()
        handleCloseUserMenu()
    }

    const handleToggleOriginal = () => handleAction(
        entrantFormService.postPrevEduOriginal,
        { type: userInfo.has_original ? "not_submitted" : "real", user: userInfo.id },
        userInfo.has_original ? "Оригинал отозван" : "Оригинал подан",
        userInfo.has_original ? "Не удалось отозвать оригинал" : "Не удалось подать оригинал",
    ).then(() => { dispatch(setDisplayedUserInfo({ ...userInfo, has_original: !userInfo.has_original })) })

    const handleNavigateToAllInfo = () => {
        window.open(routes.entrantAllInfo.makePath(userInfo.id), "_blank")
    }

    const handleNavigateToApplications = () => {
        window.open(routes.entrantApplicationsOperator.makePath(userInfo.id), "_blank")
    }

    const handleNavigateToForm = () => {
        window.open(routes.entrantFormOperator.makePath(userInfo.id), "_blank")
    }

    const handleUpdateUserIntent = () => {
        onOpenDialog({
            dialogType: dialogTypes.USER_FORM,
            dialogMode: dialogModes.UPDATE,
            dialogData: userInfo
        })
    }

    const handleNavigateToUnprocessedDocs = () => {
        window.open(routes.entrantUnprocessedDocs.makePath(userInfo.id), "_blank")
    }

    const actionHandlersMap = {
        download_voucher: () => undefined, // Эту кнопку рендерю отдельно
        toggle_original: handleToggleOriginal,
        accept_docs: handleNavigateToAllInfo,
        create_app: handleNavigateToApplications,
        edit_profile: handleNavigateToForm,
        edit_user: handleUpdateUserIntent,
        check_epgu_docs: handleNavigateToUnprocessedDocs,
    }

    const actionNamesMap = {
        download_voucher: "Скачать расписку",
        toggle_original: "Подать / отозвать оригинал",
        accept_docs: "Проверить документы",
        create_app: "Заявления",
        edit_profile: "Анкета",
        edit_user: "Редактировать информацию о пользователе",
        check_epgu_docs: "Документы из ЕПГУ"
    }

    return {
        handleOpenUserMenu, handleCloseUserMenu,
        handleCreateUserIntent, handleCreateUser, handleUpdateUser,
        handleCloseUserForm,
        actionHandlersMap, actionNamesMap,
    }
}

export default useUserListActionHandlers