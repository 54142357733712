import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useFormik } from "formik"
import * as yup from "yup"

const CopyScorecardDialog = ({ open, onClose, data, onSave, subjectOptions, subjectLookups, subjectsIsLoading }) => {

    const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
        initialValues: data,
        enableReinitialize: true,
        onSubmit: onSave,
        validationSchema: yup.object({
            id: yup.number().required(),
            subject: yup.number().required(),
        })
    })

    if (!values || !open) return null

    return <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Копирование ведомости {subjectLookups[data.subject]} от {new Date(data.test_date).toLocaleDateString()}</DialogTitle>
        <DialogContent>
            <Autocomplete
                options={subjectOptions}
                value={values.subject}
                onChange={(_, value) => setFieldValue("subject", value)}
                getOptionLabel={option => subjectLookups[option] ?? ""}
                loading={subjectsIsLoading}
                renderInput={props => <TextField
                    label="Предмет"
                    error={touched.subject && Boolean(errors.subject)}
                    {...props}
                />}
            />
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={handleSubmit}>
                Сохранить
            </Button>
            <Button variant="contained" color="error" onClick={onClose}>
                Закрыть
            </Button>
        </DialogActions>
    </Dialog>
}

export default CopyScorecardDialog