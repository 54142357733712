const PngFileIcon = () => {
    return <svg width="72" height="80" viewBox="0 0 72 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.75" y="0.75" width="70.5" height="78.5" rx="3.25" fill="white" />
        <g opacity="0.32">
            <path d="M44.985 12H15.963C15.155 12 14.5 12.655 14.5 13.926V67C14.5 67.345 15.155 68 15.963 68H56.037C56.845 68 57.5 67.345 57.5 67V24.978C57.5 24.282 57.407 24.058 57.243 23.893L45.607 12.257C45.442 12.093 45.218 12 44.985 12Z" fill="#E9E9E0" />
            <path d="M56.037 68H15.963C15.155 68 14.5 67.345 14.5 66.537V51H57.5V66.537C57.5 67.345 56.845 68 56.037 68Z" fill="#659C35" />
            <path d="M45.5 12.1509V23.9999H57.349L45.5 12.1509Z" fill="#D9D7CA" />
            <path d="M25.3851 64.9998H23.7441V54.9238H26.6421C27.0701 54.9238 27.4941 54.9918 27.9131 55.1288C28.3321 55.2658 28.7081 55.4708 29.0411 55.7438C29.3741 56.0168 29.6431 56.3478 29.8481 56.7348C30.0531 57.1218 30.1561 57.5568 30.1561 58.0408C30.1561 58.5518 30.0691 59.0138 29.8961 59.4288C29.7231 59.8438 29.4811 60.1928 29.1711 60.4748C28.8611 60.7568 28.4871 60.9758 28.0501 61.1308C27.6131 61.2858 27.1291 61.3628 26.6011 61.3628H25.3841L25.3851 64.9998ZM25.3851 56.1678V60.1598H26.8891C27.0891 60.1598 27.2871 60.1258 27.4841 60.0568C27.6801 59.9888 27.8601 59.8768 28.0241 59.7218C28.1881 59.5668 28.3201 59.3508 28.4201 59.0728C28.5201 58.7948 28.5701 58.4508 28.5701 58.0408C28.5701 57.8768 28.5471 57.6868 28.5021 57.4738C28.4561 57.2598 28.3631 57.0548 28.2221 56.8588C28.0801 56.6628 27.8821 56.4988 27.6271 56.3668C27.3721 56.2348 27.0341 56.1688 26.6151 56.1688L25.3851 56.1678Z" fill="white" />
            <path d="M39.3163 54.9238V64.9998H37.6483L33.6973 58.0548V64.9998H32.0293V54.9238H33.6973L37.6483 61.8688V54.9238H39.3163Z" fill="white" />
            <path d="M49.1606 59.8048V63.7008C48.9506 63.9658 48.7166 64.1808 48.4566 64.3498C48.1966 64.5188 47.9236 64.6578 47.6366 64.7668C47.3496 64.8758 47.0526 64.9538 46.7476 64.9998C46.4416 65.0458 46.1396 65.0678 45.8386 65.0678C45.2366 65.0678 44.6836 64.9588 44.1776 64.7398C43.6716 64.5208 43.2296 64.1978 42.8516 63.7688C42.4736 63.3398 42.1766 62.8028 41.9626 62.1558C41.7486 61.5088 41.6416 60.7608 41.6416 59.9138C41.6416 59.0668 41.7486 58.3208 41.9626 57.6788C42.1766 57.0358 42.4726 56.5008 42.8516 56.0728C43.2296 55.6438 43.6736 55.3188 44.1846 55.0948C44.6946 54.8708 45.2466 54.7598 45.8386 54.7598C46.3856 54.7598 46.8956 54.8508 47.3696 55.0328C47.8436 55.2158 48.2666 55.4888 48.6406 55.8528L47.5056 56.8648C47.2866 56.5998 47.0356 56.4088 46.7536 56.2908C46.4706 56.1728 46.1796 56.1128 45.8786 56.1128C45.5416 56.1128 45.2196 56.1758 44.9146 56.3038C44.6086 56.4318 44.3356 56.6478 44.0946 56.9528C43.8526 57.2588 43.6636 57.6518 43.5276 58.1358C43.3916 58.6198 43.3176 59.2108 43.3086 59.9128C43.3176 60.5968 43.3886 61.1888 43.5206 61.6898C43.6526 62.1908 43.8346 62.6008 44.0676 62.9198C44.3006 63.2388 44.5646 63.4758 44.8606 63.6308C45.1566 63.7858 45.4686 63.8628 45.7976 63.8628C45.8976 63.8628 46.0316 63.8558 46.2006 63.8418C46.3686 63.8278 46.5376 63.8058 46.7066 63.7738C46.8746 63.7418 47.0366 63.6988 47.1916 63.6438C47.3466 63.5888 47.4606 63.5118 47.5336 63.4118V60.9238H45.8246V59.8028H49.1606V59.8048Z" fill="white" />
            <path d="M26.9313 30.9998C29.4547 30.9998 31.5003 28.9542 31.5003 26.4308C31.5003 23.9074 29.4547 21.8618 26.9313 21.8618C24.4079 21.8618 22.3623 23.9074 22.3623 26.4308C22.3623 28.9542 24.4079 30.9998 26.9313 30.9998Z" fill="#F3D55B" />
            <path d="M14.5 51H25.5H57.5V40L47.5 30.5L37 42L31.517 36.517L14.5 51Z" fill="#88C057" />
        </g>
        <rect x="0.75" y="0.75" width="70.5" height="78.5" rx="3.25" stroke="#122D52" strokeWidth="1.5" strokeDasharray="8 8" />
    </svg>

}

export default PngFileIcon
