import { createSlice } from "@reduxjs/toolkit";
import { entrantFormService } from "../../services/entrantService";
import { createCustomAsyncThunk } from "../utils";

export const getOptionsInfo = createCustomAsyncThunk(
    "entrantForm/optionsInfo/get",
    async userId => await entrantFormService.getOptions(userId)
)

export const createOptionsInfo = createCustomAsyncThunk(
    "entrantForm/optionsInfo/create",
    async data => await entrantFormService.postOptions(data)
)

export const updateOptionsInfo = createCustomAsyncThunk(
    "entrantForm/optionsInfo/update",
    async data => await entrantFormService.patchOptions(data)
)

const initialValues = {
    id: null,
    submit_docs_way: null,
    return_docs_way: null,
    special_conditions_entrance_test: null,
    is_need_hostel: false,
    levels: []
}

const personalInfoSlice = createSlice({
    name: "entrantForm/optionsInfo",
    initialState: {
        data: initialValues,
        isLoading: false,
        error: null,
    },
    extraReducers: builder => {
        builder
            .addCase(getOptionsInfo.pending, state => { state.isLoading = true })
            .addCase(getOptionsInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload.length > 0 ? action.payload[0] : initialValues
            })
            .addCase(getOptionsInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(createOptionsInfo.pending, state => { state.isLoading = true })
            .addCase(createOptionsInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(createOptionsInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(updateOptionsInfo.pending, state => { state.isLoading = true })
            .addCase(updateOptionsInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(updateOptionsInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
    }
})

export default personalInfoSlice.reducer