import { Autocomplete, Checkbox, FormControl, ListItemText, MenuItem, Select, TablePagination, TextField, Typography } from "@mui/material"
import React from "react"

export const TextFilter = ({ columnDef, onFilterChanged }) => {

    return <TextField
        inputProps={{ style: { fontSize: 12 } }}
        onChange={e => onFilterChanged(columnDef.tableData.id, e.target.value)}
        variant="standard"
        margin="none"
    />
}

const SelectFilter = ({ columnDef, onFilterChanged, options = [] }) => {

    const [value, setValue] = React.useState(null)

    const handleChange = (_, value) => {
        setValue(value)
        onFilterChanged(columnDef.tableData.id, value?.id ?? null)
    }

    return <Autocomplete
        size="small"
        options={options}
        value={value}
        getOptionLabel={option => option.name}
        renderOption={(props, option) => (
            <li key={option} {...props}>
                <Typography sx={{ fontSize: 12 }}>{option.name}</Typography>
            </li>
        )}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={handleChange}
        forcePopupIcon={false}
        renderInput={props => <TextField
            {...props}
            variant="standard"
            margin="none"
            sx={{ fontSize: 12 }}
            inputProps={{ ...props.inputProps, style: { fontSize: 12 } }}
        />}
    />
}

export const TimeRangeFilter = props => <SelectFilter
    options={[
        { id: 'today', name: 'Сегодня' },
        { id: 'yesterday', name: 'Вчера' },
        { id: 'week', name: 'За прошлую неделю' },
        { id: 'year', name: 'За прошлый месяц' },
    ]}
    {...props}
/>

export const PriorityFilter = props => <SelectFilter
    options={[
        { id: 'max', name: 'Высший приоритет' },
        { id: 'other', name: 'Прочие' },
    ]}
    {...props}
/>

export const LookupFilter = ({ columnDef, onFilterChanged }) => {

    // ВАЖНО! Надо приводить всё к строкам

    React.useEffect(() => {
        if (columnDef.tableData.filterValue) {
            setValues(columnDef.tableData.filterValue)
        }
    }, [columnDef.tableData.filterValue])

    const [values, setValues] = React.useState(columnDef.defaultFilter ?? [])

    const handleChange = e => {
        setValues(e.target.value)
        onFilterChanged(columnDef.tableData.id, e.target.value)
    }

    const options = Object.keys(columnDef.lookup).map(key => key)

    return <FormControl sx={{ m: 0, p: 0, width: '100%', mt: '4px' }}>
        <Select
            multiple
            value={values}
            onChange={handleChange}
            variant="standard"
            renderValue={values => values.map(el => columnDef.lookup[el]).join(', ')}
            sx={{ fontSize: 12 }}
            multiline
            maxRows={2}
        >
            {options.map(el => (
                <MenuItem key={el} value={el} sx={{ m: 0, p: 0, px: 0.5, }}>
                    <Checkbox size="small" checked={values.includes(el)} />
                    <ListItemText primaryTypographyProps={{ fontSize: 14 }} primary={columnDef.lookup[el]} />
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}

// Большой привет разрабам @material-table/core 
// Эти ящеры зачем-то убрали labelDisplayedRows

export const CustomPagination = props =>
    <TablePagination
        {...props}
        sx={{
            ...props.sx,
            "& .MuiTablePagination-displayedRows": undefined
        }}
        labelDisplayedRows={({ from, to, count }) => `${from}–${to} из ${count !== -1 ? count : `более чем ${to}`}`}
    />