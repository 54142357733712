import { createSlice } from "@reduxjs/toolkit";
import { dictService } from "../../services/dictService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getCgSubspecs = createCustomAsyncThunk(
    "dicts/cgSubspecs/get",
    async params => await dictService.getCGSubspecs(params)
)

const cgSubspecsSlice = createSlice({
    name: "dicts/cgSubspecs",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getCgSubspecs.pending, state => { state.isLoading = true })
            .addCase(getCgSubspecs.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getCgSubspecs.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const cgSubspecSelectors = defaultEntityAdapter.getSelectors(x => x.dicts.cgSubspecs)

export default cgSubspecsSlice.reducer