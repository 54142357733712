import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk } from "./utils";
import { operatorService } from "../services/operatorService";

const initialAchievementsState = {
    added: [],
    not_added: [],
}

export const getCurrentCGForAchievements = createCustomAsyncThunk(
    "competitiveGroup/achievement/getCurrentCG",
    async id => await operatorService.getCompetitiveGroup(id)
)

export const getCGAchievements = createCustomAsyncThunk(
    "competitiveGroup/achievement/get",
    async id => await operatorService.getCompetitiveGroupAchievements(id)
)

export const addCGAchievement = createCustomAsyncThunk(
    "competitiveGroup/achievement/add",
    async data => await operatorService.addCompetitiveGroupAchievement(data)
)

export const deleteCGAchievement = createCustomAsyncThunk(
    "competitiveGroup/achievement/delete",
    async data => await operatorService.deleteCompetitiveGroupAchievement(data)
)

const competitiveGroupAchievementsSlice = createSlice({
    name: "competitiveGroup/achievement",
    initialState: {
        current: null,
        currentIsLoading: false,
        achievements: initialAchievementsState,
        achievementsIsLoading: false,
        error: null,
    },
    extraReducers: builder => {
        builder
            .addCase(getCurrentCGForAchievements.pending, state => {
                state.currentIsLoading = true
            })
            .addCase(getCurrentCGForAchievements.fulfilled, (state, action) => {
                state.current = action.payload
                state.currentIsLoading = false
            })
            .addCase(getCurrentCGForAchievements.rejected, (state, action) => {
                state.error = action.error
                state.currentIsLoading = false
            })

            .addCase(getCGAchievements.pending, state => {
                state.achievementsIsLoading = true
            })
            .addCase(getCGAchievements.fulfilled, (state, action) => {
                state.achievements = action.payload
                state.achievementsIsLoading = false
            })
            .addCase(getCGAchievements.rejected, (state, action) => {
                state.error = action.error
                state.achievementsIsLoading = false
            })

            .addCase(addCGAchievement.fulfilled, (state, action) => {
                const achievement = state.achievements.not_added.find(el => el.id === action.payload)
                state.achievements = {
                    not_added: state.achievements.not_added.filter(el => el.id !== achievement.id),
                    added: [...state.achievements.added, achievement]
                }
            })

            .addCase(deleteCGAchievement.fulfilled, (state, action) => {
                const achievement = state.achievements.added.find(el => el.id === action.payload)
                state.achievements = {
                    added: state.achievements.added.filter(el => el.id !== achievement.id),
                    not_added: [...state.achievements.not_added, achievement]
                }
            })
    }
})

export default competitiveGroupAchievementsSlice.reducer