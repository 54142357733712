
import React from 'react'
import { Flags } from "react-feature-flags"
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import routes from '.'
import { operatorRoles } from '../../constants'
import AppUnavailable from '../../pages/AppUnavailable'
import CompetitiveGroupListPage from '../../pages/CompetitiveGroupList'
import EntranceTestRegistrations from '../../pages/EntranceTestRegistrations'
import EntrantAllInfoPage from '../../pages/EntrantAllInfoPage'
import CompetitiveGroupAchievements from '../../pages/EntrantAppItemAchievements'
import EntrantApplicationsPage from '../../pages/EntrantApplications'
import EntrantApplicationsOperatorWrapper from '../../pages/EntrantApplications/EntrantApplicationsOrepatorWrapper'
import EntrantFormPage from "../../pages/EntrantForm"
import EntrantFormOperatorWrapper from '../../pages/EntrantForm/EntrantFormOrepatorWrapper'
import EntrantSurveyPage from '../../pages/EntrantSurvey'
import EntrantUnprocessedDocs from '../../pages/EntrantUnprocessedDocs'
import FeedbackPage from '../../pages/Feedback'
import LoginPage from '../../pages/Login'
import OperatorOtherServicesPage from '../../pages/OperatorOtherServices'
import OrdersPage from '../../pages/Orders'
import OrderItems from '../../pages/Orders/OrderItems'
import PageUnavailable from '../../pages/PageUnavailable'
import ProfilePage from '../../pages/Profile'
import RatingsPage from '../../pages/Ratings'
import RatingCompetitiveGroupSelect from '../../pages/Ratings/RatingCompetitiveGroupSelect'
import RatingList from '../../pages/Ratings/RatingList'
import StaticRatingList from '../../pages/Ratings/StaticRatingList'
import RegistrationPage from '../../pages/Registration'
import ReportsPage from '../../pages/Reports'
import ApplicationStatusReport from '../../pages/Reports/ApplicationStatusReport'
import DepartmentInfoReport from '../../pages/Reports/DepartmentInfoReport'
import RestorePasswordPage from '../../pages/RestorePassword'
import SatExamsPage from '../../pages/SatExams/Index'
import ScorecardsPage from '../../pages/Scorecards'
import ScorecardResults from '../../pages/Scorecards/ScorecardResults'
import UserListPage from '../../pages/UserList'
import { getUserInfo } from '../../redux/userSlice'
import MainLayout from '../layouts/MainLayout'
import PrivateRoute from './PrivateRoute'
import AverageSatExamsPointsReport from '../../pages/Reports/AverageSatExamsPointsReport'
import ContingentReport from '../../pages/Reports/ContingentReport'
import AverageSatExamsInKCPPointsReport from '../../pages/Reports/AverageSatExamsInKCPPointsReport'
import RegionReport from '../../pages/Reports/RegionReport'
import CountryReport from '../../pages/Reports/CountryReport'
import ProtocolReport from '../../pages/Reports/ProtocolReport'


const RouteList = () => {

    const { userInfo, isLoading, isAuthenticated } = useSelector(x => x.user)

    const isUserLoggedIn = userInfo && !isLoading && isAuthenticated

    const dispatch = useDispatch()

    React.useEffect(() => { dispatch(getUserInfo()) }, [dispatch])

    return <Flags authorizedFlags={['temporaryUnavailable']}
        renderOn={() => <AppUnavailable />}
        renderOff={() => <BrowserRouter>
            <Routes>

                <Route path="*" element={<Navigate to={isUserLoggedIn ? routes.profile.path : routes.login.path} />} />

                <Route path={routes.login.path} element={isUserLoggedIn ? (
                    <Navigate to={routes.profile.path} />
                ) : (
                    <MainLayout>
                        <LoginPage />
                    </MainLayout>
                )} />

                <Route path={routes.register.path} element={isUserLoggedIn ? (
                    <Navigate to={routes.profile.path} />
                ) : (
                    <MainLayout>
                        <RegistrationPage />
                    </MainLayout>
                )} />

                <Route path={routes.restorePassword.path} element={<MainLayout><RestorePasswordPage /></MainLayout>} />

                <Route path={routes.profile.path} element={<PrivateRoute isCommon>
                    <ProfilePage />
                </PrivateRoute>} />

                {/* Абитуриент */}
                <Route path={routes.form.path} element={<PrivateRoute>
                    <Flags authorizedFlags={["entrantFormEnabled"]}
                        renderOn={() => <EntrantFormPage />}
                        renderOff={() => <PageUnavailable />}
                    />
                </PrivateRoute>
                } />

                <Route path={routes.entrantApplications.path} element={<PrivateRoute>
                    <Flags authorizedFlags={["entrantApplicationsEnabled"]}
                        renderOn={() => <EntrantApplicationsPage />}
                        renderOff={() => <PageUnavailable />}
                    />
                </PrivateRoute>
                } />


                <Route path={routes.entrantSurvey.path} element={<PrivateRoute>
                    <Flags authorizedFlags={["entrantSurveyEnabled"]}
                        renderOn={() => <EntrantSurveyPage />}
                        renderOff={() => <PageUnavailable />}
                    />
                </PrivateRoute>
                } />

                <Route path={routes.entrantFeedback.path} element={<PrivateRoute>
                    <FeedbackPage isOperator={false} />
                </PrivateRoute>
                } />

                {/* Оператор */}

                <Route path={routes.userList.path} element={<PrivateRoute operatorRoleNeeded>
                    <UserListPage />
                </PrivateRoute>} />

                <Route path={routes.competitiveGroupList.path} element={<PrivateRoute operatorRoleNeeded >
                    <CompetitiveGroupListPage />
                </PrivateRoute>} />

                <Route path={routes.entrantAllInfo.path} element={<PrivateRoute operatorRoleNeeded >
                    <EntrantAllInfoPage />
                </PrivateRoute>} />

                <Route path={routes.entrantUnprocessedDocs.path} element={<PrivateRoute operatorRoleNeeded >
                    <EntrantUnprocessedDocs />
                </PrivateRoute>} />

                <Route path={routes.competitiveGroupAchievements.path} element={<PrivateRoute operatorRoleNeeded >
                    <CompetitiveGroupAchievements />
                </PrivateRoute>} />

                <Route path={routes.entrantApplicationsOperator.path} element={<PrivateRoute operatorRoleNeeded >
                    <EntrantApplicationsOperatorWrapper />
                </PrivateRoute>} />

                <Route path={routes.entrantFormOperator.path} element={<PrivateRoute operatorRoleNeeded >
                    <EntrantFormOperatorWrapper />
                </PrivateRoute>} />

                <Route path={routes.operatorOtherServices.path} element={<PrivateRoute operatorRoleNeeded >
                    <OperatorOtherServicesPage />
                </PrivateRoute>} />

                <Route path={routes.operatorFeedback.path} element={<PrivateRoute operatorRoleNeeded >
                    <FeedbackPage isOperator />
                </PrivateRoute>} />

                <Route path={routes.satExams.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.CHECK_SAT_EXAM]}
                >
                    <SatExamsPage />
                </PrivateRoute>} />

                {/* Экз. ведомости, ВИ */}

                <Route path={routes.scorecards.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.DOC_EDITOR]}
                >
                    <ScorecardsPage />
                </PrivateRoute>} />

                <Route path={routes.scorecardResults.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.DOC_EDITOR]}
                >
                    <ScorecardResults />
                </PrivateRoute>} />

                <Route path={routes.entranceTestRegistrations.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.COMMISSION_MEMBER, operatorRoles.ADMIN]}
                >
                    <EntranceTestRegistrations />
                </PrivateRoute>} />

                {/* Отчеты */}

                <Route path={routes.reports.path} element={<PrivateRoute operatorRoleNeeded>
                    <ReportsPage />
                </PrivateRoute>} />

                <Route path={routes.departmentInfoReport.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.COMMISSION_MEMBER, operatorRoles.ADMIN]}
                >
                    <DepartmentInfoReport />
                </PrivateRoute>} />

                <Route path={routes.applicationsStatusReport.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.COMMISSION_MEMBER, operatorRoles.ADMIN]}
                >
                    <ApplicationStatusReport />
                </PrivateRoute>} />

                <Route path={routes.averageSatExamPointsReport.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.COMMISSION_MEMBER, operatorRoles.ADMIN]}
                >
                    <AverageSatExamsPointsReport />
                </PrivateRoute>} />
                <Route path={routes.averageSatExamPointsInKCPReport.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.COMMISSION_MEMBER, operatorRoles.ADMIN]}
                >
                    <AverageSatExamsInKCPPointsReport />
                </PrivateRoute>} />
                <Route path={routes.contingentReport.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.COMMISSION_MEMBER, operatorRoles.ADMIN]}
                >
                    <ContingentReport />
                </PrivateRoute>} />
                <Route path={routes.regionReport.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.COMMISSION_MEMBER, operatorRoles.ADMIN]}
                >
                    <RegionReport />
                </PrivateRoute>} />
                <Route path={routes.countryReport.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.COMMISSION_MEMBER, operatorRoles.ADMIN]}
                >
                    <CountryReport />
                </PrivateRoute>} />

                <Route path={routes.protocolReport.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.COMMISSION_MEMBER, operatorRoles.ADMIN]}
                >
                    <ProtocolReport />
                </PrivateRoute>} />
                {/* Рейтинг-листы */}

                <Route path={routes.ratings.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.ADMIN, operatorRoles.RATINGS]}
                >
                    <RatingsPage type="dynamic" />
                </PrivateRoute>} />

                <Route path={routes.dynamicRatingCGSelect.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.ADMIN, operatorRoles.RATINGS]}
                >
                    <RatingCompetitiveGroupSelect type="dynamic" />
                </PrivateRoute>} />

                <Route path={routes.dynamicRatingInfo.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.ADMIN, operatorRoles.RATINGS]}
                >
                    <RatingList type="dynamic" />
                </PrivateRoute>} />

                <Route path={routes.staticRatingList.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.ADMIN, operatorRoles.RATINGS]}
                >
                    <StaticRatingList />
                </PrivateRoute>} />

                <Route path={routes.staticRatingCGSelect.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.ADMIN, operatorRoles.RATINGS]}
                >
                    <RatingCompetitiveGroupSelect type="static" />
                </PrivateRoute>} />

                <Route path={routes.staticRatingInfo.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.ADMIN, operatorRoles.RATINGS]}
                >
                    <RatingList type="static" />
                </PrivateRoute>} />

                {/* Приказы */}

                <Route path={routes.orderList.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.ADMIN, operatorRoles.DOC_EDITOR]}
                >
                    <OrdersPage />
                </PrivateRoute>} />

                <Route path={routes.order.path} element={<PrivateRoute
                    operatorRoleNeeded
                    requiredRoles={[operatorRoles.ADMIN, operatorRoles.DOC_EDITOR]}
                >
                    <OrderItems />
                </PrivateRoute>} />

            </Routes>
        </BrowserRouter >}
    />
}

export default RouteList 