import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useFormik } from "formik"
import * as yup from "yup"
import { regionTypeLookups, regionTypeOptions, settlementTypeLookups, settlementTypeOptions } from "./constants"
import React from "react"
import useCitizenships from "../../hooks/useCitizenships"


const addressInitialValues = {
    value: "",
    unrestricted_value: "",
    data: {
        country: "РОССИЯ",

        region: "",
        region_type: null,
        region_type_full: null,

        city: "",

        settlement: "",
        settlement_type: null,
        settlement_type_full: null,

        street: "",
        house: "",
        flat: "",
        fias_id: null,
        fias_level: "1",
    }
}

const deleteEmptyStrings = object => {
    const result = {}
    Object.keys(object).forEach(key => {
        const value = typeof object[key] === "string" ? object[key].trim() : null
        value === "" ? result[key] = null : result[key] = value
    })
    return result
}

const getUnrestrictedValue = data => {
    let result = []
    if (data.country.toLowerCase() !== "россия") result.push(data.country)
    if (data.region) result.push(`${data.region_type} ${data.region}`)
    if (data.city) result.push(`г ${data.city}`)
    if (data.settlement) result.push(`${data.settlement_type} ${data.settlement}`)
    if (data.street) result.push(`ул ${data.street}`)
    if (data.house) result.push(`д ${data.house}`)
    if (data.flat) result.push(`кв ${data.house}`)
    return result.join(", ")
}

const getFiasLevel = data => {
    if (data.street) return "7"
    if (data.settlement || data.city) return "6"
    return 0
}

const AddressForm = ({ open, onClose, data, onSubmit }) => {

    const { citizenships } = useCitizenships()

    const countryOptions = React.useMemo(() => citizenships.map(el => el.sname), [citizenships])

    const { values, errors, touched, handleChange, setFieldValue, handleSubmit, } = useFormik({
        initialValues: addressInitialValues,
        enableReinitialize: true,
        onSubmit: values => {
            const clearValues = deleteEmptyStrings(values.data)
            const unrestrictedValue = getUnrestrictedValue(values.data)
            onSubmit({
                value: unrestrictedValue,
                unrestricted_value: unrestrictedValue,
                data: { ...clearValues, fias_level: getFiasLevel(clearValues) }
            })
            onClose()
        },
        // TODO какие поля необходимы
        validationSchema: yup.object({
            data: yup.object({
                country: yup.string().required(),
                street: yup.string().required(),
                house: yup.string().required(),
            })
        })
    })

    if (!open || !values) return null

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>Добавление адреса</DialogTitle>
        <DialogContent>

            <Autocomplete
                id="data.country"
                name="data.country"
                options={countryOptions}
                value={values.data.country}
                onChange={(_, value) => setFieldValue("data.country", value)}
                freeSolo
                renderInput={props => <TextField
                    label="Страна"
                    required
                    fullWidth
                    error={touched?.data?.country && Boolean(errors?.data?.country)}
                    {...props}
                />}
            />

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Autocomplete
                    id="data.region_type_full"
                    name="data.region_type_full"
                    value={values.data.region_type_full}
                    options={regionTypeOptions}
                    onChange={(_, value) => {
                        setFieldValue("data.region_type_full", value)
                        setFieldValue("data.region_type", regionTypeLookups[value])
                    }}
                    isOptionEqualToValue={(option, value) => option?.toLowerCase() === value?.toLowerCase()}
                    disableClearable
                    sx={{ width: "40%" }}
                    renderInput={props => <TextField
                        label="Тип региона"
                        {...props}
                    />}

                />
                <TextField
                    id="data.region"
                    name="data.region"
                    value={values.data.region}
                    onChange={handleChange}
                    label="Регион"
                    fullWidth
                    sx={{ width: "59%" }}
                />
            </Box>


            <TextField
                id="data.city"
                name="data.city"
                value={values.data.city ?? ""}
                onChange={handleChange}
                label="Город"
                fullWidth
            />

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Autocomplete
                    id="data.settlement_type_full"
                    name="data.settlement_type_full"
                    value={values.data.settlement_type_full}
                    options={settlementTypeOptions}
                    onChange={(_, value) => {
                        setFieldValue("data.settlement_type_full", value)
                        setFieldValue("data.settlement_type", settlementTypeLookups[value])
                    }}
                    isOptionEqualToValue={(option, value) => option?.toLowerCase() === value?.toLowerCase()}
                    disableClearable
                    sx={{ width: "40%" }}
                    renderInput={props => <TextField
                        label="Тип н. п."
                        {...props}
                    />}

                />
                <TextField
                    id="data.settlement"
                    name="data.settlement"
                    value={values.data.settlement ?? ""}
                    onChange={handleChange}
                    label="Населенный пункт"
                    fullWidth
                    sx={{ width: "59%" }}
                />
            </Box>

            <TextField
                id="data.street"
                name="data.street"
                value={values.data.street ?? ""}
                onChange={handleChange}
                label="Улица"
                required
                fullWidth
                error={touched?.data?.street && Boolean(errors?.data?.street)}
            />

            <TextField
                id="data.house"
                name="data.house"
                value={values.data.house ?? ""}
                onChange={handleChange}
                required
                label="Дом"
                fullWidth
                error={touched?.data?.house && Boolean(errors?.data?.house)}
            />

            <TextField
                id="data.flat"
                name="data.flat"
                value={values.data.flat ?? ""}
                onChange={handleChange}
                label="Квартира"
                fullWidth
            />

        </DialogContent>
        <DialogActions>
            <Button onClick={handleSubmit}>
                Сохранить
            </Button>
            <Button color="error" onClick={onClose}>
                Закрыть
            </Button>
        </DialogActions>
    </Dialog>
}

export default AddressForm