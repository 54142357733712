import { Add, Delete, Edit, Lock, RemoveRedEye } from "@mui/icons-material"
import { LookupFilter } from "../../../components/MaterialTableWrapper/components"
import { orderCategoryLookups } from "../../../constants"
import { ordersService } from "../../../services/operatorService"

const useOrderTableProps = (universityLookups, levelLookups, onCreate, onEdit, onDelete, onClose, onOpen, onNavigate) => {

    const title = "Приказы"

    const data = query => new Promise((resolve, reject) => {

        const params = {
            page_size: query.pageSize,
            page: query.page + 1,
            search: query.search,
        }

        query.filters.forEach(filter => {
            // Если boolean
            if (filter.column.type === "boolean") {
                params[filter.column.field] = filter.value === "checked"
            } else {
                params[filter.column.field] = filter.value
            }
        })

        ordersService.get(params).then(res => {
            resolve({
                data: res,
                page: query.page,
                totalCount: res.length,
            })
        }).catch(e => {
            console.error(e)
            reject("Произошла ошибка при загрузке списка приказов")
        })
    })

    const columns = [
        { field: "number", title: "Номер", filtering: false, width: 150 },
        { field: "university", title: "Университет", lookup: universityLookups, filterComponent: LookupFilter, width: 200 },
        { field: "level", title: "Уровень образования", lookup: levelLookups, filterComponent: LookupFilter, width: 150 },
        { field: "is_open", title: "Открыт", type: "boolean", width: 80 },
        { field: "category", title: "Категория", lookup: orderCategoryLookups, filtering: false, width: 150 },
        { field: "date", title: "Дата", type: "date", filtering: false, width: 100 },
        { field: "comment", title: "Комментарий", filtering: false, width: 200 },
        { field: "modified_at", title: "Последнее изменение", type: "date", filtering: false, width: 150 },
    ]

    const actions = [
        {
            icon: Add,
            iconProps: { color: "secondary" },
            isFreeAction: true,
            tooltip: "Создать приказ",
            onClick: onCreate
        },
        {
            icon: RemoveRedEye,
            iconProps: { color: "secondary" },
            tooltip: "Просмотр приказа",
            onClick: onNavigate,
        },
        rowData => ({
            icon: Edit,
            iconProps: { color: rowData.is_open ? "secondary" : "disabled" },
            tooltip: rowData.is_open ? "Редактировать приказ" : "Приказ закрыт",
            onClick: onEdit,
            disabled: !rowData.is_open
        }),
        // rowData => ({
        //     icon: rowData.is_open ? Lock : LockOpen,
        //     iconProps: { color: rowData.is_open ? "error" : "secondary" },
        //     tooltip: rowData.is_open ? "Закрыть приказ" : "Открыть приказ",
        //     onClick: rowData.is_open ? onClose : onOpen,
        // }),
        rowData => ({
            icon: Lock,
            iconProps: { color: rowData.is_open ? "error" : "disabled" },
            tooltip: rowData.is_open ? "Закрыть приказ" : "",
            onClick: onClose,
            disabled: !rowData.is_open
        }),
        rowData => ({
            icon: Delete,
            iconProps: { color: rowData.is_open ? "error" : "disabled" },
            tooltip: "Удалить приказ",
            onClick: onDelete,
            disabled: !rowData.is_open
        }),
    ]

    const options = {
        pageSize: 50,
        pageSizeOptions: [10, 25, 50, 100],
        padding: "dense",
        maxBodyHeight: "calc(100vh - 117px)",
        minBodyHeight: "calc(100vh - 117px)",
        debounceInterval: 1000,
        headerStyle: { position: "sticky", top: 0, zIndex: 1 },
        maxColumnSort: 0,
        search: false,
        actionsColumnIndex: -1,
         tableLayout: "fixed",
        emptyRowsWhenPaging: false,
        filtering: true,
    }

    return {
        title,
        data,
        columns,
        actions,
        options,
    }
}

export default useOrderTableProps