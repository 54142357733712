import { Autocomplete, Box, Button, Checkbox, Divider, FormControlLabel, Link, Paper, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import * as yup from "yup"
import AddressSuggestions from "../../components/DadataSuggestions/AddressSuggestions"
import useLevels from "../../hooks/useLevels"
import usePrevEduInstitutions from "../../hooks/usePrevEduInstitutions"
import useSpecs from "../../hooks/useSpecs"
import { createEntrantSurvey, getEntrantSurvey, updateEntrantSurvey } from "../../redux/entrantSurveySlice"
import useSubjects from "../EntrantForm/hooks/useSubjects"


const EntrantSurveyPage = () => {

    const dispatch = useDispatch()

    const userInfo = useSelector(x => x.user.userInfo)

    const { data } = useSelector(x => x.entrantSurvey)

    const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
        initialValues: data,
        onSubmit: values => {
            const newValues = { ...values, user: userInfo.id }
            dispatch(values?.id ? updateEntrantSurvey(newValues) : createEntrantSurvey(newValues))
        },
        enableReinitialize: true,
        validationSchema:
            yup.object({
                address: yup.string().required(),
                current_edu_place: yup.string().required(),
                current_edu_course: yup.number(),
                is_training_courses: yup.bool(),
                levels: yup.array().of(yup.number()).min(1),
                specs: yup.array().of(yup.number()).min(1),
                subjects: yup.array().of(yup.number()).min(1)
            })
    })

    const { levelLookups, universityLevelOptions } = useLevels()

    const { subjectLookups, subjectOptions } = useSubjects()

    const { prevEduInstitutionOptions, prevEduInstitutionsIsLoading } = usePrevEduInstitutions()

    const { specLookups, specsIsLoading, specsByLevelOptions } = useSpecs(values.levels)

    React.useEffect(() => {
        if (userInfo?.id) dispatch(getEntrantSurvey(userInfo.id))
    }, [dispatch, userInfo])

    return <Box sx={{ display: "flex", flexDirection: "column", minWidth: 250, maxWidth: 600, margin: "auto" }}>

        <Box sx={{
            m: 1,
            p: 2,
            borderRadius: 1,
            display: "flex",
            backgroundColor: "primary.main",
        }}>
            <Typography sx={{ ml: 2 }} variant="font2" color="white">
                Анкета абитуриента
            </Typography>
        </Box>
        <Paper sx={{ m: 1, justifySelf: "center" }}>


            <Box sx={{ p: 2 }}>

                <AddressSuggestions
                    name="address"
                    type="address"
                    label="Населенный пункт, в котором Вы проживаете"
                    value={values.address}
                    onChange={value => { setFieldValue("address", value.value) }}
                    required
                    error={touched.address && Boolean(errors.address)}
                    canAdd={false}
                    size="normal"
                    margin="normal"
                />

                <Autocomplete
                    options={prevEduInstitutionOptions}
                    freeSolo
                    value={values.current_edu_place}
                    onChange={(_, value) => setFieldValue("current_edu_place", value)}
                    loading={prevEduInstitutionsIsLoading}
                    renderInput={props => <TextField
                        onChange={e => setFieldValue("current_edu_place", e.target.value)}
                        label="Где вы учитесь?"
                        required
                        error={touched.current_edu_place && Boolean(errors.current_edu_place)}
                        margin="normal"
                        {...props}
                        size="normal"
                    />}
                />

                <TextField
                    id="current_edu_course"
                    name="current_edu_course"
                    label="В каком классе / на каком курсе Вы учитесь?"
                    type="number"
                    inputProps={{ min: 1, max: 11 }}
                    value={values.current_edu_course}
                    onChange={handleChange}
                    required
                    fullWidth
                    error={touched.current_edu_course && Boolean(errors.current_edu_course)}
                    size="normal"
                    margin="normal"
                />

                <Autocomplete
                    options={universityLevelOptions}
                    getOptionLabel={option => levelLookups[option]?.name ?? ""}
                    value={values.levels}
                    onChange={(_, value) => {
                        setFieldValue("levels", value)
                        setFieldValue("specs", [])
                    }}
                    multiple
                    disableCloseOnSelect
                    renderInput={props => <TextField
                        label="Какие уровни образования Вас интересуют?"
                        error={touched.levels && Boolean(errors.levels)}
                        required
                        margin="normal"
                        {...props}
                        size="normal"
                    />}
                />

                <Autocomplete
                    options={specsByLevelOptions}
                    getOptionLabel={option => specLookups[option]?.name ?? ""}
                    value={values.specs}
                    onChange={(_, value) => setFieldValue("specs", value)}
                    multiple
                    disableCloseOnSelect
                    required
                    loading={specsIsLoading}
                    renderInput={props => <TextField
                        label="Какие направления подготовки Вас интересуют?"
                        required
                        error={touched.specs && Boolean(errors.specs)}
                        margin="normal"
                        {...props}
                        size="normal"
                    />}
                />

                <Autocomplete
                    options={subjectOptions}
                    getOptionLabel={option => subjectLookups[option]?.name ?? ""}
                    value={values.subjects}
                    onChange={(_, value) => setFieldValue("subjects", value)}
                    multiple
                    disableCloseOnSelect
                    renderInput={props => <TextField
                        label="Какие экзамены Вы планируете сдавать?"
                        required
                        error={touched.subjects && Boolean(errors.subjects)}
                        margin="normal"
                        {...props}
                        size="normal"
                    />}
                />

                <FormControlLabel
                    label="Мне нужны подготовительные курсы"
                    control={<Checkbox
                        checked={values.is_training_courses}
                        onChange={e => setFieldValue("is_training_courses", e.target.checked)}
                    />}
                />

                <Divider sx={{ mt: 1, mb: 1.5 }} />

                <Button fullWidth variant="contained" onClick={handleSubmit}>
                    {values?.id ? "Сохранить" : "Отправить"}
                </Button>

                {values.id && <>
                    <Divider sx={{ mt: 1.5, mb: 1.5 }} />
                    <Typography>
                        {"Спасибо за участие в опросе! Всю необходимую информацию о ходе приемной кампании в БГТУ им. В.Г. Шухова вы можете узнать "}
                        <Link href={process.env.PUBLIC_URL + "/files/Сведения_для_поступающих_в_БГТУ.pdf"}>здесь</Link>
                        {" и "}
                        <Link href={process.env.PUBLIC_URL + "/files/Справочник_абитуриента.pdf"}>здесь</Link>
                    </Typography>
                </>}

            </Box>
        </Paper>
    </Box>
}

export default EntrantSurveyPage