import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { cgSubspecSelectors, getCgSubspecs } from "../redux/dicts/cgSubspecsSlice"

const useCgSubspecs = (
    level,
    university,
    edu_form,
    is_budget,
    is_english_program,
    is_foreign_with_direction,
    quota_types,
    without_quota_types,
    spec
) => {

    const dispatch = useDispatch()

    React.useEffect(() => {

        if (level && university && edu_form && spec) {
            const params = {
                level, university, edu_form, spec,
                is_budget, is_english_program, is_foreign_with_direction,

            }
            if (!without_quota_types) { params.quota_types = quota_types }

            dispatch(getCgSubspecs(params))
        }
    }, [
        dispatch,
        level, university, edu_form, spec,
        is_budget, is_english_program, is_foreign_with_direction,
        quota_types, without_quota_types
    ])

    const subspecOptions = useSelector(cgSubspecSelectors.selectIds)
    const subspecLookups = useSelector(cgSubspecSelectors.selectEntities)
    const subspecsIsLoading = useSelector(x => x.dicts.cgSubspecs.isLoading)

    return { subspecOptions, subspecLookups, subspecsIsLoading }
}

export default useCgSubspecs