import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const toastWrapper = (msg, type = "error") => toast(
    msg,
    {
        position: toast.POSITION.TOP_LEFT,
        hideProgressBar: true,
        type: type,
    })


export default toastWrapper