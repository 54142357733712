import { useDispatch, useSelector } from "react-redux"
import { getSpecs, specSelectors } from "../redux/dicts/specsSlice"
import React from "react"


const useSpecs = (levels = []) => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(getSpecs())
    }, [dispatch])

    const specs = useSelector(specSelectors.selectAll)
    const specOptions = useSelector(specSelectors.selectIds)
    const specLookups = useSelector(specSelectors.selectEntities)
    const specsIsLoading = useSelector(x => x.dicts.specs.isLoading)
    const specsByLevelOptions = React.useMemo(() => {
        return levels.length > 0 ? specOptions.filter(spec => levels.includes(specLookups[spec]?.level)) : specOptions
    }, [levels, specOptions, specLookups])

    return { specs, specOptions, specLookups, specsIsLoading, specsByLevelOptions }
}

export default useSpecs