import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import React from "react"
import ReactDOM from "react-dom/client"
import { FlagsProvider } from "react-feature-flags"
import { Provider } from "react-redux"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import RouteList from "./components/routes/RouteList"
import { store } from "./redux/store"
import { injectStore } from "./services/api"
import theme from "./theme"
import flags from "./flags"
import CookiesNotification from "./components/CookiesNotification"

const root = ReactDOM.createRoot(document.getElementById("root"))

injectStore(store);

root.render(
	<Provider store={store}>
		<FlagsProvider value={flags}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<RouteList />
				<CookiesNotification />
				<ToastContainer />
			</ThemeProvider>
		</FlagsProvider>
	</Provider>,
)
