import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk } from "../utils";
import { entrantFormService } from "../../services/entrantService";

export const getDirectionEduMinistryInfo = createCustomAsyncThunk(
    "entrantForm/targetdirectionEduMinistryContract/get",
    async userId => await entrantFormService.getDirEduMin(userId)
)

export const createDirectionEduMinistryInfo = createCustomAsyncThunk(
    "entrantForm/directionEduMinistry/create",
    async data => await entrantFormService.postDirEduMin(data)
)

export const updateDirectionEduMinistryInfo = createCustomAsyncThunk(
    "entrantForm/directionEduMinistry/update",
    async data => await entrantFormService.patchDirEduMin(data)
)

const initialValues = {
    id: null,
    can_edit_and_delete: true,
    series: "",
    number: "",
    issue_date: "",
    issue_dep: "",
}

const directionEduMinistrySlice = createSlice({
    name: "entrantForm/directionEduMinistry",
    initialState: {
        data: initialValues,
        isLoading: true,
        error: null,
    },
    extraReducers: builder => {
        builder
            .addCase(getDirectionEduMinistryInfo, state => { state.isLoading = true })
            .addCase(getDirectionEduMinistryInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload.length > 0 ? action.payload[0] : initialValues
            })
            .addCase(getDirectionEduMinistryInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(createDirectionEduMinistryInfo.pending, state => { state.isLoading = true })
            .addCase(createDirectionEduMinistryInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(createDirectionEduMinistryInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(updateDirectionEduMinistryInfo.pending, state => { state.isLoading = true })
            .addCase(updateDirectionEduMinistryInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(updateDirectionEduMinistryInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
    }
})

export default directionEduMinistrySlice.reducer