import { Box, Button, TextField, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { getCurrentISODate } from "../../../../utils";
import useIsSmallScreen from "../../../../hooks/useIsSmallScreen";
import Spinner from "../../../../components/Spinner";

const OldPassportForm = ({ data, onSubmit, profileInfo, isLoading }) => {

    const { handleSubmit, handleChange, values, errors, touched } = useFormik({
        enableReinitialize: true,
        initialValues: data,
        onSubmit: onSubmit,
        validationSchema: yup.object({
            series: yup.string(),
            number: yup.string().required(),

            issue_dep: yup.string(),
            issue_dep_code: yup.string(),
            issue_date: yup.date().required().min(new Date("1970-01-01")).max(getCurrentISODate())
        }),
    })

    const isSmallScreen = useIsSmallScreen()

    const componentHeight = isSmallScreen ? 250 : 210

    if (isLoading) return <Spinner height={componentHeight} />

    return <Box sx={{ height: componentHeight, display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", width: "100%", justifyContent: "space-between" }}>
            <TextField
                id="series"
                name="series"
                label="Серия"
                value={values.series}
                onChange={handleChange}
                error={touched.series && Boolean(errors.series)}
                sx={{ width: isSmallScreen ? "100%" : "39%" }}
            />
            <TextField
                id="number"
                name="number"
                label="Номер"
                value={values.number}
                onChange={handleChange}
                error={touched.number && Boolean(errors.number)}
                required
                sx={{ width: isSmallScreen ? "100%" : "60%" }}
            />
        </Box>
        <TextField
            id="issue_date"
            name="issue_date"
            label="Дата выдачи"
            InputLabelProps={{ shrink: true }}
            value={values.issue_date}
            onChange={handleChange}
            required
            error={touched.issue_date && Boolean(errors.issue_date)}
            type="date"
        />
        <TextField
            id="issue_dep_code"
            name="issue_dep_code"
            label="Код подразделения"
            value={values.issue_dep_code}
            onChange={handleChange}
            error={touched.issue_dep_code && Boolean(errors.issue_dep_code)}
        />
        <Tooltip title={!values?.can_edit_and_delete ? "Вы не можете редактировать данный раздел анкеты, когда есть поданные заявления" : ""}><span>
            <Button
                disabled={!values?.can_edit_and_delete}
                sx={{ mt: 1 }}
                variant="contained"
                onClick={handleSubmit}
                fullWidth
            >
                Сохранить
            </Button>
        </span></Tooltip>
    </Box>
}

export default OldPassportForm