import { Help, KeyboardBackspace } from "@mui/icons-material"
import { Autocomplete, Box, Checkbox, FormControlLabel, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import Spinner from "../../components/Spinner"
import routes from "../../components/routes"
import { applicationStatuses } from "../../constants"
import useDepartments from "../../hooks/useDepartments"
import { getApplicationStatusReport, setCurrentDepartmentForASReport, setOnlyMaxPriorityForASReport } from "../../redux/reports/applicationStatusSlice"

const StyledTableCell = props => <TableCell {...props} sx={{ ...props.sx, m: 1, p: 1 }} />

const ApplicationStatusReport = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { departments, departmentsIsLoading } = useDepartments()

    const { current, onlyMaxPriority, data, isLoading } = useSelector(x => x.reports.applicationStatus)

    const userInfo = useSelector(x => x.user.userInfo)

    const allowedDepIds = React.useMemo(() => {
        if (userInfo.accesses.find(el => el.role.id === 4)) {
            return departments.map(el => el.id)
        } else return [...new Set(userInfo?.accesses?.map(el => el?.dep?.id).filter(el => el))]
    }, [departments, userInfo])

    React.useEffect(() => {
        if (current?.id) dispatch(getApplicationStatusReport({ dep_id: current.id, only_max_priority: onlyMaxPriority }))
    }, [dispatch, current, onlyMaxPriority])

    React.useEffect(() => {
        if (departments?.length > 0 && !current && allowedDepIds?.length > 0) {
            const depToSet = departments.find(el => el.id === allowedDepIds[0]) ?? null
            if (depToSet) dispatch(setCurrentDepartmentForASReport(depToSet))
        }
        // eslint-disable-next-line
    }, [dispatch, allowedDepIds, departments])

    const handleChangeDepartment = (_, value) => { dispatch(setCurrentDepartmentForASReport(value)) }

    const handleChangeOnlyMaxPriority = (_, value) => { dispatch(setOnlyMaxPriorityForASReport(value)) }

    const handleNavigateToReports = () => { navigate(routes.reports.path) }

    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 1500, minWidth: 280, margin: "auto" }}>

        <Box sx={{
            m: 1,
            p: 2,
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
            backgroundColor: "secondary.main",
            height: 56,
        }}>
            <IconButton onClick={handleNavigateToReports} size="small">
                <KeyboardBackspace sx={{ color: "white" }} />
            </IconButton>
            <Typography sx={{ ml: 2 }} variant="font2" color="white">
                Статусы заявлений
            </Typography>
        </Box>

        <Paper sx={{
            display: "flex",
            flexDirection: "column",
            m: 1, mb: 3, p: 2,
            minHeight: 300,
            maxWidth: 1500,
            overflow: "auto"
        }}>
            <Autocomplete
                value={current}
                options={departments}
                filterOptions={options => options.filter(el => allowedDepIds.includes(el.id))}
                getOptionLabel={option => option?.name ?? ""}
                onChange={handleChangeDepartment}
                disableClearable
                loading={departmentsIsLoading}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                fullWidth
                renderInput={props => <TextField
                    label="Институт"
                    {...props}
                />}
            />

            <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <FormControlLabel
                    label="Только первый приоритет"
                    control={<Checkbox
                        checked={onlyMaxPriority}
                        onChange={handleChangeOnlyMaxPriority}
                    />}
                />
                <Tooltip title={`Б - бюджетная основа обучения, П - с компенсацией затрат`}>
                    <Help />
                </Tooltip>
            </Box>

            {isLoading ? <Spinner height={200} /> : (
                <Table sx={{ mt: 2, "& .MuiTableCell-root": { border: "1px solid rgba(224, 224, 224, 1)" } }}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell rowSpan={2} align="center">
                                Направление
                            </StyledTableCell>
                            {applicationStatuses.map(status => <StyledTableCell
                                key={status.id}
                                colSpan={2}
                                align="center"
                                sx={{ width: 100 }}
                            >
                                {status.name}
                            </StyledTableCell>)}
                        </TableRow>
                        <TableRow>
                            {applicationStatuses.map(status => <React.Fragment key={status.id}>
                                <StyledTableCell align="center">
                                    Б
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    П
                                </StyledTableCell>
                            </React.Fragment>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((spec, index) => <TableRow key={index}>

                            <StyledTableCell>{spec.name}</StyledTableCell>

                            {applicationStatuses.map(status => <React.Fragment key={`${spec.id}-${status.id}`}>
                                <StyledTableCell align="center">
                                    {spec.statuses.free[status.id]}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {spec.statuses.paid[status.id]}
                                </StyledTableCell>
                            </React.Fragment>
                            )}
                        </TableRow>)}
                    </TableBody>
                </Table>
            )}
        </Paper>
    </Box>
}

export default ApplicationStatusReport