import { createSvgIcon } from "@mui/material";

const Checked = createSvgIcon(
    <svg viewBox="-2 -2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 10L9.21053 14L15 6" stroke="#0989BB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#6C7180" />
    </svg>,
    "Checked"
)

export default Checked