import routes from "../../../components/routes"
import toastWrapper from "../../../components/toastWrapper"
import { dialogModes, dialogTypes } from "../../../constants"
import { getMessageFromObject } from "../../../services/errors"
import { entranceTestsService } from "../../../services/operatorService"
import { getCurrentISODate } from "../../../utils"

const scorecardInitialValues = {
    id: null,
    is_add_entrants: false,
    test_date: getCurrentISODate(),
    university: null,
    subject: null,
    subspec: null,
}

const useScorecardActionHandlers = (tableRef, dialogData, openDialog, closeDialog) => {

    const handleAction = (f, data, successMessage, errorMessage) => {
        f(data).then(() => {
            toastWrapper(successMessage, "success")
            tableRef.current.onQueryChange()
            closeDialog()
        }).catch(e => {
            console.error(e)
            if (e?.response?.status === 400) {
                toastWrapper(getMessageFromObject(e?.response?.data) ?? errorMessage, "error")
            } else {
                toastWrapper(errorMessage, "error")
            }
            tableRef.current.onQueryChange()
        })
    }

    const handleIntent = (dialogData, dialogMode, dialogType) => { openDialog({ dialogData, dialogMode, dialogType }) }

    const handleCreateScorecardIntent = () => handleIntent(
        scorecardInitialValues, dialogModes.CREATE, dialogTypes.SCORECARD
    )

    const handleCreateScorecard = data => handleAction(
        entranceTestsService.postScorecard, data,
        "Экзаменационная ведомость создана",
        "Не удалось создать экзаменационную ведомость"
    )

    const handleDeleteScorecardIntent = (_, data) => handleIntent(
        data, dialogModes.DELETE, dialogTypes.SCORECARD
    )

    const handleDeleteScorecard = () => handleAction(
        entranceTestsService.deleteScorecard, dialogData,
        "Экзаменационная ведомость удалена",
        "Не удалось удалить экзаменационную ведомость"
    )

    const handleCloseScorecardIntent = (_, data) => handleIntent(
        data, null, dialogTypes.SCORECARD_CLOSE
    )

    const handleCloseScorecard = () => handleAction(
        entranceTestsService.closeScorecard, dialogData,
        "Экзаменационная ведомость закрыта",
        "Не удалось закрыть экзаменационную ведомость"
    )

    const handleCopyScorecardIntent = (_, data) => handleIntent(
        data, null, dialogTypes.SCORECARD_COPY
    )

    const handleCopyScorecard = data => handleAction(
        entranceTestsService.copyScorecard, { ...data, scorecard_id: dialogData.id },
        "Экзаменационная ведомость скопирована",
        "Не удалось скопировать экзаменационную ведомость"
    )

    const handleNavigateToScorecardResults = (_, data) => {
        window.open(routes.scorecardResults.makePath(data.id), "_blank")
    }

    return {
        handleCreateScorecardIntent, handleCreateScorecard,
        handleDeleteScorecardIntent, handleDeleteScorecard,
        handleCloseScorecardIntent, handleCloseScorecard,
        handleCopyScorecardIntent, handleCopyScorecard,
        handleNavigateToScorecardResults,
    }
}

export default useScorecardActionHandlers