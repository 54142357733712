import { createSlice } from "@reduxjs/toolkit";
import { entrantFormService } from "../../services/entrantService";
import { createCustomAsyncThunk } from "../utils";

export const getOlympInfo = createCustomAsyncThunk(
    "entrantForm/olympInfo/get",
    async userId => await entrantFormService.getOlymps(userId)
)

export const createOlympInfo = createCustomAsyncThunk(
    "entrantForm/olympInfo/create",
    async data => await entrantFormService.postOlymp(data)
)

export const updateOlympInfo = createCustomAsyncThunk(
    "entrantForm/olympInfo/update",
    async data => await entrantFormService.patchOlymp(data)
)

export const deleteOlympInfo = createCustomAsyncThunk(
    "entramtForm/olympInfo/delete",
    async data => await entrantFormService.deleteOlymp(data)
)

const initialValues = {
    name: "",
    result: null,
    can_edit_and_delete: true,
}

const olympInfoSlice = createSlice({
    name: "entrantForm/olympInfo",
    initialState: {
        data: initialValues,
        isLoading: false,
        error: null,
    },
    extraReducers: builder => {
        builder
            .addCase(getOlympInfo.pending, state => { state.isLoading = true })
            .addCase(getOlympInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload.length > 0 ? action.payload[0] : initialValues
            })
            .addCase(getOlympInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(createOlympInfo.pending, state => { state.isLoading = true })
            .addCase(createOlympInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(createOlympInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(updateOlympInfo.pending, state => { state.isLoading = true })
            .addCase(updateOlympInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(updateOlympInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(deleteOlympInfo.fulfilled, state => { state.data = initialValues })
    }
})

export default olympInfoSlice.reducer