import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useFormik } from "formik"


const EditDepCodeDialog = ({ open, onClose, currentCG, onSubmit }) => {

    const { values, handleChange, handleSubmit } = useFormik({
        initialValues: currentCG ?? { dep_code: "" },
        enableReinitialize: true,
        onSubmit,
    })

    const getTitle = cg => {
        let result = ""
        if (cg?.app_number) { result += `${cg.app_number} ` }
        if (cg?.full_name) { result += cg.full_name }

        return result
    }

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>
            {getTitle(currentCG)}
        </DialogTitle>
        <DialogContent>
            <TextField
                id="dep_code"
                name="dep_code"
                value={values.dep_code ?? ""}
                onChange={handleChange}
                fullWidth
                label="Код подразделения РИМЦ"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSubmit}>
                Сохранить
            </Button>
            <Button onClick={onClose} color="error">
                Закрыть
            </Button>
        </DialogActions>
    </Dialog>
}

export default EditDepCodeDialog