import { useDispatch, useSelector } from "react-redux"
import { getLevels, levelSelectors } from "../redux/dicts/levelsSlice"
import React from "react"


const useLevels = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(getLevels())
    }, [dispatch])

    const levels = useSelector(levelSelectors.selectAll)
    const levelOptions = useSelector(levelSelectors.selectIds)
    const levelLookups = useSelector(levelSelectors.selectEntities)
    const levelsIsLoading = useSelector(x => x.dicts.levels.isLoading)
    const universityLevelOptions = levelOptions.filter(lvl => levelLookups[lvl]?.university_edu)

    return { levels, levelOptions, universityLevelOptions, levelLookups, levelsIsLoading }
}

export default useLevels