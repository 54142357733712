import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAppPriorities, getTargetAppPriorities } from "../../../redux/entrantApplications/appPrioritiesSlice"


const useEntrantApplicationPriorities = (currentStep, appId) => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (currentStep === 2 && appId) {
            dispatch(getAppPriorities(appId))
            dispatch(getTargetAppPriorities(appId))
        }
    }, [dispatch, currentStep, appId])

    const {
        commonPriorities,
        commonPrioritiesIsLoading,
        targetPriorities,
        targetPrioritiesIsLoading
    } = useSelector(x => x.entrantApplications.appPriorities)

    const isLoading = React.useMemo(() => commonPrioritiesIsLoading || targetPrioritiesIsLoading, [commonPrioritiesIsLoading, targetPrioritiesIsLoading])

    return { commonPriorities, targetPriorities, isLoading }
}

export default useEntrantApplicationPriorities