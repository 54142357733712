import { Add, Close, CopyAll, Delete, RemoveRedEye } from "@mui/icons-material"
import { LookupFilter } from "../../../components/MaterialTableWrapper/components"
import { entranceTestsService } from "../../../services/operatorService"


const useScorecardTableProps = (onCreate, onDelete, onClose, onNavigate, onCopy, canEdit, universityLookups, subjectLookups, subspecLookups) => {

    const title = "Экзаменационные ведомости"

    const data = query => new Promise((resolve, reject) => {

        const params = {
            page_size: query.pageSize,
            page: query.page + 1,
            search: query.search,
        }

        query.filters.forEach(filter => {
            // Если boolean
            if (filter.column.type === "boolean") {
                params[filter.column.field] = filter.value === "checked"
            } else {
                params[filter.column.field] = filter.value
            }
        })

        entranceTestsService.getScorecards(params).then(res => {
            resolve({
                data: res.results,
                totalCount: res.count,
                page: query.page
            })
        }).catch(e => {
            console.error(e)
            reject("Произошла ошибка при загрузке списка экзаменационных ведомостей")
        })
    })

    const columns = [
        { field: "university", title: "Университет", lookup: universityLookups, filterComponent: LookupFilter, width: 150, },
        { field: "subject", title: "Предмет", lookup: subjectLookups, filterComponent: LookupFilter, width: 150, },
        { field: "subspec", title: "Профиль", lookup: subspecLookups, filtering: false, width: 250, },
        { field: "test_date", title: "Дата проведения", type: "date", filtering: false, width: 150, },
        { field: "is_open", title: "Открыта", type: "boolean", width: 80, },
    ]

    const defaultActions = [
        {
            icon: RemoveRedEye,
            iconProps: { color: "secondary" },
            onClick: onNavigate,
            tooltip: "Просмотр ведомости"
        }
    ]

    const editorActions = [
        {
            icon: Add,
            iconProps: { color: "secondary" },
            onClick: onCreate,
            isFreeAction: true
        },
        {
            icon: CopyAll,
            iconProps: { color: "secondary" },
            onClick: onCopy,
            tooltip: "Копировать ведомость",
        },
        rowData => rowData.is_open && ({
            icon: Close,
            iconProps: { color: "error" },
            onClick: onClose,
            tooltip: "Закрыть ведомость"
        }),
        rowData => rowData.is_open && ({
            icon: Delete,
            iconProps: { color: "error" },
            onClick: onDelete,
            tooltip: "Удалить ведомость"
        }),
    ].filter(el => el)

    const style = {
        // fontSize: 12,
    }

    const options = {
        pageSize: 25,
        pageSizeOptions: [10, 25, 50, 100],
        padding: "dense",
        maxBodyHeight: "calc(100vh - 117px)",
        minBodyHeight: "calc(100vh - 117px)",
        debounceInterval: 1000,
        headerStyle: { fontSize: 12, position: "sticky", top: 0, zIndex: 1 },
        maxColumnSort: 0,
        tableLayout: "fixed",
        filtering: true,
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
    }

    return {
        title,
        data,
        columns,
        actions: canEdit ? [...defaultActions, ...editorActions] : defaultActions,
        style,
        options
    }

}

export default useScorecardTableProps