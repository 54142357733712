import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";
import { ordersService } from "../../services/operatorService";

export const getOrderAvailableApps = createCustomAsyncThunk(
    "operator/orders/getAvailableApps",
    async params => await ordersService.getAvailableApps(params)
)

const orderAvailableAppsSlice = createSlice({
    name: "operator/orders/current",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getOrderAvailableApps.pending, state => { state.availableAppsIsLoading = true })
            .addCase(getOrderAvailableApps.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.availableAppsIsLoading = false
            })
            .addCase(getOrderAvailableApps.rejected, (state, action) => {
                state.error = action.error
                state.availableAppsIsLoading = false
            })
    }
})

export const orderAvailableAppSelectors = defaultEntityAdapter.getSelectors(x => x.orders.availableApps)

export default orderAvailableAppsSlice.reducer