import { Delete } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";


const EntrantApplicationTest = ({ data, onDelete }) => <Box sx={{
    my: 1, p: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 2,
    backgroundColor: "primary.lightBlue"
}}>

    <Box>
        <Typography variant="font5">
            {data.subject_name}
        </Typography>

        <Typography>
            {data.location_info.university}
        </Typography>
        <Typography>
            Язык проведения - {data.location_info.language}
        </Typography>
        <Typography>
            Время проведения - {new Date(data.location_info.test_datetime).toLocaleString()}
        </Typography>
    </Box>

    <IconButton size="small" onClick={() => onDelete(data)}>
        <Delete color="error" />
    </IconButton>
</Box>

export default EntrantApplicationTest