import { Autocomplete, TextField } from "@mui/material"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useDebouncedCallback } from "use-debounce"
import { getOrderAvailableApps, orderAvailableAppSelectors } from "../../redux/orders/orderAvailableAppsSlice"

const AvailableOrderAppSearch = ({ onRowDataChange, current }) => {

    const availableApps = useSelector(orderAvailableAppSelectors.selectAll)
    const availableAppsIsLoading = useSelector(x => x.orders.availableApps.isLoading)

    const dispatch = useDispatch()

    const debouncedSearch = useDebouncedCallback(event => {
        if (event.target.value?.length > 2) { dispatch(getOrderAvailableApps({ search: event.target.value })) }
    }, 1000)

    return <Autocomplete
        options={availableApps}
        getOptionLabel={app => `${app.fio} ${app.snils} ${app.number} ${app.competitive_group_name}`}
        onChange={(_, value) => {
            if (current?.id && value?.id) onRowDataChange({ order: current.id, app: value.id })
        }}
        loading={availableAppsIsLoading}
        renderInput={props => <TextField
            helperText="Для поиска начните вводить ФИО абитуриента или № заявления"
            onChange={debouncedSearch}
            {...props}
        />}
    />
}

export default AvailableOrderAppSearch