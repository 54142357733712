import { Add } from "@mui/icons-material"
import { Box, Button } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import EntrantApplicationCG from "./EntrantApplicationCG"
import EntrantApplicationCGForm from "./EntrantApplicationCGForm"


const EntrantApplicationFormStep2 = ({
    data, onSave, onClose, onCloseEdit, onNextStep,
    onDelete,
    onAddNew,
    ...rest
}) => {

    const currentApplicationEditItem = useSelector(x => x.ui.currentApplicationEditItem)

    const isEditing = currentApplicationEditItem === 0 || Boolean(currentApplicationEditItem)

    return <>

        <Box sx={{ height: "calc(100% - 60px)", overflow: "auto", pr: 2, pl: 2 }}>

            {data?.items?.map((item, index) => item.status === 9 ? null : index === currentApplicationEditItem ? (
                <EntrantApplicationCGForm
                    key={index}
                    app={data}
                    item={item}
                    onClose={() => onCloseEdit(item)}
                    onSave={onSave}
                    {...rest}
                />
            ) : (
                <EntrantApplicationCG
                    key={index}
                    item={item}
                    onDelete={() => onDelete(item)}
                />
            ))}

            <Button
                disabled={isEditing}
                onClick={() => onAddNew(data)}
                sx={{ my: 1 }}
            >
                <Add /> Добавить направление подготовки
            </Button>

        </Box>


        <Box sx={{ mt: 1, display: "flex", justifyContent: "flex-end", p: 1 }}>
            <Button
                disabled={data?.items?.length === 0 || isEditing}
                variant="contained"
                sx={{ mr: 2 }}
                onClick={onNextStep}
            >
                Далее
            </Button>
            <Button variant="contained" color="error" onClick={onClose}>
                Закрыть
            </Button>
        </Box>
    </>
}

export default EntrantApplicationFormStep2