import { Download } from "@mui/icons-material";
import { Button, IconButton, Link, MenuItem } from "@mui/material"
import React from "react";
import toastWrapper from "./toastWrapper"
import { api } from "../services/api";

const FileDownloadButton = ({ icon = null, iconColor, component = "button", url, name = "", disabled = false, buttonLabel = "Скачать файл", ...rest }) => {

    const [isDownloading, setIsDownloading] = React.useState(false)

    const handleDownload = () => {
        setIsDownloading(true)
        const link = document.createElement("a");
        link.target = "_blank";
        //link.download = name
        api.get(url, { responseType: "blob", })
            .then((res) => {
                link.href = URL.createObjectURL(
                    new Blob([res.data], { type: res.data.type })
                );
                link.click();
                setIsDownloading(false)
            })
            .catch(e => {
                console.error(e)
                toastWrapper('Не удалось загрузить файл', 'error')
                setIsDownloading(false)
            })
    };

    switch (component) {
        case "link":
            return <Link
                onClick={isDownloading ? undefined : handleDownload}
                {...rest}
                sx={{ ...rest?.sx, cursor: 'pointer' }}
            >
                {name}
            </Link>
        case "menuItem":
            return <MenuItem
                disabled={isDownloading || disabled}
                onClick={handleDownload}
                {...rest}
            >
                {name}
            </MenuItem>
        case "icon":

            const Component = icon ?? Download

            return <IconButton
                disabled={isDownloading || disabled}
                onClick={handleDownload}
                {...rest}
            >
                <Component color={isDownloading || disabled ? "disabled" : iconColor ?? "primary"} />
            </IconButton>
        default:
            return <Button
                disabled={isDownloading || disabled}
                onClick={handleDownload}
                {...rest}
            >
                {buttonLabel}
            </Button>
    }


}

export default FileDownloadButton