import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { resetSatExamsCheckAcceptedFiles, sendSatExamsCheckFile, setSatExamsCheckAcceptedFiles } from "../../redux/satExamsCheckSlice"
import toastWrapper from "../../components/toastWrapper"

const useSatExamsCheck = () => {

    const dispatch = useDispatch()

    const { acceptedFiles, isLoading } = useSelector(x => x.satExamsCheck)

    const handleSelectFile = file => {
        dispatch(setSatExamsCheckAcceptedFiles(file))
    }

    const handleSendFile = () => {
        dispatch(sendSatExamsCheckFile(acceptedFiles[0])).unwrap()
            .then(() => {
                toastWrapper("Информация о проверке результатов ЕГЭ в ФИС сохранена", "success")
                dispatch(resetSatExamsCheckAcceptedFiles())
            })
            .catch(e => {
                console.error(e)
                toastWrapper("Не удалось сохранить информацию о проверке результатов ЕГЭ в ФИС", "error")
                dispatch(resetSatExamsCheckAcceptedFiles())
            })
    }

    const handleDeleteFile = () => { dispatch(resetSatExamsCheckAcceptedFiles()) }

    return { acceptedFiles, isLoading, handleSelectFile, handleSendFile, handleDeleteFile }
}

export default useSatExamsCheck