import { ContentPasteGoOutlined, FeedbackOutlined, Grading, HistoryEdu, HowToReg, MoreHoriz, NotificationsNone, PeopleOutline, QueryStats, TrendingUp } from "@mui/icons-material"
import Applications from "../Icons/Applications"
import Form from "../Icons/Form"
import Person from "../Icons/Person"

const routes = {

    // Общие

    register: {
        name: "Регистрация",
        path: "/register",
    },
    restorePassword: {
        name: "Восстановление пароля",
        path: "/restore",
    },
    login: {
        name: "Авторизация",
        path: "/login",
    },
    profile: {
        name: "Профиль",
        path: "/profile",
        icon: Person,
    },

    // Абитуриент

    entrantSurvey: {
        name: "Опрос",
        path: "/entrant/survey",
        icon: Grading,
    },
    form: {
        name: "Анкета",
        path: "/entrant/form",
        icon: Form,
    },
    entrantApplications: {
        name: "Заявления",
        path: "/entrant/applications",
        icon: Applications,
    },
    entrantNotifications: {
        name: "Уведомления",
        path: "/entrant/notifications",
        icon: NotificationsNone
    },
    entrantFeedback: {
        name: "Обратная связь",
        path: "/entrant/feedback",
        icon: FeedbackOutlined,
    },

    // Оператор

    userList: {
        name: "Абитуриенты",
        path: "/operator/users",
        icon: PeopleOutline,
    },
    entrantAllInfo: {
        path: "/operator/users/:id/check",
        makePath: id => `/operator/users/${id}/check`
    },
    entrantApplicationsOperator: {
        path: "/operator/users/:id/applications",
        makePath: id => `/operator/users/${id}/applications`
    },
    entrantFormOperator: {
        path: "/operator/users/:id/form",
        makePath: id => `/operator/users/${id}/form`
    },
    entrantUnprocessedDocs: {
        path: "/operator/users/:id/unprocessed_docs",
        makePath: id => `/operator/users/${id}/unprocessed_docs`,
    },
    competitiveGroupList: {
        name: "Конкурсные группы",
        path: "/operator/competitive_groups",
        icon: Applications,
    },
    operatorOtherServices: {
        name: "Прочее",
        path: "/operator/other_services",
        icon: MoreHoriz
    },
    scorecards: {
        path: "/operator/scorecards",
        icon: Grading,
        name: "Экз. ведомости"
    },
    scorecardResults: {
        path: "/operator/scorecards/:id/results",
        makePath: id => `/operator/scorecards/${id}/results`
    },
    entranceTestRegistrations: {
        path: "/operator/entrance_test_registrations",
        name: "Регистрация на ВИ",
        icon: HowToReg,
    },
    satExams: {
        name: "Проверка ЕГЭ",
        path: "/operator/sat_exams",
        icon: HistoryEdu,
    },
    operatorFeedback: {
        name: "Обратная связь",
        path: "/operator/feedback",
        icon: FeedbackOutlined,
    },
    competitiveGroupAchievements: {
        path: "/operator/competitive_groups/:id/achievements",
        makePath: id => `/operator/competitive_groups/${id}/achievements`
    },

    // Отчеты

    reports: {
        name: "Отчеты",
        path: "/operator/reports",
        icon: QueryStats
    },
    departmentInfoReport: {
        path: "/operator/reports/department_info",
    },
    applicationsStatusReport: {
        path: "/operator/reports/applications_status",
    },
    averageSatExamPointsReport: {
        path: "/operator/reports/average_sat_exams_points",
    },
    averageSatExamPointsInKCPReport: {
        path: "/operator/reports/average_sat_exams_points_in_kcp",
    },
    contingentReport: {
        path: "/operator/reports/contingent",
    },
    regionReport: {
        path: "/operator/reports/regions",
    },
    countryReport: {
        path: "/operator/reports/countries",
    },
    protocolReport: {
        path: "/operator/reports/protocol",
    },

    /* Рейтинг листы */
    ratings: {
        name: "Рейтинг-листы",
        path: "/operator/ratings",
        icon: TrendingUp
    },
    dynamicRatingCGSelect: {
        name: "Рейтинг-листы",
        path: "/operator/ratings/dynamic",
    },
    dynamicRatingInfo: {
        path: "/operator/ratings/dynamic/:cgId",
        makePath: id => `/operator/ratings/dynamic/${id}`
    },
    staticRatingList: {
        name: "Рейтинг-листы",
        path: "/operator/ratings/static",
        icon: TrendingUp
    },
    staticRatingCGSelect: {
        path: "/operator/ratings/static/:id",
        makePath: id => `/operator/ratings/static/${id}`
    },
    staticRatingInfo: {
        path: "/operator/ratings/static/:id/:cgId",
        makePath: (id, cgId) => `/operator/ratings/static/${id}/${cgId}`
    },

    orderList: {
        path: "/operator/orders",
        name: "Приказы",
        icon: ContentPasteGoOutlined,
    },
    order: {
        path: "/operator/orders/:id",
        makePath: id => `/operator/orders/${id}`
    },
}

export default routes