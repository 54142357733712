import { NotificationsOutlined } from "@mui/icons-material"
import { Box, Button, IconButton, Menu, MenuItem, Paper, Typography, Link as MuiLink } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import AlertDialog from "../../components/AlertDialog"
import Form from "../../components/Icons/Form"
import Spinner from "../../components/Spinner"
import routes from "../../components/routes"
import { dialogModes, dialogTypes } from "../../constants"
import useLevels from "../../hooks/useLevels"
import useMenu from "../../hooks/useMenu"
import { getAchievementTypes } from "../../redux/dicts/achievementTypesSlice"
import { entrantApplicationSelectors, getEntrantApplications } from "../../redux/entrantApplications/appsSlice"
import { getAchievementsInfo } from "../../redux/entrantForm/achievementsInfoSlice"
import { getProfileInfo } from "../../redux/userSlice"
import EntrantApplicationForm from "./components/EntrantApplicationForm"
import EntrantApplicationItems from "./components/EntrantApplicationItems"
import EntrantNotificationsDrawer from "./components/EntrantNotificationsDrawer"
import useEntrantApplicationActionHandlers from "./hooks/useEntrantApplicationActionHandlers"


const EntrantApplicationsPage = ({ isInner = false }) => {

    const dispatch = useDispatch()

    const { profileInfo, profileInfoIsLoading, displayedUserInfo: userInfo } = useSelector(x => x.user)

    React.useEffect(() => {
        if (userInfo?.id) {
            dispatch(getEntrantApplications(userInfo.id))
            dispatch(getProfileInfo(userInfo.id))
            dispatch(getAchievementsInfo(userInfo.id))
            dispatch(getAchievementTypes())
        }
    }, [dispatch, userInfo])

    const applications = useSelector(entrantApplicationSelectors.selectAll)
    const applicationsIsLoading = useSelector(x => x.entrantApplications.apps.isLoading)
    const actionsIsLoading = useSelector(x => x.entrantApplications.apps.actionsIsLoading)
    const achievementsIsLoading = useSelector(x => x.entrantForm.achievements.isLoading)
    const achievementTypesIsLoading = useSelector(x => x.dicts.achievementTypes.isLoading)

    const isLoading = profileInfoIsLoading || applicationsIsLoading || achievementsIsLoading || achievementTypesIsLoading

    const { dialogMode, dialogType, dialogIsOpen } = useSelector(x => x.ui)
    const applicationDialogIsOpen = dialogIsOpen && dialogMode !== dialogModes.DELETE && dialogType === dialogTypes.ENTRANT_APPLICATION
    const deleteDialogIsOpen = dialogIsOpen && dialogMode === dialogModes.DELETE && dialogType === dialogTypes.ENTRANT_APPLICATION

    const { levelLookups, levelsIsLoading } = useLevels()

    const { anchorEl, open, handleDefaultMenuClick, handleMenuClose } = useMenu()

    const currentAppItem = useSelector(x => x.entrantApplications.apps.currentAppItem)

    const {
        handleCreateApplicationIntent,
        handleEditApplication,
        handleDeleteApplicationIntent, handleDeleteApplication,
        handleClose,

        handleOpenMenu, handleCloseMenu,
        actionNamesMap, handleAction,

        handleOpenEntrantNotificationsDrawer
    } = useEntrantApplicationActionHandlers(handleDefaultMenuClick, handleMenuClose, currentAppItem)

    const entrantActions = ["submit", "withdraw", "withdraw_confirmation", "add_confirmation", "delete"]

    return <Box sx={{ display: "flex", flexDirection: "column", minWidth: 350, maxWidth: 1200, m: "auto" }}>

        <Box
            sx={{
                m: 1,
                p: 1.5,
                borderRadius: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "secondary.main",
            }}
        >
            <Typography variant="font2" color="white">
                {isInner ? `${userInfo?.surname ?? ""} ${userInfo?.name ?? ""} ${userInfo?.patronymic ?? ""} - Заявления` : "Заявления"}
            </Typography>

            <IconButton size="small" onClick={handleOpenEntrantNotificationsDrawer}>
                <NotificationsOutlined sx={{ color: "white" }} />
            </IconButton>
        </Box>

        <Paper elevation={0} sx={{ m: 1, p: 2, justifySelf: "center", minHeight: 500, boxShadow: isInner ? "none" : undefined }}>
            {isLoading ? <Spinner height={400} /> : <>

                <Box>

                    {applications.length === 0 && <Typography
                        variant="font3"
                        sx={{ my: 2 }}
                    >
                        {isInner ? "Абитуриент еще не подавал заявления на поступление" : "Вы еще не подавали заявления на поступление"}
                    </Typography>}

                    {applications.map(application => <EntrantApplicationItems
                        key={application.id}
                        data={application}
                        onDelete={handleDeleteApplicationIntent}
                        onEdit={handleEditApplication}
                        levelLookups={levelLookups}
                        anchorEl={anchorEl}
                        menuIsOpen={open}
                        onOpenMenu={handleOpenMenu}
                        onCloseMenu={handleCloseMenu}
                    />)}

                </Box>

                {!profileInfo?.is_ready_for_create_app && <Box sx={{ pt: 2, pb: 2 }}>
                    {isInner ? (
                        <Typography variant="font3" sx={{ display: "flex", alignItems: "center" }}>
                            Для подачи заявления заполните необходимые разделы в анкете
                        </Typography>
                    ) : (

                        <Typography variant="font3" sx={{ display: "flex", alignItems: "center" }}>
                            Для подачи заявления заполните необходимые разделы в
                            <Form fontSize="small" color="primary" sx={{ ml: 1, mr: 0.5 }} />
                            <Link to={routes.form.path}>Анкете</Link>
                        </Typography>
                    )}
                </Box>}

                <Button
                    disabled={!profileInfo?.is_ready_for_create_app}
                    sx={{ height: 40 }}
                    variant="contained"
                    onClick={handleCreateApplicationIntent}
                >
                    Добавить заявление
                </Button>

            </>}
        </Paper>

        <EntrantApplicationForm
            levelLookups={levelLookups}
            levelsIsLoading={levelsIsLoading}
            userInfo={userInfo}
            open={applicationDialogIsOpen}
            onClose={handleClose}
        />

        <AlertDialog
            open={deleteDialogIsOpen}
            onCancel={handleClose}
            onOk={handleDeleteApplication}
            title="Удаление заявления"
            description="Вы уверены, что хотите удалить заявление?"
        />

        <EntrantNotificationsDrawer />

        <Menu
            id="user-actions"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
        >
            {currentAppItem?.available_actions?.filter(el => entrantActions.includes(el))?.map(action => <MenuItem
                key={action}
                onClick={() => handleAction(action)}
                disabled={actionsIsLoading}
            >
                {actionNamesMap[action]}
            </MenuItem>)}
        </Menu>

    </Box>
}

export default EntrantApplicationsPage