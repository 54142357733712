
import { createSvgIcon } from "@mui/material";


const Person = createSvgIcon(
    <svg  viewBox="0 0 20 29" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.3578 15.1849C16.9545 14.7661 16.2684 14.7373 15.8291 15.1218C15.3897 15.5062 15.3595 16.1602 15.7628 16.5791C17.0929 17.9619 17.8272 19.7578 17.8272 21.6341V26.0693C17.8272 26.2759 17.6467 26.4479 17.43 26.4479H2.55793C2.34125 26.4479 2.16069 26.2759 2.16069 26.0693V21.6341C2.16069 17.5144 5.67558 14.1693 9.99091 14.1693C13.9392 14.1693 17.1532 11.1053 17.1532 7.34142C17.1532 3.57749 13.9452 0.519287 9.99692 0.519287C6.04874 0.519287 2.83478 3.58323 2.83478 7.34715C2.83478 8.47175 3.12969 9.5906 3.68341 10.5775C3.96628 11.0824 4.62232 11.266 5.15196 10.9963C5.6816 10.7267 5.87419 10.1013 5.59132 9.59634C5.20612 8.90781 5.00149 8.13322 5.00149 7.34715C5.00149 4.71929 7.24042 2.58486 9.99692 2.58486C12.7535 2.58486 14.9924 4.71929 14.9924 7.34715C14.9924 9.97503 12.7535 12.1095 9.99692 12.1095C4.48389 12.1095 0 16.3841 0 21.6398V26.075C0 27.4233 1.14957 28.5193 2.56394 28.5193H17.436C18.8504 28.5193 20 27.4233 20 26.075V21.6398C20 19.2414 19.0611 16.9521 17.3578 15.1849Z" />
    </svg>,
    "Unchecked"
)

export default Person


