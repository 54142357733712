import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const AlertDialog = ({ open, title, description, onCancel, onOk }) => <Dialog
    open={open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
>
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {description}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={onOk}>
            Да
        </Button>
        <Button color="error" onClick={onCancel}>
            Нет
        </Button>
    </DialogActions>
</Dialog>

export default AlertDialog