import { FilterList, MoreVert } from "@mui/icons-material"
import { Box, Checkbox, FormControlLabel, Link, Typography } from "@mui/material"
import React from "react"
import { LookupFilter, PriorityFilter, TextFilter, TimeRangeFilter } from "../../../components/MaterialTableWrapper/components"
import { applicationStatusLookups, eduFormLookups, eduTypeLookups, originalLookups, submitDocWayLookups } from "../../../constants"
import { operatorService } from "../../../services/operatorService"

const useCompetitiveGroupTableProps = (
    specLookups, subspecLookups, universityLookups, universityLevelLookups, citizenshipLookups, regionLookups,
    showOnlyMaxPriority,
    hiddenColumns, displayedLevels, displayedSpecs, displayedSubspecs,
    onOpenMenu, onHistoryOpen, onHideColumnsDialogOpen,
    dictsIsLoading,
    onShowOnlyMaxPriorityChange
) => {

    const title = <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6">
            Конкурсные группы
        </Typography>
        <FormControlLabel
            sx={{ ml: 2 }}
            label={<Typography fontSize={14}>К рассмотрению по 1 приоритету</Typography>}
            control={<Checkbox
                size="small"
                checked={showOnlyMaxPriority}
                onChange={(_, value) => { onShowOnlyMaxPriorityChange(value) }}
            />}
        />
    </Box>

    const data = query => new Promise((resolve, reject) => {

        const requiredColumns = ["contract", "without_entrance_tests", "spec", "edu_form", "edu_type", "app"]
        const columnsToShow = defaultColumns.map(el => el.field).filter(el => !hiddenColumns.includes(el))

        const params = {
            page_size: query.pageSize,
            page: query.page + 1,
            search: query.search,
            fields: [...new Set([...columnsToShow, requiredColumns])].join(","),
        }

        if (displayedLevels.length > 0) { params.level = displayedLevels }
        if (displayedSpecs.length > 0) params.spec = displayedSpecs
        if (displayedSubspecs.length > 0) params.subspec = displayedSubspecs
        if (showOnlyMaxPriority) params.is_max_priority = showOnlyMaxPriority

        query.filters.forEach(filter => {
            // Если boolean
            if (filter.column.type === "boolean") {
                params[filter.column.field] = filter.value === "checked"
            } else {
                params[filter.column.field] = filter.value
            }
        })

        operatorService.getCompetitiveGroupList(params).then(res => {
            resolve({
                data: res.results,
                totalCount: res.count,
                page: query.page
            })
        }).catch(e => {
            console.error(e)
            reject("Произошла ошибка при загрузке списка конкурсных групп")
        })
    })

    const defaultStatusFilterValue = ["2", "3", "5", "6", "7"]

    const defaultColumns = React.useMemo(() => [
        { title: "№ заявл.", field: "app_number", filtering: false, width: 120 },
        { title: "ФИО", field: "full_name", filtering: false, width: 150 },
        { title: "Университет", field: "university", filterComponent: LookupFilter, lookup: universityLookups, width: 150 },
        { title: "Подразделение", field: "department", filtering: false, width: 125 },
        { title: "Уровень обр-я", field: "level", filtering: false, lookup: universityLevelLookups, width: 125 },
        {
            title: "Направление",
            field: "spec",
            lookup: specLookups,
            cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 200 },
            filtering: false,
            width: 200
        },
        {
            title: "Профиль",
            field: "subspec",
            lookup: subspecLookups,
            cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 150 },
            filtering: false,
            width: 150
        },
        { title: "Приоритет", field: "priority", filterComponent: PriorityFilter, width: 120, align: "center" },
        {
            title: "Статус",
            field: "status",
            lookup: applicationStatusLookups,
            filterComponent: LookupFilter,
            defaultFilter: defaultStatusFilterValue,
            width: 130
        },
        { title: "Приказ", field: "order", filterComponent: TextFilter, width: 120, },
        {
            title: "Баллы",
            field: "points",
            filtering: false,
            width: 100
        },
        {
            title: "Достижения",
            field: "achievement_points",
            filtering: false,
            width: 100
        },
        { title: "Форма обучения", field: "edu_form", lookup: eduFormLookups, filterComponent: LookupFilter, width: 120, },
        {
            title: "Основа обучения",
            field: "edu_type",
            lookup: eduTypeLookups,
            filterComponent: LookupFilter,
            cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 120 },
            width: 120,
        },
        { title: "Оригинал", field: "original", lookup: originalLookups, filterComponent: LookupFilter, width: 100 },
        { title: "После СПО", field: "is_spo", type: "boolean", width: 80 },
        { title: "СКЦП", field: "is_foreign_with_direction", type: "boolean", width: 80 },
        { title: "Нужд. в общежитии", field: "is_need_hostel", type: "boolean", width: 80 },
        { title: "Документы поданы", field: "submit_docs_way", lookup: submitDocWayLookups, filterComponent: LookupFilter, width: 125 },
        { title: "Из ЕПГУ", field: "is_epgu", type: "boolean", width: 90 },
        { title: "БВИ", field: "without_entrance_tests", type: "boolean", width: 80 },
        { title: "Телефон", field: "phone", filtering: false, width: 115 },
        { title: "СНИЛС", field: "snils", filtering: false, width: 115, },
        { title: "Не указан СНИЛС", field: "is_null_snils", type: "boolean", width: 115 },
        { title: "Гражданство", field: "citizen", filtering: false, width: 115, lookup: citizenshipLookups },
        { title: "Адрес", field: "reg_address", filtering: false, width: 250 },
        { title: "Последнее изм. приоритета", field: "date_of_change_priority", filterComponent: TimeRangeFilter, type: "datetime", width: 130 },
        { title: "Регион получения пред. обр.", field: "prev_edu_region", filtering: true, width: 250, filterComponent: LookupFilter, lookup: regionLookups },
        { title: "Код подразделения РИМЦ", field: "dep_code", width: 160, filtering: false },
        {
            title: "Последнее изменение",
            field: "modified_at",
            type: "datetime",
            filterComponent: TimeRangeFilter,
            width: 130,
            render: rowData => <Link
                sx={{ cursor: "pointer", m: 0, p: 0 }}
                onClick={() => onHistoryOpen(rowData)}
            >
                {new Date(rowData.modified_at).toLocaleString()}
            </Link>
        },
        /* 
            ПОЯСНЕНИЕ ДЛЯ МЕНЯ ИЗ БУДУЩЕГО
            Почему useMemo а в зависимостях только загрузка диктов
            Какая-то мразь (скорее всего меню с действиями) вызывает обновление DOM, срабатывает ререндер
            всей таблицы, и после этого в т.ч. обновляется дефолтное значение фильтров. 
            Компонент LookupFilter офигевает с таких раскладов и обновляет знаение фильтров!
            
            Поэтому выше по DOMу дожидаемся загрузки диктов, только после этого рендерим таблицу
            Нормально будет работать только так.
            
            !!!!!!!!! Возможно будет баговать, надо вести расследование !!!!!!!!!

            Перепиши этот говнокод или живи дальше в проклятом мире, который сам и создал :^)
        */
        // eslint-disable-next-line
    ], [dictsIsLoading])

    const columns = defaultColumns.filter(el => !hiddenColumns.includes(el.field))

    const actions = [
        rowData => ({
            icon: MoreVert,
            iconProps: { color: rowData.available_actions.length > 0 ? "secondary" : "disabled" },
            disabled: rowData.available_actions.length === 0,
            onClick: onOpenMenu
        }),
        {
            icon: FilterList,
            iconProps: { color: "secondary" },
            onClick: onHideColumnsDialogOpen,
            tooltip: "Скрыть столбцы",
            isFreeAction: true,
        },
    ]

    const options = {
        pageSize: 25,
        pageSizeOptions: [10, 25, 50, 100],
        padding: "dense",
        maxBodyHeight: "calc(100vh - 162px)",
        minBodyHeight: "calc(100vh - 162px)",
        debounceInterval: 1000,
        headerStyle: { fontSize: 12, position: "sticky", top: 0, zIndex: 1 },
        maxColumnSort: 0,
        tableLayout: "fixed",
        filtering: true,
        emptyRowsWhenPaging: false,
    }

    const style = {
        fontSize: 12,
        minWidth: 1100
    }

    return {
        title,
        data,
        defaultColumns,
        columns,
        actions,
        options,
        style,
    }
}

export default useCompetitiveGroupTableProps