import { Menu } from "@mui/icons-material"
import { Box, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { drawerWidth } from "../../theme"


const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    borderColor: "#E6F7FF",
    borderRadius: 0,
    overflowX: "hidden",
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    borderColor: "#E6F7FF",
    borderRadius: 0,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled("div", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: open ? "center" : "space-between",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    })
)

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
        }),
    }),
)

const DesktopNavigation = ({ children, menuItems }) => {

    const [open, setOpen] = React.useState(false)

    const location = useLocation()

    const navigate = useNavigate()

    const handleSelect = path => {
        navigate(path)
    }

    const theme = useTheme()

    const isCurrentPage = (path) => location.pathname.includes(path)

    return <Box sx={{ display: "flex" }}>

        <StyledDrawer variant="permanent" open={open}>
            <DrawerHeader>
                <img
                    style={{ height: 35, display: open ? undefined : "none" }}
                    src={`${process.env.PUBLIC_URL}/icons/logoWithText.svg`}
                    alt=""
                />

                <Divider
                    orientation="vertical"
                    sx={{
                        ml: 1.5,
                        height: 24,
                        display: open ? undefined : "none",
                        borderColor: theme.palette.font.main
                    }}
                />

                <IconButton sx={{ ml: open ? 0 : 0.5 }} onClick={() => setOpen(!open)}>
                    <Menu sx={{ color: theme.palette.font.main }} />
                </IconButton>

            </DrawerHeader>

            <List sx={{ pt: 0 }}>
                {menuItems.map(item => {

                    const selected = isCurrentPage(item.path)

                    return <ListItemButton
                        key={item.path}
                        sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }}
                        onClick={() => handleSelect(item.path)}
                    >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center", }}>
                            <item.icon sx={{ color: selected ? theme.palette.primary.main : theme.palette.font.main }} />
                        </ListItemIcon>
                        <ListItemText
                            primary={item.name}
                            primaryTypographyProps={{
                                variant: "font3",
                                color: selected ? theme.palette.primary.main : theme.palette.font.main
                            }}
                            sx={{ opacity: open ? 1 : 0 }}
                            color={isCurrentPage(item.path) ? theme.palette.primary.main : theme.palette.font.main}
                        />
                    </ListItemButton>
                })}
            </List>

        </StyledDrawer>

        <Box
            component="main"
            sx={{
                height: "100vh",
                width: `calc(100vw - ${open ? drawerWidth : 65}px)`,
                overflow: "auto",
            }}>
            {children}
        </Box>
    </Box>
}

export default DesktopNavigation