import { Autocomplete, Box, Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import { useSelector } from "react-redux"
import * as yup from "yup"
import { eduForms } from "../../../../constants"
import { getLookups, getOptions } from "../../../../utils"
import useCgSpecs from "../../../../hooks/useCgSpecs"
import useCgSubspecs from "../../../../hooks/useCgSubspecs"
import useIsSmallScreen from "../../../../hooks/useIsSmallScreen"


const EntrantApplicationCGForm = ({ app, item, universityOptions, universityLookups, universitiesIsLoading, onSave, onClose }) => {

    const eduFormOptions = getOptions(eduForms)
    const eduFormLookups = getLookups(eduForms)

    const achievements = useSelector(x => x.entrantForm.achievements.data)
    const achievementOptions = achievements.map(el => ({ achievement: el.id, achievement_name: el.achievement_name }))

    const { values, setFieldValue, handleSubmit, errors, touched } = useFormik({
        initialValues: {
            ...item,
            without_quota_types: !item.quota,
            quota_types: item.quota ? [item.quota] : [],
            achievements: item.achievements ?? achievements.map(el => ({ achievement: el.id, achievement_name: el.achievement_name }))
        },
        enableReinitialize: true,
        onSubmit: values => onSave(app, values),
        validationSchema: yup.object({
            university: yup.number().required(),
            edu_form: yup.number().required(),
            spec: yup.number().required(),
            subspec: yup.number().required(),
        })
    })

    const { specOptions, specLookups, specsIsLoading } = useCgSpecs(
        app.level,
        values.university,
        values.edu_form,
        app.is_budget,
        values.is_english_program,
        values.is_foreign_with_direction,
        values.quota_types,
        values.without_quota_types,
    )

    const { subspecOptions, subspecLookups, subspecsIsLoading } = useCgSubspecs(
        app.level,
        values.university,
        values.edu_form,
        app.is_budget,
        values.is_english_program,
        values.is_foreign_with_direction,
        values.quota_types,
        values.without_quota_types,
        values.spec,
    )

    const isSmallScreen = useIsSmallScreen()

    return <Box sx={{ borderRadius: 2, backgroundColor: "primary.lightBlue", my: 1, p: 2 }}>
        <Autocomplete
            options={universityOptions}
            value={values?.university}
            getOptionLabel={option => universityLookups[option]?.sname ?? ""}
            loading={universitiesIsLoading}
            onChange={(_, value) => {
                setFieldValue("university", value)
                setFieldValue("spec", null)
                setFieldValue("subspec", null)
            }}
            renderInput={props => <TextField
                label="Университет"
                required
                error={touched.university && Boolean(errors.university)}
                {...props}
            />}
        />

        <Autocomplete
            options={eduFormOptions}
            value={values.edu_form}
            getOptionLabel={option => eduFormLookups[option]}
            onChange={(_, value) => {
                setFieldValue(`edu_form`, value)
                setFieldValue("spec", null)
                setFieldValue("subspec", null)
            }}
            renderInput={props => <TextField
                label="Форма обучения"
                error={touched.edu_form && Boolean(errors.edu_form)}
                {...props}
            />}
        />

        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormControlLabel
                label="Прием иностранцев по направлению Минобрнауки"
                control={<Checkbox
                    checked={values.is_foreign_with_direction}
                    onChange={(_, value) => {
                        setFieldValue(`is_foreign_with_direction`, value)
                        setFieldValue("spec", null)
                        setFieldValue("subspec", null)
                    }}
                />}
            />

            <FormControlLabel
                label="Английская программа обучения"
                control={<Checkbox
                    checked={values.is_english_program}
                    onChange={(_, value) => {
                        setFieldValue(`is_english_program`, value)
                        setFieldValue("spec", null)
                        setFieldValue("subspec", null)
                    }}
                />}
            />
        </Box>

        {app.is_budget && <>
            <Typography variant="font5">Квоты:</Typography>

            <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row" }}>
                <FormControlLabel
                    label="Целевое обучение"
                    control={<Checkbox
                        checked={values.quota_types.includes(2)}
                        onChange={() => {
                            if (values.quota_types.includes(2)) {
                                if (values.quota_types.length === 1) {
                                    setFieldValue(`without_quota_types`, true)
                                }
                                setFieldValue(`quota_types`, values.quota_types.filter(el => el !== 2))
                            } else {
                                setFieldValue(`quota_types`, [...values.quota_types, 2])
                                setFieldValue(`without_quota_types`, false)
                            }
                            setFieldValue("spec", null)
                            setFieldValue("subspec", null)
                        }}
                    />}
                />
                <FormControlLabel
                    label="Особая квота"
                    control={<Checkbox
                        checked={values.quota_types.includes(3)}
                        onChange={() => {
                            if (values.quota_types.includes(3)) {
                                if (values.quota_types.length === 1) {
                                    setFieldValue(`without_quota_types`, true)
                                }
                                setFieldValue(`quota_types`, values.quota_types.filter(el => el !== 3))
                            } else {
                                setFieldValue(`quota_types`, [...values.quota_types, 3])
                                setFieldValue(`without_quota_types`, false)
                            }
                            setFieldValue("spec", null)
                            setFieldValue("subspec", null)
                        }}
                    />}
                />
                <FormControlLabel
                    label="Отдельная квота"
                    control={<Checkbox
                        checked={values.quota_types.includes(4)}
                        onChange={() => {
                            if (values.quota_types.includes(4)) {
                                if (values.quota_types.length === 1) {
                                    setFieldValue(`without_quota_types`, true)
                                }
                                setFieldValue(`quota_types`, values.quota_types.filter(el => el !== 4))
                            } else {
                                setFieldValue(`quota_types`, [...values.quota_types, 4])
                                setFieldValue(`without_quota_types`, false)
                            }
                            setFieldValue("spec", null)
                            setFieldValue("subspec", null)
                        }}
                    />}
                />
            </Box>
        </>}

        <Autocomplete
            options={specOptions}
            value={values.spec}
            loading={specsIsLoading}
            disabled={!values.university || !values.edu_form}
            getOptionLabel={option => {
                const spec = specLookups[option]
                if (!spec) return ""
                return `${spec.code} - ${spec.name}`
            }}
            onChange={(_, value) => {
                setFieldValue("spec", value)
                setFieldValue("subspec", null)
            }}
            renderInput={props => <TextField
                label="Направление подготовки"
                id="spec"
                name="spec"
                error={touched.spec && Boolean(errors.spec)}
                {...props}
            />}
        />

        <Autocomplete
            options={subspecOptions}
            value={values.subspec}
            loading={subspecsIsLoading}
            disabled={!values.university || !values.edu_form || !values.spec}
            getOptionLabel={option => subspecLookups[option]?.name ?? ""}
            onChange={(_, value) => { setFieldValue("subspec", value) }}
            renderInput={props => <TextField
                label="Профиль"
                error={touched.subspec && Boolean(errors.subspec)}
                {...props}
            />}
        />

        <Autocomplete
            options={achievementOptions}
            value={values.achievements}
            multiple
            onChange={(_, value) => { setFieldValue("achievements", value) }}
            getOptionLabel={option => option.achievement_name}
            isOptionEqualToValue={(option, value) => option.achievement === value.achievement}
            renderInput={props => <TextField
                label="Достижения"
                {...props}
            />}
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1, }}>
            <Button sx={{ mr: 2 }} variant="contained" onClick={handleSubmit}>
                Сохранить
            </Button>
            <Button variant="contained" color="error" onClick={onClose}>
                Отмена
            </Button>
        </Box>
    </Box>
}

export default EntrantApplicationCGForm