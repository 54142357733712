
import { createSlice } from "@reduxjs/toolkit";
import { entrantFormService } from "../../services/entrantService";
import { createCustomAsyncThunk } from "../utils";

export const getPrevEduInfo = createCustomAsyncThunk(
    "entrantForm/prevEduInfo/get",
    async userId => await entrantFormService.getPrevEduInfo(userId)
)

export const createPrevEduInfo = createCustomAsyncThunk(
    "entrantForm/prevEduInfo/create",
    async data => await entrantFormService.postPrevEduInfo(data)
)

export const updatePrevEduInfo = createCustomAsyncThunk(
    "entrantForm/prevEduInfo/update",
    async data => await entrantFormService.patchPrevEduInfo(data)
)

const initialValues = {
    id: null,
    level: 1,
    with_honors: false,
    year_end: 2023,
    institution: "",
    series: "",
    number: "",
    issue_date: "",
    average_mark: '0',
    diploma_mark: '',
    spec: null,
    doc_type: null,
    reg_number: '',
    citizen: parseInt(window._env_?.DEFAULT_CITIZENSHIP) ?? null,
    region: null,
    can_edit_and_delete: true,
}

const prevEduInfoSlice = createSlice({
    name: "entrantForm/prevEduInfo",
    initialState: {
        data: initialValues,
        isLoading: false,
        error: null,
    },
    extraReducers: builder => {
        builder
            .addCase(getPrevEduInfo.pending, state => { state.isLoading = true })
            .addCase(getPrevEduInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload.length > 0 ? action.payload[0] : initialValues
            })
            .addCase(getPrevEduInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
        
            .addCase(createPrevEduInfo.pending, state => { state.isLoading = true })
            .addCase(createPrevEduInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(createPrevEduInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
        
            .addCase(updatePrevEduInfo.pending, state => { state.isLoading = true })
            .addCase(updatePrevEduInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(updatePrevEduInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
    }
})

export default prevEduInfoSlice.reducer