import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDynamicRatings, getStaticRatings } from "../../redux/ratingsSlice"

const useRatings = (currentCG, currentRatingId, type) => {

    const dispatch = useDispatch()

    const {
        staticRatings, staticRatingsIsLoading,
        dynamicRatings, dynamicRatingsIsLoading,
        params
    } = useSelector(x => x.ratings)

    React.useEffect(() => {
        if (currentCG && type === "dynamic") {
            dispatch(getDynamicRatings({ competitive_group_id: currentCG.id }))
        }
    }, [dispatch, currentCG, type])

    React.useEffect(() => {
        if (currentCG && currentRatingId && type === "static") {
            const preparedParams = {}
            if (params.is_max_priority) preparedParams.is_max_priority = true
            if (params.is_original) preparedParams.is_original = true

            dispatch(getStaticRatings({ id: currentRatingId, competitive_group_id: currentCG.id, ...preparedParams }))
        }
    }, [dispatch, currentCG, currentRatingId, type, params])



    if (type === "static") {
        return { ratings: staticRatings, ratingsIsLoading: staticRatingsIsLoading, params }
    } else {
        return { ratings: dynamicRatings, ratingsIsLoading: dynamicRatingsIsLoading, params }
    }
}

export default useRatings