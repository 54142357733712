import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk } from "../utils";
import { operatorService } from "../../services/operatorService";

export const getDepartmentInfoReport = createCustomAsyncThunk(
    "reports/departmentInfo/get",
    async params => await operatorService.getDepartmentInfoReport(params)
)

const departmentInfoSlice = createSlice({
    name: "reports/departmentInfo",
    initialState: {
        current: null,
        data: null,
        isLoading: false,
        error: null,
    },
    reducers: {
        setCurrent: (state, action) => { state.current = action.payload },
        resetCurrent: state => { state.current = null }
    },
    extraReducers: builder => {
        builder
            .addCase(getDepartmentInfoReport.pending, state => { state.isLoading = true })
            .addCase(getDepartmentInfoReport.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(getDepartmentInfoReport.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const {
    setCurrent: setCurrentDepartmentForDIReport ,
    resetCurrent: resetCurrentDepartmentForDIReport,
} = departmentInfoSlice.actions

export default departmentInfoSlice.reducer