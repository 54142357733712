import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getNoticeTypes, noticeTypeSelectors } from "../redux/dicts/noticeTypesSlice"


const useNoticeTypes = () => {

    const dispatch = useDispatch()

    React.useEffect(() => { dispatch(getNoticeTypes()) }, [dispatch])

    const noticeTypeOptions = useSelector(noticeTypeSelectors.selectIds)
    const noticeTypeLookups = useSelector(noticeTypeSelectors.selectEntities)
    const noticeTypesIsLoading = useSelector(x => x.dicts.noticeTypes.isLoading)


    return { noticeTypeOptions, noticeTypeLookups, noticeTypesIsLoading }
}

export default useNoticeTypes