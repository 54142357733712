import { createSlice } from "@reduxjs/toolkit";
import { entrantFormService } from "../../services/entrantService";
import { createCustomAsyncThunk } from "../utils";

export const getPersonalInfo = createCustomAsyncThunk(
    "entrantForm/personalInfo/get",
    async userId => await entrantFormService.getPersonalInfo(userId)
)

export const createPersonalInfo = createCustomAsyncThunk(
    "entrantForm/personalInfo/create",
    async data => await entrantFormService.postPersonalInfo(data)
)

export const updatePersonalInfo = createCustomAsyncThunk(
    "entrantForm/personalInfo/update",
    async data => await entrantFormService.patchPersonalInfo(data)
)

const initialValues = {
    id: null,
    birthday: "",
    birth_place: "",
    gender: 1,
    reg_address: "",
    fact_address: "",
    snils_number: "",
    citizen: parseInt(window._env_?.DEFAULT_CITIZENSHIP) ?? null,
    foreign_lang: parseInt(window._env_?.DEFAULT_LANGUAGE) ?? null,
    last_surname: "",
    can_edit_and_delete: true,
}

const personalInfoSlice = createSlice({
    name: "entrantForm/personalInfo",
    initialState: {
        data: initialValues,
        isLoading: false,
        error: null,
    },
    extraReducers: builder => {
        builder
            .addCase(getPersonalInfo.pending, state => { state.isLoading = true })
            .addCase(getPersonalInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload.length > 0 ? action.payload[0] : initialValues
            })
            .addCase(getPersonalInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
        
            .addCase(createPersonalInfo.pending, state => { state.isLoading = true })
            .addCase(createPersonalInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(createPersonalInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
        
            .addCase(updatePersonalInfo.pending, state => { state.isLoading = true })
            .addCase(updatePersonalInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(updatePersonalInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
    }
})

export default personalInfoSlice.reducer