import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLanguages, languageSelectors } from "../../../redux/dicts/languagesSlice"

const useLanguages = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (languageOptions.length === 0) {
            dispatch(getLanguages())
        }
    // eslint-disable-next-line
    }, [dispatch])

    const languageOptions = useSelector(languageSelectors.selectIds)
    const languageLookups = useSelector(languageSelectors.selectEntities)
    const languagesIsLoading = useSelector(x => x.dicts.languages.isLoading)

    return { languageOptions, languageLookups, languagesIsLoading }
}

export default useLanguages