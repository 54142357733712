import { createSlice } from "@reduxjs/toolkit";
import { entrantSurveyService } from "../services/entrantService";
import { createCustomAsyncThunk } from "./utils";

const initialValues = {
    address: "",
    current_edu_place: "",
    current_edu_course: 11,
    is_training_courses: false,
    levels: [],
    specs: [],
    subjects: []
}

export const getEntrantSurvey = createCustomAsyncThunk(
    "entrant/survey/get",
    async userId => await entrantSurveyService.get(userId)
)

export const createEntrantSurvey = createCustomAsyncThunk(
    "entrant/survey/create",
    async data => await entrantSurveyService.post(data)
)

export const updateEntrantSurvey = createCustomAsyncThunk(
    "entrant/survey/update",
    async data => await entrantSurveyService.patch(data)
)

const entrantSurveySlice = createSlice({
    name: "entrant/survey",
    initialState: {
        data: initialValues,
        isLoading: false,
        error: null
    },
    extraReducers: builder => {
        builder
            .addCase(getEntrantSurvey.pending, state => { state.isLoading = true })
            .addCase(getEntrantSurvey.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload.length > 0 ? action.payload[0] : initialValues
            })
            .addCase(getEntrantSurvey.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
        
            .addCase(createEntrantSurvey.pending, state => { state.isLoading = true })
            .addCase(createEntrantSurvey.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(createEntrantSurvey.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
        
            .addCase(updateEntrantSurvey.pending, state => { state.isLoading = true })
            .addCase(updateEntrantSurvey.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(updateEntrantSurvey.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
    }
})

export default entrantSurveySlice.reducer