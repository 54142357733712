import { Alert, Autocomplete, Button, ButtonGroup, Grid, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import { useSelector } from "react-redux"
import useIsMediumScreen from "../../hooks/useIsMediumScreen"
import useIsSmallScreen from "../../hooks/useIsSmallScreen"
import * as yup from "yup"
import { thirdPartyApprovalStatusLookups } from "../../constants"


const ApprovalActions = ({
    data, onSave,
    noticeTypeOptions, noticeTypeLookups,
    approveButtonLabel = "Пометить раздел как проверенный",
    reworkButtonLabel = "Отправить раздел на доработку"
}) => {

    const userInfo = useSelector(x => x.user.userInfo)

    const options = React.useMemo(() => noticeTypeOptions.filter(option => noticeTypeLookups[option].status === 5), [noticeTypeOptions, noticeTypeLookups])

    const { values, errors, touched, handleChange, setFieldValue, handleSubmit } = useFormik({
        enableReinitialize: true,
        initialValues: {
            comment: "",
            status: "ok",
            notice_type: null,
            id: data?.id, // Это не id approval, это id раздела анкеты
        },
        validationSchema: yup.object({
            status: yup.string().required(),
            notice_type: yup.number().nullable()
                .when("status", { is: "error", then: () => yup.number().required() }),
            comment: yup.string()
                .when("status", { is: "error", then: () => yup.string().required() })
        }),
        onSubmit: onSave
    })

    const handleOk = () => {
        setFieldValue("status", "ok")
        setFieldValue("comment", "")
        setFieldValue("notice_type", null)
        handleSubmit()
    }

    const handleRework = () => {
        setFieldValue("status", "error")
    }

    const isApprovedByUser = Boolean(data.approvals.find(el => el.user === userInfo.id && el.status === "ok"))
    const hasApprovedTwice = data.approvals.filter(el => el.status === "ok").length >= 2

    const disabled = isApprovedByUser || hasApprovedTwice

    const isMediumScreen = useIsMediumScreen()

    const isSmallScreen = useIsSmallScreen()

    if (!data) return null

    return <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>

        {data?.third_party_approval_status !== 1 ? (
            <Alert severity={thirdPartyApprovalStatusLookups?.[data.third_party_approval_status] ? "success" : "warning"}>
                <Typography variant="font4">
                    {thirdPartyApprovalStatusLookups?.[data.third_party_approval_status] ?? "Проверен, но не подтвержден третьей стороной"}
                </Typography>
            </Alert>
        ) : hasApprovedTwice ? (
            <Alert severity="success">
                <Typography variant="font4">
                    Раздел подтвержден двумя или более пользователями
                </Typography>
            </Alert>

        ) : isApprovedByUser ? (
            <Alert severity="success">
                <Typography variant="font4">
                    Вы уже проверяли этот раздел анкеты
                </Typography>
            </Alert>
        ) : (<>

            <ButtonGroup disabled={disabled} sx={{ alignSelf: "center" }}>
                <Button variant="contained" onClick={handleOk} sx={{ height: isMediumScreen ? 60 : isSmallScreen ? 120 : undefined }}>
                    {approveButtonLabel}
                </Button>

                <Button variant="contained" color="error" onClick={handleRework} sx={{ height: isMediumScreen ? 60 : isSmallScreen ? 120 : undefined }}>
                    {reworkButtonLabel}
                </Button>
            </ButtonGroup>


            {values.status === "error" && <>
                <Autocomplete
                    options={options}
                    value={values.notice_type}
                    onChange={(_, value) => { setFieldValue("notice_type", value) }}
                    getOptionLabel={option => noticeTypeLookups[option]?.name ?? ""}
                    renderInput={props => <TextField
                        label="Причина"
                        required
                        error={touched.notice_type && Boolean(errors.notice_type)}
                        {...props}
                    />}
                />
                <TextField
                    id="comment"
                    name="comment"
                    label="Комментарий"
                    helperText="Заполняется только если необходимо отправить раздел анкеты на доработку"
                    value={values.comment}
                    multiline
                    minRows={2}
                    maxRows={4}
                    onChange={handleChange}
                    disabled={disabled}
                    error={touched.comment && Boolean(errors.comment)}
                    required
                />
                <Button sx={{ alignSelf: "center", maxWidth: 300, height: 50 }} variant="contained" onClick={handleSubmit}>
                    Отправить на доработку с указанным комментарием
                </Button>
            </>}
        </>)}

    </Grid>
}

export default ApprovalActions