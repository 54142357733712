import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSubspecs, subspecSelectors } from "../redux/dicts/subspecsSlice"


const useSubspecs = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(getSubspecs())
    }, [dispatch])

    const subspecs = useSelector(subspecSelectors.selectAll)
    const subspecOptions = useSelector(subspecSelectors.selectIds)
    const subspecLookups = useSelector(subspecSelectors.selectEntities)
    const subspecsIsLoading = useSelector(x => x.dicts.subspecs.isLoading)

    return { subspecs, subspecOptions, subspecLookups, subspecsIsLoading }
}

export default useSubspecs