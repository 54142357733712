const localization = {
    body: {
        emptyDataSourceMessage: "Нет записей для отображения",
        addTooltip: "Добавить",
        deleteTooltip: "Удалить",
        editTooltip: "Редактировать",
        filterRow: {
            filterTooltip: "Фильтровать",
        },
        editRow: {
            deleteText: "Вы уверены, что хотите удалить эту строку?",
            cancelTooltip: "Закрыть",
            saveTooltip: "Сохранить"
        }
    },
    header: {
        actions: ""
    },
    pagination: {
        labelDisplayedRows: "{from}-{to} из {count}",
        labelRowsPerPage: "Строк на странице:",
        labelRows: "строк",
        firstAriaLabel: "Первая страница",
        firstTooltip: "Первая страница",
        previousAriaLabel: "Предыдущая страница",
        previousTooltip: "Предыдущая страница",
        nextAriaLabel: "Следующая страница",
        nextTooltip: "Следующая страница",
        lastAriaLabel: "Последняя страница",
        lastTooltip: "Последняя страница",
    },
    toolbar: {
        addRemoveColumns: "Добавить или удалить столбцы",
        nRowsSelected: "{0} строк выбрано",
        showColumnsTitle: "Показать столбцы",
        showColumnsAriaLabel: "Показать столбцы",
        exportName: "Экспорт в CSV",
        searchTooltip: "Поиск",
        searchPlaceholder: "Поиск"
    }
}

export default localization
