import { api } from "./api"

export const dictService = {
    getCitizenships: () => api.get("/dict/citizen/"),
    getDepartments: () => api.get("/dict/department/"),
    getUniversities: () => api.get("/dict/university/"),
    getLanguages: () => api.get("/dict/lang/"),
    getRegions: () => api.get("/dict/regions/"),
    getPreviousEduInstitution: () => api.get("/dict/prev_edu_institution/"),
    getSpecs: params => api.get("/dict/spec/", { params }),
    getCGSpecs: params => api.get("/dict/competitive_group/specs/", { params }),
    getCGSubspecs: params => api.get("/dict/competitive_group/subspecs/", { params }),
    getSubspecs: () => api.get("/dict/subspec/"),
    getSpecialRights: () => api.get("/dict/special_right/"),
    getSubjects: () => api.get("/dict/subject/"),
    getLevels: () => api.get("/dict/levels/"),
    getPersonalIdentityDocTypes: () => api.get("/dict/person_identity_doc_type/"),
    getEducationDocTypes: () => api.get("/dict/education_doc_type/"),
    getExemptionDocs: () => api.get("/dict/exemption_doc/"),
    getExemptionDocCategories: () => api.get("/dict/exemption_doc_category/"),
    getOksoSpecs: params => api.get("/dict/okso_spec/", { params }),
    getAchievements: params => api.get("/dict/achievement/", { params }),
    getCompetitiveGroups: params => api.get("/dict/competitive_group/", { params }),
    getCompetitiveGroup: id => api.get(`/dict/competitive_group/${id}/`),
    getEduForms: () => api.get("/dict/edu_form/"),
    getEduTypes: () => api.get("/dict/edu_type/"),
    getSpecialConditions: () => api.get("/dict/special_condition/"),
    getNoticeTypes: () => api.get(`/dict/notice_type/`)
}