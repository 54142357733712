import { Alert, Box, Divider, Grid, Typography } from "@mui/material"


const ApprovalsHistory = ({ approvals, noticeTypeLookups }) => {

    return <Grid item xs={12}>
        <Typography variant="font1">
            История изменений
        </Typography>
        <Divider sx={{ my: 1 }} />
        {approvals.map(approval => <Alert
            key={approval.id}
            sx={{ mt: 2 }}
            severity={approval.status === "error" ? "error" : "success"}
        >
            <Typography variant="font4">
                {`${approval.username} ${approval.status === "ok" ? "проверил" : "отправил на доработку"} ${new Date(approval.date).toLocaleString()}`}
            </Typography>
            {approval.status === "error" && <Box sx={{display: "flex", flexDirection: "column"}}>
                <Typography variant="">
                    {`Причина: ${noticeTypeLookups[approval.notice_type]?.name ?? "че"}`}
                </Typography>
                <Typography variant="">
                    {`Комментарий: ${approval.comment}`}
                </Typography>
            </Box>}
        </Alert>)}
    </Grid>
}

export default ApprovalsHistory