import { KeyboardBackspace } from "@mui/icons-material"
import { Autocomplete, Box, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import Spinner from "../../components/Spinner"
import routes from "../../components/routes"
import useDepartments from "../../hooks/useDepartments"
import { getDepartmentInfoReport, setCurrentDepartmentForDIReport } from "../../redux/reports/departmentInfoSlice"

const StyledTableCell = props => <TableCell {...props} sx={{ ...props.sx, m: 1, p: 1 }} />

const DepartmentInfoReport = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { departments, departmentsIsLoading } = useDepartments()

    const { current, data, isLoading } = useSelector(x => x.reports.departmentInfo)

    React.useEffect(() => {
        if (current?.id) dispatch(getDepartmentInfoReport({ dep_id: current.id }))
    }, [dispatch, current])

    React.useEffect(() => {
        if (departments?.length > 0 && !current) {
            dispatch(setCurrentDepartmentForDIReport(departments[0]))
        }
        // eslint-disable-next-line
    }, [dispatch, departments])

    const handleChangeDepartment = (_, value) => { dispatch(setCurrentDepartmentForDIReport(value)) }

    const handleNavigateToReports = () => { navigate(routes.reports.path) }

    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 1200, minWidth: 280, margin: "auto" }}>

        <Box sx={{
            m: 1,
            p: 2,
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
            backgroundColor: "secondary.main",
            height: 56,
        }}>
            <IconButton onClick={handleNavigateToReports} size="small">
                <KeyboardBackspace sx={{ color: "white" }} />
            </IconButton>
            <Typography sx={{ ml: 2 }} variant="font2" color="white">
                Ход приемной кампании по институтам
            </Typography>
        </Box>

        <Paper sx={{
            display: "flex",
            flexDirection: "column",
            m: 1, mb: 3, p: 2,
            minHeight: 300,
            maxWidth: 1200,
            overflow: "auto"
        }}>
            <Autocomplete
                value={current}
                options={departments}
                getOptionLabel={option => option?.name ?? ""}
                onChange={handleChangeDepartment}
                disableClearable
                loading={departmentsIsLoading}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={props => <TextField
                    label="Институт"
                    {...props}
                />}
            />

            {isLoading ? <Spinner height={200} /> : (
                <Table sx={{ mt: 2, "& .MuiTableCell-root": { border: "1px solid rgba(224, 224, 224, 1)" } }}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center" rowSpan={2}>Направление</StyledTableCell>
                            <StyledTableCell align="center" rowSpan={2}>Всего бюджетных мест</StyledTableCell>
                            <StyledTableCell align="center" colSpan={6}>Кол-во заявлений</StyledTableCell>
                            <StyledTableCell align="center" colSpan={2}>Оригиналы</StyledTableCell>
                            <StyledTableCell align="center" colSpan={2}>Конкурс</StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell align="center">Общий конкурс</StyledTableCell>
                            <StyledTableCell align="center">Спец. квота</StyledTableCell>
                            <StyledTableCell align="center">Особая квота</StyledTableCell>
                            <StyledTableCell align="center">Целевое</StyledTableCell>
                            <StyledTableCell align="center">C комп. затрат</StyledTableCell>
                            <StyledTableCell align="center">Всего</StyledTableCell>
                            <StyledTableCell align="center">Бюджет</StyledTableCell>
                            <StyledTableCell align="center">С комп. затрат</StyledTableCell>
                            <StyledTableCell align="center">Бюджет</StyledTableCell>
                            <StyledTableCell align="center">С комп. затрат</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.specs.map(spec => <TableRow key={spec.spec_id}>
                            <StyledTableCell>{spec.spec_name}</StyledTableCell>
                            <StyledTableCell align="center">{spec.free_places ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{spec.free_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{spec.special_quota_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{spec.svo_quota_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{spec.target_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{spec.paid_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{spec.total_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{spec.orig_on_budget_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{spec.orig_on_paid_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{spec.budget_on_place_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{spec.paid_on_place_count ?? 0}</StyledTableCell>
                        </TableRow>)}

                        <TableRow>
                            <StyledTableCell>Всего</StyledTableCell>
                            <StyledTableCell align="center">{data?.total?.free_places ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{data?.total?.free_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{data?.total?.special_quota_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{data?.total?.svo_quota_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{data?.total?.target_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{data?.total?.paid_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{data?.total?.total_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{data?.total?.orig_on_budget_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{data?.total?.orig_on_paid_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{data?.total?.budget_on_place_count ?? 0}</StyledTableCell>
                            <StyledTableCell align="center">{data?.total?.paid_on_place_count ?? 0}</StyledTableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            )}
        </Paper>
    </Box>
}

export default DepartmentInfoReport