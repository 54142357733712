import React from "react"
import { useSelector } from "react-redux"
import MaterialTableWrapper from "../../components/MaterialTableWrapper"
import { dialogTypes } from "../../constants"
import useMenu from "../../hooks/useMenu"
import useSpecs from "../../hooks/useSpecs"
import useSubspecs from "../../hooks/useSubspecs"
import useUniversities from "../../hooks/useUniversities"
import { getLookups } from "../../utils"
import CompetitiveGroupHistoryDialog from "./CompetitiveGroupHistoryDialog"
import CompetitiveGroupListActionMenu from "./CompetitiveGroupListActionMenu"
import CompetitiveGroupListTableToolbar from "./CompetitiveGroupListTableToolbar"
import ReworkDialgog from "./ReworkDialog"
import useCompetitiveGroupActionHandlers from "./hooks/useCompetitiveGroupActionHandlers"
import useCompetitiveGroupHistory from "./hooks/useCompetitiveGroupHistory"
import useCompetitiveGroupTableProps from "./hooks/useCompetitiveGroupTableProps"
import HideColumnsDialog from "./HideColumnsDialog"
import useLocalStorage from "use-local-storage"
import useLevels from "../../hooks/useLevels"
import Spinner from "../../components/Spinner"
import useCitizenships from "../../hooks/useCitizenships"
import useRegions from "../EntrantForm/hooks/useRegions"
import EditDepCodeDialog from "./EditDepCodeDialog"


const CompetitiveGroupListPage = () => {

    const tableRef = React.useRef(null)

    const { dialogIsOpen, dialogType } = useSelector(x => x.ui)
    const reworkDialogIsOpen = dialogIsOpen && [
        dialogTypes.ENTRANT_APPLICATION_REJECT, dialogTypes.ENTRANT_APPLICATION_REWORK, dialogTypes.ENTRANT_APPLICATION_TO_FAIL_COMPETITION
    ].includes(dialogType)

    const historyDialogIsOpen = dialogIsOpen && dialogType === dialogTypes.ENTRANT_APPLICATION_COMPETITIVE_GROUP_HISTORY
    const hideColumnsDialogIsOpen = dialogIsOpen && dialogType === dialogTypes.HIDE_COLUMNS
    const editDepCodeDialogIsOpen = dialogIsOpen && dialogType === dialogTypes.ENTRANT_APPLICATION_DEP_CODE

    const [hiddenColumns, setHiddenColumns] = useLocalStorage("hiddenColumns", [])
    const [displayedLevels, setDisplayedLevels] = useLocalStorage("levels", [])
    const [displayedSpecs, setDisplayedSpecs] = useLocalStorage("specs", [])
    const [displayedSubspecs, setDisplayedSubspecs] = useLocalStorage("subspecs", [])

    const { levelLookups, universityLevelOptions, levelsIsLoading } = useLevels()
    const universityLevelLookups = React.useMemo(() => Object.fromEntries(
        universityLevelOptions.map(el => [el, levelLookups[el]?.name ?? ""]
        )), [universityLevelOptions, levelLookups])

    const { universities, universitiesIsLoading } = useUniversities()
    const universityLookups = React.useMemo(() => getLookups(universities, ["sname"]), [universities])

    const { specs, specsIsLoading, specsByLevelOptions } = useSpecs(displayedLevels.includes(4) ? [...displayedLevels, 5] : displayedLevels)
    const specLookups = React.useMemo(() => getLookups(specs, ["code", "name"], " - "), [specs])

    const { subspecs, subspecOptions, subspecLookups, subspecsIsLoading } = useSubspecs()
    const subspecNameLookups = React.useMemo(() => getLookups(subspecs), [subspecs])

    const { citizenships, citizenshipsIsLoading } = useCitizenships()
    const citizenshipLookups = React.useMemo(() => getLookups(citizenships, ["sname"]), [citizenships])

    const { regions, regionsIsLoading } = useRegions()
    const regionLookups = React.useMemo(() => getLookups(regions), [regions])

    const dictsIsLoading = specsIsLoading || subspecsIsLoading || levelsIsLoading || universitiesIsLoading || citizenshipsIsLoading || regionsIsLoading

    const { anchorEl, open: menuIsOpen, handleDefaultMenuClick, handleMenuClose } = useMenu()

    const [currentCG, setCurrentCG] = React.useState(null)
    const [showOnlyMaxPriority, setShowOnlyMaxPriority] = React.useState(false)

    const {
        statusHistory, priorityHistory, originalHistory, historyIsLoading,
        ...noticeTypeProps
    } = useCompetitiveGroupHistory(historyDialogIsOpen && currentCG)

    const {
        actionsIsLoading, actionHandlersMap, handleMenuOpen, handleMenuCloseAndResetState, handleDialogClose,
        handleCompetitiveGroupHistoryDialogOpen, handleCompetitiveGroupHistoryDialogClose,
        handleHideColumnsDialogOpen, handleHideColumnsDialogClose,
        handleEditDepCodeIntent, handleEditDepCode
    } = useCompetitiveGroupActionHandlers(
        tableRef,
        currentCG, value => setCurrentCG(value), () => setCurrentCG(null),
        handleDefaultMenuClick, handleMenuClose,
    )

    const onShowOnlyMaxPriorityChange = value => {
        setShowOnlyMaxPriority(value)
        tableRef.current.onQueryChange()
    }

    const tableProps = useCompetitiveGroupTableProps(
        specLookups, subspecNameLookups, universityLookups, universityLevelLookups, citizenshipLookups, regionLookups,
        showOnlyMaxPriority,
        hiddenColumns, displayedLevels, displayedSpecs, displayedSubspecs,
        handleMenuOpen, handleCompetitiveGroupHistoryDialogOpen, handleHideColumnsDialogOpen,
        dictsIsLoading,
        onShowOnlyMaxPriorityChange,
    )

    const existingColumns = tableProps.defaultColumns.map(el => el.field)
    const existingColumnsTitles = getLookups(tableProps.defaultColumns, ["title"], "", "field")
    const displayedColums = existingColumns.filter(el => !hiddenColumns.includes(el))

    if (dictsIsLoading) return <Spinner height="100%" />

    return <>

        <MaterialTableWrapper

            tableRef={tableRef}

            components={{
                Toolbar: props => <CompetitiveGroupListTableToolbar
                    tProps={props}
                    tableRef={tableRef}

                    displayedColums={displayedColums}

                    displayedLevels={displayedLevels}
                    onDisplayedLevelsChange={value => {
                        setDisplayedSpecs([])
                        setDisplayedSubspecs([])
                        setDisplayedLevels(value)
                        tableRef.current.onQueryChange()
                    }}
                    universityLevelOptions={universityLevelOptions}
                    universityLevelLookups={universityLevelLookups}

                    displayedSpecs={displayedSpecs}
                    specOptions={specsByLevelOptions}
                    specLookups={specLookups}
                    onDisplayedSpecsChange={value => {
                        setDisplayedSpecs(value)
                        setDisplayedSubspecs([])
                        tableRef.current.onQueryChange()
                    }}

                    displayedSubspecs={displayedSubspecs}
                    subspecOptions={subspecOptions}
                    subspecLookups={subspecLookups}
                    onDisplayedSubspecsChange={value => {
                        setDisplayedSubspecs(value)
                        tableRef.current.onQueryChange()
                    }}
                />
            }}

            {...tableProps}
        />

        <CompetitiveGroupListActionMenu
            currentCG={currentCG}
            anchorEl={anchorEl}
            open={menuIsOpen}
            disabled={actionsIsLoading}
            onClose={handleMenuCloseAndResetState}
            actionHandlersMap={actionHandlersMap}
            onEditDepCode={handleEditDepCodeIntent}
        />

        <ReworkDialgog
            open={reworkDialogIsOpen}
            onClose={handleDialogClose}
            // Временный костыль
            onSave={
                dialogType === dialogTypes.ENTRANT_APPLICATION_REJECT ? actionHandlersMap["perform_reject"] :
                    dialogType === dialogTypes.ENTRANT_APPLICATION_REWORK ? actionHandlersMap["perform_to_rework"] :
                        dialogType === dialogTypes.ENTRANT_APPLICATION_TO_FAIL_COMPETITION ? actionHandlersMap["perform_to_fail_competition"] : null
            }
            {...noticeTypeProps}
        />

        <CompetitiveGroupHistoryDialog
            currentCG={currentCG}
            statusHistory={statusHistory}
            priorityHistory={priorityHistory}
            originalHistory={originalHistory}
            historyIsLoading={historyIsLoading}
            open={historyDialogIsOpen}
            onClose={handleCompetitiveGroupHistoryDialogClose}
            specLookups={specLookups}
            {...noticeTypeProps}
        />

        <HideColumnsDialog
            open={hideColumnsDialogIsOpen}
            hiddenColumns={hiddenColumns}
            onSave={setHiddenColumns}
            existingColumns={existingColumns}
            existingColumnsTitles={existingColumnsTitles}
            onClose={handleHideColumnsDialogClose}
        />

        <EditDepCodeDialog
            open={editDepCodeDialogIsOpen}
            onClose={handleDialogClose}
            onSubmit={handleEditDepCode}
            currentCG={currentCG}
        />
    </>
}

export default CompetitiveGroupListPage