import { Box, Divider, Grid, Link, Paper, TextField, Typography } from "@mui/material"
import React from "react"
import { useParams } from "react-router-dom"
import Spinner from "../../components/Spinner"
import useEntrantUnprocessedDocsData from "./useEntrantUnprocessedDocsData"
import useDisplayedUserInfo from "../../hooks/useDisplayedUserInfo"
import { useSelector } from "react-redux"
import EntrantInfoField from "../EntrantAllInfoPage/EntrantInfoField"

const EntrantUnprocessedDocs = () => {

    const { id } = useParams()

    const userInfoHasLoaded = useDisplayedUserInfo(id)

    const user = useSelector(x => x.user.displayedUserInfo)

    const { data, isLoading } = useEntrantUnprocessedDocsData(userInfoHasLoaded && id)

    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 1200, minWidth: 500, margin: "auto" }}>

        <Box
            sx={{
                m: 1,
                p: 2,
                borderRadius: 1,
                display: "flex",
                backgroundColor: "primary.grey",
            }}
        >
            {(isLoading || !userInfoHasLoaded) ? (
                <Typography variant="font2" color="white">Загрузка...</Typography>
            ) : (
                <Typography variant="font2" color="white">
                    {`${user?.surname ?? ""} ${user?.name ?? ""} ${user?.patronymic ?? ""} - необработанные документы из ЕПГУ`}
                </Typography>
            )}
        </Box>

        {(isLoading || !userInfoHasLoaded) ? <Spinner height="100%" /> : (
            <Paper sx={{ m: 1, p: 2, overflow: "auto", width: "calc(100% - 18px)", height: "calc(100vh - 90px)" }}>
                <Grid container spacing={2}>

                    {data?.map((doc, index) => <React.Fragment key={doc.id}>

                        <Grid item xs={12} sx={{ mt: index !== 0 ? 4 : undefined }}>
                            <Typography variant="font2">
                                {doc?.doc_name ?? "Документ без названия"}
                            </Typography>
                            <Divider />
                        </Grid>

                        <EntrantInfoField
                            name="ID в ЛК абитуриента"
                            value={doc.id}
                        />
                        <EntrantInfoField
                            name="GUID ЕПГУ"
                            value={doc.guid}
                        />
                        <EntrantInfoField
                            name="Серия документа"
                            value={doc.doc_series}
                        />
                        <EntrantInfoField
                            name="Номер документа"
                            value={doc.doc_number}
                        />
                        <EntrantInfoField
                            name="Дата выдачи документа"
                            value={doc.issue_date}
                            type="date"
                        />
                        <EntrantInfoField
                            name="Кем выдан"
                            value={doc.doc_organization}
                        />

                        {(doc.raw && doc.raw !== "") && <Grid item xs={12}>
                            <TextField
                                label="Необработанные данные документа"
                                value={doc.raw}
                                multiline
                                minRows={2}
                                maxRows={10}
                                inputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Grid>}

                        {doc?.files?.length > 0 && <Grid item xs={12}>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {doc?.files?.map((file, index) => <Link
                                    key={index}
                                    href={file}
                                    target="_blank"
                                >
                                    {`${doc.doc_name} - ${index + 1}`}
                                </Link>)}
                            </Box>
                        </Grid>}

                    </React.Fragment>)}
                </Grid>
            </Paper>
        )}
    </Box>
}

export default EntrantUnprocessedDocs