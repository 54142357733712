import { Add, ArrowRightAltOutlined, Delete } from "@mui/icons-material"
import { operatorService } from "../../services/operatorService"

const useStaticRatingsTableProps = (levelLookups, onAdd, onDelete, onNavigateToRating) => {

    const title = "Статические рейтинг-листы"

    const data = query => new Promise((resolve, reject) => {
        operatorService.getStaticRatings().then(res => {
            resolve({
                data: res,
                totalCount: res.length,
                page: query.page
            })
        }).catch(e => {
            console.error(e)
            reject("Произошла ошибка при загрузке списка рейтинг-листов")
        })
    })

    const columns = [
        { title: "Дата формирования", field: "datetime", type: "datetime", },
        { title: "Уровень образования", field: "level", lookup: levelLookups },
        { title: "Бюджет", field: "is_budget", type: "boolean" },
    ]

    const actions = [
        {
            icon: Add,
            iconProps: { color: "secondary" },
            tooltip: "Создать рейтинг-лист",
            onClick: onAdd,
            isFreeAction: true
        },
        {
            icon: ArrowRightAltOutlined,
            iconProps: { color: "secondary" },
            tooltip: "Перейти к рейтинг-листу",
            onClick: onNavigateToRating,
        },
        {
            icon: Delete,
            iconProps: { color: "error" },
            tooltip: "Удалить рейтинг-лист",
            onClick: onDelete,
        },
    ]

    const options = {
        pageSize: 50,
        pageSizeOptions: [10, 25, 50, 100],
        padding: "dense",
        maxBodyHeight: "calc(100vh - 117px)",
        minBodyHeight: "calc(100vh - 117px)",
        debounceInterval: 1000,
        headerStyle: { position: "sticky", top: 0, zIndex: 1 },
        maxColumnSort: 0,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1
    }

    const style = {
        margin: "auto",
        alignSelf: "center",
        maxWidth: 800,
        minWidth: 400,
    }

    return {
        title,
        data,
        columns,
        actions,
        options,
        style
    }
}

export default useStaticRatingsTableProps