import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import * as yup from "yup"

const StaticRatingForm = ({ open, onClose, onSave, data, levelOptions, levelLookups }) => {

    const [isLoading, setIsLoading] = React.useState(false)

    const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
        initialValues: data,
        enableReinitialize: true,
        onSubmit: values => {
            setIsLoading(true)
            onSave(values).then(() => setIsLoading(false)).catch(() => setIsLoading(false))
        },
        validationSchema: yup.object({
            level: yup.number().required(),
            is_budget: yup.bool().required()
        })
    })

    if (!open || !values || !data) return null

    return <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Создание рейтинг-листа</DialogTitle>
        <DialogContent>
            <Autocomplete
                value={values.level}
                options={levelOptions}
                getOptionLabel={option => levelLookups[option] ?? ""}
                onChange={(_, value) => setFieldValue("level", value)}
                renderInput={props => <TextField
                    label="Уровень образования"
                    error={touched.level && Boolean(errors.level)}
                    {...props}
                />}
            />
            <FormControlLabel
                label="Бюджет"
                control={<Checkbox
                    checked={values.is_budget}
                    onChange={(_, value) => setFieldValue("is_budget", value)}
                />}
            />
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={handleSubmit} disabled={isLoading}>
                Сохранить
            </Button>
            <Button variant="contained" color="error" onClick={onClose} disabled={isLoading}>
                Закрыть
            </Button>
        </DialogActions>
    </Dialog>
}

export default StaticRatingForm