import { createSlice } from "@reduxjs/toolkit";
import { entrantFormService } from "../../services/entrantService";
import { createCustomAsyncThunk } from "../utils";

export const getSatExamsInfo = createCustomAsyncThunk(
    "entrantForm/satExams/get",
    async userId => await entrantFormService.getSatExams(userId)
)

export const updateSatExamsInfo = createCustomAsyncThunk(
    "entrantForm/satExams/update",
    async data => {
        const requests = []
        data.created.forEach(el => requests.push(entrantFormService.postSatExams(el)))
        data.updated.forEach(el => requests.push(entrantFormService.patchSatExams(el)))
        data.deleted.forEach(el => requests.push(entrantFormService.deleteSatExams(el)))
        return Promise.all(requests).then(() => entrantFormService.getSatExams(data.userId))
    }
)

const satExamsInfoSlice = createSlice({
    name: "entrantForm/satExams",
    initialState: {
        data: [],
        isLoading: true,
        error: null,
    },
    extraReducers: builder => {
        builder
            .addCase(getSatExamsInfo.pending, state => { state.isLoading = true })
            .addCase(getSatExamsInfo.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(getSatExamsInfo.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
        
            .addCase(updateSatExamsInfo.pending, state => { state.isLoading = true })
            .addCase(updateSatExamsInfo.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(updateSatExamsInfo.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export default satExamsInfoSlice.reducer