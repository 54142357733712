import { createSlice } from "@reduxjs/toolkit";
import { entrantApplicationService } from "../../services/entrantService";
import { createCustomAsyncThunk } from "../utils";

export const getAppPriorities = createCustomAsyncThunk(
    "entrantApplications/appPriorities/getCommon",
    async id => await entrantApplicationService.getPriorities({ id, is_target: false })
)

export const getTargetAppPriorities = createCustomAsyncThunk(
    "entrantApplications/appPriorities/getTarget",
    async id => await entrantApplicationService.getPriorities({ id, is_target: true })
)

export const saveCommonPriorities = createCustomAsyncThunk(
    "entrantApplications/appPriorities/postCommon",
    async data => await entrantApplicationService.postPriority(data)
)

export const saveTargetPriorities = createCustomAsyncThunk(
    "entrantApplications/appPriorities/postTarget",
    async data => await entrantApplicationService.postPriority(data)
)


const appPrioritiesSlice = createSlice({
    name: "entrantApplications/appPriorities",
    initialState: {
        commonPriorities: [],
        commonPrioritiesIsLoading: false,
        targetPriorities: [],
        targetPrioritiesIsLoading: false,
        error: null,
    },
    reducers: {
        swapCommonPriorities: (state, action) => {
            const result = Array.from(state.commonPriorities)
            const [removed] = result.splice(action.payload.src, 1)
            result.splice(action.payload.dst, 0, removed)
            state.commonPriorities = result
        },
        swapTargetPriorities: (state, action) => {
            const result = Array.from(state.targetPriorities)
            const [removed] = result.splice(action.payload.src, 1)
            result.splice(action.payload.dst, 0, removed)
            state.targetPriorities = result
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAppPriorities.pending, state => { state.commonPrioritiesIsLoading = true })
            .addCase(getAppPriorities.fulfilled, (state, action) => {
                state.commonPrioritiesIsLoading = false
                state.commonPriorities = action.payload.map((el, index) => ({ ...el, id: `nt-draggable-${index}` }))
            })
            .addCase(getAppPriorities.rejected, (state, action) => {
                state.commonPrioritiesIsLoading = false
                state.error = action.error
            })

            .addCase(saveCommonPriorities.pending, state => { state.commonPrioritiesIsLoading = true })
            .addCase(saveCommonPriorities.fulfilled, (state, action) => {
                state.commonPrioritiesIsLoading = false
                state.commonPriorities = action.payload.map((el, index) => ({ ...el, id: `nt-draggable-${index}` }))
            })
            .addCase(saveCommonPriorities.rejected, (state, action) => {
                state.commonPrioritiesIsLoading = false
                state.error = action.error
            })

            .addCase(getTargetAppPriorities.pending, state => { state.targetPrioritiesIsLoading = true })
            .addCase(getTargetAppPriorities.fulfilled, (state, action) => {
                state.targetPrioritiesIsLoading = false
                state.targetPriorities = action.payload.map((el, index) => ({ ...el, id: `t-draggable-${index}` }))
            })
            .addCase(getTargetAppPriorities.rejected, (state, action) => {
                state.targetPrioritiesIsLoading = false
                state.error = action.error
            })

            .addCase(saveTargetPriorities.pending, state => { state.commonPrioritiesIsLoading = true })
            .addCase(saveTargetPriorities.fulfilled, (state, action) => {
                state.commonPrioritiesIsLoading = false
                state.commonPriorities = action.payload.map((el, index) => ({ ...el, id: `t-draggable-${index}` }))
            })
            .addCase(saveTargetPriorities.rejected, (state, action) => {
                state.commonPrioritiesIsLoading = false
                state.error = action.error
            })
    }
})

export const { swapCommonPriorities, swapTargetPriorities } = appPrioritiesSlice.actions

export default appPrioritiesSlice.reducer