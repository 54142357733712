import { Box, IconButton, Typography } from "@mui/material"
import JpgFileIcon from "./JpgFileIcon"
import PngFileIcon from "./PngFileIcon"
import PdfFileIcon from "./PdfFileIcon"
import { Close } from "@mui/icons-material"
import OtherFileIcon from "./OtherFileIcon"

const typesMap = {
    "image/jpg": JpgFileIcon,
    "image/jpeg": JpgFileIcon,
    "image/png": PngFileIcon,
    "application/pdf": PdfFileIcon,
}

const FileIcon = ({ name, type, onDelete }) => {

    const Icon = typesMap[type] ?? OtherFileIcon

    return <Box sx={{ width: 70.5, height: 78.5, m: 1 }}>

        <Icon />

        <Typography sx={{ mx: 1, position: "relative", top: -50, fontSize: 10 }} noWrap>
            {name}
        </Typography>

        <IconButton
            sx={{ height: 20, width: 20, position: "relative", top: -103, left: 50 }}
            onClick={onDelete}
            size="small"
            disableRipple
        >
            <Close  color="error" fontSize="small" />
        </IconButton>

    </Box>
}

export default FileIcon