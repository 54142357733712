import { createSlice } from "@reduxjs/toolkit";
import { dictService } from "../../services/dictService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getAchievementTypes = createCustomAsyncThunk(
    "dicts/achievementTypes/get",
    async params => await dictService.getAchievements(params)
)

const achievementTypesSlice = createSlice({
    name: "dicts/achievementTypes",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getAchievementTypes.pending, state => { state.isLoading = true })
            .addCase(getAchievementTypes.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getAchievementTypes.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const achievementTypeSelectors = defaultEntityAdapter.getSelectors(x => x.dicts.achievementTypes)

export default achievementTypesSlice.reducer