import AlertDialog from "../../components/AlertDialog"
import MaterialTableWrapper from "../../components/MaterialTableWrapper"
import { dialogModes, dialogTypes, operatorRoles } from "../../constants"
import useDialogProps from "../../hooks/useDialogProps"
import useLevels from "../../hooks/useLevels"
import usePermissionRedirect from "../../hooks/usePermissionRedirect"
import useUniversities from "../../hooks/useUniversities"
import { getLookups } from "../../utils"
import OrderForm from "./OrderForm"
import useOrdersTableActionHandlers from "./hooks/useOrdersTableActionHandlers"
import useOrderTableProps from "./hooks/useOrdersTableProps"
import React from "react"

const OrdersPage = () => {

    usePermissionRedirect([operatorRoles.ADMIN, operatorRoles.DOC_EDITOR])

    const tableRef = React.useRef(null)

    const { universities, universityOptions } = useUniversities()
    const universityLookups = getLookups(universities, ["sname"])

    const { universityLevelOptions, levelLookups } = useLevels()
    const universityLevelLookups = React.useMemo(() => Object.fromEntries(
        universityLevelOptions.map(el => [el, levelLookups[el]?.name ?? ""]
        )), [universityLevelOptions, levelLookups])

    const { dialogData, dialogMode, dialogType, dialogIsOpen, openDialog, closeDialog } = useDialogProps()

    const {
        handleCreateOrderIntent, handleCreateOrder,
        handleEditOrderIntent, handleEditOrder,
        handleDeleteOrderIntent, handleDeleteOrder,
        handleCloseOrderIntent, handleCloseOrder,
        handleOpenOrder,
        handleNavigateToOrder
    } = useOrdersTableActionHandlers(tableRef, dialogData, openDialog, closeDialog)

    const tableProps = useOrderTableProps(
        universityLookups,
        universityLevelLookups,
        handleCreateOrderIntent,
        handleEditOrderIntent,
        handleDeleteOrderIntent,
        handleCloseOrderIntent,
        handleOpenOrder,
        handleNavigateToOrder
    )

    const editDialogIsOpen = dialogIsOpen && dialogMode !== dialogModes.DELETE && dialogType === dialogTypes.ORDER
    const deleteDialogIsOpen = dialogIsOpen && dialogMode === dialogModes.DELETE && dialogType === dialogTypes.ORDER
    const closeDialogIsOpen = dialogIsOpen && dialogType === dialogTypes.ORDER_CLOSE

    return <>
        <MaterialTableWrapper

            tableRef={tableRef}

            {...tableProps}
        />

        <OrderForm
            open={editDialogIsOpen}
            onClose={closeDialog}
            data={dialogData}
            mode={dialogMode}
            levelOptions={universityLevelOptions}
            levelLookups={universityLevelLookups}
            universityOptions={universityOptions}
            universityLookups={universityLookups}
            onSave={dialogMode === dialogModes.CREATE ? handleCreateOrder : handleEditOrder}
        />

        <AlertDialog
            open={deleteDialogIsOpen}
            title="Удаление приказа"
            description="Вы уверены, что хотите удалить приказ?"
            onOk={handleDeleteOrder}
            onCancel={closeDialog}
        />

        <AlertDialog
            open={closeDialogIsOpen}
            title="Закрытие приказа"
            description="Вы уверены, что хотите закрыть приказ?"
            onOk={handleCloseOrder}
            onCancel={closeDialog}
        />
    </>
}

export default OrdersPage