import { api } from "./api"

export const entrantFormService = {
    getPersonalInfo: userId => api.get("/entrant/personal_infos/", { params: { user: userId } }),
    postPersonalInfo: data => api.post("/entrant/personal_infos/", data),
    patchPersonalInfo: data => api.patch(`/entrant/personal_infos/${data.id}/`, data),

    getPassportInfo: userId => api.get("/entrant/passports/", { params: { user: userId } }),
    postPassportInfo: data => api.post("/entrant/passports/", data),
    patchPassportInfo: data => api.patch(`/entrant/passports/${data.id}/`, data),

    getOldPassportInfo: userId => api.get("/entrant/old_passports/", { params: { user: userId } }),
    postOldPassportInfo: data => api.post("/entrant/old_passports/", data),
    patchOldPassportInfo: data => api.patch(`/entrant/old_passports/${data.id}/`, data),
    deleteOldPassportInfo: data => api.delete(`/entrant/old_passports/${data.id}/`).then(() => ({ data })),

    getPrevEduInfo: userId => api.get("/entrant/prev_edus/", { params: { user: userId } }),
    postPrevEduInfo: data => api.post("/entrant/prev_edus/", data),
    patchPrevEduInfo: data => api.patch(`/entrant/prev_edus/${data.id}/`, data),

    getOptions: userId => api.get("/entrant/options/", { params: { user: userId } }),
    postOptions: data => api.post("/entrant/options/", data),
    patchOptions: data => api.patch(`/entrant/options/${data.id}/`, data),

    getSatExams: userId => api.get("/entrant/sat_exams/", { params: { user: userId } }),
    postSatExams: data => api.post("/entrant/sat_exams/", data),
    patchSatExams: data => api.patch(`/entrant/sat_exams/${data.id}/`, data),
    deleteSatExams: data => api.delete(`/entrant/sat_exams/${data.id}/`, data),

    getPayerInfo: userId => api.get("/entrant/payers/", { params: { user: userId } }),
    postPayerInfo: data => api.post("/entrant/payers/", data),
    patchPayerInfo: data => api.patch(`/entrant/payers/${data.id}/`, data),
    deletePayerInfo: data => api.delete(`/entrant/payers/${data.id}/`).then(() => ({ data })),

    getPayerOrgInfo: userId => api.get("/entrant/payers_org/", { params: { user: userId } }),
    postPayerOrgInfo: data => api.post("/entrant/payers_org/", data),
    patchPayerOrgInfo: data => api.patch(`/entrant/payers_org/${data.id}/`, data),
    deletePayerOrgInfo: data => api.delete(`/entrant/payers_org/${data.id}/`).then(() => ({ data })),


    getSpecialRights: userId => api.get("/entrant/special_rights/", { params: { user: userId } }),
    postSpecialRights: data => api.post("/entrant/special_rights/", data),
    patchSpecialRights: data => api.patch(`/entrant/special_rights/${data.id}/`, data),
    deleteSpecialRights: data => api.delete(`/entrant/special_rights/${data.id}/`, data),

    getExemptions: userId => api.get("/entrant/exemption/", { params: { user: userId } }),
    postExemptions: data => api.post("/entrant/exemption/", data),
    patchExemptions: data => api.patch(`/entrant/exemption/${data.id}/`, data),
    deleteExemptions: data => api.delete(`/entrant/exemption/${data.id}/`, data),

    getOlymps: userId => api.get("/entrant/olymps/", { params: { user: userId } }),
    postOlymp: data => api.post("/entrant/olymps/", data),
    patchOlymp: data => api.patch(`/entrant/olymps/${data.id}/`, data),
    deleteOlymp: data => api.delete(`/entrant/olymps/${data.id}/`, data),

    getTargetContract: userId => api.get("/entrant/target_contracts/", { params: { user: userId } }),
    postTargetContract: data => api.post("/entrant/target_contracts/", data),
    patchTargetContract: data => api.patch(`/entrant/target_contracts/${data.id}/`, data),
    deleteTargetContract: data => api.delete(`/entrant/target_contracts/${data.id}/`, data),

    getAchievements: userId => api.get("/entrant/achievements/", { params: { user: userId } }),
    postAchievement: data => api.post("/entrant/achievements/", data),
    patchAchievement: data => api.patch(`/entrant/achievements/${data.id}/`, data),
    deleteAchievement: data => api.delete(`/entrant/achievements/${data.id}/`, data),

    getDirEduMin: userId => api.get("/entrant/direction_edu_ministry/", { params: { user: userId } }),
    postDirEduMin: data => api.post("/entrant/direction_edu_ministry/", data),
    patchDirEduMin: data => api.patch(`/entrant/direction_edu_ministry/${data.id}/`, data),

    getSupFiles: userId => api.get(`/entrant/sup_files/${userId}/`).then(res => res.data),
    postSupFiles: ({ userId, ...data }) => api.post(`/entrant/sup_files/${userId}/`, data).then(res => res.data),
    deleteSupFile: id => api.delete(`/files/${id}/`),
    postTempFile: async file => {
        const formData = new FormData()
        formData.append("file", file, file.name)
        const config = { headers: { "Content-Type": "multipart/form-data" } }
        return api.post(`/files/`, formData, config).then(res => res.data)
    },

    getMilitaryDoc: userId => api.get("/entrant/military_doc/", { params: { user: userId } }),
    postMilitaryDoc: data => api.post("/entrant/military_doc/", data),
    patchMilitaryDoc: data => api.patch(`/entrant/military_doc/${data.id}/`, data),

    getPrevEduOriginal: user => api.get("/entrant/original/", { params: { user } }),
    postPrevEduOriginal: data => api.post("/entrant/original/", data),
}

export const entrantSurveyService = {
    get: userId => api.get("/entrant/questionnaires/", { params: { user: userId } }),
    post: data => api.post("/entrant/questionnaires/", data),
    patch: data => api.patch(`/entrant/questionnaires/${data.id}/`, data),
}

export const entrantNotificationsService = {
    getNotifications: user => api.get("/entrant_app/notice/", { params: { user } }),
    getStatusHistory: app_item => api.get("/entrant_app/notice/", { params: { app_item } }),
    getPriorityHistory: app_item => api.get("entrant_app/notice_priority/", { params: { app_item } }),
    getOriginalHistory: user => api.get("entrant_app/notice_original/", { params: { user } }),
}

export const entrantAllInfoService = {
    get: user => api.get(`/entrant/entrant_all_info/${user}/`),
    approveExemption: ({ id, ...data }) => api.post(`/entrant/exemption/${id}/approve/`, data),
    approveAchievement: ({ id, ...data }) => api.post(`/entrant/achievements/${id}/approve/`, data),
    approveDirectionEduMinistry: ({ id, ...data }) => api.post(`/entrant/direction_edu_ministry/${id}/approve/`, data),
    approveOldPassport: ({ id, ...data }) => api.post(`/entrant/old_passports/${id}/approve/`, data),
    approvePassport: ({ id, ...data }) => api.post(`/entrant/passports/${id}/approve/`, data),
    approvePersonalInfo: ({ id, ...data }) => api.post(`/entrant/personal_infos/${id}/approve/`, data),
    approvePrevEdu: ({ id, ...data }) => api.post(`/entrant/prev_edus/${id}/approve/`),
    approveTargetContract: ({ id, ...data }) => api.post(`/entrant/target_contracts/${id}/approve/`, data),
}

export const entrantUnprocessedDocsService = {
    get: entrant_ss__user => api.get("/super_service/documentss/", { params: { entrant_ss__user } })
}

export const entrantApplicationService = {
    get: userId => api.get("/entrant_app/app/", { params: { user: userId } }),
    post: data => api.post("/entrant_app/app/", data),
    deleteApp: data => api.delete(`/entrant_app/app/${data.id}/`).then(() => ({ data })),

    getItems: userId => api.get("/entrant_app/app_item/", { params: { user: userId } }),
    getItem: id => api.get(`/entrant_app/app_item/${id}/`),
    postItem: data => api.post("/entrant_app/app_item/", data),
    patchItem: data => api.patch(`/entrant_app/app_item/${data.id}/`, data),
    delete: data => api.delete(`/entrant_app/app_item/${data.id}/`).then(() => ({ data })),

    getPriorities: params => api.get(`/entrant_app/app/${params.id}/priorities/`, { params: { is_target: params.is_target } }),
    postPriority: ({ app, ...data }) => api.post(`/entrant_app/app/${app}/priorities/`, data),

    getAvailableTestSubjects: app => api.get("/entrant_app/available_test_subjects/", { params: { app } }),
    getEntranceTestRegistration: app => api.get("/entrant_app/entrance_test_registration/", { params: { app } }),
    postEntranceTestRegistration: data => api.post("/entrant_app/entrance_test_registration/", data, { params: { app: data.app } }),
    deleteEntranceTestRegistration: data => api.delete(`/entrant_app/entrance_test_registration/${data.id}/`, { params: { app: data.app } }).then(() => ({ data })),

    // Действия
    submit: data => api.post(`/entrant_app/app_item/${data.id}/submit/`, data),
    withdraw: data => api.post(`/entrant_app/app_item/${data.id}/withdraw/`, data),
    accept: data => api.post(`/entrant_app/app_item/${data.id}/accept/`),
    take_for_review: data => api.post(`/entrant_app/app_item/${data.id}/take_for_review/`),
    to_rework: data => api.post(`/entrant_app/app_item/${data.id}/to_rework/`, data),
    reject: data => api.post(`/entrant_app/app_item/${data.id}/reject/`, data),
    to_order: data => api.post(`/entrant_app/app_item/${data.id}/to_order/`, data),
    refuse_enroll: data => api.post(`/entrant_app/app_item/${data.id}/refuse_enroll/`, data),
    enroll: data => api.post(`/entrant_app/app_item/${data.id}/enroll/`, data),
    to_fail_competition: data => api.post(`/entrant_app/app_item/${data.id}/to_fail_competition/`, data),
    to_competitive: data => api.post(`/entrant_app/app_item/${data.id}/to_competitive/`, data),
    add_confirmation: data => api.post(`/entrant_app/app_item/${data.id}/add_confirmation/`),
    withdraw_confirmation: data => api.post(`/entrant_app/app_item/${data.id}/withdraw_confirmation/`),
    requestContract: data => api.post(`/entrant_app/contract_item/${data.id}/`),
    getContract: data => api.get(`/entrant_app/contract_item/${data.id}/`),
    setTestStatus: data => api.post(`/entrant_app/set_test_status/`, data),

    patchCGDepCode: data => api.put(`/operator/applications/update_app_item/${data.id}/`, { dep_code: data.dep_code })
}