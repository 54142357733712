import { Add, MoreVert } from "@mui/icons-material"
import { operatorService } from "../../services/operatorService"


const useUserListTableProps = (onOpenUserMenu, onCreateUserIntent) => {

    const title = "Абитуриенты"

    const data = query => new Promise((resolve, reject) => {
        const params = {
            page_size: query.pageSize,
            page: query.page + 1,
            search: query.search,
        }

        query.filters.forEach(filter => {
            params[filter.column.field] = filter.column.type === "boolean" ? filter.value === "checked" : filter.value
        })

        operatorService.getUserList(params).then(res => {
            resolve({
                data: res.results,
                totalCount: res.count,
                page: query.page
            })
        }).catch(e => {
            console.error(e)
            reject("Произошла ошибка при загрузке списка пользователей")
        })
    })

    const columns = [
        { title: "ФИО", field: "full_name", filtering: false, width: 200, },
        { title: "Телефон", field: "phone", filtering: false, width: 145 },
        { title: "СНИЛС", field: "snils_number", filtering: false, width: 150 },
        { title: "Почта", field: "email", filtering: false, width: 150, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 150 } },
        { title: "UID", field: "short_uid", filtering: false, width: 145 },
        { title: "Подан оригинал", field: "has_original", type: "boolean", filtering: false, width: 140 },
        { title: "Из ЕПГУ", field: "is_epgu", type: "boolean", width: 90, },
        { title: "Заполнена анкета", field: "is_full_profile", type: "boolean", width: 100, filtering: false },
    ]

    const actions = [
        rowData => ({
            icon: MoreVert,
            iconProps: { color: rowData?.available_actions?.length === 0 ? "disabled" : "secondary" },
            onClick: onOpenUserMenu,
            disabled: rowData?.available_actions?.length === 0
        }),
        {
            icon: Add,
            iconProps: { color: "primary" },
            tooltip: "Добавить пользователя",
            onClick: onCreateUserIntent,
            isFreeAction: true
        }
    ]


    const options = {
        pageSize: 50,
        pageSizeOptions: [10, 25, 50, 100],
        padding: "dense",
        maxBodyHeight: "calc(100vh - 117px)",
        minBodyHeight: "calc(100vh - 117px)",
        debounceInterval: 1000,
        headerStyle: { position: "sticky", top: 0, zIndex: 1 },
        maxColumnSort: 0,
        tableLayout: "fixed",
        filtering: true,
        emptyRowsWhenPaging: false,
    }

    return {
        title,
        data,
        columns,
        actions,
        options
    }
}

export default useUserListTableProps