import { Add } from "@mui/icons-material"
import { Box, Button } from "@mui/material"
import React from "react"
import Spinner from "../../../../components/Spinner"
import EntrantApplicationTest from "./EntrantApplicationTest"
import EntrantApplicationTestForm from "./EntrantApplicationTestForm"


const EntrantApplicationFormStep4 = ({
    onAdd, onDelete, onDeleteNew, onClose, onSave,
    availableTestSubjectOptions, availableTestSubjectLookups, availableTestSubjectsIsLoading,
    entranceTestRegistrations, entranceTestRegistrationsIsLoading,
}) => {

    const isEditing = Boolean(entranceTestRegistrations.find(el => el.id === null))
    return <>

        <Box sx={{ height: "calc(100% - 60px)", overflow: "auto", pr: 2, pl: 2 }}>
            {availableTestSubjectsIsLoading ? <Spinner height="100%" /> : entranceTestRegistrations.map((test, index) => test.id === null ? (
                <EntrantApplicationTestForm
                    key={index}
                    availableTestSubjectOptions={availableTestSubjectOptions}
                    availableTestSubjectLookups={availableTestSubjectLookups}
                    data={test}
                    onClose={onDeleteNew}
                    onSave={onSave}
                />
            ) : (
                <EntrantApplicationTest
                    key={index}
                    data={test}
                    onDelete={onDelete}
                />
            ))}

            <Button
                disabled={isEditing}
                onClick={onAdd}
                sx={{ my: 1 }}
            >
                <Add /> Добавить вступительное испытание
            </Button>

        </Box>

        <Box sx={{ mt: 1, display: "flex", justifyContent: "flex-end", p: 1 }}>
            <Button variant="contained" color="error" onClick={onClose}>
                Закрыть
            </Button>
        </Box>
    </>
}

export default EntrantApplicationFormStep4