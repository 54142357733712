import React from "react"

const useMenu = () => {

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleMenuClick = target => { setAnchorEl(target) }

    const handleDefaultMenuClick = event => setAnchorEl(event.currentTarget)

    const handleMenuClose = () => { setAnchorEl(null) }

    return { anchorEl, open, handleMenuClick, handleDefaultMenuClick, handleMenuClose }
}

export default useMenu