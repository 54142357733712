import { createSlice } from "@reduxjs/toolkit";
import { dictService } from "../../services/dictService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getSubjects = createCustomAsyncThunk(
    "dicts/subjects/get",
    async () => await dictService.getSubjects()
)

const subjectsSlice = createSlice({
    name: "dicts/subjects",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getSubjects.pending, state => { state.isLoading = true })
            .addCase(getSubjects.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getSubjects.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const subjectSelectors = defaultEntityAdapter.getSelectors(x => x.dicts.subjects)

export default subjectsSlice.reducer