const JpgFileIcon = () => {
    return <svg width="72" height="80" viewBox="0 0 72 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.75" y="0.75" width="70.5" height="78.5" rx="3.25" fill="white" />
        <g opacity="0.32">
            <path d="M44.985 12H15.963C15.155 12 14.5 12.655 14.5 13.926V67C14.5 67.345 15.155 68 15.963 68H56.037C56.845 68 57.5 67.345 57.5 67V24.978C57.5 24.282 57.407 24.058 57.243 23.893L45.607 12.257C45.442 12.093 45.218 12 44.985 12Z" fill="#E9E9E0" />
            <path d="M45.5 12.1509V23.9999H57.349L45.5 12.1509Z" fill="#D9D7CA" />
            <path d="M26.9313 30.9998C29.4547 30.9998 31.5003 28.9542 31.5003 26.4308C31.5003 23.9074 29.4547 21.8618 26.9313 21.8618C24.4079 21.8618 22.3623 23.9074 22.3623 26.4308C22.3623 28.9542 24.4079 30.9998 26.9313 30.9998Z" fill="#F3D55B" />
            <path d="M14.5 51H25.5H57.5V40L47.5 30.5L37 42L31.517 36.517L14.5 51Z" fill="#26B99A" />
            <path d="M56.037 68H15.963C15.155 68 14.5 67.345 14.5 66.537V51H57.5V66.537C57.5 67.345 56.845 68 56.037 68Z" fill="#14A085" />
            <path d="M29.4258 54.6499V62.4979C29.4258 62.9719 29.3388 63.3709 29.1658 63.6939C28.9928 64.0169 28.7598 64.2769 28.4688 64.4729C28.1768 64.6689 27.8418 64.8059 27.4638 64.8829C27.0848 64.9609 26.6958 64.9999 26.2948 64.9999C26.0938 64.9999 25.8588 64.9789 25.5908 64.9379C25.3218 64.8969 25.0438 64.8339 24.7568 64.7469C24.4698 64.6599 24.1938 64.5619 23.9298 64.4529C23.6648 64.3439 23.4418 64.2209 23.2598 64.0839L23.9568 62.9769C24.0478 63.0399 24.1778 63.1069 24.3468 63.1749C24.5148 63.2429 24.6998 63.3069 24.9008 63.3659C25.1008 63.4259 25.3108 63.4769 25.5298 63.5229C25.7488 63.5689 25.9538 63.5909 26.1448 63.5909C26.6278 63.5909 27.0128 63.4969 27.2998 63.3109C27.5868 63.1249 27.7388 62.8069 27.7578 62.3609V54.6499H29.4258Z" fill="white" />
            <path d="M33.514 64.932H31.873V54.855H34.771C35.199 54.855 35.623 54.923 36.042 55.06C36.461 55.197 36.837 55.402 37.17 55.675C37.503 55.948 37.772 56.279 37.977 56.666C38.182 57.053 38.285 57.488 38.285 57.972C38.285 58.483 38.198 58.945 38.025 59.36C37.852 59.775 37.61 60.124 37.3 60.406C36.99 60.688 36.616 60.907 36.179 61.062C35.742 61.217 35.258 61.294 34.73 61.294H33.513L33.514 64.932ZM33.514 56.1V60.092H35.018C35.218 60.092 35.416 60.058 35.613 59.989C35.809 59.921 35.989 59.809 36.153 59.654C36.317 59.499 36.449 59.283 36.549 59.005C36.649 58.727 36.699 58.383 36.699 57.973C36.699 57.809 36.676 57.619 36.631 57.406C36.585 57.192 36.492 56.987 36.351 56.791C36.209 56.595 36.011 56.431 35.756 56.299C35.5 56.166 35.163 56.1 34.744 56.1H33.514Z" fill="white" />
            <path d="M47.4995 59.7359V63.6319C47.2895 63.8969 47.0555 64.1119 46.7955 64.2809C46.5355 64.4499 46.2625 64.5889 45.9755 64.6979C45.6885 64.8069 45.3925 64.8849 45.0865 64.9299C44.7805 64.9779 44.4785 64.9999 44.1775 64.9999C43.5755 64.9999 43.0225 64.8909 42.5165 64.6719C42.0105 64.4529 41.5685 64.1299 41.1905 63.7009C40.8125 63.2719 40.5155 62.7349 40.3015 62.0879C40.0875 61.4409 39.9805 60.6929 39.9805 59.8459C39.9805 58.9989 40.0875 58.2529 40.3015 57.6109C40.5155 56.9679 40.8115 56.4329 41.1905 56.0049C41.5685 55.5759 42.0125 55.2509 42.5235 55.0269C43.0335 54.8029 43.5855 54.6919 44.1775 54.6919C44.7245 54.6919 45.2345 54.7829 45.7085 54.9649C46.1825 55.1479 46.6055 55.4209 46.9795 55.7849L45.8445 56.7969C45.6255 56.5319 45.3745 56.3409 45.0925 56.2229C44.8095 56.1049 44.5185 56.0449 44.2175 56.0449C43.8805 56.0449 43.5585 56.1079 43.2535 56.2359C42.9475 56.3639 42.6745 56.5799 42.4335 56.8849C42.1915 57.1909 42.0025 57.5839 41.8665 58.0679C41.7305 58.5519 41.6565 59.1429 41.6475 59.8449C41.6565 60.5289 41.7275 61.1209 41.8595 61.6219C41.9915 62.1229 42.1735 62.5329 42.4065 62.8519C42.6395 63.1709 42.9035 63.4079 43.1995 63.5629C43.4955 63.7179 43.8075 63.7949 44.1365 63.7949C44.2365 63.7949 44.3705 63.7879 44.5395 63.7739C44.7075 63.7599 44.8765 63.7379 45.0455 63.7059C45.2135 63.6739 45.3755 63.6309 45.5305 63.5759C45.6855 63.5209 45.7995 63.4439 45.8725 63.3439V60.8559H44.1635V59.7349L47.4995 59.7359Z" fill="white" />
        </g>
        <rect x="0.75" y="0.75" width="70.5" height="78.5" rx="3.25" stroke="#122D52" strokeWidth="1.5" strokeDasharray="8 8" />
    </svg>

}

export default JpgFileIcon