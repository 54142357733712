import { useSelector } from "react-redux"
import routes from "../components/routes"
import React from "react"
import { useNavigate } from "react-router"

const usePermissionRedirect = (roleIds, pathToRedirect = routes.profile.path) => {
    // Редиректит на pathToRedirect, если у пользователя нет прав на просмотр текущей страницы

    const { userInfo, isLoading } = useSelector(x => x.user)

    const navigate = useNavigate()

    React.useEffect(() => {
        if (userInfo?.accesses && !isLoading) {
            const hasAccess = Boolean(userInfo?.accesses?.find(el => roleIds.includes(el.role.id)))
            if (!hasAccess) { navigate(pathToRedirect) }
        }
    }, [navigate, pathToRedirect, roleIds, userInfo, isLoading])
}

export default usePermissionRedirect