import { Box, Link as MuiLink, Paper, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import FileDownloadButton from "../../components/FileDownloadButton"
import routes from "../../components/routes"
import { apiUrl } from "../../services/api"
import { operatorRoles } from "../../constants"

const ReportsPage = () => {

    const userInfo = useSelector(x => x.user.userInfo)

    const isComissionMember = Boolean(
        userInfo?.accesses?.find(el => el.role.id === operatorRoles.ADMIN || el.role.id === operatorRoles.COMMISSION_MEMBER)
    )

    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 650, minWidth: 280, margin: "auto" }}>

        <Box sx={{
            m: 1,
            p: 2,
            borderRadius: 1,
            display: "flex",
            backgroundColor: "secondary.main",
        }}>
            <Typography sx={{ ml: 2 }} variant="font2" color="white">
                Отчеты
            </Typography>
        </Box>

        <Paper sx={{ display: "flex", flexDirection: "column", m: 1, p: 2, minHeight: 300 }}>
            {!isComissionMember && <Typography align="center">Нет доступных отчетов</Typography>}
            {isComissionMember && <FileDownloadButton
                component="link"
                name="Ход приемной кампании (СПО)"
                url={`${apiUrl}/entrant_app/count_app_spo/`}
            />}
            {isComissionMember && <FileDownloadButton
                component="link"
                name="Ход приемной кампании (Бакалавриат / Специалитет)"
                url={`${apiUrl}/entrant_app/count_app_xslx/`}
            />}
            {isComissionMember && <FileDownloadButton
                component="link"
                name="Ход приемной кампании (Магистратура)"
                url={`${apiUrl}/entrant_app/count_app_master/`}
            />}
            {isComissionMember && <MuiLink
                component={Link}
                to={routes.departmentInfoReport.path}
            >
                Ход приемной кампании по институтам
            </MuiLink>}
            {isComissionMember && <MuiLink
                component={Link}
                to={routes.applicationsStatusReport.path}
            >
                Статусы заявлений
            </MuiLink>}
            {isComissionMember && <MuiLink
                component={Link}
                to={routes.averageSatExamPointsReport.path}
            >
                Средний балл ЕГЭ
            </MuiLink>}
            {isComissionMember && <MuiLink
                component={Link}
                to={routes.averageSatExamPointsInKCPReport.path}
            >
                Средний балл ЕГЭ в рамках КЦП
            </MuiLink>}
            {isComissionMember && <FileDownloadButton
                component="link"
                name="Зачисленные по регионам (Бакалавриат / Специалитет)"
                url={`${apiUrl}/operator/reports/region_xlsx/`}
            />}
            {isComissionMember && <FileDownloadButton
                component="link"
                name="Зачисленные абитуриенты, нуждающиеся в общежитии"
                url={`${apiUrl}/operator/reports/in_need_hostel_xlsx/`}
            />}
            {isComissionMember && <MuiLink
                component={Link}
                to={routes.contingentReport.path}
            >
                Выгрузка массивов
            </MuiLink>}
            {isComissionMember && <MuiLink
                component={Link}
                to={routes.regionReport.path}
            >
                Отчет по регионам
            </MuiLink>}
            {isComissionMember && <MuiLink
                component={Link}
                to={routes.countryReport.path}
            >
                Отчет по странам
            </MuiLink>}
            {isComissionMember && <FileDownloadButton
                component="link"
                name="Отчет по регионам и институтам"
                url={`${apiUrl}/operator/reports/region_departments_xlsx/`}
            />}
            {isComissionMember && <MuiLink
                component={Link}
                to={routes.protocolReport.path}
            >
                Протокол заседания ПК по рекомендации к зачислению
            </MuiLink>}
        </Paper>
    </Box>
}
export default ReportsPage