import { ArrowLeft, ArrowRight } from "@mui/icons-material"
import { Box, IconButton, List, ListItem, ListItemText, Paper, Typography, capitalize } from "@mui/material"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Spinner from "../../components/Spinner"
import toastWrapper from "../../components/toastWrapper"
import { applicationStatusLookups, eduFormLookups, eduTypeLookups } from "../../constants"
import useAchievementTypes from "../../hooks/useAchievementTypes"
import useSpecs from "../../hooks/useSpecs"
import { addCGAchievement, deleteCGAchievement } from "../../redux/competitiveGroupAchievementsSlice"
import useCompetitiveGroupAchievements from "./useCompetitiveGroupAchievements"
import useCurrentCompetitiveGroup from "./useCurrentCompetitiveGroup"


const CompetitiveGroupAchievements = () => {

    const { id } = useParams()

    const { current, currentIsLoading } = useCurrentCompetitiveGroup(id)

    const { achievements, achievementsIsLoading } = useCompetitiveGroupAchievements(id)
    // const { achievements, achievementsIsLoading } = useCompetitiveGroupAchievements(current?.id)

    const isLoading = currentIsLoading || achievementsIsLoading

    const { specLookups } = useSpecs()

    const { achievementTypeLookups } = useAchievementTypes()

    const getAchievementInfo = data => {
        const infos = [data.achievement_name?.trim() ?? null]
        if (data.doc_series && data.doc_series !== "") infos.push(`Серия ${data.doc_series}`)
        if (data.doc_number && data.doc_number !== "") infos.push(`номер ${data.doc_series}`)
        if (data.issue_date && data.issue_date !== "") infos.push(`выдан ${new Date(data.issue_date)?.toLocaleDateString()}`)

        return capitalize(infos.filter(el => el).join(", "))
    }

    const dispatch = useDispatch()

    const handleAddAchievement = achievement => dispatch(addCGAchievement({ id, achievement: achievement.id }))
        .unwrap().catch(e => {
            console.error(e)
            toastWrapper("Не удалось привязать достижение", "error")
        })

    const handleDeleteAchievement = achievement => dispatch(deleteCGAchievement({ id, achievement: achievement.id }))
        .unwrap().catch(e => {
            console.error(e)
            toastWrapper("Не удалось отвязать достижение", "error")
        })

    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 1200, minWidth: 500, margin: "auto" }}>

        <Box
            sx={{
                m: 1,
                p: 2,
                borderRadius: 1,
                // display: "flex",
                backgroundColor: "primary.grey",
                minHeight: 76
            }}
        >
            {isLoading ? (
                <Typography variant="font2" color="white">
                    Загрузка...
                </Typography>
            ) : <>
                <Typography variant="font2" color="white">
                    {isLoading ? "Загрузка..." : `${current?.full_name} - заявление № ${current?.app_number}(${applicationStatusLookups[current?.status] ?? ""})`}
                </Typography>
                <Typography variant="font4" color="white">
                    {`${specLookups[current?.spec]?.code ?? ""} - ${specLookups[current?.spec]?.name ?? ""}, ${eduFormLookups[current?.edu_form]}, ${eduTypeLookups[current?.edu_type]}`}
                </Typography>
            </>}
        </Box>

        <Paper sx={{ m: 1, p: 2, overflow: "auto", width: "calc(100% - 18px)", height: "calc(100vh - 120px)" }}>
            {isLoading ? <Spinner height="100%" /> : <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "45%" }}>
                    <Typography variant="font4">
                        Привязанные достижения
                    </Typography>
                    <List>
                        {achievements?.added?.map((achievement, index) => <ListItem
                            key={index}
                            secondaryAction={<IconButton onClick={() => handleDeleteAchievement(achievement)}>
                                <ArrowRight color="secondary" />
                            </IconButton>}
                        >
                            <ListItemText
                                primary={achievementTypeLookups[achievement.type]?.name}
                                secondary={getAchievementInfo(achievement)}
                            />
                        </ListItem>)}
                    </List>
                </Box>

                <Box sx={{ width: "45%" }}>
                    <Typography variant="font4">
                        Не привязанные достижения
                    </Typography>
                    <List>
                        {achievements?.not_added?.map((achievement, index) => <ListItem
                            key={index}
                            secondaryAction={<IconButton onClick={() => handleAddAchievement(achievement)}>
                                <ArrowLeft color="secondary" />
                            </IconButton>}
                        >
                            <ListItemText
                                primary={achievementTypeLookups[achievement.type]?.name}
                                secondary={getAchievementInfo(achievement)}
                            />
                        </ListItem>)}
                    </List>
                </Box>
            </Box>}
        </Paper>
    </Box>
}
export default CompetitiveGroupAchievements