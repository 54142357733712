import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { achievementTypeSelectors, getAchievementTypes } from "../redux/dicts/achievementTypesSlice"

const useAchievementTypes = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (achievementTypeOptions.length === 0) {
            dispatch(getAchievementTypes())
        }
        // eslint-disable-next-line
    }, [dispatch])

    const achievementTypeOptions = useSelector(achievementTypeSelectors.selectIds)
    const achievementTypeLookups = useSelector(achievementTypeSelectors.selectEntities)
    const achievementTypesIsLoading = useSelector(x => x.dicts.achievementTypes.isLoading)

    return { achievementTypeOptions, achievementTypeLookups, achievementTypesIsLoading }
}

export default useAchievementTypes