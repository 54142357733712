
export const MuiTypography = {
    defaultProps: {
        variantMapping: {
            font1: "h6",
            font2: "h5",
            font3: "p",
            font4: "p",
        },
    },
    variants: [
        {
            props: { variant: "font1" },
            style: {
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "20px",
                lineHeight: "24px",
            }
        },
        {
            props: { variant: "font2" },
            style: {
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "24px",
            }
        },
        {
            props: { variant: "font3" },
            style: {
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "20px",
            }
        },
        {
            props: { variant: "font4" },
            style: {
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "20px",
            }
        },
        {
            props: { variant: "font5" },
            style: {
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
            }
        },
    ]
}
