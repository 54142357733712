import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";


const EntrantApplicationTestForm = ({ data, onSave, onClose, availableTestSubjectOptions, availableTestSubjectLookups, }) => {

    const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
        initialValues: data,
        enableReinitialize: true,
        onSubmit: onSave,
        validationSchema: yup.object({
            subject: yup.number().required(),
            location: yup.number().required(),
        })
    })

    const [locationOptions, locationLookups] = React.useMemo(() => {
        const test = availableTestSubjectLookups[values.subject]
        if (!test) return [[], {}]
        const options = []
        const lookups = {}
        test.locations.forEach(location => {
            options.push(location.id)
            lookups[location.id] = `${location.university}, Язык проведения - ${location.language}, время проведения - ${new Date(location.test_datetime).toLocaleString()}`
        })
        return [options, lookups]
    }, [availableTestSubjectLookups, values.subject])

    return <Box sx={{ borderRadius: 2, backgroundColor: "primary.lightBlue", my: 1, p: 2 }}>
        <Autocomplete
            options={availableTestSubjectOptions}
            value={values.subject}
            onChange={(_, value) => {
                setFieldValue("location", null)
                setFieldValue("subject", value)
            }}
            getOptionLabel={option => availableTestSubjectLookups[option]?.name ?? ""}
            renderInput={props => <TextField
                label="Предмет"
                required
                error={touched.subject && Boolean(errors.subject)}
                {...props}
            />}
        />
        <Autocomplete
            options={locationOptions}
            value={values.location}
            onChange={(_, value) => { setFieldValue("location", value) }}
            getOptionLabel={option => locationLookups[option] ?? ""}
            disabled={!values.subject}
            renderInput={props => <TextField
                label="Место проведения"
                required
                error={touched.location && Boolean(errors.location)}
                {...props}
            />}
        />

        <Box sx={{ mt: 1, display: "flex", justifyContent: "flex-end", p: 1 }}>
            <Button onClick={handleSubmit} variant="contained" sx={{ mr: 2 }}>
                Сохранить
            </Button>
            <Button onClick={onClose} variant="contained" color="error">
                Отмена
            </Button>
        </Box>
    </Box>
}

export default EntrantApplicationTestForm