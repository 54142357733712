import { KeyboardBackspace } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Autocomplete, Box, Checkbox, FormControlLabel, IconButton, Paper, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import { useNavigate } from "react-router-dom"
import routes from "../../components/routes"
import toastWrapper from "../../components/toastWrapper"
import useSpecs from "../../hooks/useSpecs"
import useUniversities from "../../hooks/useUniversities"
import { api } from "../../services/api"


const AverageSatExamsPointsReport = () => {

    const navigate = useNavigate()

    const navigateToReports = () => navigate(routes.reports.path)

    const { universityLookups, universityOptions } = useUniversities()

    const { specsByLevelOptions, specLookups } = useSpecs()

    const [isLoading, setIsLoading] = React.useState(false)

    const { values, setFieldValue, handleSubmit } = useFormik({
        initialValues: {
            university: null,
            spec: null,
            is_budget: true,
        },
        enableReinitialize: true,
        onSubmit: values => {
            setIsLoading(true)
            const link = document.createElement("a")
            link.target = "_blank"
            api.get(`/operator/reports/average_ball_xlsx/`, { responseType: "blob", params: values })
                .then((res) => {
                    link.href = URL.createObjectURL(new Blob([res.data], { type: res.data.type }))
                    link.click()
                    setIsLoading(false)
                })
                .catch(e => {
                    console.error(e)
                    toastWrapper('Не удалось загрузить файл', 'error')
                    setIsLoading(false)
                })
        }
    })

    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 700, minWidth: 280, margin: "auto" }}>

        <Box sx={{
            m: 1,
            p: 2,
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
            backgroundColor: "secondary.main",
            height: 56,
        }}>
            <IconButton onClick={navigateToReports} size="small">
                <KeyboardBackspace sx={{ color: "white" }} />
            </IconButton>
            <Typography sx={{ ml: 2 }} variant="font2" color="white">
                Средний балл ЕГЭ
            </Typography>
        </Box>

        <Paper sx={{
            display: "flex",
            flexDirection: "column",
            m: 1, mb: 3, p: 2,
            minHeight: 265,
            maxWidth: 700,
            overflow: "auto"
        }}>
            <Autocomplete
                options={universityOptions}
                value={values.university}
                onChange={(_, value) => setFieldValue("university", value)}
                getOptionLabel={option => universityLookups[option]?.sname ?? ""}
                renderInput={props => <TextField
                    label="Университет"
                    margin="normal"
                    {...props}
                />}
            />
            <Autocomplete
                options={specsByLevelOptions}
                value={values.spec}
                onChange={(_, value) => setFieldValue("spec", value)}
                getOptionLabel={option => {
                    const spec = specLookups[option]
                    if (!spec) return ""
                    return `${spec.code} - ${spec.name}`
                }}
                renderInput={props => <TextField
                    label="Направление"
                    margin="normal"
                    {...props}
                />}
            />

            <FormControlLabel
                label="Бюджетная основа обучения"
                control={<Checkbox
                    checked={values.is_budget}
                    onChange={(_, value) => setFieldValue("is_budget", value)}
                />}
            />

            <LoadingButton loading={isLoading} variant="contained" onClick={handleSubmit} sx={{ mt: 1 }}>
                Скачать отчет
            </LoadingButton>
        </Paper>
    </Box>
}

export default AverageSatExamsPointsReport