import MaterialTable from "@material-table/core";
import React from "react";
import localization from "./localization";
import { CustomPagination } from "./components";
import tableIcons from "./icons";

const MaterialTableWrapper = props => {
    return <MaterialTable
        {...props}
        icons={tableIcons}
        localization={localization}
        components={{
            Pagination: CustomPagination,
            ...props.components,
        }}
    />
}

export default MaterialTableWrapper