import { Box, Button, IconButton, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import Info from "../../components/Icons/Info";


const ChangePasswordForm = ({ onChange }) => {

    const { handleSubmit, handleChange, values, errors } = useFormik({
        onSubmit: onChange,
        initialValues: {
            old_password: "",
            new_password: "",
            passwordRepeat: "",
        },
        validationSchema: yup.object({
            old_password: yup.string().required("Это поле необходимо заполнить"),
            new_password: yup.string().required("Это поле необходимо заполнить").matches(
                //eslint-disable-next-line
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
                "Пароль должен содержать не менее шести знаков, включать цифры и латинские буквы в разных регистрах"
            ),
            passwordRepeat: yup.string().required("Это поле необходимо заполнить")
                .oneOf([yup.ref("new_password"), null], "Пароли должны совпадать")
        }),
    })

    const [showHint, setShowHint] = React.useState(false)

    return <Box>

        <Typography variant="h6">Смена пароля</Typography>
        
        <form>

            <TextField
                id="old_password"
                name="old_password"
                placeholder="Старый пароль"
                type="password"
                autoComplete="password"
                value={values.old_password}
                onChange={handleChange}
                error={Boolean(errors.old_password)}
                helperText={errors?.old_password}
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
            />

            <TextField
                id="new_password"
                name="new_password"
                placeholder="Новый пароль"
                type="password"
                autoComplete="off"
                value={values.new_password}
                onChange={handleChange}
                error={Boolean(errors.new_password)}
                helperText={errors?.new_password}
                inputProps={{ autoComplete: "new_password", form: { autoComplete: "off" } }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">

                        <Tooltip
                            title="Пароль должен содержать не менее шести знаков, включать цифры и латинские буквы в разных регистрах"
                            open={showHint}
                        >
                            <IconButton onClick={() => setShowHint(!showHint)} >
                                <Info fontSize="small" color={showHint ? "primary" : undefined} />
                            </IconButton>
                        </Tooltip>

                    </InputAdornment>
                }}
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
            />

            <TextField
                id="passwordRepeat"
                name="passwordRepeat"
                placeholder="Повторите пароль"
                type="password"
                autoComplete="off"
                value={values.passwordRepeat}
                onChange={handleChange}
                error={Boolean(errors.passwordRepeat)}
                helperText={errors?.passwordRepeat}
                inputProps={{ autoComplete: "passwordRepeat", form: { autoComplete: "off" } }}
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
            />
        </form>

        <Button variant="contained" sx={{ mt: 1 }} onClick={handleSubmit} fullWidth>
            Изменить пароль
        </Button>

    </Box>
}

export default ChangePasswordForm
