import { Delete, Edit, MoreVert, Print } from "@mui/icons-material"
import { Box, Divider, IconButton, Menu, Typography } from "@mui/material"
import React from "react"
import FileDownloadButton from "../../../components/FileDownloadButton"
import { applicationStatusLookups, eduFormLookups, operatorRoles } from "../../../constants"
import useIsMediumScreen from "../../../hooks/useIsMediumScreen"
import useIsSmallScreen from "../../../hooks/useIsSmallScreen"
import useMenu from "../../../hooks/useMenu"
import { apiUrl } from "../../../services/api"
import { useSelector } from "react-redux"

const EntrantApplicationItems = ({
    data,
    onEdit, onDelete, levelLookups, onOpenMenu,
}) => {

    // TODO почему по названием университетов, а не по id ???
    const universities = React.useMemo(() => [...new Set(data.items.map(el => el.university_name))], [data])
    const itemsByUniversty = React.useMemo(() => {
        const mappedItems = {}
        universities.forEach(univ => {
            mappedItems[univ] = data.items.filter(el => el.university_name === univ)
        })
        return mappedItems
    }, [data, universities])

    const isSmallScreen = useIsSmallScreen()
    const isMediumScreen = useIsMediumScreen()

    const dividerWidth = isSmallScreen ? 650 : isMediumScreen ? 700 : "100%"

    const { anchorEl, open, handleDefaultMenuClick, handleMenuClose } = useMenu()

    const isDisabled = item => item?.available_actions?.filter(el => !["edit_profile", "accept_docs", "create_app", "check_epgu_docs", "check_achievements"].includes(el))?.length === 0

    const isEditDisabled = !data?.can_edit_priority

    return <Box sx={{ mb: 2 }}>

        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography variant="font5" sx={{ mr: 1 }}>
                Заявление {data.number} {`(${data.is_budget ? "Бюджетное" : "Платное"}, ${data.level === 4 ? "Бакалавриат / Специалитет" : levelLookups[data.level]?.name ?? ""})`}
            </Typography>

            {(data.items.length > 0 || data.is_epgu) && <IconButton onClick={handleDefaultMenuClick}>
                <Print color="secondary" />
            </IconButton>}

            <IconButton
                sx={{ width: 30, height: 30, mr: 0.3, ml: 0.3 }}
                onClick={() => onEdit(data)}

                disabled={isEditDisabled}
            >
                <Edit color={isEditDisabled ? "disabled" : "secondary"} />
            </IconButton>

            <IconButton
                disabled={Boolean(data.items.find(el => el.status !== 1))}
                sx={{ width: 30, height: 30, mr: 0.3, ml: 0.3 }}
                onClick={() => onDelete(data)}
            >
                <Delete color={Boolean(data.items.find(el => el.status !== 1)) ? "disabled" : "error"} />
            </IconButton>
        </Box>

        {universities.map((university, index) => <Box
            key={index}
            sx={{
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                width: "100%",
                my: universities.length > 1 ? 1 : 0
            }}
        >
            <Box sx={{
                minWidth: isSmallScreen ? "100%" : 160,
                maxWidth: isSmallScreen ? "100%" : 160,
                display: "flex", alignItems: "center", justifyContent: "center",
                backgroundColor: "primary.lightBlue",
                borderRadius: "4px",
                p: 0.5,
                mb: isSmallScreen ? 2 : 0,
                mr: 1,
                minHeight: 60,
            }}>
                <Typography color="font.main" variant="font4" align="center">
                    {university}
                </Typography>
            </Box>

            <Box sx={{ width: "100%", overflow: "auto" }}>
                <Divider sx={{ width: dividerWidth }} />
                {itemsByUniversty?.[university].map((item, itemIndex) => <React.Fragment key={itemIndex}>
                    <Box sx={{ py: 1, display: "flex", alignItems: "center", minHeight: 68 }}>

                        <Typography sx={{ mx: 1, minWidth: 10, maxWidth: 10, }} variant="font5" color="secondary.main">
                            {/* Богомерзкий костыль, TODO вынести на бек */}
                            {item.status !== 9 ? item.priority : ""}
                        </Typography>

                        <Typography sx={{ mx: 1, minWidth: 60, maxWidth: 60 }} variant="font5" color="primary">
                            {item.spec_code}
                        </Typography>

                        <Box sx={{
                            mx: 1,
                            minWidth: isSmallScreen ? 250 : isMediumScreen ? 300 : "calc(100% - 464px)",
                            maxWidth: isSmallScreen ? 250 : isMediumScreen ? 300 : "calc(100% - 464px)",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <Typography noWrap variant="font5">
                                {item.spec_name}
                            </Typography>

                            <Typography noWrap variant="font3">
                                {item.subspec_name}
                            </Typography>
                        </Box>


                        <Box sx={{ mx: 1, display: "flex", flexDirection: "column", maxWidth: 170, minWidth: 170 }}>
                            <Typography align="center" noWrap variant="font4" >
                                {eduFormLookups[item.edu_form] ?? ""}
                            </Typography>
                            <Typography align="center" noWrap variant="font3" >
                                {item.edu_type_name}
                            </Typography>
                        </Box>

                        <Typography sx={{ mx: 1, minWidth: 100, maxWidth: 100 }} noWrap variant="font3">
                            {applicationStatusLookups[item.status] ?? ""}
                        </Typography>

                        <IconButton
                            disabled={isDisabled(item)}
                            size="small"
                            onClick={event => onOpenMenu(event, item)}
                        >
                            <MoreVert color={isDisabled(item) ? "disabled" : "secondary"} />
                        </IconButton>

                    </Box>

                    <Divider sx={{ width: dividerWidth }} />

                </React.Fragment>)}
            </Box>

        </Box>)}

        <Menu
            id="print-actions"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
        >
            {data.items.length > 0 && <FileDownloadButton
                component="menuItem"
                name="Скачать заявление"
                url={`${apiUrl}/entrant_app/app/${data.id}/download/`}
            />}

            {data.is_epgu && <FileDownloadButton
                component="menuItem"
                name="Скачать приложение к личному делу"
                url={`${apiUrl}/entrant_app/app/${data.id}/ref_epgu/`}
            />}

            {data.is_epgu && <FileDownloadButton
                component="menuItem"
                name="Скачать опись личного дела"
                url={`${apiUrl}/entrant_app/app/${data.id}/descript_pers_file/`}
            />}

            {data.is_epgu && <FileDownloadButton
                component="menuItem"
                name="Скачать выписку из приказа"
                url={`${apiUrl}/entrant_app/app/${data.id}/extract_from_order_pdf/`}
            />}
        </Menu>

    </Box >
}

export default EntrantApplicationItems