import { createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import { BadRequestError, NotFoundError, ServerError, UnauthorizedError } from "../services/errors"

const handleError = axiosError => {
    let error = null
    switch (axiosError?.response?.status) {
        case 400: {
            error = new BadRequestError(axiosError?.response?.data, "Ошибка запроса")
            break
        }
        case 401: {
            error = new UnauthorizedError()
            break
        }
        case 404: {
            error = new NotFoundError()
            break
        }
        case 500: {
            error = new ServerError()
            break
        }
        default: error = axiosError
    }
    return error
}

export const createCustomAsyncThunk = (name, f) => createAsyncThunk(
    name,
    async (arg, { rejectWithValue }) => f(arg)
        .then(res => res?.data ?? null)
        .catch(e => rejectWithValue(handleError(e)))
)

export const defaultEntityAdapter = createEntityAdapter()