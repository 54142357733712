import { Box } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import MaterialTableWrapper from "../../components/MaterialTableWrapper"
import useSubjects from "../../hooks/useSubjects"
import useCurrentScorecard from "./hooks/useCurrentScorecard"
import useScorecardResults from "./hooks/useScorecardResults"
import useScorecardResultsActionHandlers from "./hooks/useScorecardResultsActionHandlers"
import useScorecardResultsTableProps from "./hooks/useScorecardResultsTableProps"


const ScorecardResults = () => {

    const userInfo = useSelector(x => x.user.userInfo)

    const { id } = useParams()
    
    const current = useCurrentScorecard(id)
    
    const canEdit = React.useMemo(() => Boolean(userInfo.accesses.find(el => el.role.id === 5) && current?.is_open), [userInfo, current])

    const { scorecardResults, scorecardResultsIsLoading } = useScorecardResults(current)

    const { subjectLookups } = useSubjects()

    const { handleCreate, handleUpdate, handleDelete, handleNavigateToScorecardList } = useScorecardResultsActionHandlers(current)

    const tableProps = useScorecardResultsTableProps(
        scorecardResultsIsLoading, subjectLookups, current,
        handleCreate, handleUpdate, handleDelete, handleNavigateToScorecardList,
        canEdit
    )

    return <Box
        sx={{
            height: "100%",
            minWidth: 800,
            overflow: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <MaterialTableWrapper

            data={scorecardResults}

            isLoading={scorecardResultsIsLoading}

            {...tableProps}
        />
    </Box>
}

export default ScorecardResults