import { useNavigate } from "react-router-dom"
import routes from "../../components/routes"
import toastWrapper from "../../components/toastWrapper"
import { dialogModes, dialogTypes } from "../../constants"
import { operatorService } from "../../services/operatorService"

const initialValues = {
    level: null,
    is_budget: true
}

const useStaticRatingsActionHandlers = (tableRef, openDialog, closeDialog, dialogData) => {

    const navigate = useNavigate()

    const handleIntent = (dialogMode, dialogData) => openDialog({ dialogMode, dialogType: dialogTypes.RATING, dialogData })

    const handleAction = async (f, data, successMessage, errorMessage) => f(data).then(() => {
        toastWrapper(successMessage, "success")
        tableRef.current.onQueryChange()
        closeDialog()
    }).catch(e => {
        console.error(e)
        toastWrapper(errorMessage, "error")
        tableRef.current.onQueryChange()
        closeDialog()
    })

    const handleCreateRatingIntent = () => handleIntent(dialogModes.CREATE, initialValues)

    const handleCreateRating = data => handleAction(
        operatorService.postStaticRating,
        data,
        "Рейтинг-лист создан",
        "Не удалось создать рейтинг-лист"
    )

    const handleDeleteRatingIntent = (_, data) => handleIntent(dialogModes.DELETE, data)

    const handleDeleteRating = () => handleAction(
        operatorService.deleteStaticRating,
        dialogData,
        "Рейтинг-лист удален",
        "Не удалось удалить рейтинг-лист"
    )

    const handleNavigateToRating = (_, rowData) => navigate(routes.staticRatingCGSelect.makePath(rowData.id))

    return {
        handleCreateRatingIntent, handleCreateRating,
        handleDeleteRatingIntent, handleDeleteRating,
        handleNavigateToRating,
    }
}

export default useStaticRatingsActionHandlers