import { getCurrentISODate } from "../../../../utils";
import { Autocomplete, Box, Button, Checkbox, FormControlLabel, TextField, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import CustomDadataSuggestions from "../../../../components/DadataSuggestions/CustomDadataSuggestions";
import Spinner from "../../../../components/Spinner";
import useIsSmallScreen from "../../../../hooks/useIsSmallScreen";


const PassportForm = ({
    data, onSubmit,
    docTypeOptions, docTypeLookups,
    profileInfo, isLoading
}) => {

    const [notPresentIssueDep, setNotPresentIssueDep] = React.useState(false)

    const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
        enableReinitialize: true,
        initialValues: data,
        onSubmit: onSubmit,
        validationSchema: yup.object({
            doc_type: yup.number(),
            series: yup.string()
                .when("doc_type", { is: 14, then: () => yup.string().required().matches(/^\d{4}$/) })
                .when("doc_type", { is: 3, then: () => yup.string().required().matches(/^[а-яёА-ЯЁ]{2}$/) })
                .when("doc_type", { is: 12, then: () => yup.string().max(0) })
                .when("doc_type", { is: 10, then: () => yup.string().required().matches(/^\d{2}$/) })
                .when("doc_type", { is: 15, then: () => yup.string().required().matches(/^\d{2}$/) })
                .when("doc_type", { is: 11, then: () => yup.string().required().matches(/^\d{2}$/) })
                .when("doc_type", { is: 7, then: () => yup.string().required().matches(/^\d{2}$/) })
                .when("doc_type", { is: 9, then: () => yup.string().required().matches(/^[а-яёА-ЯЁ]{2}$/) })
                .when("doc_type", { is: 6, then: () => yup.string().required().matches(/^\d{2}$|^[а-яёА-ЯЁ]{2}$/) })
                .when("doc_type", { is: 1, then: () => yup.string().required().matches(/^[а-яёА-ЯЁ]{2}$/) })
                .when("doc_type", { is: 2, then: () => yup.string().required().matches(/^[а-яёА-ЯЁ]{3}$|^[a-zA-Z]{3}$/) }),
            number: yup.string().required()
                .when("doc_type", { is: 14, then: () => yup.string().required().matches(/^\d{6}$/) })
                .when("doc_type", { is: 3, then: () => yup.string().required().matches(/^\d{7}$/) })
                .when("doc_type", { is: 12, then: () => yup.string().required().matches(/^\d{12}$/) })
                .when("doc_type", { is: 10, then: () => yup.string().required().matches(/^\d{7}$/) })
                .when("doc_type", { is: 15, then: () => yup.string().required().matches(/^\d{7}$/) })
                .when("doc_type", { is: 11, then: () => yup.string().required().matches(/^\d{7}$/) })
                .when("doc_type", { is: 7, then: () => yup.string().required() })
                .when("doc_type", { is: 9, then: () => yup.string().required().matches(/^\d{7}$/) })
                .when("doc_type", { is: 6, then: () => yup.string().required().matches(/^\d{7}$/) })
                .when("doc_type", { is: 1, then: () => yup.string().required().matches(/^\d{6}$|^\d{7}$/) })
                .when("doc_type", { is: 2, then: () => yup.string().required().matches(/^\d{7}$/) }),
            issue_dep: yup.string().when("doc_type", { is: 14, then: () => yup.string().required() }),
            issue_dep_code: yup.string()
                .when("doc_type", { is: 14, then: () => yup.string().required().matches(/^\d{3}-\d{3}$/) })
                .when("doc_type", { is: 12, then: () => yup.string().required().matches(/^\d{6}$/) }),
            issue_date: yup.date().min(new Date("1970-01-01")).max(getCurrentISODate())
                .when("doc_type", {
                    is: 14,
                    then: () => yup.date().required().min(new Date("1970-01-01")).max(getCurrentISODate())
                }),
        }),
    })

    const docTypesWithSeriesValidation = [14, 3, 10, 15, 11, 7, 9, 6, 1, 2]

    const isSmallScreen = useIsSmallScreen()

    const calculateComponentHeight = () => {

        let height = isSmallScreen ? 310 : 250

        if (values.doc_type === 14 || values.doc_type === 12) height += 100

        return height
    }

    const componentHeight = calculateComponentHeight()

    return isLoading ? <Spinner height={componentHeight} /> : <Box sx={{ height: componentHeight, display: "flex", flexDirection: "column" }}>
        <Autocomplete
            options={docTypeOptions}
            getOptionLabel={option => docTypeLookups[option]?.name ?? ""}
            value={values.doc_type}
            onChange={(_, value) => setFieldValue("doc_type", value)}
            renderInput={props => <TextField
                label="Тип документа"
                required
                error={touched.doc_type && Boolean(errors.doc_type)}
                {...props}
            />}
        />
        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", width: "100%", justifyContent: "space-between" }}>
            <TextField
                id="series"
                name="series"
                label="Серия"
                value={values.series}
                onChange={handleChange}
                error={touched.series && Boolean(errors.series)}
                required={docTypesWithSeriesValidation.includes(values.doc_type)}
                sx={{ width: isSmallScreen ? "100%" : "39%" }}
            />
            <TextField
                id="number"
                name="number"
                label="Номер"
                value={values.number}
                onChange={handleChange}
                error={touched.number && Boolean(errors.number)}
                required
                sx={{ width: isSmallScreen ? "100%" : "60%" }}
            />
        </Box>
        <TextField
            id="issue_date"
            name="issue_date"
            label="Дата выдачи"
            InputLabelProps={{ shrink: true }}
            value={values.issue_date}
            onChange={handleChange}
            required
            error={touched.issue_date && Boolean(errors.issue_date)}
            type="date"
        />
        {[14, 12].includes(values.doc_type) ? (<>
            {notPresentIssueDep ? (
                <TextField
                    id="issue_dep"
                    name="issue_dep"
                    label="Кем выдан"
                    required
                    value={values.issue_dep}
                    error={touched.issue_dep && Boolean(errors.issue_dep)}
                    onChange={handleChange}
                />
            ) : (
                <CustomDadataSuggestions
                    label="Кем выдан"
                    type="fms_unit"
                    value={values.issue_dep}
                    error={touched.issue_dep && Boolean(errors.issue_dep)}
                    required
                    onChange={value => {
                        if (value?.value && value?.data?.code) {
                            setFieldValue("issue_dep", value.value)
                            setFieldValue("issue_dep_code", value.data.code)
                        }
                    }}
                    sx={{ mb: 0.7 }}
                />

            )}
            <FormControlLabel
                label="Подразделения, выдавшего паспорт, нет в списке"
                control={<Checkbox
                    checked={notPresentIssueDep}
                    onClick={e => {
                        setFieldValue("issue_dep", "")
                        setNotPresentIssueDep(e.target.checked)
                    }}
                />}
            />
            <TextField
                id="issue_dep_code"
                label="Код подразделения"
                name="issue_dep_code"
                value={values.issue_dep_code}
                error={touched.issue_dep_code && Boolean(errors.issue_dep_code)}
                required={[14, 12].includes(values.doc_type)}
                onChange={handleChange}
            />
        </>
        ) : (<>
            <TextField
                id="issue_dep"
                name="issue_dep"
                label="Кем выдан"
                value={values.issue_dep}
                onChange={handleChange}
            />
        </>
        )}
        <Tooltip title={!values?.can_edit_and_delete ? "Вы не можете редактировать данный раздел анкеты, когда есть поданные заявления" : ""}><span>
            <Button
                disabled={!values?.can_edit_and_delete}
                sx={{ mt: 1 }}
                variant="contained"
                onClick={handleSubmit}
                fullWidth
            >
                Сохранить
            </Button>
        </span></Tooltip>
    </Box>
}

export default PassportForm
