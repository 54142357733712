import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";
import { entrantFormService } from "../../services/entrantService";

export const getExemptions = createCustomAsyncThunk(
    "entrantForm/exemptions/get",
    async userId => await entrantFormService.getExemptions(userId)
)

export const updateExemptions = createCustomAsyncThunk(
    "entrantForm/exemptions/update",
    async data => {
        const requests = []

        data.created.forEach(el => requests.push(entrantFormService.postExemptions(el)))
        data.updated.forEach(el => requests.push(entrantFormService.patchExemptions(el)))
        data.deleted.forEach(el => requests.push(entrantFormService.deleteExemptions(el)))

        return Promise.all(requests)
    }
)

const exemptionSlice = createSlice({
    name: "entrantForm/exemptions",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null
    }),
    extraReducers: builder => {
        builder
            .addCase(getExemptions.pending, state => { state.isLoading = true })
            .addCase(getExemptions.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getExemptions.rejected, (state, action) => {
                state.error = action.payload
                state.isLoading = false
            })
    }
})

export const exemptionSelectors = defaultEntityAdapter.getSelectors(x => x.entrantForm.exemptions)

export default exemptionSlice.reducer