import { Add, Delete } from "@mui/icons-material";
import { Alert, Autocomplete, Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import toastWrapper from "../../../components/toastWrapper";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import { getSatExamsInfo, updateSatExamsInfo } from "../../../redux/entrantForm/satExamsInfoSlice";
import useSubjects from "../hooks/useSubjects";
import OlympForm from "./OlympForm";

const examsInitialValues = {
    id: null,
    is_init_values: true,
    subject: null,
    exam_type: 1,
    points: "",
    can_edit_and_delete: true,
    edited: true,
    is_checked: false,
}

const handlePointsChange = points => {
    if (points > 100) return 100
    if (points < 0) return 0
    return points
}

const SatExamsInfo = ({ userInfo }) => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (userInfo?.id) dispatch(getSatExamsInfo(userInfo.id))
    }, [dispatch, userInfo])


    const { data } = useSelector(x => x.entrantForm.satExams)
    const { subjectOptions, subjectLookups, subjectsIsLoading } = useSubjects()

    const examTypes = [{ id: 1, name: "ЕГЭ" }, { id: 6, name: "ЦТ Республики Беларусь" }]

    const { handleSubmit, values, errors, touched, setFieldValue, setValues } = useFormik({
        enableReinitialize: true,
        initialValues: data,
        onSubmit: values => {
            const newValues = values.map(el => ({ ...el, user: userInfo.id }))
            const created = newValues.filter(el => el.is_init_values)
            const updated = newValues.filter(el => !el.is_init_values && el.id && el.edited)
            const deleted = data.filter(el => !newValues.map(el => el.id).includes(el.id))
            dispatch(updateSatExamsInfo({ created, updated, deleted, userId: userInfo.id })).unwrap()
                .then(() => { toastWrapper("Изменения сохранены", "success") })
                .catch(e => {
                    console.error(e)
                    toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
                })
        },
        validationSchema: yup.array().of(yup.object({
            points: yup.number().required(),
            subject: yup.number().required(),
            exam_type: yup.number().required(),
        }))
    })

    const isSmallScreen = useIsSmallScreen()

    return <>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            minHeight: 130,
        }} >
            <Typography variant="h6" align="center">Результаты ЕГЭ</Typography>
            {
                values.map((value, index) => <Box key={index}>
                    {!value.is_init_values && value.is_checked ? (
                        <Alert sx={{ mt: 1, mb: 1, width: "100%" }} variant="outlined">Проверено</Alert>
                    ) : (
                        <Alert sx={{ mt: 1, mb: 1, width: "100%" }} variant="outlined" severity="warning" >Не проверено</Alert>
                    )}

                    <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", width: "100%", justifyContent: "center", alignItems: "center" }}>

                        <Autocomplete
                            options={examTypes.map(el => el.id)}
                            getOptionLabel={option => examTypes.find(el => el.id === option)?.name ?? ""}
                            value={value.exam_type}
                            onChange={(_, value) => {
                                setFieldValue(`[${index}].exam_type`, value)
                                setFieldValue(`[${index}].edited`, true)
                            }}
                            fullWidth
                            disabled={!value.can_edit_and_delete || value.is_checked}
                            renderInput={props => <TextField
                                label="Тип"
                                required
                                error={touched[index]?.exam_type && Boolean(errors[index]?.exam_type)}
                                {...props}
                            />}
                            sx={{ mr: isSmallScreen ? 0 : 1 }}
                        />

                        <Autocomplete
                            options={subjectOptions}
                            getOptionLabel={option => subjectLookups[option]?.name ?? ""}
                            value={value.subject}
                            loading={subjectsIsLoading}
                            onChange={(_, value) => {
                                setFieldValue(`[${index}].subject`, value)
                                setFieldValue(`[${index}].edited`, true)
                            }}
                            fullWidth
                            disabled={!value.can_edit_and_delete || value.is_checked}
                            renderInput={props => <TextField
                                error={touched[index]?.subject && Boolean(errors[index]?.subject)}
                                label="Предмет"
                                required
                                {...props}
                            />}
                            sx={{ mr: isSmallScreen ? 0 : 1 }}
                        />

                        <TextField
                            label="Кол-во баллов"
                            value={value.points}
                            type="number"
                            autoComplete="off"
                            required
                            disabled={!value.can_edit_and_delete || value.is_checked}
                            onChange={e => {
                                setFieldValue(`[${index}].points`, handlePointsChange(e.target.value))
                                setFieldValue(`[${index}].edited`, true)
                            }}
                            error={touched[index]?.points && Boolean(errors[index]?.points)}
                            inputProps={{ min: 0, max: 100 }}
                            sx={{ ml: isSmallScreen ? 0 : 1 }}
                            fullWidth
                        />

                        {isSmallScreen ? (
                            <Button
                                disabled={!value.can_edit_and_delete}
                                key="1"
                                fullWidth
                                onClick={() => setValues(values.filter((_, ind) => index !== ind))}
                            >
                                <Delete color={value.can_edit_and_delete ? "error" : "disabled"} />
                            </Button>
                        ) : (
                            <IconButton
                                disabled={!value.can_edit_and_delete}
                                onClick={() => setValues(values.filter((_, ind) => index !== ind))}
                            >
                                <Delete color={value.can_edit_and_delete ? "error" : "disabled"} />
                            </IconButton>
                        )}
                    </Box>

                </Box>)
            }
            <Button
                fullWidth
                sx={{ mt: 1, mb: 1 }}
                onClick={() => setValues([...values, examsInitialValues])}
            >
                <Add color="primary" />
            </Button>
            <Button
                sx={{ mt: 1, mb: 1 }}
                variant="contained"
                onClick={handleSubmit}
                fullWidth
            >
                Сохранить
            </Button>
        </Box>
        <OlympForm userInfo={userInfo} />
    </>
}

export default SatExamsInfo