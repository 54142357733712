import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk } from "./utils";
import { operatorService } from "../services/operatorService";

export const sendSatExamsCheckFile = createCustomAsyncThunk(
    "operator/satExamsCheck",
    async file => await operatorService.postSatExamsFile(file)
)

const satExamsCheckSlice = createSlice({
    name: "operator/satExamsCheck",
    initialState: {
        acceptedFiles: [],
        isLoading: false,
        error: null
    },
    reducers: {
        setSatExamsCheckAcceptedFiles: (state, action) => { state.acceptedFiles = action.payload },
        resetSatExamsCheckAcceptedFiles: state => { state.acceptedFiles = [] }
    },
    extraReducers: builder => {
        builder
            .addCase(sendSatExamsCheckFile.pending, state => { state.isLoading = true })
            .addCase(sendSatExamsCheckFile.fulfilled, (state, action) => {
                state.isLoading = false
            })
            .addCase(sendSatExamsCheckFile.rejected, (state, action) => { state.isLoading = false })
    }
})

export const {
    setSatExamsCheckAcceptedFiles,
    resetSatExamsCheckAcceptedFiles,
} = satExamsCheckSlice.actions

export default satExamsCheckSlice.reducer