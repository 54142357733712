import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";
import { entranceTestsService } from "../../services/operatorService";

export const getScorecardResults = createCustomAsyncThunk(
    "entranceTests/scorecardResults/get",
    async params => await entranceTestsService.getScorecardResults(params)
)

export const createScorecardResult = createCustomAsyncThunk(
    "entranceTests/scorecardResults/create",
    async data => await entranceTestsService.postScorecardResult(data)
)

export const updateScorecardResult = createCustomAsyncThunk(
    "entranceTests/scorecardResults/update",
    async data => await entranceTestsService.patchScorecardResult(data)
)

export const deleteScorecardResult = createCustomAsyncThunk(
    "entranceTests/scorecardResults/delete",
    async data => await entranceTestsService.deleteScorecardResult(data)
)

const scorecardResultsSlice = createSlice({
    name: "entranceTests/scorecardResults",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: true,
        error: null,
    }),
    extraReducers: builder => {
        builder
        .addCase(getScorecardResults.pending, state => { state.isLoading = true })
        .addCase(getScorecardResults.fulfilled, (state, action) => {
            defaultEntityAdapter.setAll(state, action.payload)
            state.isLoading = false
        })
        .addCase(getScorecardResults.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })
        
        .addCase(createScorecardResult.pending, state => { state.isLoading = true })
        .addCase(createScorecardResult.fulfilled, (state, action) => {
            defaultEntityAdapter.addOne(state, action.payload)
            state.isLoading = false
        })
        .addCase(createScorecardResult.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })

        .addCase(updateScorecardResult.pending, state => { state.isLoading = true })
        .addCase(updateScorecardResult.fulfilled, (state, action) => {
            defaultEntityAdapter.upsertOne(state, action.payload)
            state.isLoading = false
        })
        .addCase(updateScorecardResult.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })

        .addCase(deleteScorecardResult.pending, state => { state.isLoading = true })
        .addCase(deleteScorecardResult.fulfilled, (state, action) => {
            defaultEntityAdapter.removeOne(state, action.payload.id)
            state.isLoading = false
        })
        .addCase(deleteScorecardResult.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })
    }
})

export const scorecardResultsSelector = defaultEntityAdapter.getSelectors(x => x.entranceTests.scorecardResults)

export default scorecardResultsSlice.reducer