import { createSlice } from "@reduxjs/toolkit";
import { dictService } from "../../services/dictService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getNoticeTypes = createCustomAsyncThunk(
    "dicts/noticeTypes/get",
    async () => await dictService.getNoticeTypes()
)

const noticeTypesSlice = createSlice({
    name: "dicts/noticeTypes",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getNoticeTypes.pending, state => { state.isLoading = true })
            .addCase(getNoticeTypes.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getNoticeTypes.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const noticeTypeSelectors = defaultEntityAdapter.getSelectors(x => x.dicts.noticeTypes)

export default noticeTypesSlice.reducer