import { useDispatch, useSelector } from "react-redux"
import { cgSpecSelectors, getCgSpecs } from "../redux/dicts/cgSpecsSlice"
import React from "react"

const useCgSpecs = (
    level,
    university,
    edu_form,
    is_budget,
    is_english_program,
    is_foreign_with_direction,
    quota_types,
    without_quota_types,
) => {

    const dispatch = useDispatch()

    React.useEffect(() => {

        if (level && university && edu_form) {
            const params = {
                level, university, edu_form,
                is_budget, is_english_program, is_foreign_with_direction,
            }
            if (!without_quota_types) { params.quota_types = quota_types }

            dispatch(getCgSpecs(params))
        }
    }, [
        dispatch,
        level, university, edu_form,
        is_budget, is_english_program, is_foreign_with_direction,
        quota_types, without_quota_types
    ])

    const specOptions = useSelector(cgSpecSelectors.selectIds)
    const specLookups = useSelector(cgSpecSelectors.selectEntities)
    const specsIsLoading = useSelector(x => x.dicts.cgSpecs.isLoading)

    return { specOptions, specLookups, specsIsLoading }
}

export default useCgSpecs