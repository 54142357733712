import { Box } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import AlertDialog from "../../components/AlertDialog"
import MaterialTableWrapper from "../../components/MaterialTableWrapper"
import { dialogModes, dialogTypes } from "../../constants"
import useDialogProps from "../../hooks/useDialogProps"
import useSpecs from "../../hooks/useSpecs"
import useSubjects from "../../hooks/useSubjects"
import useSubspecs from "../../hooks/useSubspecs"
import useUniversities from "../../hooks/useUniversities"
import { getLookups } from "../../utils"
import ScorecardForm from "./ScorecardForm"
import useScorecardActionHandlers from "./hooks/useScorecardActionHandlers"
import useScorecardTableProps from "./hooks/useScorecardTableProps"
import CopyScorecardDialog from "./CopyScorecardDialog"

const EntranceTestsPage = () => {

    const tableRef = React.useRef(null)

    const { dialogData, dialogMode, dialogType, dialogIsOpen, openDialog, closeDialog } = useDialogProps()

    const { universities, universityOptions, universitiesIsLoading } = useUniversities()
    const universitiesLookups = getLookups(universities, ["sname"])

    const specProps = useSpecs()

    const { subspecs, subspecOptions, subspecLookups, subspecsIsLoading } = useSubspecs()
    const subspecTableLookups = getLookups(subspecs)

    const { subjects, subjectOptions, subjectsIsLoading } = useSubjects()
    const subjectLookups = getLookups(subjects)

    const {
        handleCreateScorecardIntent, handleCreateScorecard,
        handleDeleteScorecardIntent, handleDeleteScorecard,
        handleCloseScorecardIntent, handleCloseScorecard,
        handleCopyScorecardIntent, handleCopyScorecard,
        handleNavigateToScorecardResults,
    } = useScorecardActionHandlers(tableRef, dialogData, openDialog, closeDialog)

    const userInfo = useSelector(x => x.user.userInfo)

    const allowedUniversities = React.useMemo(() => [
        ...new Set(userInfo.accesses.map(a => a?.university?.id).filter(el => el))
    ], [userInfo])

    const canEdit = React.useMemo(() => Boolean(userInfo.accesses.find(el => el.role.id === 5)), [userInfo])

    const createDialogIsOpen = dialogIsOpen && dialogMode === dialogModes.CREATE && dialogType === dialogTypes.SCORECARD
    const deleteDialogIsOpen = dialogIsOpen && dialogMode === dialogModes.DELETE && dialogType === dialogTypes.SCORECARD
    const closeDialogIsOpen = dialogIsOpen && dialogType === dialogTypes.SCORECARD_CLOSE
    const copyDialogIsOpen = dialogIsOpen && dialogType === dialogTypes.SCORECARD_COPY


    const tableProps = useScorecardTableProps(
        handleCreateScorecardIntent, handleDeleteScorecardIntent,
        handleCloseScorecardIntent, handleNavigateToScorecardResults, handleCopyScorecardIntent,
        canEdit,
        universitiesLookups, subjectLookups, subspecTableLookups,
    )

    return <Box
        sx={{
            height: "100%",
            minWidth: 800,
            overflow: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <MaterialTableWrapper

            tableRef={tableRef}

            {...tableProps}
        />

        {canEdit && <>
            <ScorecardForm
                data={dialogData}
                open={createDialogIsOpen}
                onClose={closeDialog}
                dialogMode={dialogMode}
                onSave={handleCreateScorecard}
                allowedUniversities={allowedUniversities}
                universityOptions={universityOptions}
                universityLookups={universitiesLookups}
                universitiesIsLoading={universitiesIsLoading}
                subjectOptions={subjectOptions}
                subjectLookups={subjectLookups}
                subjectsIsLoading={subjectsIsLoading}
                subspecOptions={subspecOptions}
                subspecsIsLoading={subspecsIsLoading}
                subspecLookups={subspecLookups}
                {...specProps}
            />

            <AlertDialog
                open={closeDialogIsOpen}
                title="Закрытие ведомости"
                description="Вы уверены, что хотите закрыть экзаменационную ведомость?"
                onOk={handleCloseScorecard}
                onCancel={closeDialog}
            />

            <AlertDialog
                open={deleteDialogIsOpen}
                title="Удаление ведомости"
                description="Вы уверены, что хотите удалить экзаменационную ведомость?"
                onOk={handleDeleteScorecard}
                onCancel={closeDialog}
            />

            <CopyScorecardDialog
                open={copyDialogIsOpen}
                data={dialogData}
                onClose={closeDialog}
                subjectLookups={subjectLookups}
                subjectOptions={subjectOptions}
                subjectsIsLoading={subjectsIsLoading}
                onSave={handleCopyScorecard}
            />
        </>}

    </Box>
}

export default EntranceTestsPage