import React from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import routes from "../components/routes"


const useRedirectToProfile = () => {

    const { userInfo, isLoading, isAuthenticated } = useSelector(x => x.user)

    const navigate = useNavigate()

    React.useEffect(() => {
        if (userInfo !== null && !isLoading && isAuthenticated) {
            navigate(routes.profile.path)
        }
    }, [userInfo, isLoading, isAuthenticated, navigate])
}

export default useRedirectToProfile