import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk } from "./utils";
import { dictService } from "../services/dictService";
import { operatorService } from "../services/operatorService";

export const getRatingCurrentCG = createCustomAsyncThunk(
    "ratings/getCurrentCG",
    async id => await dictService.getCompetitiveGroup(id)
)

export const getDynamicRatings = createCustomAsyncThunk(
    "ratings/dynamic/get",
    async params => await operatorService.getDynamicRatings(params)
)

export const getStaticRatings = createCustomAsyncThunk(
    "ratings/static/get",
    async params => await operatorService.getStaticRating(params)
)


const ratingsSlice = createSlice({
    name: "ratings",
    initialState: {
        currentCompetitiveGroup: null,
        currentCompetitiveGroupIsLoading: false,

        dynamicRatings: [],
        dynamicRatingsIsLoading: false,

        staticRatings: [],
        staticRatingsIsLoading: false,

        params: { is_max_priority: false, is_original: false },

        error: null,
    },
    reducers: {
        reset: state => {
            state.currentCompetitiveGroup = null
            state.dynamicRatings = []
            state.staticRatings = []
            state.params = { is_max_priority: false, is_original: false }
        },
        setParams: (state, action) => { state.params = action.payload }
    },
    extraReducers: builder => {
        builder
            .addCase(getRatingCurrentCG.pending, state => { state.currentCompetitiveGroupIsLoading = true })
            .addCase(getRatingCurrentCG.fulfilled, (state, action) => {
                state.currentCompetitiveGroupIsLoading = false
                state.currentCompetitiveGroup = action.payload
            })
            .addCase(getRatingCurrentCG.rejected, (state, action) => {
                state.currentCompetitiveGroupIsLoading = false
                state.error = action.error
            })

            .addCase(getDynamicRatings.pending, state => { state.dynamicRatingsIsLoading = true })
            .addCase(getDynamicRatings.fulfilled, (state, action) => {
                state.dynamicRatingsIsLoading = false
                state.dynamicRatings = action.payload
            })
            .addCase(getDynamicRatings.rejected, (state, action) => {
                state.dynamicRatingsIsLoading = false
                state.error = action.error
            })

            .addCase(getStaticRatings.pending, state => { state.staticRatingsIsLoading = true })
            .addCase(getStaticRatings.fulfilled, (state, action) => {
                state.staticRatingsIsLoading = false
                state.staticRatings = action.payload
            })
            .addCase(getStaticRatings.rejected, (state, action) => {
                state.staticRatingsIsLoading = false
                state.error = action.error
            })
    }
})

export const { reset: resetRatings, setParams: setRatingParams } = ratingsSlice.actions

export default ratingsSlice.reducer