import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useFormik } from "formik"


const HideColumnsDialog = ({hiddenColumns, onSave, existingColumns, existingColumnsTitles, open, onClose }) => {

    const { values, setValues, handleSubmit } = useFormik({
        initialValues: hiddenColumns,
        onSubmit: values => {
            onSave(values)
            onClose()
         }
    })

    return <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle>
            Выберите столбцы, которые нужно скрыть
        </DialogTitle>
        <DialogContent>
            <Autocomplete
                value={values}
                options={existingColumns}
                onChange={(_, values) => setValues(values)}
                getOptionLabel={option => existingColumnsTitles[option] ?? ""}
                multiple
                renderInput={props => <TextField
                    {...props}
                />}
            />
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={handleSubmit}>
                Сохранить
            </Button>
            <Button variant="contained" color="error" onClick={onClose}>
                Закрыть
            </Button>
        </DialogActions>
    </Dialog>
}

export default HideColumnsDialog