import { MuiTelInput } from 'mui-tel-input'

const PhoneField = ({onlyCountries = ['RU'], ...rest}) => <MuiTelInput
    onlyCountries={onlyCountries}
    defaultCountry='RU'
    localization={{ 'Russia': 'Россия' }}
    fullWidth
    {...rest}
/>


export default PhoneField