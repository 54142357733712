import { Box, Link, Typography } from "@mui/material"

const AppUnavailable = () => {

    return <Box sx={{
        p: 2,
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    }}>
        <Typography align="center" variant="h4" sx={{ mb: 4 }}>Проводятся технические работы</Typography>
        <Typography align="center">Приносим извинения за временные неудобства</Typography>
        <Typography align="center">По вопросам приемной кампании {new Date().getFullYear()} г. вы можете обратиться в социальных сетях:</Typography>
        <Box sx={{ display: "flex" }}>
            <Link sx={{ mr: 1 }} href="https://vk.com/public195297960">Вконтакте</Link> <Typography>или по телефонам (4722) 55-41-03, 30-99-07</Typography>
        </Box>
    </Box>
}

export default AppUnavailable