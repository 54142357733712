import { Autocomplete, Box, Button, Checkbox, FormControlLabel, TextField } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import InputMask from "react-input-mask"
import * as yup from "yup"
import AddressSuggestions from "../../../../components/DadataSuggestions/AddressSuggestions"
import CustomDadataSuggestions from "../../../../components/DadataSuggestions/CustomDadataSuggestions"
import PhoneField from "../../../../components/PhoneField"
import useIsSmallScreen from "../../../../hooks/useIsSmallScreen"
import { validateSnils } from "../../../../utils"

const PayerForm = ({
    data, onSubmit,
    citizenshipOptions, citizenshipLookups, citizenshipsIsLoading,
    personalDocTypeOptions, personalDocTypeLookups
}) => {

    const isSmallScreen = useIsSmallScreen()

    const [notPresentIssueDep, setNotPresentIssueDep] = React.useState(false)

    const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
        enableReinitialize: true,
        initialValues: data,
        onSubmit: onSubmit,
        validationSchema: yup.object({
            surname: yup.string(),
            name: yup.string(),
            patronymic: yup.string(),
            passport_series: yup.string(),
            passport_number: yup.string(),
            passport_issue_dep: yup.string(),
            passport_issue_date: yup.date(),
            passport_issue_dep_code: yup.string(),
            address: yup.object().nullable(),
            doc_type: yup.number().required(),
            birthday: yup.date().required(),
            birth_place: yup.string(),
            gender: yup.number().required(),
            snils_number: yup.string().test({ test: value => !validateSnils(value) }),
            inn: yup.string(),
            email: yup.string()
                .matches(
                    //eslint-disable-next-line
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    "Введен неверный адрес электронной почты"
                ),
            phone: yup.string()
                .matches(
                    //eslint-disable-next-line
                    /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/,
                    "Введен неверный номер телефона"
                ),
            citizen: yup.number()
        })
    })

    return <>

        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextField
                id="surname"
                name="surname"
                label="Фамилия"
                value={values.surname}
                onChange={handleChange}
                // disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "32%" }}
                error={touched.surname && Boolean(errors.surname)}
            />
            <TextField
                id="name"
                name="name"
                label="Имя"
                value={values.name}
                onChange={handleChange}
                // disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "32%" }}
                error={touched.name && Boolean(errors.name)}
            />
            <TextField
                id="patronymic"
                name="patronymic"
                label="Отчество (при наличии)"
                value={values.patronymic}
                onChange={handleChange}
                // disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "32%" }}
                error={touched.patronymic && Boolean(errors.patronymic)}
            />
        </Box>

        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <Autocomplete
                options={[0, 1]}
                value={values.gender}
                onChange={(_, value) => setFieldValue("gender", value)}
                getOptionLabel={option => option === 0 ? "Женский" : "Мужской"}
                sx={{ width: isSmallScreen ? "100%" : "32%" }}
                // disabled={!values.can_edit_and_delete}
                renderInput={props => <TextField
                    label="Пол"
                    required
                    error={touched.gender && Boolean(errors.gender)}
                    {...props}
                />}
            />
            <TextField
                id="birthday"
                name="birthday"
                label="Дата рождения"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={values.birthday}
                onChange={handleChange}
                // disabled={!values.can_edit_and_delete}
                error={touched.birthday && Boolean(errors.birthday)}
                required
                sx={{ width: isSmallScreen ? "100%" : "32%" }}
            />
            <TextField
                id="birth_place"
                name="birth_place"
                label="Место рождения"
                value={values.birth_place}
                onChange={handleChange}
                // disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "32%" }}
                error={touched.birth_place && Boolean(errors.birth_place)}
            />
        </Box>

        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextField
                id="inn"
                name="inn"
                label="ИНН"
                value={values.inn}
                onChange={handleChange}
                // disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                error={touched.inn && Boolean(errors.inn)}
            />
            <InputMask
                maskChar=""
                mask="999-999-999 99"
                id="snils_number"
                name="snils_number"
                value={values.snils_number}
                onChange={event => {
                    let newValue = event.target.value?.replaceAll("-", "").replaceAll(" ", "")
                    setFieldValue("snils_number", newValue)
                }}
            >
                {(props) => <TextField
                    {...props}
                    label="СНИЛС"
                    error={Boolean(validateSnils(values.snils_number)) && values.snils_number !== ""}
                    helperText={validateSnils(values.snils_number)}
                    sx={{ width: isSmallScreen ? "100%" : "49%" }}
                />}
            </InputMask>
        </Box>

        <Autocomplete
            options={citizenshipOptions}
            getOptionLabel={option => citizenshipLookups[option]?.sname}
            value={values.citizen}
            onChange={(_, value) => setFieldValue("citizen", value)}
            // disabled={!values.can_edit_and_delete}
            loading={citizenshipsIsLoading}
            renderInput={props => <TextField
                label="Гражданство"
                required
                error={touched.citizen && Boolean(errors.citizen)}
                {...props}
            />}
        />

        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <Autocomplete
                options={personalDocTypeOptions}
                value={values.doc_type}
                getOptionLabel={option => personalDocTypeLookups[option]?.name ?? ""}
                onChange={(_, value) => setFieldValue("doc_type", value)}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                // disabled={!values.can_edit_and_delete}
                renderInput={props => <TextField
                    label="Тип документа, подтв. личн."
                    required
                    error={touched.doc_type && Boolean(errors.doc_type)}
                    {...props}
                />}
            />
            <TextField
                id="passport_issue_date"
                name="passport_issue_date"
                label="Дата выдачи"
                type="date"
                value={values.passport_issue_date}
                onChange={handleChange}
                // disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                error={touched.passport_issue_date && Boolean(errors.passport_issue_date)}
            />
        </Box>

        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextField
                id="passport_series"
                name="passport_series"
                label="Серия"
                value={values.passport_series}
                onChange={handleChange}
                // disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                error={touched.passport_series && Boolean(errors.passport_series)}
            />
            <TextField
                id="passport_number"
                name="passport_number"
                label="Номер"
                value={values.passport_number}
                onChange={handleChange}
                // disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                error={touched.passport_number && Boolean(errors.passport_number)}
            />
        </Box>

        {(values.citizen === parseInt(window._env_?.DEFAULT_CITIZENSHIP) && !notPresentIssueDep) ? (
            <CustomDadataSuggestions
                value={values.passport_issue_dep}
                label="Кем выдан"
                type="fms_unit"
                // disabled={!values.can_edit_and_delete}
                onChange={value => {
                    if (value?.value) {
                        setFieldValue("passport_issue_dep", value.value)
                        setFieldValue("passport_issue_dep_code", value.data.code)
                    }
                }}
                error={touched.passport_issue_dep && Boolean(errors.passport_issue_dep)}
            />
        ) : (
            <TextField
                id="passport_issue_dep"
                name="passport_issue_dep"
                label="Кем выдан"
                value={values.passport_issue_dep}
                error={touched.passport_issue_dep && Boolean(errors.passport_issue_dep)}
                onChange={handleChange}
            // disabled={!values.can_edit_and_delete}
            />
        )}

        <FormControlLabel
            label="Подразделения, выдавшего документ, нет в списке"
            // disabled={!values.can_edit_and_delete}
            control={<Checkbox
                checked={notPresentIssueDep}
                onClick={e => {
                    setFieldValue("passport_issue_dep", "")
                    setNotPresentIssueDep(e.target.checked)
                }}
            />}
        />

        <AddressSuggestions
            value={values.address}
            label="Адрес прописки"
            onChange={value => setFieldValue("address", value)}
            // disabled={!values.can_edit_and_delete}
            error={touched.address && Boolean(errors.address)}
        />

        <TextField
            id="passport_issue_dep_code"
            name="passport_issue_dep_code"
            label="Код подразделения"
            value={values.passport_issue_dep_code}
            onChange={handleChange}
            // disabled={!values.can_edit_and_delete}
            error={touched.passport_issue_dep_code && Boolean(errors.passport_issue_dep_code)}
        />
        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextField
                id="email"
                name="email"
                type="email"
                label="Адрес эл. почты"
                value={values.email}
                onChange={handleChange}
                helperText={touched.email && errors.email}
                error={touched.email && Boolean(errors.email)}
                // disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
            />
            <PhoneField
                id="phone"
                name="phone"
                type="tel"
                label="Номер телефона"
                value={values.phone}
                onChange={value => setFieldValue("phone", value)}
                helperText={touched.phone && errors.phone}
                error={touched.phone && Boolean(errors.phone)}
                // disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
            />
        </Box>
        <FormControlLabel
            label="Использовать средства мат. капитала"
            // disabled={!values.can_edit_and_delete}
            control={<Checkbox
                checked={values.is_mat_capital}
                onChange={(_, value) => setFieldValue("is_mat_capital", value)}
            />}
        />
        <Button
            // disabled={!values.can_edit_and_delete}
            sx={{ mt: 1 }}
            variant="contained"
            onClick={handleSubmit}
            fullWidth
        >
            Сохранить
        </Button>
    </>
}

export default PayerForm