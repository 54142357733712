import { Autocomplete, Box, Button, TextField } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import * as yup from "yup"
import Spinner from "../../../components/Spinner"
import { militaryCategories, militaryDocTypes } from "../../../constants"
import useIsSmallScreen from "../../../hooks/useIsSmallScreen"
import { createMilitaryDocInfo, getMilitaryDocInfo, updateMilitaryDocInfo } from "../../../redux/entrantForm/militaryDocInfoSlice"
import { getLookups, getOptions } from "../../../utils"
import toastWrapper from "../../../components/toastWrapper"


const MilitaryDocInfo = ({ userInfo }) => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (userInfo?.id) dispatch(getMilitaryDocInfo(userInfo.id))
    }, [dispatch, userInfo])

    const { data, isLoading } = useSelector(x => x.entrantForm.militaryDocInfo)

    const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
        initialValues: data,
        enableReinitialize: true,
        onSubmit: values => {
            const newValues = { ...values, user: userInfo?.id }
            dispatch(newValues?.id ? updateMilitaryDocInfo(newValues) : createMilitaryDocInfo(newValues)).unwrap()
            .then(() => { toastWrapper("Изменения сохранены", "success") })
            .catch(e => {
                console.error(e)
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
            })
        },
        validationSchema: yup.object({
            type: yup.number().required(),
            series: yup.string().required(),
            number: yup.string().required(),
            issue_mil_office: yup.string().required(),
            issue_date: yup.date().required(),
            category: yup.string().required(),
        })
    })

    const isSmallScreen = useIsSmallScreen()

    const componentHeight = isSmallScreen ? 370 : 265

    const militaryDocTypeOptions = React.useMemo(() => getOptions(militaryDocTypes), [])
    const militaryDocTypeLookups = React.useMemo(() => getLookups(militaryDocTypes), [])

    const militaryCategoryOptions = React.useMemo(() => getOptions(militaryCategories), [])
    const militaryCategoryLookups = React.useMemo(() => getLookups(militaryCategories), [])

    if (isLoading) return <Spinner height={componentHeight} />

    return <Box sx={{ height: componentHeight, display: "flex", flexDirection: "column" }}>

        <Autocomplete
            options={militaryDocTypeOptions}
            value={values.type}
            onChange={(_, value) => setFieldValue("type", value)}
            getOptionLabel={option => militaryDocTypeLookups[option] ?? ""}
            renderInput={props => <TextField
                label="Тип документа"
                error={touched.type && Boolean(errors.type)}
                required
                {...props}
            />}
        />

        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextField
                id="series"
                name="series"
                label="Серия"
                required
                value={values.series}
                onChange={handleChange}
                error={touched.series && Boolean(errors.series)}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
            />
            <TextField
                id="number"
                name="number"
                label="Номер"
                required
                value={values.number}
                onChange={handleChange}
                error={touched.number && Boolean(errors.number)}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
            />
        </Box>

        <TextField
            id="issue_mil_office"
            name="issue_mil_office"
            label="Кем выдан"
            required
            value={values.issue_mil_office}
            onChange={handleChange}
            error={touched.issue_mil_office && Boolean(errors.issue_mil_office)}
        />

        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextField
                id="issue_date"
                name="issue_date"
                label="Дата выдачи"
                type="date"
                required
                value={values.issue_date}
                onChange={handleChange}
                error={touched.issue_date && Boolean(errors.issue_date)}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
            />

            <Autocomplete
                options={militaryCategoryOptions}
                value={values.category}
                onChange={(_, value) => setFieldValue("category", value)}
                getOptionLabel={option => militaryCategoryLookups[option] ?? ""}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                renderInput={props => <TextField
                    label="Категория"
                    required
                    error={touched.category && Boolean(errors.category)}
                    {...props}
                />}
            />
        </Box>


        <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ mt: 1 }}
        >
            Сохранить
        </Button>
    </Box>
}

export default MilitaryDocInfo