import React from "react"
import { useDispatch } from "react-redux"
import { getCurrentCGForAchievements } from "../../redux/competitiveGroupAchievementsSlice"
import { useSelector } from "react-redux"

const useCurrentCompetitiveGroup = id => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (id) dispatch(getCurrentCGForAchievements(id))
    }, [dispatch, id])

    const { current, currentIsLoading } = useSelector(x => x.competitiveGroupAchievements)

    return { current, currentIsLoading }
}

export default useCurrentCompetitiveGroup