import { Box } from "@mui/material"
import MaterialTableWrapper from "../../components/MaterialTableWrapper"
import useEntranceTestRegistrationTableProps from "./useEntranceTestRegistrationTableProps"
import useSubjects from "../../hooks/useSubjects"
import React from "react"
import { getLookups } from "../../utils"

const EntranceTestRegistrations = () => {

    const { subjects } = useSubjects()

    const subjectLookups = React.useMemo(() => getLookups(subjects), [subjects])

    const tableProps = useEntranceTestRegistrationTableProps(subjectLookups)

    return <Box
        sx={{
            height: "100%",
            minWidth: 800,
            overflow: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <MaterialTableWrapper

            {...tableProps}
        />
    </Box>
}

export default EntranceTestRegistrations