import { useDispatch, useSelector } from "react-redux"
import { citizenshipSelectors, getCitizenships } from "../redux/dicts/citizenshipsSlice"
import React from "react"

const useCitizenships = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (citizenshipOptions.length === 0) {
            dispatch(getCitizenships())
        }
        //eslint-disable-next-line
    }, [dispatch])

    const citizenships = useSelector(citizenshipSelectors.selectAll)
    const citizenshipOptions = useSelector(citizenshipSelectors.selectIds)
    const citizenshipLookups = useSelector(citizenshipSelectors.selectEntities)
    const citizenshipsIsLoading = useSelector(x => x.dicts.citizenships.isLoading)

    return { citizenships, citizenshipOptions, citizenshipLookups, citizenshipsIsLoading }
}

export default useCitizenships