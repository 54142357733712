const PdfFileIcon = () => {
    return <svg width="72" height="80" viewBox="0 0 72 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.75" y="0.75" width="70.5" height="78.5" rx="3.25" fill="white" />
        <g opacity="0.32">
            <path d="M44.985 12H15.963C15.155 12 14.5 12.655 14.5 13.926V67C14.5 67.345 15.155 68 15.963 68H56.037C56.845 68 57.5 67.345 57.5 67V24.978C57.5 24.282 57.407 24.058 57.243 23.893L45.607 12.257C45.442 12.093 45.218 12 44.985 12Z" fill="#E9E9E0" />
            <path d="M45.5 12.1509V23.9999H57.349L45.5 12.1509Z" fill="#D9D7CA" />
            <path d="M27.5137 45.3242C27.1657 45.3242 26.8317 45.2112 26.5467 44.9982C25.5057 44.2172 25.3657 43.3482 25.4317 42.7562C25.6137 41.1282 27.6267 39.4242 31.4167 37.6882C32.9207 34.3922 34.3517 30.3312 35.2047 26.9382C34.2067 24.7662 33.2367 21.9482 33.9437 20.2952C34.1917 19.7162 34.5007 19.2722 35.0777 19.0802C35.3057 19.0042 35.8817 18.9082 36.0937 18.9082C36.5977 18.9082 37.0407 19.5572 37.3547 19.9572C37.6497 20.3332 38.3187 21.1302 36.9817 26.7592C38.3297 29.5432 40.2397 32.3792 42.0697 34.3212C43.3807 34.0842 44.5087 33.9632 45.4277 33.9632C46.9937 33.9632 47.9427 34.3282 48.3297 35.0802C48.6497 35.7022 48.5187 36.4292 47.9397 37.2402C47.3827 38.0192 46.6147 38.4312 45.7197 38.4312C44.5037 38.4312 43.0877 37.6632 41.5087 36.1462C38.6717 36.7392 35.3587 37.7972 32.6807 38.9682C31.8447 40.7422 31.0437 42.1712 30.2977 43.2192C29.2727 44.6542 28.3887 45.3242 27.5137 45.3242ZM30.1757 40.1982C28.0387 41.3992 27.1677 42.3862 27.1047 42.9422C27.0947 43.0342 27.0677 43.2762 27.5357 43.6342C27.6847 43.5872 28.5547 43.1902 30.1757 40.1982ZM43.8127 35.7562C44.6277 36.3832 44.8267 36.7002 45.3597 36.7002C45.5937 36.7002 46.2607 36.6902 46.5697 36.2592C46.7187 36.0502 46.7767 35.9162 46.7997 35.8442C46.6767 35.7792 46.5137 35.6472 45.6247 35.6472C45.1197 35.6482 44.4847 35.6702 43.8127 35.7562ZM36.3427 29.1742C35.6277 31.6482 34.6837 34.3192 33.6687 36.7382C35.7587 35.9272 38.0307 35.2192 40.1647 34.7182C38.8147 33.1502 37.4657 31.1922 36.3427 29.1742ZM35.7357 20.7122C35.6377 20.7452 34.4057 22.4692 35.8317 23.9282C36.7807 21.8132 35.7787 20.6982 35.7357 20.7122Z" fill="#CC4B4C" />
            <path d="M56.037 68H15.963C15.155 68 14.5 67.345 14.5 66.537V51H57.5V66.537C57.5 67.345 56.845 68 56.037 68Z" fill="#CC4B4C" />
            <path d="M25.3851 64.9998H23.7441V54.9238H26.6421C27.0701 54.9238 27.4941 54.9918 27.9131 55.1288C28.3321 55.2658 28.7081 55.4708 29.0411 55.7438C29.3741 56.0168 29.6431 56.3478 29.8481 56.7348C30.0531 57.1218 30.1561 57.5568 30.1561 58.0408C30.1561 58.5518 30.0691 59.0138 29.8961 59.4288C29.7231 59.8438 29.4811 60.1928 29.1711 60.4748C28.8611 60.7568 28.4871 60.9758 28.0501 61.1308C27.6131 61.2858 27.1291 61.3628 26.6011 61.3628H25.3841L25.3851 64.9998ZM25.3851 56.1678V60.1598H26.8891C27.0891 60.1598 27.2871 60.1258 27.4841 60.0568C27.6801 59.9888 27.8601 59.8768 28.0241 59.7218C28.1881 59.5668 28.3201 59.3508 28.4201 59.0728C28.5201 58.7948 28.5701 58.4508 28.5701 58.0408C28.5701 57.8768 28.5471 57.6868 28.5021 57.4738C28.4561 57.2598 28.3631 57.0548 28.2221 56.8588C28.0801 56.6628 27.8821 56.4988 27.6271 56.3668C27.3721 56.2348 27.0341 56.1688 26.6151 56.1688L25.3851 56.1678Z" fill="white" />
            <path d="M40.135 59.758C40.135 60.587 40.046 61.296 39.868 61.884C39.69 62.472 39.465 62.964 39.191 63.361C38.917 63.758 38.61 64.07 38.268 64.298C37.926 64.526 37.596 64.696 37.277 64.811C36.958 64.925 36.666 64.998 36.402 65.03C36.138 65.06 35.942 65.076 35.814 65.076H32V55H35.035C35.883 55 36.628 55.135 37.27 55.403C37.912 55.671 38.446 56.03 38.87 56.476C39.294 56.922 39.61 57.431 39.82 58C40.03 58.57 40.135 59.156 40.135 59.758ZM35.268 63.873C36.38 63.873 37.182 63.518 37.674 62.807C38.166 62.096 38.412 61.066 38.412 59.717C38.412 59.298 38.362 58.883 38.262 58.473C38.161 58.063 37.968 57.692 37.681 57.359C37.394 57.026 37.004 56.757 36.512 56.552C36.02 56.347 35.382 56.244 34.598 56.244H33.641V63.873H35.268Z" fill="white" />
            <path d="M44.2657 56.1678V59.3398H48.4767V60.4608H44.2657V64.9998H42.5977V54.9238H48.8997V56.1678H44.2657Z" fill="white" />
        </g>
        <rect x="0.75" y="0.75" width="70.5" height="78.5" rx="3.25" stroke="#122D52" strokeWidth="1.5" strokeDasharray="8 8" />
    </svg>
}

export default PdfFileIcon