import { Grid, Typography } from "@mui/material"


const EntrantInfoField = ({ name, value, type = "string", xs = 4, ...rest }) => {

    if (value === null) return null

    const prepareValue = () => {
        if (type === "boolean") return value === false ? "Нет" : value === true ? "Да" : "Не указано"

        if (type === "date") return !value ? "Не указана" : new Date(value).toLocaleDateString() ?? "Не указана"

        return !value || value === "" ? "Не указано" : value
    }

    return <Grid item xs={xs} {...rest}>
        <Typography variant="font5">
            {name}
        </Typography>
        <Typography variant="font3">
            {prepareValue()}
        </Typography>
    </Grid>
}


export default EntrantInfoField