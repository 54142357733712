import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { closeDialogAndResetState, setAllDialogStates } from "../redux/uiSlice"


const useDialogProps = () => {

    const { dialogData, dialogMode, dialogType, dialogIsOpen } = useSelector(x => x.ui)

    const dispatch = useDispatch()

    const openDialog = props => { dispatch(setAllDialogStates(props)) }

    const closeDialog = () => { dispatch(closeDialogAndResetState()) }

    return { dialogData, dialogMode, dialogType, dialogIsOpen, openDialog, closeDialog }
}

export default useDialogProps