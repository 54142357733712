import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSubjects, subjectSelectors } from "../../../redux/dicts/subjectsSlice"


const useSubjects = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (subjectOptions.length === 0) {
            dispatch(getSubjects())
        }
        // eslint-disable-next-line
    }, [dispatch])

    const subjectOptions = useSelector(subjectSelectors.selectIds)
    const subjectLookups = useSelector(subjectSelectors.selectEntities)
    const subjectsIsLoading = useSelector(x => x.dicts.subjects.isLoading)

    return { subjectOptions, subjectLookups, subjectsIsLoading }
}

export default useSubjects