import { Alert, Box, Divider, Grid, Link, Paper, Typography } from "@mui/material"
import React from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Spinner from "../../components/Spinner"
import toastWrapper from "../../components/toastWrapper"
import { olympResultLookups } from "../../constants"
import useNoticeTypes from "../../hooks/useNoticeTypes"
import { approveAchievement, approveExemption, approveOldPassport, approvePassport, approvePersonalInfo, approvePrevEdu, approveTargetContract } from "../../redux/entrantAllInfoSlice"
import ApprovalActions from "./ApprovalActions"
import ApprovalsHistory from "./ApprovalsHistory"
import EntrantInfoField from "./EntrantInfoField"
import useEntrantAllInfoData from "./useEntrantAllInfoData"

const EntrantAllInfoPage = () => {

    const { id } = useParams()

    const { user, data, isLoading } = useEntrantAllInfoData(id)

    const dispatch = useDispatch()

    const noticeTypes = useNoticeTypes()

    const handleApprove = (f, data, successMessage, errorMessage) => {
        dispatch(f(data)).unwrap()
            .then(() => {
                toastWrapper(successMessage ?? "Изменения сохранены", "success")
            })
            .catch(e => {
                console.error(e)
                toastWrapper(errorMessage ?? "Не удалось сохранить изменения", "error")
            })
    }

    if (isLoading) return <Spinner height="100vh" />

    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 1200, minWidth: 500, margin: "auto" }}>

        <Box
            sx={{
                m: 1,
                p: 2,
                borderRadius: 1,
                display: "flex",
                backgroundColor: "primary.grey",
            }}
        >
            <Typography variant="font2" color="white">{`${user?.surname ?? ""} ${user?.name ?? ""} ${user?.patronymic ?? ""}`}</Typography>
        </Box>

        {isLoading ? <Spinner height="100%" /> : <>
            <Paper sx={{ m: 1, p: 2, overflow: "auto", width: "calc(100% - 18px)", height: "calc(100vh - 90px)" }}>
                <Grid container spacing={2} >

                    {data?.personal_info && <>

                        <Grid item xs={12}>
                            <Typography variant="font2">Персональная информация</Typography>
                            <Divider />
                        </Grid>

                        <EntrantInfoField name="ФИО"
                            value={`${data.personal_info?.surname ?? ""} ${data.personal_info.name ?? ""} ${data.personal_info.patronymic ?? ""}`}
                        />
                        <EntrantInfoField name="Пол" value={data.personal_info.gender === 1 ? "Мужской" : "Женский"} />
                        <EntrantInfoField name="Предыдущая фамилия" value={data.personal_info.last_surname} />
                        <EntrantInfoField name="Дата рождения" value={data.personal_info.birthday} type="date" />
                        <EntrantInfoField name="Место рождения" value={data.personal_info.birth_place} />
                        <EntrantInfoField name="Гражданство" value={data.personal_info.citizen} />
                        <EntrantInfoField name="СНИЛС" value={data.personal_info.snils_number} />
                        <EntrantInfoField name="Телефон" value={data.personal_info.phone} />
                        <EntrantInfoField name="Email" value={data.personal_info.email} />
                        <EntrantInfoField name="Иностранный язык" value={data.personal_info.foreign_lang} />
                        <EntrantInfoField name="Адрес регистрации" value={data.personal_info.reg_address?.value} xs={12} />
                        <EntrantInfoField name="Адрес проживания" value={data.personal_info.fact_address?.value} xs={12} />

                        <Grid item xs={12}>
                            <Typography variant="font1">Подтверждающие документы</Typography>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            {data.personal_info.files.length === 0 ? "Нет файлов" : <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {data.personal_info.files.map((file, index) => <Link
                                    key={index}
                                    href={file}
                                    target="_blank"
                                >
                                    {`Персональная информация - ${index + 1}`}
                                </Link>)}
                            </Box>}
                        </Grid>

                        <ApprovalsHistory approvals={data.personal_info.approvals} {...noticeTypes} />

                        <ApprovalActions
                            data={data.personal_info}
                            onSave={data => handleApprove(approvePersonalInfo, data)}
                            {...noticeTypes}
                        />

                    </>}

                    {data?.passport && <>
                        <Grid item xs={12}>
                            <Typography variant="font2">Документ, удостоверяющий личность</Typography>
                            <Divider />
                        </Grid>

                        <EntrantInfoField name="Тип документа" value={data.passport.doc_type} xs={12} />
                        <EntrantInfoField name="Серия" value={data.passport.series} xs={2} />
                        <EntrantInfoField name="Номер" value={data.passport.number} xs={2} />
                        <EntrantInfoField name="Кем выдан" value={data.passport.issue_dep} />
                        <EntrantInfoField name="Код подразделения" value={data.passport.issue_dep_code} />
                        <EntrantInfoField name="Дата выдачи" value={data.passport.issue_date} type="date" />
                        <EntrantInfoField name="Подтвержден в СС" value={data.passport.is_checked} type="boolean" />

                        <Grid item xs={12}>
                            <Typography variant="font1">Подтверждающие документы</Typography>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            {data.passport.files.length === 0 ? "Нет файлов" : <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {data.passport.files.map((file, index) => <Link
                                    key={index}
                                    href={file}
                                    target="_blank"
                                >
                                    {`Документ, удостоверяющий личность - ${index + 1}`}
                                </Link>)}
                            </Box>}
                        </Grid>

                        <ApprovalsHistory approvals={data.passport.approvals} {...noticeTypes} />

                        <ApprovalActions
                            data={data.passport}
                            onSave={data => handleApprove(approvePassport, data)}
                            {...noticeTypes}
                        />
                    </>}

                    {data?.old_passport && <>
                        <Grid item xs={12}>
                            <Typography variant="font2">Документ, по которому сдавался ЕГЭ</Typography>
                            <Divider />
                        </Grid>

                        <EntrantInfoField name="Серия" value={data.old_passport.series} xs={2} />
                        <EntrantInfoField name="Номер" value={data.old_passport.number} xs={2} />
                        <EntrantInfoField name="Код подразделения" value={data.old_passport.issue_dep_code} />
                        <EntrantInfoField name="Дата выдачи" value={data.old_passport.issue_date} type="date" />

                        <Grid item xs={12}>
                            <Typography variant="font1">Подтверждающие документы</Typography>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            {data.old_passport.files.length === 0 ? "Нет файлов" : <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {data.old_passport.files.map((file, index) => <Link
                                    key={index}
                                    href={file}
                                    target="_blank"
                                >
                                    {`Документ, по которому сдавался ЕГЭ - ${index + 1}`}
                                </Link>)}
                            </Box>}
                        </Grid>

                        <ApprovalsHistory approvals={data.old_passport.approvals} {...noticeTypes} />

                        <ApprovalActions
                            data={data.old_passport}
                            onSave={data => handleApprove(approveOldPassport, data)}
                            {...noticeTypes}
                        />
                    </>}

                    {data?.prev_edu && <>
                        <Grid item xs={12}>
                            <Typography variant="font2">Сведения о предыдущем образовании</Typography>
                            <Divider />
                        </Grid>

                        <EntrantInfoField name="Тип документа" value={data.prev_edu.doc_type} xs={12} />
                        <EntrantInfoField name="Серия" value={data.prev_edu.series} />
                        <EntrantInfoField name="Номер" value={data.prev_edu.number} />
                        <EntrantInfoField name="Регистрационный номер" value={data.prev_edu.reg_number} />
                        <EntrantInfoField name="Дата выдачи" value={data.prev_edu.issue_date} type="date" xs={2} />
                        <EntrantInfoField name="Год окончания" value={data.prev_edu.year_end} xs={2} />
                        <EntrantInfoField name="Уровень образования" value={data.prev_edu.level} />
                        <EntrantInfoField name="Направление подготовки" value={data.prev_edu.spec} />
                        <EntrantInfoField name="Страна, в которой документ был выдан" value={data.prev_edu.citizen} />
                        <EntrantInfoField name="Регион" value={data.prev_edu.region} />
                        <EntrantInfoField name="Образовательное учреждение" value={data.prev_edu.institution} />
                        <EntrantInfoField name="С отличием" value={data.prev_edu.with_honors} type="boolean" />
                        <EntrantInfoField name="Средний балл" value={data.prev_edu.average_mark} />
                        <EntrantInfoField name="Оценка ВКР" value={data.prev_edu.diploma_mark} />
                        <EntrantInfoField name="Подтвержден в СС" value={data.prev_edu.is_checked} type="boolean" />

                        <Grid item xs={12}>
                            <Typography variant="font1">Подтверждающие документы</Typography>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            {data.prev_edu.files.length === 0 ? "Нет файлов" : <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {data.prev_edu.files.map((file, index) => <Link
                                    key={index}
                                    href={file}
                                    target="_blank"
                                >
                                    {`Сведения о предыдущем образовании - ${index + 1}`}
                                </Link>)}
                            </Box>}
                        </Grid>

                        <ApprovalsHistory approvals={data.prev_edu.approvals} {...noticeTypes} />

                        <ApprovalActions
                            data={data.prev_edu}
                            onSave={data => handleApprove(approvePrevEdu, data)}
                            {...noticeTypes}
                        />
                    </>}

                    {data?.exemptions?.length > 0 && <>
                        <Grid item xs={12}>
                            <Typography variant="font2">Особые или преимущественные права</Typography>
                            <Divider />
                        </Grid>

                        {data.exemptions.map((exemption, index) => <React.Fragment key={index}>

                            <EntrantInfoField name="Категория документа" value={exemption.doc_type_category} xs={12} />
                            <EntrantInfoField name="Тип документа" value={exemption.doc_type} xs={12} />
                            <EntrantInfoField name="Серия" value={exemption.series} xs={2} />
                            <EntrantInfoField name="Номер" value={exemption.number} xs={2} />
                            <EntrantInfoField name="Наименование документа" value={exemption.name} />
                            <EntrantInfoField name="Дата выдачи" value={exemption.issue_date} type="date" />
                            <EntrantInfoField name="Кем выдан" value={exemption.organization} />
                            <EntrantInfoField name="Группа инвалидности" value={exemption.invalid_group} />
                            <EntrantInfoField name="Дата предоставления оригинала документа" value={exemption.original_date} type="date" />

                            <Grid item xs={12}>
                                <Typography variant="font1">Подтверждающие документы</Typography>
                                <Divider />
                            </Grid>

                            <Grid item xs={12}>
                                {exemption.files.length === 0 ? "Нет файлов" : <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    {exemption.files.map((file, index) => <Link
                                        key={index}
                                        href={file}
                                        target="_blank"
                                    >
                                        {`${exemption.doc_type_category} - ${exemption.doc_type} - ${index + 1}`}
                                    </Link>)}
                                </Box>}
                            </Grid>

                            <ApprovalsHistory approvals={exemption.approvals} {...noticeTypes} />

                            <ApprovalActions
                                data={exemption}
                                onSave={data => handleApprove(approveExemption, data)}
                                approveButtonLabel="Пометить особое или преим. право как проверенное"
                                reworkButtonLabel="Отправить на доработку"
                                {...noticeTypes}
                            />

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                        </React.Fragment>)}
                    </>}


                    {data?.sat_exams?.length > 0 && <>
                        <Grid item xs={12}>
                            <Typography variant="font2">Результаты ЕГЭ</Typography>
                            <Divider />
                        </Grid>

                        {data?.sat_exams?.map(exam => <React.Fragment key={exam.id}>

                            <EntrantInfoField name="Проверено в ФИС" value={exam.is_checked} xs={3} type="boolean" />
                            <EntrantInfoField name="Тип" value={exam.exam_type} xs={3} />
                            <EntrantInfoField name="Предмет" value={exam.subject} xs={3} />
                            <EntrantInfoField name="Баллы" value={exam.points} xs={3} />

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                        </React.Fragment>)}
                    </>}

                    {data?.olymps?.length > 0 && <>
                        <Grid item xs={12}>
                            <Typography variant="font2">Олимпиады, подтверждаемые баллами ЕГЭ по соответствующему предмету</Typography>
                            <Divider />
                        </Grid>

                        {data?.olymps?.map(olymp => <React.Fragment key={olymp.id}>

                            <EntrantInfoField name="Название олимпиады" value={olymp.name} xs={6} />
                            <EntrantInfoField name="Результат" value={olympResultLookups[olymp.result] ?? ""} xs={6} />

                            <Grid item xs={12}>
                                <Typography variant="font1">Подтверждающие документы</Typography>
                                <Divider />
                            </Grid>

                            <Grid item xs={12}>
                                {olymp.files.length === 0 ? "Нет файлов" : <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    {olymp.files.map((file, index) => <Link
                                        key={index}
                                        href={file}
                                        target="_blank"
                                    >
                                        {`${olymp.name} - ${index + 1}`}
                                    </Link>)}
                                </Box>}
                            </Grid>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                        </React.Fragment>)}
                    </>}

                    {data?.achievements?.length > 0 && <>
                        <Grid item xs={12}>
                            <Typography variant="font2">Индивидуальные достижения</Typography>
                            <Divider />
                        </Grid>

                        {data?.achievements?.map((achievement, index) => <React.Fragment key={index}>

                            {achievement.is_unidentified && <Grid item xs={12}>
                                <Alert severity="warning">
                                    Неопознанное достижение
                                </Alert>
                            </Grid>}

                            <EntrantInfoField name="Тип" value={achievement.type} xs={12} />
                            <EntrantInfoField name="Подтверждающий документ" value={achievement.confirmation_doc} xs={12} />
                            <EntrantInfoField name="Серия" value={achievement.doc_number} />
                            <EntrantInfoField name="Номер" value={achievement.doc_series} />
                            <EntrantInfoField name="Макс кол-во баллов" value={achievement.max_points} />

                            <Grid item xs={12}>
                                <Typography variant="font1">Подтверждающие документы</Typography>
                                <Divider />
                            </Grid>

                            <Grid item xs={12}>
                                {achievement.files.length === 0 ? "Нет файлов" : <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    {achievement.files.map((file, index) => <Link
                                        key={index}
                                        href={file}
                                        target="_blank"
                                    >
                                        {`${achievement.type} - ${index + 1}`}
                                    </Link>)}
                                </Box>}
                            </Grid>

                            <ApprovalsHistory approvals={achievement.approvals} {...noticeTypes} />

                            <ApprovalActions
                                data={achievement}
                                onSave={data => handleApprove(approveAchievement, data)}
                                approveButtonLabel="Пометить достижение как проверенное"
                                reworkButtonLabel="Отправить на доработку"
                                {...noticeTypes}
                            />

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                        </React.Fragment>)}
                    </>}

                    {data?.payer && <>
                        <Grid item xs={12}>
                            <Typography variant="font2">Платежная информация</Typography>
                            <Divider />
                        </Grid>

                        <EntrantInfoField name="ФИО" value={`${data.payer?.surname ?? ""} ${data.payer.name ?? ""} ${data.payer.patronymic ?? ""}`} xs={3} />
                        <EntrantInfoField name="Гражданство" value={data.payer.citizen} xs={3} />
                        <EntrantInfoField name="Email" value={data.payer.email} xs={3} />
                        <EntrantInfoField name="Телефон" value={data.payer.phone} xs={3} />
                        <EntrantInfoField name="Дата рождения" value={data.payer.birthday} type="date" xs={3} />
                        <EntrantInfoField name="СНИЛС" value={data.payer.snils_number} xs={3} />
                        <EntrantInfoField name="Место рождения" value={data.payer.birth_place} xs={6} />
                        <EntrantInfoField name="Пол" value={data.payer.gender === 1 ? "Мужской" : "Женский"} />
                        <EntrantInfoField name="Тип документа, удостоверяющий личность" value={data.payer.doc_type} xs={12} />
                        <EntrantInfoField name="Серия" value={data.payer.passport_series} xs={2} />
                        <EntrantInfoField name="Номер" value={data.payer.passport_number} xs={2} />
                        <EntrantInfoField name="Кем выдан" value={data.payer.passport_issue_dep} xs={4} />
                        <EntrantInfoField name="Код подразделения" value={data.payer.passport_issue_dep_code} xs={2} />
                        <EntrantInfoField name="Дата выдачи" value={data.payer.passport_issue_date} type="date" xs={2} />
                        <EntrantInfoField name="Адрес" value={data.payer.address?.value} xs={12} />

                    </>}

                    {data?.target_contract && <>

                        <Grid item xs={12}>
                            <Typography variant="font2">Договор о целевом обучении</Typography>
                            <Divider />
                        </Grid>

                        <EntrantInfoField name="Номер договора" value={data.target_contract.number} xs={6} />
                        <EntrantInfoField name="Дата" value={data.target_contract.date} type="date" xs={6} />

                        <EntrantInfoField name="Наименование организации" value={data.target_contract.organization.name} xs={12} />
                        <EntrantInfoField name="Сокращенное наименование организации" value={data.target_contract.organization.sname} xs={12} />
                        <EntrantInfoField name="ОГРН" value={data.target_contract.organization.ogrn} />
                        <EntrantInfoField name="КПП" value={data.target_contract.organization.kpp} />
                        <EntrantInfoField name="ИНН" value={data.target_contract.organization.inn} />
                        <EntrantInfoField name="ФИО руководителя организации" value={data.target_contract.organization.head_name} />
                        <EntrantInfoField name="Телефон" value={data.target_contract.organization.phone} />
                        <EntrantInfoField name="Email" value={data.target_contract.organization.email} />
                        <EntrantInfoField name="Адрес" value={data.target_contract.organization.address} xs={12} />

                        <Grid item xs={12}>
                            <Typography variant="font1">Подтверждающие документы</Typography>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            {data.target_contract.files.length === 0 ? "Нет файлов" : <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {data.target_contract.files.map((file, index) => <Link
                                    key={index}
                                    href={file}
                                    target="_blank"
                                >
                                    {`Договор о целевом обучении - ${index + 1}`}
                                </Link>)}
                            </Box>}
                        </Grid>

                        <ApprovalsHistory approvals={data.target_contract.approvals} {...noticeTypes} />

                        <ApprovalActions
                            data={data.target_contract}
                            onSave={data => handleApprove(approveTargetContract, data)}
                            {...noticeTypes}
                        />
                    </>}

                    {data?.profile_options && <>

                        <Grid item xs={12}>
                            <Typography variant="font2">Дополнительная информация</Typography>
                            <Divider />
                        </Grid>

                        <EntrantInfoField name="Уровни образования, на которые абитуриент планирует поступать" value={data.profile_options.levels.join(", ")} xs={12} />
                        <EntrantInfoField name="Специальные условия при проведении вступительных испытаний" value={data.profile_options.special_conditions_entrance_test} xs={12} />
                        <EntrantInfoField name="Нуждается в общежитии" value={data.profile_options.is_need_hostel} type="boolean" />

                        <Grid item xs={12}>
                            <Typography variant="font1">Подтверждающие документы</Typography>
                            <Divider />
                        </Grid>

                        <Grid item>
                            {data.profile_options.files.length === 0 ? "Нет файлов" : <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {data.profile_options.files.map((file, index) => <Link
                                    key={index}
                                    href={file}
                                    target="_blank"
                                >
                                    {`Дополнительная информация - ${index + 1}`}
                                </Link>)}
                            </Box>}
                        </Grid>
                    </>}

                    {data?.ad_files && <>
                        <Grid item xs={12}>
                            <Typography variant="font2">Прочие документы</Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {data.ad_files.map((adFile, adIndex) => adFile.files.map((file, fIndex) => <Link
                                    key={`${adIndex}-${fIndex}`}
                                    href={file}
                                    target="_blank"
                                >
                                    {`${adFile.name} - ${fIndex + 1}`}
                                </Link>))}
                            </Box>
                        </Grid></>}

                </Grid>
            </Paper>
        </>}
    </Box >
}

export default EntrantAllInfoPage