import { useDispatch } from "react-redux"
import React from "react"
import { getCGAchievements } from "../../redux/competitiveGroupAchievementsSlice"
import { useSelector } from "react-redux"

const useCompetitiveGroupAchievements = id => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (id) dispatch(getCGAchievements(id))
    }, [dispatch, id])

    const { achievements, achievementsIsLoading } = useSelector(x => x.competitiveGroupAchievements)

    return { achievements, achievementsIsLoading }
}

export default useCompetitiveGroupAchievements