import { Autocomplete, Box, Button, Checkbox, FormControlLabel, Paper, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import * as yup from "yup"
import routes from "../../components/routes"
import toastWrapper from "../../components/toastWrapper"
import { eduFormLookups, eduForms } from "../../constants"
import useCgSpecs from "../../hooks/useCgSpecs"
import useCgSubspecs from "../../hooks/useCgSubspecs"
import useIsSmallScreen from "../../hooks/useIsSmallScreen"
import useLevels from "../../hooks/useLevels"
import useUniversities from "../../hooks/useUniversities"
import { dictService } from "../../services/dictService"
import { getOptions } from "../../utils"

const RatingCompetitiveGroupSelect = ({ type }) => {

    const navigate = useNavigate()

    const { id } = useParams()

    const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
        initialValues: {
            level: null,
            is_budget: true,
            university: null,
            edu_form: null,
            is_foreign_with_direction: false,
            is_english_program: false,
            without_quota_types: true,
            quota_types: [],
            spec: null,
            subspec: null,
        },
        validationSchema: yup.object({
            level: yup.number().required(),
            is_budget: yup.boolean(),
            university: yup.number().required(),
            edu_form: yup.number().required(),
            is_foreign_with_direction: yup.boolean(),
            is_english_program: yup.boolean(),
            without_quota_types: yup.boolean(),
            quota_types: yup.array().of(yup.number()),
            spec: yup.number().required(),
            subspec: yup.number().required(),
        }),
        onSubmit: values => {
            dictService.getCompetitiveGroups(values).then(res => {
                if (res?.data?.length === 1) {
                    navigate(type === "dynamic" ?
                        routes.dynamicRatingInfo.makePath(res.data[0].id) :
                        routes.staticRatingInfo.makePath(id, res.data[0].id)
                    )
                } else {
                    toastWrapper("Не удалось найти конкурсную группу")
                }
            })
        }
    })
    const { universityLevelOptions, levelLookups, levelsIsLoading } = useLevels()
    const { universityOptions, universityLookups, universitiesIsLoading } = useUniversities()
    const eduFormOptions = getOptions(eduForms)

    const { specOptions, specLookups, specsIsLoading } = useCgSpecs(
        values.level,
        values.university,
        values.edu_form,
        values.is_budget,
        values.is_english_program,
        values.is_foreign_with_direction,
        values.quota_types,
        values.without_quota_types,
    )

    const { subspecOptions, subspecLookups, subspecsIsLoading } = useCgSubspecs(
        values.level,
        values.university,
        values.edu_form,
        values.is_budget,
        values.is_english_program,
        values.is_foreign_with_direction,
        values.quota_types,
        values.without_quota_types,
        values.spec
    )

    const isSmallScreen = useIsSmallScreen()

    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 650, minWidth: 280, margin: "auto" }}>

        <Box sx={{
            m: 1,
            p: 2,
            borderRadius: 1,
            display: "flex",
            backgroundColor: "secondary.main",
        }}>
            <Typography sx={{ ml: 2 }} variant="font2" color="white">
                {type === "static" ? "Статический рейтинг-лист" : "Динамический рейтинг-лист"}
            </Typography>
        </Box>

        <Paper sx={{ display: "flex", flexDirection: "column", m: 1, p: 2, minHeight: 300 }}>
            <Autocomplete
                value={values.level}
                options={universityLevelOptions}
                getOptionLabel={option => levelLookups[option]?.name ?? ""}
                onChange={(_, value) => setFieldValue("level", value)}
                loading={levelsIsLoading}
                renderInput={props => <TextField
                    label="Уровень образования"
                    error={touched.level && Boolean(errors.level)}
                    {...props}
                />}
            />

            <FormControlLabel
                label="Бюджетная основа обучения"
                control={<Checkbox
                    checked={values.is_budget}
                    onChange={(_, value) => {
                        setFieldValue("is_budget", value)
                        setFieldValue("quota_types", [])
                        setFieldValue("without_quota_types", true)
                    }}
                />}
            />

            <Autocomplete
                value={values.university}
                options={universityOptions}
                getOptionLabel={option => universityLookups[option]?.sname ?? ""}
                onChange={(_, value) => setFieldValue("university", value)}
                loading={universitiesIsLoading}
                renderInput={props => <TextField
                    label="Университет"
                    error={touched.university && Boolean(errors.university)}
                    {...props}
                />}
            />

            <Autocomplete
                value={values.edu_form}
                options={eduFormOptions}
                getOptionLabel={option => eduFormLookups[option]}
                onChange={(_, value) => setFieldValue("edu_form", value)}
                renderInput={props => <TextField
                    label="Форма обучения"
                    error={touched.edu_form && Boolean(errors.edu_form)}
                    {...props}
                />}
            />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <FormControlLabel
                    label="Прием иностранцев по направлению Минобрнауки"
                    control={<Checkbox
                        checked={values.is_foreign_with_direction}
                        onChange={(_, value) => {
                            setFieldValue(`is_foreign_with_direction`, value)
                            setFieldValue("spec", null)
                            setFieldValue("subspec", null)
                        }}
                    />}
                />

                <FormControlLabel
                    label="Английская программа обучения"
                    control={<Checkbox
                        checked={values.is_english_program}
                        onChange={(_, value) => {
                            setFieldValue(`is_english_program`, value)
                            setFieldValue("spec", null)
                            setFieldValue("subspec", null)
                        }}
                    />}
                />

                {values.is_budget && <>
                    <Typography variant="font5">Квоты:</Typography>

                    <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row" }}>
                        <FormControlLabel
                            label="Целевое обучение"
                            control={<Checkbox
                                checked={values.quota_types.includes(2)}
                                onChange={() => {
                                    if (values.quota_types.includes(2)) {
                                        if (values.quota_types.length === 1) {
                                            setFieldValue(`without_quota_types`, true)
                                        }
                                        setFieldValue(`quota_types`, values.quota_types.filter(el => el !== 2))
                                    } else {
                                        setFieldValue(`quota_types`, [...values.quota_types, 2])
                                        setFieldValue(`without_quota_types`, false)
                                    }
                                    setFieldValue("spec", null)
                                    setFieldValue("subspec", null)
                                }}
                            />}
                        />
                        <FormControlLabel
                            label="Особая квота"
                            control={<Checkbox
                                checked={values.quota_types.includes(3)}
                                onChange={() => {
                                    if (values.quota_types.includes(3)) {
                                        if (values.quota_types.length === 1) {
                                            setFieldValue(`without_quota_types`, true)
                                        }
                                        setFieldValue(`quota_types`, values.quota_types.filter(el => el !== 3))
                                    } else {
                                        setFieldValue(`quota_types`, [...values.quota_types, 3])
                                        setFieldValue(`without_quota_types`, false)
                                    }
                                    setFieldValue("spec", null)
                                    setFieldValue("subspec", null)
                                }}
                            />}
                        />
                        <FormControlLabel
                            label="Отдельная квота"
                            control={<Checkbox
                                checked={values.quota_types.includes(4)}
                                onChange={() => {
                                    if (values.quota_types.includes(4)) {
                                        if (values.quota_types.length === 1) {
                                            setFieldValue(`without_quota_types`, true)
                                        }
                                        setFieldValue(`quota_types`, values.quota_types.filter(el => el !== 4))
                                    } else {
                                        setFieldValue(`quota_types`, [...values.quota_types, 4])
                                        setFieldValue(`without_quota_types`, false)
                                    }
                                    setFieldValue("spec", null)
                                    setFieldValue("subspec", null)
                                }}
                            />}
                        />
                    </Box>
                </>}

                <Autocomplete
                    options={specOptions}
                    value={values.spec}
                    loading={specsIsLoading}
                    disabled={!values.university || !values.edu_form}
                    getOptionLabel={option => {
                        const spec = specLookups[option]
                        if (!spec) return ""
                        return `${spec.code} - ${spec.name}`
                    }}
                    onChange={(_, value) => {
                        setFieldValue("spec", value)
                        setFieldValue("subspec", null)
                    }}
                    renderInput={props => <TextField
                        label="Направление подготовки"
                        id="spec"
                        name="spec"
                        error={touched.spec && Boolean(errors.spec)}
                        {...props}
                    />}
                />

                <Autocomplete
                    options={subspecOptions}
                    value={values.subspec}
                    loading={subspecsIsLoading}
                    disabled={!values.university || !values.edu_form || !values.spec}
                    getOptionLabel={option => subspecLookups[option]?.name ?? ""}
                    onChange={(_, value) => { setFieldValue("subspec", value) }}
                    renderInput={props => <TextField
                        label="Профиль"
                        error={touched.subspec && Boolean(errors.subspec)}
                        {...props}
                    />}
                />

                <Button sx={{ mt: 1 }} variant="contained" onClick={handleSubmit}>
                    Перейти к рейтинг-листу выбранной конкурсной группы
                </Button>

            </Box>

        </Paper>
    </Box>
}

export default RatingCompetitiveGroupSelect