import { Autocomplete, Box, Button, Checkbox, FormControlLabel, TextField, Tooltip } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import InputMask from "react-input-mask"
import { useDispatch, useSelector } from "react-redux"
import * as yup from "yup"
import AddressSuggestions from "../../../components/DadataSuggestions/AddressSuggestions"
import Spinner from "../../../components/Spinner"
import toastWrapper from "../../../components/toastWrapper"
import useCitizenships from "../../../hooks/useCitizenships"
import useIsSmallScreen from "../../../hooks/useIsSmallScreen"
import { createPersonalInfo, getPersonalInfo, updatePersonalInfo } from "../../../redux/entrantForm/personalInfoSlice"
import { getProfileInfo } from "../../../redux/userSlice"
import { validateSnils } from "../../../utils"
import useLanguages from "../hooks/useLanguages"

const PersonalInfo = ({ userInfo, profileInfo }) => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (userInfo?.id) dispatch(getPersonalInfo(userInfo.id))
    }, [dispatch, userInfo])


    const { data, isLoading } = useSelector(x => x.entrantForm.personalInfo)

    const { citizenshipOptions, citizenshipLookups, citizenshipsIsLoading } = useCitizenships()
    const { languageOptions, languageLookups, languagesIsLoading } = useLanguages()

    const isSmallScreen = useIsSmallScreen()

    const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...data,
            isSameAddress: data.reg_address?.data?.fias_id && data.fact_address?.data?.fias_id ?
                data?.reg_address?.data?.fias_id === data?.fact_address?.data?.fias_id :
                data?.reg_address?.unrestricted_value === data?.fact_address?.unrestricted_value ?? false
        },
        onSubmit: values => {
            const newValues = { ...values, user: userInfo.id }
            delete (newValues.isSameAddress)
            dispatch(values?.id !== null ? updatePersonalInfo(newValues) : createPersonalInfo(newValues)).unwrap()
                .then(() => {
                    toastWrapper("Изменения сохранены", "success")
                    dispatch(getProfileInfo(userInfo.id))
                })
                .catch(e => {
                    dispatch(getProfileInfo(userInfo.id))
                    console.error(e)
                    toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
                })
        },
        validationSchema: yup.object({
            birthday: yup.date().required().min(new Date("1960-01-01")).max(`${new Date().getFullYear() - 11}-01-01`),
            birth_place: yup.string().required(),
            gender: yup.number(),
            citizen: yup.number().required(),
            reg_address: yup.mixed().test({
                test: value => {
                    if (value?.data?.fias_level) {
                        // мда
                        return value?.data?.fias_level && parseInt(value?.data?.fias_level?.[0]) >= 7
                    }
                    return value?.unrestricted_value?.length > 0
                }
            }),
            fact_address: yup.mixed().test({
                test: value => {
                    if (value?.data?.fias_level) {
                        return value?.data?.fias_level && parseInt(value?.data?.fias_level?.[0]) >= 7
                    }
                    return value?.unrestricted_value?.length > 0
                }
            }),
            snils_number: yup.string().test({ test: value => !validateSnils(value) }),
            foreign_lang: yup.number().required(),
        })
    })

    const componentHeight = isSmallScreen ? 590 : 365

    if (isLoading) return <Spinner height={componentHeight} />

    return <Box sx={{ height: componentHeight, display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextField
                id="birthday"
                name="birthday"
                label="Дата рождения"
                type="date"
                required
                value={values.birthday}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                sx={{ width: isSmallScreen ? "100%" : "32%" }}
                error={touched.birthday && Boolean(errors.birthday)}
            />
            <Autocomplete
                options={[0, 1]}
                value={values.gender}
                onChange={(_, value) => setFieldValue("gender", value)}
                getOptionLabel={option => option === 0 ? "Женский" : "Мужской"}
                sx={{ width: isSmallScreen ? "100%" : "32%" }}
                renderInput={props => <TextField
                    label="Пол"
                    required
                    {...props}
                />}
            />
            <Autocomplete
                id="citizen"
                name="citizen"
                options={citizenshipOptions}
                value={values.citizen}
                getOptionLabel={option => citizenshipLookups[option]?.sname ?? ""}
                loading={citizenshipsIsLoading}
                onChange={(_, value) => { setFieldValue("citizen", value) }}
                sx={{ width: isSmallScreen ? "100%" : "32%" }}
                renderInput={props => <TextField
                    label="Гражданство"
                    error={touched.citizen && Boolean(errors.citizen)}
                    required
                    {...props}
                />}
            />
        </Box>
        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <Autocomplete
                id="foreign_lang"
                name="foreign_lang"
                options={languageOptions}
                value={values.foreign_lang}
                getOptionLabel={option => languageLookups[option]?.name ?? ""}
                onChange={(_, value) => setFieldValue("foreign_lang", value)}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                loading={languagesIsLoading}
                renderInput={props => <TextField
                    label="Иностранный язык"
                    error={touched.foreign_lang && Boolean(errors.foreign_lang)}
                    required
                    {...props}
                />}
            />
            <TextField
                id="birth_place"
                name="birth_place"
                label="Место рождения"
                value={values.birth_place}
                onChange={handleChange}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                required
                error={touched.birth_place && Boolean(errors.birth_place)}
            />
        </Box>

        <FormControlLabel
            label="Адрес регистрации совпадает с адресом фактического проживания"
            componentsProps={{ typography: { variant: "subtitle2" } }}
            control={<Checkbox
                checked={values.isSameAddress}
                onChange={e => {
                    setFieldValue("isSameAddress", e.target.checked)
                    if (e.target.checked) setFieldValue("fact_address", values.reg_address)
                }}
            />}
        />

        <AddressSuggestions
            name="reg_address"
            type="address"
            label="Адрес регистрации"
            required
            value={values.reg_address}
            onChange={value => {
                if (values.isSameAddress) { setFieldValue("fact_address", value) }
                setFieldValue("reg_address", value)
            }}
            error={
                (touched.reg_address && Boolean(errors.reg_address)) ||
                (values?.reg_address?.data?.fias_level && parseInt(values.reg_address.data.fias_level?.[0]) < 7)
            }
        />

        <AddressSuggestions
            name="fact_address"
            label="Адрес проживания"
            required
            value={values.fact_address}
            onChange={value => { setFieldValue("fact_address", value) }}
            disabled={values.isSameAddress}
            error={
                // ПОЗОР! TODO Придумать как сделать нормально
                !values.isSameAddress ? touched.fact_address && Boolean(errors.fact_address) : null
            }
        />

        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <InputMask
                maskChar=""
                mask="999-999-999 99"
                id="snils_number"
                name="snils_number"
                value={values.snils_number}
                onChange={event => {
                    let newValue = event.target.value?.replaceAll("-", "").replaceAll(" ", "")
                    setFieldValue("snils_number", newValue)
                }}
            >
                {(props) => <TextField
                    {...props}
                    label="СНИЛС"
                    error={Boolean(validateSnils(values.snils_number)) && values.snils_number !== ""}
                    helperText={validateSnils(values.snils_number)}
                    sx={{ width: isSmallScreen ? "100%" : "49%" }}
                />}
            </InputMask>
            <TextField
                id="last_surname"
                name="last_surname"
                label="Предыдущая фамилия"
                value={values.last_surname}
                onChange={handleChange}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
            />
        </Box>
        <Tooltip title={!values?.can_edit_and_delete ? "Вы не можете редактировать данный раздел анкеты, когда есть поданные заявления" : ""}><span>
            <Button
                disabled={!values?.can_edit_and_delete}
                sx={{ mt: 1 }}
                variant="contained"
                onClick={handleSubmit}
                fullWidth
            >
                Сохранить
            </Button>
        </span></Tooltip>
    </Box >
}

export default PersonalInfo