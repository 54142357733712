import { Box, Link as MuiLink, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import routes from "../components/routes"

const PageUnavailable = () => {

    return <Box sx={{
        p: 2,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    }}>
        <Typography align="center" variant="h4" sx={{ mb: 4 }}>Раздел временно недоступен</Typography>

        <Box sx={{ display: "flex" }}>
            <MuiLink sx={{ mr: 1 }} component={Link} to={routes.profile.path}>Вернуться на главную</MuiLink>
        </Box>
    </Box>
}

export default PageUnavailable