import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk } from "../utils";
import { operatorService } from "../../services/operatorService";

export const getApplicationStatusReport = createCustomAsyncThunk(
    "reports/applicationStatus/get",
    async params => await operatorService.getApplicationStatusReport(params)
)

const applicationStatusSlice = createSlice({
    name: "reports/applicationStatus",
    initialState: {
        current: null,
        data: null,
        isLoading: false,
        error: null,
        onlyMaxPriority: false,
    },
    reducers: {
        setCurrent: (state, action) => { state.current = action.payload },
        resetCurrent: state => { state.current = null },
        setOnlyMaxPriority: (state, action) => { state.onlyMaxPriority = action.payload }
    },
    extraReducers: builder => {
        builder
            .addCase(getApplicationStatusReport.pending, state => { state.isLoading = true })
            .addCase(getApplicationStatusReport.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(getApplicationStatusReport.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const {
    setCurrent: setCurrentDepartmentForASReport,
    setOnlyMaxPriority: setOnlyMaxPriorityForASReport,
    resetCurrent: resetCurrentDepartmentForASReport,
} = applicationStatusSlice.actions

export default applicationStatusSlice.reducer