import { entrantAllInfoService } from "../services/entrantService";
import { userService } from "../services/userService";
import { createCustomAsyncThunk } from "./utils";

const { createSlice } = require("@reduxjs/toolkit");

export const getEntrantAllInfo = createCustomAsyncThunk(
    "entrant/allInfo/get",
    async userId => await entrantAllInfoService.get(userId)
)

export const getEntrant = createCustomAsyncThunk(
    "entrant/allInfo/getEntrant",
    async userId => await userService.getUserInfoById(userId)
)

export const approvePersonalInfo = createCustomAsyncThunk(
    "entrant/allInfo/personalInfo/approve",
    async data => await entrantAllInfoService.approvePersonalInfo(data)
)

export const approvePassport = createCustomAsyncThunk(
    "entrant/allInfo/passport/approve",
    async data => await entrantAllInfoService.approvePassport(data)
)

export const approveOldPassport = createCustomAsyncThunk(
    "entrant/allInfo/oldPassport/approve",
    async data => await entrantAllInfoService.approveOldPassport(data)
)

export const approvePrevEdu = createCustomAsyncThunk(
    "entrant/allInfo/prevEdu/approve",
    async data => await entrantAllInfoService.approvePrevEdu(data)
)

export const approveTargetContract = createCustomAsyncThunk(
    "entrant/allInfo/targetContract/approve",
    async data => await entrantAllInfoService.approveTargetContract(data)
)

export const approveDirectionEduMinistry = createCustomAsyncThunk(
    "entrant/allInfo/directionEduMinistry/approve",
    async data => await entrantAllInfoService.approveDirectionEduMinistry(data)
)

export const approveExemption = createCustomAsyncThunk(
    "entrant/allInfo/exemptions/approve",
    async data => await entrantAllInfoService.approveExemption(data)
)

export const approveAchievement = createCustomAsyncThunk(
    "entrant/allInfo/achievements/approve",
    async data => await entrantAllInfoService.approveAchievement(data)
)

const entrantAllInfoSlice = createSlice({
    name: "entrant/allInfo",
    initialState: {
        userId: null,
        user: null,
        userIsLoading: false,
        data: null,
        dataIsLoading: false,
        error: null,
    },
    reducers: {
        resetEntrantAllInfo: state => {
            state.open = false
            state.data = null
            state.user = null
            state.userId = null
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getEntrantAllInfo.pending, state => { state.dataIsLoading = true })
            .addCase(getEntrantAllInfo.fulfilled, (state, action) => {
                state.data = action.payload
                state.dataIsLoading = false
            })
            .addCase(getEntrantAllInfo.rejected, (state, action) => {
                state.error = action.error
                state.dataIsLoading = false
            })

            .addCase(getEntrant.pending, state => { state.userIsLoading = true })
            .addCase(getEntrant.fulfilled, (state, action) => {
                state.user = action.payload
                state.userIsLoading = false
            })
            .addCase(getEntrant.rejected, (state, action) => {
                state.error = action.error
                state.userIsLoading = false
            })

            .addCase(approvePersonalInfo.fulfilled, (state, action) => {
                state.data.personal_info.approvals = action.payload.approvals
            })
            .addCase(approvePassport.fulfilled, (state, action) => {
                state.data.passport.approvals = action.payload.approvals
            })
            .addCase(approveOldPassport.fulfilled, (state, action) => {
                state.data.old_passport.approvals = action.payload.approvals
            })
            .addCase(approvePrevEdu.fulfilled, (state, action) => {
                state.data.prev_edu.approvals = action.payload.approvals
            })
            .addCase(approveTargetContract.fulfilled, (state, action) => {
                // TODO КОСТЫЛЬ, СДЕЛАТЬ НОРМАЛЬНО
                state.data.target_contract.approvals = action.payload.approvals
            })
            .addCase(approveDirectionEduMinistry.fulfilled, (state, action) => {
                state.data.direction_edu_ministry.approvals = action.payload.approvals
            })
            .addCase(approveExemption.fulfilled, (state, action) => {
                state.data.exemptions = state.data.exemptions.map(ex =>
                    ex.id === action.payload.id ? { ...ex, approvals: action.payload.approvals } : ex
                )
            })
            .addCase(approveAchievement.fulfilled, (state, action) => {
                // TODO ТОЖЕ КОСТЫЛЬ
                state.data.achievements = state.data.achievements.map(ach =>
                    ach.id === action.payload.id ? { ...ach, approvals: action.payload.approvals } : ach
                )
            })
    }
})

export const { openEntrantAllInfoDialog, resetEntrantAllInfo } = entrantAllInfoSlice.actions

export default entrantAllInfoSlice.reducer