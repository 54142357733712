import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProfileInfo, updateProfileInfo } from "../../../redux/userSlice"


const useProfileInfo = userId => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (userId) {
            dispatch(getProfileInfo(userId))
        }
    }, [dispatch, userId])

    const { profileInfo, profileInfoIsLoading } = useSelector(x => x.user)

    const handleChangeProfileInfoLevels = levels => {
        dispatch(updateProfileInfo({ userId, ...profileInfo, levels }))
    }

    return { profileInfo, profileInfoIsLoading, handleChangeProfileInfoLevels }
}

export default useProfileInfo