import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import MaterialTableWrapper from "../../components/MaterialTableWrapper"
import useLevels from "../../hooks/useLevels"
import useUniversities from "../../hooks/useUniversities"
import useCurrentOrder from "./hooks/useCurrentOrder"
import useOrderItemsTableProps from "./hooks/useOrderItemsTableProps"
import routes from "../../components/routes"
import useSpecs from "../../hooks/useSpecs"
import { getLookups } from "../../utils"

const OrderItems = () => {

    const { id } = useParams()

    const { current, isLoading } = useCurrentOrder(id)

    const { levelLookups, levelsIsLoading } = useLevels()

    const { universityLookups, universitiesIsLoading } = useUniversities()

    const { specs } = useSpecs()
    const specLookups = React.useMemo(() => getLookups(specs.filter(el => el?.level === current?.level), ["code", "name"], " - "), [specs, current])

    const dictsIsLoading = levelsIsLoading || universitiesIsLoading

    const navigate = useNavigate()

    const handleNavigate = () => navigate(routes.orderList.path)

    const tableRef = React.useRef()
    const tableProps = useOrderItemsTableProps(
        id, current, isLoading || dictsIsLoading, levelLookups, universityLookups, specLookups, handleNavigate
    )

    return <>
        <MaterialTableWrapper
            tableRef={tableRef}
            {...tableProps}
        />
    </>
}

export default OrderItems