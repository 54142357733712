import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { eduDocTypeSelectors, getEduDocTypes } from "../../../redux/dicts/eduDocTypesSlice"

const useEduDocTypes = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (eduDocTypeOptions.length === 0) {
            dispatch(getEduDocTypes())
        }
        //eslint-disable-next-line
    }, [dispatch])

    const eduDocTypes = useSelector(eduDocTypeSelectors.selectAll)
    const eduDocTypeOptions = useSelector(eduDocTypeSelectors.selectIds)
    const eduDocTypeLookups = useSelector(eduDocTypeSelectors.selectEntities)
    const eduDocTypesIsLoading = useSelector(x => x.dicts.eduDocTypes.isLoading)

    return { eduDocTypes, eduDocTypeOptions, eduDocTypeLookups, eduDocTypesIsLoading }
}

export default useEduDocTypes