import { TextField } from "@mui/material";
import React from "react";
import ReactDadataBox from 'react-dadata-box';


const CustomDadataSuggestions = ({ value, onChange, label, required = false, type = 'address', error = false, sx, disabled = false, ...rest }) => {

    return <ReactDadataBox
        query={value?.unrestricted_value ?? value?.value ?? value} // Если строка - value, если дадатовский объект - value.value
        token={window._env_.DADATA_TOKEN}
        type={type}
        onChange={onChange}
        debounce={1000}
        style={{ width: sx?.width ?? "100%" }}
        margin="none"
        customInput={props => <TextField
            {...props}
            disabled={disabled}
            label={label}
            value={props.value}
            required={required}
            sx={{ mb: 2, border: "0", paddingLeft: 0, paddingRight: 0, ...sx, width: "100%" }}
            error={error}
            // ВНИМАНИЕ КОСТЫЛЬ
            // Баг компонента. Если ввести невалидный запрос, получить ответ от сервера и нажать enter, то выдаст ошибку
            onKeyDown={() => null}
            {...rest}
        />
        }
    />
}

export default CustomDadataSuggestions