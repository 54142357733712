import { Add, Delete, ErrorOutline } from "@mui/icons-material"
import { Autocomplete, Box, Button, Divider, IconButton, TextField, Tooltip, Typography } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import * as yup from "yup"
import toastWrapper from "../../../components/toastWrapper"
import useIsSmallScreen from "../../../hooks/useIsSmallScreen"
import { getAchievementsInfo, updateAchievementsInfo } from "../../../redux/entrantForm/achievementsInfoSlice"
import useAchievementTypes from "../../../hooks/useAchievementTypes"

const achievementInitialValues = {
    doc_series: "",
    doc_number: "",
    issue_date: "2023-01-01",
    type: null,
    is_init_values: true,
    can_edit_and_delete: true,
    edited: true,
}

const AchievementsInfo = ({ userInfo, profileInfo }) => {

    const dispatch = useDispatch()

    const { achievementTypeOptions, achievementTypeLookups, achievementTypesIsLoading } = useAchievementTypes()

    React.useEffect(() => {
        if (userInfo?.id) dispatch(getAchievementsInfo(userInfo.id))
    }, [userInfo, dispatch])

    const { data } = useSelector(x => x.entrantForm.achievements)

    const saveAchievements = values => {
        const newAchievements = values.map(value => ({ ...value, user: userInfo.id }))
        const created = newAchievements.filter(el => el.is_init_values)
        const updated = newAchievements.filter(el => !el.is_init_values && el.id && el.edited)
        const deleted = data.filter(el => !newAchievements.map(el => el.id).includes(el.id))
        dispatch(updateAchievementsInfo({ created, updated, deleted, user: userInfo.id })).unwrap()
            .then(() => { toastWrapper("Изменения сохранены", "success") })
            .catch(e => {
                console.error(e)
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
            })
    }

    const { handleSubmit, values, handleChange, setValues, setFieldValue, errors, touched } = useFormik({
        initialValues: data,
        enableReinitialize: true,
        onSubmit: saveAchievements,
        validationSchema: yup.array().of(yup.object({
            doc_series: yup.string(),
            doc_number: yup.string(),
            issue_date: yup.date(),
            type: yup.number().required(),
        }))
    })

    const _handleChange = (e, index) => {
        handleChange(e)
        setFieldValue(`[${index}].edited`, true)
    }

    const levelsToFilterAchievementTypes = React.useMemo(() => !profileInfo ? [] :
        profileInfo?.levels?.includes(5) ? [...profileInfo?.levels, 4] : profileInfo?.levels, [profileInfo])

    const isSmallScreen = useIsSmallScreen()

    return <Box>
        {values.map((value, index) => <React.Fragment key={index}>

            {index !== 0 && <Divider sx={{ mt: 2, mb: 1 }} />}

            {value.is_unidentified && <Box sx={{ display: "flex", justifyContent: "center" }}>
                <ErrorOutline color="error" sx={{ mr: 1 }} />
                <Typography>Неопознанное достижение</Typography>
            </Box>}

            <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>

                <Autocomplete
                    options={achievementTypeOptions}
                    getOptionLabel={option => achievementTypeLookups[option]?.name ?? ""}
                    filterOptions={options => options.filter(el => levelsToFilterAchievementTypes.includes(achievementTypeLookups[el].level))}
                    value={value.type}
                    loading={achievementTypesIsLoading}
                    disabled={!value.can_edit_and_delete}
                    fullWidth
                    sx={{ mr: 1 }}
                    onChange={(_, value) => {
                        setFieldValue(`[${index}].type`, value)
                        setFieldValue(`[${index}].edited`, true)
                    }}
                    renderInput={props => <TextField
                        label="Тип достижения"
                        required
                        error={errors[index]?.type && Boolean(touched[index]?.type)}
                        {...props}
                    />}
                />

                <Tooltip title={value.can_edit_and_delete ? "Удалить достижение" : "Удалить достижение нельзя"}>
                    <span>
                        <IconButton
                            disabled={!value.can_edit_and_delete}
                            onClick={() => setValues(values.filter((_, ind) => index !== ind))}
                        >
                            <Delete color={value.can_edit_and_delete ? "error" : "disabled"} />
                        </IconButton>
                    </span>
                </Tooltip>

            </Box>

            <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", width: "100%", justifyContent: "space-between" }}>
                <TextField
                    id={`[${index}].doc_series`}
                    name={`[${index}].doc_series`}
                    label="Серия документа"
                    disabled={!value.can_edit_and_delete}
                    value={value.doc_series}
                    onChange={e =>_handleChange(e, index)}
                    sx={{ width: isSmallScreen ? "100%" : "32%" }}
                />

                <TextField
                    id={`[${index}].doc_number`}
                    name={`[${index}].doc_number`}
                    label="Номер документа"
                    disabled={!value.can_edit_and_delete}
                    value={value.doc_number}
                    onChange={e =>_handleChange(e, index)}
                    sx={{ width: isSmallScreen ? "100%" : "32%" }}
                />

                <TextField
                    id={`[${index}].issue_date`}
                    name={`[${index}].issue_date`}
                    label="Дата выдачи"
                    disabled={!value.can_edit_and_delete}
                    value={value.issue_date}
                    type="date"
                    onChange={e =>_handleChange(e, index)}
                    sx={{ width: isSmallScreen ? "100%" : "32%" }}

                />
            </Box>
        </React.Fragment>)}
        <Button
            fullWidth
            onClick={() => setValues([...values, achievementInitialValues])}
            sx={{ mb: 1, mt: 1 }}
        >
            <Add color="primary" />
        </Button>
        <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            sx={{ mt: 1 }}
        >
            Сохранить
        </Button>
    </Box >
}

export default AchievementsInfo