import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getEntranceTestRegistration } from "../../../redux/entrantApplications/entranceTestRegistrationSlice"

const useEntranceTestRegistration = appId => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (appId) dispatch(getEntranceTestRegistration(appId))
    }, [dispatch, appId])

    const entranceTestRegistrations = useSelector(x => x.entrantApplications.entranceTestRegistration.data)
    const entranceTestRegistrationsIsLoading = useSelector(x => x.entrantApplications.entranceTestRegistration.isLoading)

    return { entranceTestRegistrations, entranceTestRegistrationsIsLoading }
}

export default useEntranceTestRegistration