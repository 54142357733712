import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk } from "../utils";
import { ordersService } from "../../services/operatorService";

export const getCurrentOrder = createCustomAsyncThunk(
    "operator/orders/availableApps/get",
    async id => await ordersService.getById(id)
)

const currentOrderSlice = createSlice({
    name: "operator/orders/availableApps/",
    initialState: {
        current: null,
        isLoading: false,
        error: null,
    },
    reducers: {
        setCurrentOrder: (state, action) => { state.current = action.payload },
        resetCurrentOrder: state => { state.current = null }
    },
    extraReducers: builder => {
        builder
            .addCase(getCurrentOrder.pending, state => { state.isLoading = true })
            .addCase(getCurrentOrder.fulfilled, (state, action) => {
                state.current = action.payload
                state.isLoading = false
            })
            .addCase(getCurrentOrder.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const { setCurrentOrder } = currentOrderSlice.actions

export default currentOrderSlice.reducer