import { api } from "./api"

export const operatorService = {
    getUserList: params => api.get("/operator/users/", { params }).then(res => res.data),
    searchUsers: search => api.get("/operator/users/", { params: { search } }),
    createUser: data => api.post("/auth/users/", data).then(res => res.data),
    updateUser: ({ id, ...data }) => api.patch(`/auth/users/${id}/`, data).then(res => res.data),

    getCompetitiveGroupList: params => api.get("/operator/applications/", { params }).then(res => res.data),
    getCompetitiveGroup: id => api.get(`/operator/applications/${id}`),
    getCompetitiveGroupAchievements: id => api.get(`/entrant_app/app_item/${id}/achievements/`),
    addCompetitiveGroupAchievement: ({ id, ...data }) => api.post(`/entrant_app/app_item/${id}/achievements/add/`, data).then(() => ({ data: data.achievement })),
    deleteCompetitiveGroupAchievement: ({ id, ...data }) => api.post(`/entrant_app/app_item/${id}/achievements/delete/`, data).then(() => ({ data: data.achievement })),

    postSatExamsFile: async file => {
        const formData = new FormData()
        formData.append("file", file, file.name)
        const config = { headers: { "Content-Type": "multipart/form-data" } }
        return api.post(`/ege/set_file/`, formData, config).then(res => res.data)
    },

    // Отчеты
    getDepartmentInfoReport: params => api.get("/operator/reports/applications", { params }),
    getApplicationStatusReport: params => api.get("/operator/reports/applications_status", { params }),

    getDynamicRatings: params => api.get("/rating/rating_infos/", { params }),
    getStaticRatings: params => api.get("/rating/static_rating/", { params }).then(res => res.data),
    getStaticRating: ({ id, ...params }) => api.get(`/rating/static_rating/${id}/`, { params }),
    postStaticRating: data => api.post("/rating/static_rating/", data).then(res => res.data),
    deleteStaticRating: ({ id }) => api.delete(`/rating/static_rating/${id}/`)
}

export const entranceTestsService = {
    getScorecards: params => api.get("/entrance_test/scorecard/", { params }).then(res => res.data),
    getScorecard: id => api.get(`/entrance_test/scorecard/${id}/`),
    postScorecard: data => api.post("/entrance_test/scorecard/", data),
    patchScorecard: data => api.patch(`/entrance_test/scorecard/${data.id}/`, data),
    deleteScorecard: data => api.delete(`/entrance_test/scorecard/${data.id}/`, data).then(() => ({ data })),
    closeScorecard: data => api.post(`/entrance_test/scorecard/${data.id}/close/`),
    copyScorecard: data => api.post(`/entrance_test/copy_scorecard`, data),

    getScorecardResults: params => api.get("/entrance_test/result/", { params }),
    postScorecardResult: data => api.post("/entrance_test/result/", data),
    patchScorecardResult: data => api.patch(`/entrance_test/result/${data.id}/`, data),
    deleteScorecardResult: data => api.delete(`/entrance_test/result/${data.id}/`, data).then(() => ({ data })),

    getRegistrations: params => api.get("/operator/test_registration/", { params }).then(res => res.data)
}

export const ordersService = {
    get: params => api.get("/orders/", { params }).then(res => res.data),
    getById: id => api.get(`/orders/${id}/`),
    post: data => api.post("/orders/", data).then(res => res.data),
    patch: data => api.patch(`/orders/${data.id}/`, data).then(res => res.data),
    delete: data => api.delete(`/orders/${data.id}/`).then(() => data),
    close: data => api.post(`/orders/${data.id}/close/`, data).then(res => res.data),
    open: data => api.post(`/orders/${data.id}/open/`, data).then(res => res.data),

    getItems: params => api.get("/orders/items/", { params }).then(res => res.data),
    createItem: data => api.post("/orders/items/", data).then(res => res.data),
    deleteItem: data => api.delete(`/orders/items/${data.id}/`,).then(() => data),
    getAvailableApps: params => api.get("/orders/available_apps/", { params }),
}