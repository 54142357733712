import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tab, Tabs, Typography } from "@mui/material"
import React from "react"
import Spinner from "../../components/Spinner"
import { applicationStatusLookups, eduFormLookups, eduTypeLookups } from "../../constants"

const TabPanel = ({ children, value, index, ...other }) => <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
>
    {value === index && <Box>{children}</Box>}
</div>


const CompetitiveGroupHistoryDialog = ({
    statusHistory, priorityHistory, originalHistory,
    currentCG, historyIsLoading, open, onClose, specLookups, noticeTypeLookups
}) => {

    const [currentTab, setCurrentTab] = React.useState(1)

    if (!open || !currentCG) return null

    return <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle>
            {currentCG.full_name}
            <Typography>
                Заявление {currentCG.app_number} на направление {specLookups[currentCG.spec]}
            </Typography>
            <Typography>
                Форма обучения: {eduFormLookups[currentCG.edu_form]?.toLowerCase()}, основа обучения: {eduTypeLookups[currentCG.edu_type]?.toLowerCase()}
            </Typography>
            <Divider sx={{ my: 1 }} />
            История изменений
        </DialogTitle>
        <DialogContent sx={{ minHeight: 500 }}>
            <Tabs value={currentTab} onChange={(_, value) => setCurrentTab(value)}>
                <Tab value={1} label="Статусы" />
                <Tab value={2} label="Приоритеты" />
                <Tab value={3} label="Оригинал" />
            </Tabs>
            {historyIsLoading ? <Spinner height={400} /> : <Box sx={{ mt: 1 }}>

                <TabPanel value={currentTab} index={1}>
                    {statusHistory.map(item => <React.Fragment key={item.id}>
                        <Typography>
                            {item.username} перевел(а) конкурсную группу в статус "{applicationStatusLookups[item.status]}"
                        </Typography>
                        {item.notice_type && <Typography variant="subtitle2">
                            Причина: {noticeTypeLookups[item.notice_type]?.name ?? ""}
                        </Typography>}
                        {item.comment && <Typography variant="subtitle2">
                            Комментарий: {item.comment}
                        </Typography>}
                        <Typography variant="subtitle2" color="grey">
                            {new Date(item.modified_at).toLocaleString()}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                    </React.Fragment>)}
                </TabPanel>

                <TabPanel value={currentTab} index={2}>
                    {priorityHistory.map(item => <React.Fragment key={item.id}>
                        <Typography>
                            {item.username} изменил(а) приоритет конкурсной группы на {item.priority}
                        </Typography>
                        <Typography variant="subtitle2" color="grey">
                            {new Date(item.modified_at).toLocaleString()}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                    </React.Fragment>)}
                </TabPanel>

                <TabPanel value={currentTab} index={3}>
                    {originalHistory.map(item => <React.Fragment key={item.id}>
                        <Typography>
                            {item.username} {item.type === "not_submitted" ? "отозвал(а) " : "подал(а) "}оригинал документа о предыдущем образовании
                        </Typography>
                        <Typography variant="subtitle2" color="grey">
                            {new Date(item.modified_at).toLocaleString()}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                    </React.Fragment>)}
                </TabPanel>
            </Box>}
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="error" onClick={onClose}>
                Закрыть
            </Button>
        </DialogActions>
    </Dialog>
}

export default CompetitiveGroupHistoryDialog