import { Box, Button, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import * as yup from "yup"
import VisibleOff from "../../components/Icons/VisibleOff"
import VisibleOn from "../../components/Icons/VisibleOn"
import Spinner from "../../components/Spinner"
import routes from "../../components/routes"
import toastWrapper from "../../components/toastWrapper"
import { getUserInfo, login } from "../../redux/userSlice"


const LoginPage = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const handleLogin = values => {
        dispatch(login(values)).unwrap()
            .then(() => {
                navigate(routes.profile.path)
                dispatch(getUserInfo())
            })
            .catch(e => {
                console.error(e)
                toastWrapper("Неверный логин или пароль", "error")
            })
    }

    const { handleSubmit, handleChange, values, errors, touched } = useFormik({
        initialValues: { email: "", password: "" },
        onSubmit: handleLogin,
        validationSchema: yup.object({
            email: yup.string().required(),
            password: yup.string().required()
        })
    })

    const userInfoIsLoading = useSelector(x => x.user.isLoading)

    const [showPassword, setShowPassword] = React.useState(false)

    if (userInfoIsLoading) return <Spinner height="90vh" />

    return <Box sx={{
        m: "auto",
        height: "100%",
        maxWidth: "480px",
    }}>

        <Box sx={{
            minHeight: 72,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1,
            mb: 1
        }}>
            <img
                src={`${process.env.PUBLIC_URL}/icons/logoWithText.svg`}
                alt="БГТУ"
                style={{ height: "40px" }}
            />
            <Typography sx={{ pl: 2 }} color="font.main" variant="font2">
                Личный кабинет абитуриента
            </Typography>
        </Box>

        <Paper sx={{ p: 4, pb: 4, mb: 1 }} component="form">

            <TextField
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                required
                placeholder="Эл. почта или телефон"
                fullWidth
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
                margin="normal"
            />
            <TextField
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                required
                placeholder="Пароль"
                fullWidth
                value={values.password}
                onChange={handleChange}
                error={touched.password && Boolean(errors.password)}
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
                margin="normal"
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="Показать пароль"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end"
                            size="small"
                        >
                            {showPassword ? <VisibleOff fontSize="small" /> : <VisibleOn fontSize="small" />}
                        </IconButton>
                    </InputAdornment>
                }}
            />

        </Paper>

        <Button
            sx={{ mt: 3, mb: 1, height: "48px" }}
            fullWidth
            variant="contained"
            type="submit"
            onClick={handleSubmit}
        >
            Войти
        </Button>

        <Typography align="center" variant="subtitle1">
            <Link to={routes.restorePassword.path}>Забыли пароль?</Link>
        </Typography>
        <Typography align="center" variant="subtitle1">
            <Link to={routes.register.path}>Регистрация</Link>
        </Typography>

    </Box>
}

export default LoginPage