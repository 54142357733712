import { createSlice } from "@reduxjs/toolkit";
import { dictService } from "../../services/dictService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getCgSpecs = createCustomAsyncThunk(
    "dicts/sgSpecs/get",
    async params => await dictService.getCGSpecs(params)
)

const cgSpecsSlice = createSlice({
    name: "dicts/cgSpecs",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getCgSpecs.pending, state => { state.isLoading = true })
            .addCase(getCgSpecs.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getCgSpecs.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const cgSpecSelectors = defaultEntityAdapter.getSelectors(x => x.dicts.cgSpecs)

export default cgSpecsSlice.reducer