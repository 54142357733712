import { useDispatch, useSelector } from "react-redux"
import React from "react"
import { departmentSelectors, getDepartments } from "../redux/dicts/departmentsSlice"


const useDepartments = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(getDepartments())
    }, [dispatch])

    const departments = useSelector(departmentSelectors.selectAll)
    const departmentOptions = useSelector(departmentSelectors.selectIds)
    const departmentLookups = useSelector(departmentSelectors.selectEntities)
    const departmentsIsLoading = useSelector(x => x.dicts.departments.isLoading)

    return { departments, departmentOptions, departmentLookups, departmentsIsLoading }
}

export default useDepartments