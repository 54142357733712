import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk } from "../utils";
import { entrantFormService } from "../../services/entrantService";

export const getTargetContractInfo = createCustomAsyncThunk(
    "entrantForm/targetContract/get",
    async userId => await entrantFormService.getTargetContract(userId)
)

export const createTargetContractInfo = createCustomAsyncThunk(
    "entrantForm/targetContract/create",
    async data => await entrantFormService.postTargetContract(data)
)

export const updateTargetContractInfo = createCustomAsyncThunk(
    "entrantForm/targetContract/update",
    async data => await entrantFormService.patchTargetContract(data)
)

const initialValues = {
    id: null,
    organization: {
        id: null,
        name: "",
        sname: "",
        ogrn: "",
        kpp: "",
        inn: "",
        address: "",
        phone: "",
        email: "",
        head_name: "",
    },
    number: "",
    date: "",
    can_edit_and_delete: true,
}

const targetContractSlice = createSlice({
    name: "entrantForm/targetContract",
    initialState: {
        data: initialValues,
        isLoading: true,
        error: null,
    },
    extraReducers: builder => {
        builder
            .addCase(getTargetContractInfo, state => { state.isLoading = true })
            .addCase(getTargetContractInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload.length > 0 ? action.payload[0] : initialValues
            })
            .addCase(getTargetContractInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(createTargetContractInfo.pending, state => { state.isLoading = true })
            .addCase(createTargetContractInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(createTargetContractInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(updateTargetContractInfo.pending, state => { state.isLoading = true })
            .addCase(updateTargetContractInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(updateTargetContractInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
    }
})

export default targetContractSlice.reducer