import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { entrantFormService } from "../../services/entrantService";

export const saveTempFiles = createAsyncThunk(
    "entrantForm/supDocs/tempFiles/post",
    async files => await Promise.all(
        // Идентификаторов у временных файлов нет, поэтому храним имя и молимся, что имена уникальные
        files.map(file => entrantFormService.postTempFile(file).then(res => ({ ...res, name: file.name })))
    )
)

export const getSupFiles = createAsyncThunk(
    "entrantForm/supDocs/files/get",
    async userId => await entrantFormService.getSupFiles(userId)
)

export const createSupFiles = createAsyncThunk(
    "entrantForm/supDocs/files/create",
    async data => await entrantFormService.postSupFiles(data)
)

export const deleteSupFile = createAsyncThunk(
    "entrantForm/supDocs/files/delete",
    async ({ fileIndex, id }) => await entrantFormService.deleteSupFile(id).then(() => ({ fileIndex, id }))
)

const supDocsSlice = createSlice({
    name: "entrantForm/supDocs",
    initialState: {
        files: [],
        filesIsLoading: false,

        tempFiles: [],
        tempFilesIsLoading: false,

        acceptedFiles: [],
        error: null,

        currentStep: 0,
    },
    reducers: {
        addAcceptedFiles: (state, action) => { state.acceptedFiles = [...state.acceptedFiles, ...action.payload] },
        setAcceptedFiles: (state, action) => { state.acceptedFiles = action.payload },
        deleteAcceptedFile: (state, action) => {
            // payload - индекс 
            // Этот кошмар нужен, чтобы после удаления не оставались tempFiles, которые пользователь удалил
            let fileToDelete = state.acceptedFiles[action.payload]
            state.tempFiles = state.tempFiles.filter(tmp => tmp.name !== fileToDelete.name)
            state.acceptedFiles = state.acceptedFiles.filter((_, acc) => acc !== action.payload)
        },
        resetAcceptedFiles: state => { state.acceptedFiles = [] },
        resetTempFiles: state => { state.tempFiles = [] },
        setCurrentFileFormStep: (state, action) => { state.currentStep = action.payload },
        resetCurrentFileFormStep: state => { state.currentStep = 0 },
    },
    extraReducers: builder => {
        builder
            .addCase(saveTempFiles.pending, state => { state.tempFilesIsLoading = true })
            .addCase(saveTempFiles.fulfilled, (state, action) => {
                state.tempFiles = [...state.tempFiles, ...action.payload]
                state.tempFilesIsLoading = false
            })
            .addCase(saveTempFiles.rejected, (state, action) => {
                state.error = action.error
                state.tempFilesIsLoading = false
            })

            .addCase(getSupFiles.pending, state => { state.filesIsLoading = true })
            .addCase(getSupFiles.fulfilled, (state, action) => {
                state.files = action.payload
                state.filesIsLoading = false
            })
            .addCase(getSupFiles.rejected, (state, action) => {
                state.error = action.error
                state.filesIsLoading = false
            })

            .addCase(createSupFiles.pending, state => { state.filesIsLoading = true })
            .addCase(createSupFiles.fulfilled, (state, action) => {
                // TODO понять почему именно так
                state.files = state.files.map(el => el.content_type === action.payload.content_type && el.id === action.payload.id ? action.payload : el)
                state.filesIsLoading = false
            })
            .addCase(createSupFiles.rejected, (state, action) => {
                state.error = action.error
                state.filesIsLoading = false
            })

            .addCase(deleteSupFile.pending, state => { state.filesIsLoading = true })
            .addCase(deleteSupFile.fulfilled, (state, action) => {
                const newSavedFiles = state.files[action.payload.fileIndex].files.filter(el => el.id !== action.payload.id)
                state.files = state.files.map(
                    (f, index) => index === action.payload.fileIndex ? ({ ...state.files[action.payload.fileIndex], files: newSavedFiles }) : f
                )
                state.filesIsLoading = false
            })
            .addCase(deleteSupFile.rejected, (state, action) => {
                state.error = action.error
                state.filesIsLoading = false
            })
    }
})

export const {
    addAcceptedFiles,
    setAcceptedFiles,
    resetAcceptedFiles,
    resetTempFiles,
    setCurrentFileFormStep,
    resetCurrentFileFormStep,
    deleteAcceptedFile
} = supDocsSlice.actions

export default supDocsSlice.reducer