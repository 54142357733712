import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { dialogModes, orderCategories, orderCategoryLookups } from "../../constants"
import { useFormik } from "formik"
import * as yup from "yup"

const getTitle = mode => {
    switch (mode) {
        case dialogModes.CREATE: return "Создание приказа"
        case dialogModes.UPDATE: return "Редактирование приказа"
        default: return "Просмотр приказа"
    }
}

const OrderForm = ({ open, onClose, onSave, data, mode,
    universityOptions, universityLookups,
    levelOptions, levelLookups
}) => {

    const { values, errors, touched, handleChange, setFieldValue, handleSubmit } = useFormik({
        initialValues: data,
        enableReinitialize: true,
        validationSchema: yup.object({
            university: yup.number().required(),
            level: yup.number().required(),
            number: yup.string().required(),
            date: yup.date().required(),
            category: yup.number().required(),
            comment: yup.string()
        }),
        onSubmit: onSave,
    })

    if (!values || !data || !open) return null

    return <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" >
        <DialogTitle>
            {getTitle(mode)}
        </DialogTitle>
        <DialogContent>
            <Autocomplete
                options={universityOptions}
                value={values.university}
                onChange={(_, value) => setFieldValue("university", value)}
                getOptionLabel={option => universityLookups[option] ?? ""}
                renderInput={props => <TextField
                    label="Университет"
                    error={touched.university && Boolean(errors.university)}
                    {...props}
                />}
            />
            <Autocomplete
                options={levelOptions}
                value={values.level}
                onChange={(_, value) => setFieldValue("level", value)}
                getOptionLabel={option => levelLookups[option] ?? ""}
                renderInput={props => <TextField
                    label="Уровень образования"
                    error={touched.level && Boolean(errors.level)}
                    {...props}
                />}
            />
            <TextField
                id="number"
                name="number"
                value={values.number}
                onChange={handleChange}
                label="Номер"
                error={touched.number && Boolean(errors.number)}
                fullWidth
            />
            <TextField
                id="date"
                name="date"
                value={values.date}
                onChange={handleChange}
                type="date"
                label="Дата"
                error={touched.date && Boolean(errors.date)}
                fullWidth
            />
            <Autocomplete
                options={orderCategories.map(el => el.id)}
                value={values.category}
                onChange={(_, value) => setFieldValue("category", value)}
                getOptionLabel={option => orderCategoryLookups[option] ?? ""}
                renderInput={props => <TextField
                    label="Категория"
                    error={touched.category && Boolean(errors.category)}
                    {...props}
                />}
            />
            <TextField
                id="comment"
                name="comment"
                value={values.comment}
                onChange={handleChange}
                label="Комментарий"
                error={touched.comment && Boolean(errors.comment)}
                multiline
                minRows={2}
                maxRows={4}
                fullWidth
            />
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={handleSubmit}>
                Сохранить
            </Button>
            <Button variant="contained" color="error" onClick={onClose}>
                Закрыть
            </Button>
        </DialogActions>
    </Dialog>
}

export default OrderForm