import { createSlice } from "@reduxjs/toolkit";
import { dictService } from "../../services/dictService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getSpecialConditions = createCustomAsyncThunk(
    "dicts/specialConditions/get",
    async () => await dictService.getSpecialConditions()
)

const specialConditionsSlice = createSlice({
    name: "dicts/specialConditions",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getSpecialConditions.pending, state => { state.isLoading = true })
            .addCase(getSpecialConditions.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getSpecialConditions.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const specialConditionSelectors = defaultEntityAdapter.getSelectors(x => x.dicts.specialConditions)

export default specialConditionsSlice.reducer