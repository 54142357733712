import { Box, Button, Divider, Typography } from "@mui/material"
import React from "react"
import EntrantApplicationPrioritiesList from "./EntrantApplicationPrioritiesList"
import Spinner from "../../../../components/Spinner"


const EntrantApplicationFormStep3 = ({
    onPrevStep, onClose, onSave,
    onSwapCommonPriorities, onSwapTargetPriorities,
    commonPriorities, targetPriorities, isLoading
}) => {

    return <>

        <Box sx={{ height: "calc(100% - 60px)", overflow: "auto", pr: 2, pl: 2 }}>

            {isLoading ? <Spinner height="100%" /> : (
                <>
                    <Typography variant="font3" align="center" sx={{ m: 2 }}>
                        Отсортируйте выбранные конкурсные группы в порядке приоритетности для поступления (чем выше - тем выше приоритет)
                    </Typography>
                    {commonPriorities?.length > 0 && <Box>

                        <Typography variant="font2">
                            Нецелевые приоритеты
                        </Typography>

                        <Divider sx={{ my: 1 }} />
                        <EntrantApplicationPrioritiesList
                            droppableId="nontarget"
                            priorities={commonPriorities}
                            onSwap={onSwapCommonPriorities}
                        />
                    </Box>}

                    {targetPriorities?.length > 0 && <Box>
                        <Typography variant="font2">
                            Целевые приоритеты
                        </Typography>

                        <Divider sx={{ my: 1 }} />

                        <EntrantApplicationPrioritiesList
                            droppableId="target"
                            priorities={targetPriorities}
                            onSwap={onSwapTargetPriorities}
                        />
                    </Box>}
                </>
            )}

        </Box>

        <Box sx={{ mt: 1, display: "flex", justifyContent: "flex-end", p: 1 }}>
            <Button
                variant="contained"
                sx={{ mr: 2 }}
                onClick={onSave}
            >
                Далее
            </Button>
            <Button variant="contained" color="error" onClick={onClose}>
                Закрыть
            </Button>
        </Box>
    </>
}

export default EntrantApplicationFormStep3