import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { entrantOriginalHistorySelectors, entrantPriorityHistorySelectors, entrantStatusHistorySelectors, getCGOriginalHistory, getCGPriorityHistory, getCGStatusHistory } from "../../../redux/entrantNotificationsSlice"
import { getNoticeTypes, noticeTypeSelectors } from "../../../redux/dicts/noticeTypesSlice"


const useCompetitiveGroupHistory = (currentCG) => {

    const dispatch = useDispatch()

    React.useEffect(() => { dispatch(getNoticeTypes()) }, [dispatch])

    const noticeTypeOptions = useSelector(noticeTypeSelectors.selectIds)
    const noticeTypeLookups = useSelector(noticeTypeSelectors.selectEntities)
    const noticeTypesIsLoading = useSelector(x => x.dicts.noticeTypes.isLoading)

    React.useEffect(() => {
        if (currentCG?.id && currentCG.user) {
            dispatch(getCGStatusHistory(currentCG.id))
            dispatch(getCGOriginalHistory(currentCG.user))
            dispatch(getCGPriorityHistory(currentCG.id))
        }
    }, [currentCG, dispatch])

    const statusHistory = useSelector(entrantStatusHistorySelectors.selectAll)
    const priorityHistory = useSelector(entrantPriorityHistorySelectors.selectAll)
    const originalHistory = useSelector(entrantOriginalHistorySelectors.selectAll)
    const statusHistoryIsLoading = useSelector(x => x.entrantNotifications.status.isLoading)
    const priorityHistoryIsLoading = useSelector(x => x.entrantNotifications.priority.isLoading)
    const originalHistoryIsLoading = useSelector(x => x.entrantNotifications.original.isLoading)

    const historyIsLoading = statusHistoryIsLoading || priorityHistoryIsLoading || originalHistoryIsLoading

    return {
        statusHistory, priorityHistory, originalHistory, historyIsLoading,
        noticeTypeOptions, noticeTypeLookups, noticeTypesIsLoading
    }
}

export default useCompetitiveGroupHistory