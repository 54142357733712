import { Autocomplete, Box, Paper, Step, StepButton, StepContent, Stepper, TextField, Typography } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import useLevels from "../../hooks/useLevels"
import { setCurrentFormStep } from "../../redux/uiSlice"
import AchievementsInfo from "./components/AchievementsInfo"
import DirectionEduMinistryInfo from "./components/DirectionEduMinistryInfo"
import OptionsInfo from "./components/OptionsInfo"
import PassportInfo from "./components/PassportInfo"
import PayerInfo from "./components/PayerInfo"
import PersonalInfo from "./components/PersonalInfo"
import PrevEduInfo from "./components/PrevEduInfo"
import SatExamsInfo from "./components/SatExamsInfo"
import SupDocs from "./components/SupDocs"
import TargetContractInfo from "./components/TargetContractInfo"
import useProfileInfo from "./hooks/useProfileInfo"
import MilitaryDocInfo from "./components/MilitaryDocInfo"
import ExemptionsInfo from "./components/ExemptionsInfo"

const FormPage = ({
    isInner = false // Отображается как страница (false) или как модалка
}) => {

    const dispatch = useDispatch()

    React.useEffect(() => () => dispatch(setCurrentFormStep(0)), [dispatch])

    const { universityLevelOptions, levelLookups, levelsIsLoading } = useLevels()

    const userInfo = useSelector(x => x.user.displayedUserInfo)

    const { profileInfo, profileInfoIsLoading, handleChangeProfileInfoLevels } = useProfileInfo(userInfo?.id)

    const handleChangeStep = step => { dispatch(setCurrentFormStep(step)) }
    const currentFormStep = useSelector(x => x.ui.currentFormStep)

    const isBachelorOrSpec = React.useMemo(() => Boolean(profileInfo?.levels?.find(el => el === 4 || el === 5)), [profileInfo])

    const defaultStepProps = { userInfo, profileInfo }

    const steps = React.useMemo(() => [
        { label: "Персональная информация *", component: PersonalInfo, dependsOnProfile: false },
        { label: "Документ, удостоверяющий личность *", component: PassportInfo, dependsOnProfile: false, },
        { label: "Сведения о предыдущем образовании *", component: PrevEduInfo, dependsOnProfile: false },
        { label: "Дополнительная информация *", component: OptionsInfo, dependsOnProfile: false },
        { label: "Результаты ЕГЭ / олимпиады", component: SatExamsInfo, dependsOnProfile: false, disabled: !isBachelorOrSpec },
        { label: "Индивидуальные достижения", component: AchievementsInfo, dependsOnProfile: false },
        { label: "Особые или преимущественные права", component: ExemptionsInfo, dependsOnProfile: false },
        { label: "Платежная информация", component: PayerInfo, dependsOnProfile: false },
        { label: "Договор о целевом обучении", component: TargetContractInfo, dependsOnProfile: false },
        { label: "Воинский учет", component: MilitaryDocInfo, dependsOnProfile: false },
        { label: "Направление Минобрнауки", component: DirectionEduMinistryInfo, dependsOnProfile: false },
        { label: "Подтверждающие документы *", component: SupDocs, dependsOnProfile: true },

    ], [isBachelorOrSpec])

    return <Box sx={{ display: "flex", flexDirection: "column", minWidth: 250, maxWidth: 1000, m: "auto" }}>

        <Box
            sx={{
                m: 1,
                p: 2,
                borderRadius: 1,
                display: "flex",
                backgroundColor: "primary.grey",
            }}
        >
            <Typography variant="font2" color="white">
                {isInner ? `${userInfo?.surname ?? ""} ${userInfo?.name ?? ""} ${userInfo?.patronymic ?? ""} - Анкета` : "Анкета"}
            </Typography>
        </Box>

        <Paper elevation={isInner ? 0 : undefined} sx={{ m: 1, p: 2, justifySelf: "center", boxShadow: isInner ? "none" : undefined }}>

            <Typography variant="font4">Образование</Typography>
            <Typography variant="font3" sx={{ mt: 0.5 }}>
                Укажите уровни образования, на который вы будете подавать заявление.
            </Typography>

            <Autocomplete
                value={profileInfo?.levels ?? []}
                options={universityLevelOptions}
                getOptionLabel={option => levelLookups[option]?.name ?? ""}
                multiple
                fullWidth
                loading={levelsIsLoading || profileInfoIsLoading}
                disabled={levelsIsLoading || profileInfoIsLoading}
                disableClearable
                onChange={(_, value) => handleChangeProfileInfoLevels(value)}
                renderInput={props => <TextField
                    placeholder="Уровни образования"
                    error={profileInfo?.levels.length < 1}
                    required
                    sx={{ mb: 2, mt: 2 }}
                    {...props}
                />}
            />

            <Stepper orientation="vertical" nonLinear activeStep={currentFormStep}>
                {steps.map((step, index) => <Step key={index}>
                    <StepButton
                        disabled={(step.dependsOnProfile && !profileInfo?.is_full_profile) || step?.disabled}
                        onClick={() => { handleChangeStep(index) }}
                    >
                        <Typography color={(step.dependsOnProfile && !profileInfo?.is_full_profile) || step?.disabled ? "disabled" : "black"}>
                            {step.label}
                        </Typography>
                    </StepButton>
                    <StepContent>
                        {<step.component {...defaultStepProps} />}
                    </StepContent>
                </Step>
                )}
            </Stepper>
        </Paper>
    </Box>
}

export default FormPage