import { createSvgIcon } from "@mui/material";


const VisibleOff = createSvgIcon(
    <svg viewBox="-2 -2 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_6_176" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
            <path d="M18 0H0V18H18V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_6_176)">
            <path d="M8.59526 11.5683C7.4851 11.394 6.60598 10.5148 6.43171 9.40474L5.10143 8.07446C5.03105 8.37403 4.99463 8.684 4.99463 9.00002C4.99463 11.2085 6.79139 13.0053 8.99998 13.0053C9.31533 13.0053 9.6253 12.9688 9.92512 12.8981L8.59526 11.5683Z" fill="#6C7180" />
            <path d="M17.4391 9.81092C17.535 9.68576 17.713 9.42356 17.733 9.39403L18 8.99986L17.7329 8.60572C17.7129 8.57623 17.535 8.3141 17.4391 8.18898C16.9912 7.60475 15.9424 6.23658 14.4964 5.03975C12.6856 3.54104 10.8363 2.7811 8.99999 2.7811C7.66451 2.7811 6.31482 3.18663 4.98075 3.98693L1.01185 0.0180664L0.0181274 1.01179L16.9881 17.9818L17.9819 16.988L14.1951 13.2013C15.8031 11.9443 16.967 10.4267 17.4391 9.81092ZM11.2671 10.2733L10.3288 9.33501C10.3558 9.22778 10.3702 9.11556 10.3702 8.99993C10.3702 8.24316 9.75672 7.62968 8.99995 7.62968C8.88436 7.62968 8.77214 7.64416 8.66491 7.67109L7.72666 6.73284C8.11176 6.516 8.54791 6.39995 8.99995 6.39995C10.4336 6.39995 11.5999 7.56629 11.5999 8.9999C11.5999 9.45288 11.4838 9.88851 11.2671 10.2733ZM12.2857 11.2918C12.7532 10.6243 13.0053 9.83067 13.0053 8.99993C13.0053 6.79138 11.2086 4.99458 8.99999 4.99458C8.17104 4.99458 7.37644 5.24714 6.70833 5.71451L6.01019 5.01637C7.01967 4.46533 8.02236 4.18648 8.99995 4.18648C11.3777 4.18648 13.8303 5.80602 16.2898 8.99997C15.7783 9.66685 14.6883 11.07 13.1934 12.1996L12.2857 11.2918Z" fill="#6C7180" />
            <path d="M10.5806 13.577C10.0485 13.7341 9.5207 13.8135 9 13.8135C6.62224 13.8135 4.16967 12.1939 1.71011 9.00002C2.06311 8.5399 2.77186 7.62682 3.726 6.72242L2.73185 5.72827C1.68093 6.72871 0.921234 7.71914 0.560883 8.18914C0.465012 8.31426 0.287086 8.57635 0.267082 8.60589L0 8.99995L0.267012 9.39412C0.28698 9.42359 0.464941 9.68582 0.560883 9.81101C1.00877 10.3953 2.05766 11.7634 3.50364 12.9602C5.31439 14.459 7.16365 15.2189 9 15.2189C9.89132 15.2189 10.7889 15.0383 11.6839 14.6803L10.5806 13.577Z" fill="#6C7180" />
        </g>
    </svg>,
    "VisibleOff",
)

export default VisibleOff