export const getMessageFromObject = msg => {
    if (typeof msg === "string") return msg
    if (Array.isArray(msg)) return msg.map(el => getMessageFromObject(el)).join("; ")
    if (typeof msg === "object") return Object.keys(msg).map(key => getMessageFromObject(msg[key])).join("; ")
    return "Произошла ошибка"
}

export const DefaultError = class DefaultError {
    constructor(code, message = "Произошла ошибка") {
        this.message = message
        this.code = code
    }

    getUserMessage() { return this.message }
}

export const BadRequestError = class BadRequestError {
    constructor(data, message = "Ошибка запроса") {
        this.message = message
        this.code = 400
        this.data = data && typeof data === 'object' ? data : null;
    }

    getUserMessage() { return this.data ? getMessageFromObject(this.data) : this.message }
}

export const UnauthorizedError = class UnauthorizedError{
    constructor(message = "Недостаточно прав для доступа к ресурсу") {
        this.message = message
        this.code = 401
    }

    getUserMessage() { return this.message }
}

export const NotFoundError = class NotFoundError {
    constructor(message = "Запрашиваемый ресурс не найден") {
        this.message = message
        this.code = 404
    }

    getUserMessage() { return this.message }
}

export const ServerError = class ServerError {
    constructor(message = "Ошибка сервера") {
        this.message = message
        this.code = 500
    }

    getUserMessage() { return this.message }
}