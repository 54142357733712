import { createSlice } from "@reduxjs/toolkit";
import { dictService } from "../../services/dictService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getOksoSpecs = createCustomAsyncThunk(
    "dicts/oksoSpecs/get",
    async params => await dictService.getOksoSpecs(params)
)

const oksoSpecsSlice = createSlice({
    name: "dicts/oksoSpecs",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getOksoSpecs.pending, state => { state.isLoading = true })
            .addCase(getOksoSpecs.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getOksoSpecs.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const oksoSpecSelectors = defaultEntityAdapter.getSelectors(x => x.dicts.oksoSpecs)

export default oksoSpecsSlice.reducer