import { createSlice } from "@reduxjs/toolkit";
import { entrantApplicationService } from "../../services/entrantService";
import { createCustomAsyncThunk } from "../utils";

export const getEntranceTestRegistration = createCustomAsyncThunk(
    "entrantApplications/entranceTestRegistration/get",
    async app => await entrantApplicationService.getEntranceTestRegistration(app)
)

export const createEntranceTestRegistration = createCustomAsyncThunk(
    "entrantApplications/entranceTestRegistration/create",
    async data => await entrantApplicationService.postEntranceTestRegistration(data)
)

export const deleteEntranceTestRegistration = createCustomAsyncThunk(
    "entrantApplications/entranceTestRegistration/delete",
    async data => await entrantApplicationService.deleteEntranceTestRegistration(data)
)


const entranceTestRegistrationSlice = createSlice({
    name: "entrantApplications/entranceTestRegistration",
    initialState: {
        data: [],
        isLoading: false,
        error: null,
    },
    reducers: {
        addNewEntranceTestRegistrationItem: (state, action) => {
            state.data = [...state.data, action.payload]
        },
        deleteNewEntranceTestRegistrationItem: state => {
            state.data = state.data.filter(el => el.id !== null)
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getEntranceTestRegistration.pending, state => { state.isLoading = true })
            .addCase(getEntranceTestRegistration.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(getEntranceTestRegistration.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })

            .addCase(createEntranceTestRegistration.pending, state => { state.isLoading = true })
            .addCase(createEntranceTestRegistration.fulfilled, (state, action) => {
                state.data = [...state.data.filter(el => el.id !== null), action.payload]
                state.isLoading = false
            })
            .addCase(createEntranceTestRegistration.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })

            .addCase(deleteEntranceTestRegistration.pending, state => { state.isLoading = true })
            .addCase(deleteEntranceTestRegistration.fulfilled, (state, action) => {
                state.data = state.data.filter(el => el.id !== action.payload.id)
                state.isLoading = false
            })
            .addCase(deleteEntranceTestRegistration.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const {
    addNewEntranceTestRegistrationItem,
    deleteNewEntranceTestRegistrationItem
} = entranceTestRegistrationSlice.actions

export default entranceTestRegistrationSlice.reducer