import { createSvgIcon } from "@mui/material";

const Applications = createSvgIcon(
    <svg viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.8619 3.50383H19.3314V1.54451C19.3314 0.980638 18.87 0.519287 18.3061 0.519287H9.69424C9.13037 0.519287 8.66902 0.980638 8.66902 1.54451V6.96111C8.66902 7.52499 9.13037 7.98634 9.69424 7.98634H16.0563C16.6202 7.98634 17.0816 7.52499 17.0816 6.96111C17.0816 6.39724 16.6202 5.93589 16.0563 5.93589H10.7195V2.56974H17.2752V4.53474C17.2752 5.09862 17.7365 5.55997 18.3005 5.55997H23.831V26.4745H4.16373V5.55427H5.74143C6.3053 5.55427 6.76665 5.09292 6.76665 4.52905C6.76665 3.96518 6.3053 3.50383 5.74143 3.50383H3.1385C2.57463 3.50383 2.11328 3.96518 2.11328 4.52905V27.4941C2.11328 28.0579 2.57463 28.5193 3.1385 28.5193H24.8619C25.4257 28.5193 25.8871 28.0579 25.8871 27.4941V4.52905C25.8871 3.96518 25.4257 3.50383 24.8619 3.50383Z" />
        <path d="M19.7696 14.2004H11.7102C11.1463 14.2004 10.6849 14.6618 10.6849 15.2256C10.6849 15.7896 11.1463 16.2509 11.7102 16.2509H19.7696C20.3334 16.2509 20.7948 15.7896 20.7948 15.2256C20.7948 14.6618 20.3334 14.2004 19.7696 14.2004Z" />
        <path d="M19.7696 10.322H11.7102C11.1463 10.322 10.6849 10.7834 10.6849 11.3472C10.6849 11.9111 11.1463 12.3725 11.7102 12.3725H19.7696C20.3334 12.3725 20.7948 11.9111 20.7948 11.3472C20.7948 10.7834 20.3334 10.322 19.7696 10.322Z" />
        <path d="M19.7696 18.085H11.7102C11.1463 18.085 10.6849 18.5463 10.6849 19.1103C10.6849 19.6741 11.1463 20.1355 11.7102 20.1355H19.7696C20.3334 20.1355 20.7948 19.6741 20.7948 19.1103C20.7948 18.5463 20.3334 18.085 19.7696 18.085Z" />
        <path d="M8.3386 12.5202C8.98659 12.5202 9.5119 11.9949 9.5119 11.3469C9.5119 10.6989 8.98659 10.1736 8.3386 10.1736C7.69059 10.1736 7.16528 10.6989 7.16528 11.3469C7.16528 11.9949 7.69059 12.5202 8.3386 12.5202Z" />
        <path d="M8.3386 16.3987C8.98659 16.3987 9.5119 15.8733 9.5119 15.2253C9.5119 14.5774 8.98659 14.052 8.3386 14.052C7.69059 14.052 7.16528 14.5774 7.16528 15.2253C7.16528 15.8733 7.69059 16.3987 8.3386 16.3987Z" />
        <path d="M8.3386 20.2832C8.98659 20.2832 9.5119 19.7579 9.5119 19.1099C9.5119 18.4619 8.98659 17.9365 8.3386 17.9365C7.69059 17.9365 7.16528 18.4619 7.16528 19.1099C7.16528 19.7579 7.69059 20.2832 8.3386 20.2832Z" />
        <path d="M19.7696 21.9634H11.7102C11.1463 21.9634 10.6849 22.4247 10.6849 22.9886C10.6849 23.5525 11.1463 24.0139 11.7102 24.0139H19.7696C20.3334 24.0139 20.7948 23.5525 20.7948 22.9886C20.7948 22.4247 20.3334 21.9634 19.7696 21.9634Z" />
        <path d="M8.3386 24.1616C8.98659 24.1616 9.5119 23.6362 9.5119 22.9883C9.5119 22.3402 8.98659 21.8149 8.3386 21.8149C7.69059 21.8149 7.16528 22.3402 7.16528 22.9883C7.16528 23.6362 7.69059 24.1616 8.3386 24.1616Z" />
    </svg>,
    "Checked"
)

export default Applications



