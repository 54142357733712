import { createSlice } from "@reduxjs/toolkit";
import { dictService } from "../../services/dictService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getRegions = createCustomAsyncThunk(
    "dicts/regions/get",
    async () => await dictService.getRegions()
)

const regionsSlice = createSlice({
    name: "dicts/regions",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getRegions.pending, state => { state.isLoading = true })
            .addCase(getRegions.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getRegions.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const regionSelectors = defaultEntityAdapter.getSelectors(x => x.dicts.regions)

export default regionsSlice.reducer