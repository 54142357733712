import { Divider, Link, Menu, MenuItem } from "@mui/material"
import { actionNamesMap } from "../../redux/entrantApplications/appsSlice"
import React from "react"
import { apiUrl } from "../../services/api"
import FileDownloadButton from "../../components/FileDownloadButton"


const CompetitiveGroupListActionMenu = ({
    currentCG,
    disabled, actionHandlersMap,
    anchorEl, open, onClose,
    onEditDepCode,
}) => {

    const userDefaultActions = ["accept_docs", "create_app", "edit_profile", "check_epgu_docs", "check_achievements"]
    const contractDefaultActions = ["get_contract", "request_contract"]
    const enrolledDefaultActions = ["extract_from_order", "pers_file_desc", "fis_ege_doc"]

    // Сортировать надо чтобы действия всегда были в одном порядке
    const userActions = currentCG?.available_actions?.filter(el => userDefaultActions.includes(el))?.toSorted()
    const appItemActions = currentCG?.available_actions?.filter(el => !userDefaultActions.includes(el) && !contractDefaultActions.includes(el) && !enrolledDefaultActions.includes(el) && el !== "can_edit_dep_code")?.toSorted()
    const enrolledActions = currentCG?.available_actions.filter(el => enrolledDefaultActions.includes(el))

    const hasContractActions = Boolean(currentCG?.available_actions?.find(el => el === "get_contract" || el === "request_contract") || currentCG?.contract?.guid !== null)
    const canEditDepCode = currentCG?.available_actions?.find(x => x === "can_edit_dep_code")

    if (!currentCG) return null

    return <Menu
        id="user-actions"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
    >
        {userActions?.map(action => <MenuItem
            key={action}
            onClick={actionHandlersMap[action]}
            disabled={disabled}
        >
            {actionNamesMap[action] ?? ""}
        </MenuItem>)}

        {appItemActions?.length !== 0 && <Divider />}

        {appItemActions?.map(action => <MenuItem
            key={action}
            onClick={actionHandlersMap[action]}
            disabled={disabled}
        >
            {actionNamesMap[action] ?? ""}
        </MenuItem>)}

        {(hasContractActions || canEditDepCode) && <Divider />}

        {canEditDepCode && <MenuItem onClick={onEditDepCode} disabled={disabled}>
            Изменить код подразделения РИМЦ
        </MenuItem>}

        {currentCG?.available_actions?.includes("request_contract") && <MenuItem
            onClick={actionHandlersMap["request_contract"]}
            disabled={disabled}
        >
            {actionNamesMap.request_contract}
        </MenuItem>}

        {currentCG?.contract?.file && <MenuItem
            href={currentCG.contract?.file}
            target="_blank"
            component={Link}
            disabled={disabled}
        >
            Скачать договор (.pdf)
        </MenuItem>}

        {currentCG?.contract?.file_xlsx && <MenuItem
            href={currentCG.contract?.file_xlsx}
            target="_blank"
            component={Link}
            disabled={disabled}
        >
            Скачать договор (.xlsx)
        </MenuItem>}

        {currentCG?.contract?.filemxlcontract && <MenuItem
            href={currentCG.contract?.filemxlcontract}
            target="_blank"
            component={Link}
            disabled={disabled}
        >
            Скачать договор (.mxl)
        </MenuItem>}

        {currentCG?.contract?.filemxlreceipt && <MenuItem
            href={currentCG.contract?.filemxlreceipt}
            target="_blank"
            component={Link}
            disabled={disabled}
        >
            Скачать квитанцию (.mxl)
        </MenuItem>}

        {enrolledActions.length > 0 && <Divider />}

        {enrolledActions.includes("extract_from_order") && <FileDownloadButton
            url={`${apiUrl}/entrant_app/app/${currentCG?.app}/extract_from_order_pdf/`}
            component="menuItem"
            name="Скачать выписку из приказа"
            disabled={disabled}
        />}

        {enrolledActions.includes("pers_file_desc") && <FileDownloadButton
            url={`${apiUrl}/entrant_app/app/${currentCG?.app}/descript_pers_file/`}
            component="menuItem"
            name="Скачать опись личного дела"
            disabled={disabled}
        />}
        {enrolledActions.includes("fis_ege_doc") && <FileDownloadButton
            url={`${apiUrl}/entrant_app/app/${currentCG?.app}/ref_fis_ege_pdf/`}
            component="menuItem"
            name="Скачать справку ФИС по ЕГЭ"
            disabled={disabled}
        />}
    </Menu>
}

export default CompetitiveGroupListActionMenu