import { useParams } from "react-router"
import useDisplayedUserInfo from "../../hooks/useDisplayedUserInfo"
import EntrantApplications from "./"
import Spinner from "../../components/Spinner"

const EntrantApplicationsOperatorWrapper = () => {

    const { id } = useParams()

    const hasLoaded = useDisplayedUserInfo(id)

    if (!hasLoaded) return <Spinner height="100%" />

    return <EntrantApplications isInner />
}

export default EntrantApplicationsOperatorWrapper