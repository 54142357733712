import { createSlice } from "@reduxjs/toolkit";
import { dictService } from "../../services/dictService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getCitizenships = createCustomAsyncThunk(
    "dicts/citizenships/get",
    async () => await dictService.getCitizenships()
)

const citizenshipsSlice = createSlice({
    name: "dicts/citizenships",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: true,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getCitizenships.pending, state => { state.isLoading = true })
            .addCase(getCitizenships.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getCitizenships.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const citizenshipSelectors = defaultEntityAdapter.getSelectors(x => x.dicts.citizenships)

export default citizenshipsSlice.reducer