import { Box, IconButton, TextField, Typography } from "@mui/material"
import UserSearch from "../../../components/UserSearch"
import { KeyboardBackspace } from "@mui/icons-material"
import { MTableToolbar } from "@material-table/core"
import FileDownloadButton from "../../../components/FileDownloadButton"
import { apiUrl } from "../../../services/api"


const useScorecardResultsTableProps = (isLoading, subjectLookups, current, onCreate, onUpdate, onDelete, onNavigate, canEdit) => {

    const title = <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton sx={{ mr: 1 }} size="small" onClick={onNavigate}>
            <KeyboardBackspace color="secondary" />
        </IconButton>
        <Typography variant="font1" noWrap>
            {isLoading || !current ? "Загрузка..." : `${subjectLookups[current?.subject]?.name ?? ""},
            ${new Date(current?.test_date)?.toLocaleString("ru-RU")?.slice(0, 10) ?? ""}`}
        </Typography>
    </Box>

    const columns = [
        {
            field: "user_fio",
            title: "ФИО абитуриента",
            editComponent: props => <UserSearch
                initialSearch={props.value}
                initialValue={props.rowData.user}
                onChange={value => {
                    props.onChange(value)
                    props.onRowDataChange({ ...props.rowData, ...value })
                }}
            />
        },
        {
            field: "mark",
            title: "Оценка",
            editComponent: props => <TextField
                placeholder="Оценка"
                onChange={e => props.onChange(e.target.value)}
                value={props.value}
                type="number"
                min={0}
                max={100}
                helperText=" "
            />
        },
    ]

    const editable = {
        onRowAdd: canEdit ? onCreate : undefined,
        onRowUpdate: canEdit ? onUpdate : undefined,
        onRowDelete: canEdit ? onDelete : undefined,
    }

    const options = {
        pageSize: 25,
        pageSizeOptions: [10, 25, 50, 100],
        padding: "dense",
        maxBodyHeight: "75vh",
        minBodyHeight: "75vh",
        headerStyle: { position: "sticky", top: 0, zIndex: 1 },
        maxColumnSort: 0,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
    }

    const components = {
        Toolbar: props => <>
            <MTableToolbar {...props} />
            <Box sx={{ m: 1, ml: 2 }}>
                {current?.id && <FileDownloadButton
                    component="link"
                    url={`${apiUrl}/entrance_test/scorecard/${current?.id}/download/`}
                    name="Скачать ведомость"
                />}
            </Box>
            <Box sx={{ m: 1, ml: 2 }}>
                 {current?.id && <FileDownloadButton
                    component="link"
                    url={`${apiUrl}/entrance_test/scorecard/${current?.id}/download_snils/`}
                    name="Скачать ведомость без ФИО"
                />}
            </Box>
        </>
    }

    const style = {
        minWidth: 800,
        maxWidth: 800,
    }

    return {
        title,
        columns,
        components,
        editable,
        options,
        style
    }
}

export default useScorecardResultsTableProps