import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk, defaultEntityAdapter } from "./utils";
import { entrantUnprocessedDocsService } from "../services/entrantService";

export const getEntrantUnprocessedDocs = createCustomAsyncThunk(
    "entrant/unprocessedDocs/get",
    async user => await entrantUnprocessedDocsService.get(user)
)

const entrantUnprocessedDocsSlice = createSlice({
    name: "entrant/unprocessedDocs",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null
    }),
    extraReducers: builder => {
        builder
            .addCase(getEntrantUnprocessedDocs.pending, state => { state.isLoading = true })
            .addCase(getEntrantUnprocessedDocs.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getEntrantUnprocessedDocs.rejected, (state, action) => { 
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const enentrantUnprocessedDocSelectors = defaultEntityAdapter.getSelectors(x => x.entrantUnprocessedDocs) 

export default entrantUnprocessedDocsSlice.reducer