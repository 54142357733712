import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";
import { entrantApplicationService } from "../../services/entrantService";

export const getEntrantApplications = createCustomAsyncThunk(
    "entrantApplications/apps/get",
    async userId => await entrantApplicationService.get(userId)
)

export const createEntrantApplication = createCustomAsyncThunk(
    "entrantApplications/apps/create",
    async data => await entrantApplicationService.post(data)
)

export const deleteEntrantApplication = createCustomAsyncThunk(
    "entrantApplication/apps/delete",
    async data => await entrantApplicationService.deleteApp(data)
)

export const deleteEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/delete",
    async data => await entrantApplicationService.delete(data)
)

export const createEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/create",
    async data => await entrantApplicationService.postItem(data)
)

export const updateEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/create",
    async data => await entrantApplicationService.patchItem(data)
)

export const submitEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/submit",
    async data => await entrantApplicationService.submit(data)
)

export const addConfirmationToEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/addConfirmation",
    async data => await entrantApplicationService.add_confirmation(data)
)

export const takeForReviewEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/takeForReview",
    async data => await entrantApplicationService.take_for_review(data)
)

export const acceptEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/accept",
    async data => await entrantApplicationService.accept(data)
)

export const rejectEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/reject",
    async data => await entrantApplicationService.reject(data)
)

export const toReworkEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/toRework",
    async data => await entrantApplicationService.to_rework(data)
)

export const toCompetitiveEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/toCompetitive",
    async data => await entrantApplicationService.to_competitive(data)
)

export const withdrawEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/withdraw",
    async data => await entrantApplicationService.withdraw(data)
)

export const toOrderEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/toOrder",
    async data => await entrantApplicationService.to_order(data)
)

export const toFailCompetitionEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/toFailCompetition",
    async data => await entrantApplicationService.to_fail_competition(data)
)

export const refuseEntrollCompetitionEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/refuseEntroll",
    async data => await entrantApplicationService.refuse_enroll(data)
)

export const entrollEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/entroll",
    async data => await entrantApplicationService.enroll(data)
)

export const withdrawConfirmationEntrantApplicationItem = createCustomAsyncThunk(
    "entrantApplication/items/withdrawConfirmation",
    async data => await entrantApplicationService.withdraw_confirmation(data)
)

export const requestTargetContract = createCustomAsyncThunk(
    "entrantApplication/items/requestTargetAgreement",
    async data => await entrantApplicationService.requestContract(data)
        .then(() => entrantApplicationService.getContract(data))
        .then(() => entrantApplicationService.getItem(data.id))
)

export const actionHandlersMap = {
    submit: submitEntrantApplicationItem,
    delete: deleteEntrantApplicationItem,
    add_confirmation: addConfirmationToEntrantApplicationItem,
    take_for_review: takeForReviewEntrantApplicationItem,
    accept: acceptEntrantApplicationItem,
    reject: rejectEntrantApplicationItem,
    to_rework: toReworkEntrantApplicationItem,
    to_competitive: toCompetitiveEntrantApplicationItem,
    withdraw: withdrawEntrantApplicationItem,
    to_order: toOrderEntrantApplicationItem,
    to_fail_competition: toFailCompetitionEntrantApplicationItem,
    refuse_enroll: refuseEntrollCompetitionEntrantApplicationItem,
    enroll: entrollEntrantApplicationItem,
    withdraw_confirmation: withdrawConfirmationEntrantApplicationItem,
    request_contract: requestTargetContract,
}

export const actionNamesMap = {
    submit: "Подать на проверку",
    delete: "Удалить",
    add_confirmation: "Подать согласие на зачисление",
    take_for_review: "Взять на рассмотрение",
    accept: "Принять",
    reject: "Отклонить",
    to_rework: "Отправить на доработку",
    to_competitive: "Принять к участию в конкурсе",
    withdraw: "Отозвать",
    to_order: "В приказ",
    to_fail_competition: "Не прошел по конкурсу",
    refuse_enroll: "Отказать в зачислении",
    enroll: "Зачислить",
    withdraw_confirmation: "Отозвать согласие",
    request_contract: "Запросить договор",
    accept_docs: "Проверить документы",
    create_app: "Заявления",
    edit_profile: "Анкета",
    check_epgu_docs: "Документы из ЕПГУ",
    set_test_status: "Право поступления БВИ",
    check_achievements: "Индивидуальные достижения"
}

export const actionMessagesMap = {
    submit: { success: "Конкурсная группа подана на проверку", error: "Не удалось подать конкурсную группу на проверку", },
    delete: { success: "Конкурсная группа удалена", error: "Не удалось удалить конкурсную группу" },
    add_confirmation: { success: "Согласие на зачисление подано", error: "Не удалось подать согласие на зачисление" },
    take_for_review: { success: "Конкурсная группа взята на рассмотрение", error: "Не удалось взять конкурсную группу на рассмотрение" },
    accept: { success: "Конкурсная группа принята", error: "Не удалось принять конкурсную группу" },
    reject: { success: "Конкурсная группа отклонена", error: "Не удалось отклонить конкурсную группу" },
    to_rework: { success: "Конкурсная группа отправлена на доработку", error: "Не удалось отправить конкурсную группу на доработку" },
    to_competitive: { success: "Конкурсная группа принята к участию в конкурсе", error: "Не удалось принять конкурсную группу к участию в конкурсе" },
    withdraw: {
        success: "Изменения сохранены",
        error: "Не удалось выполнить действие"
    },
    to_order: {
        success: "Изменения сохранены",
        error: "Не удалось выполнить действие"
    },
    to_fail_competition: {
        success: "Изменения сохранены",
        error: "Не удалось выполнить действие"
    },
    refuse_enroll: {
        success: "Изменения сохранены",
        error: "Не удалось выполнить действие"
    },
    enroll: {
        success: "Изменения сохранены",
        error: "Не удалось выполнить действие"
    },
    withdraw_confirmation: {
        success: "Изменения сохранены",
        error: "Не удалось выполнить действие"
    },
    request_contract: {
        success: "Изменения сохранены",
        error: "Не удалось выполнить действие"
    }
}


const entrantApplicationSlice = createSlice({
    name: "entrantApplications/apps",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
        currentAppItem: null,
        actionsIsLoading: false,
    }),
    reducers: {
        addNewEntrantApplicationItem: (state, action) => {
            defaultEntityAdapter.upsertOne(
                state,
                {
                    id: action.payload.app,
                    items: [...state.entities[action.payload.app].items, action.payload]
                })
        },
        deleteNewEntrantApplicationItem: (state, action) => {
            defaultEntityAdapter.upsertOne(
                state,
                {
                    id: action.payload.app,
                    items: state.entities[action.payload.app].items.filter(item => item.id !== null)
                }
            )
        },
        setCurrentAppItem: (state, action) => { state.currentAppItem = action.payload },
        resetCurrentAppItem: state => { state.currentAppItem = null }
    },
    extraReducers: builder => {
        builder
            .addCase(getEntrantApplications.pending, state => { state.isLoading = true })
            .addCase(getEntrantApplications.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getEntrantApplications.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(createEntrantApplication.pending, state => { state.isLoading = true })
            .addCase(createEntrantApplication.fulfilled, (state, action) => {
                defaultEntityAdapter.addOne(state, action.payload)
                state.isLoading = false
            })
            .addCase(createEntrantApplication.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(deleteEntrantApplication.pending, state => { state.isLoading = true })
            .addCase(deleteEntrantApplication.fulfilled, (state, action) => {
                defaultEntityAdapter.removeOne(state, action.payload.id)
                state.isLoading = false
            })
            .addCase(deleteEntrantApplication.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(deleteEntrantApplicationItem.pending, state => { state.isLoading = true })
            .addCase(deleteEntrantApplicationItem.fulfilled, (state, action) => {
                defaultEntityAdapter.upsertOne(
                    state,
                    {
                        id: action.payload.app,
                        items: state.entities[action.payload.app].items.filter(item => item.id !== action.payload.id)
                    }
                )
                state.isLoading = false
            })
            .addCase(deleteEntrantApplicationItem.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(createEntrantApplicationItem.pending, state => { state.isLoading = true })
            .addCase(createEntrantApplicationItem.fulfilled, (state, action) => {
                defaultEntityAdapter.upsertOne(
                    state,
                    {
                        id: action.payload.app,
                        items: [...state.entities[action.payload.app].items, action.payload]
                    }
                )
                state.isLoading = false
            })
            .addCase(createEntrantApplicationItem.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })

            .addCase(requestTargetContract.pending, state => { state.actionsIsLoading = true })
            .addCase(requestTargetContract.fulfilled, (state, action) => {
                defaultEntityAdapter.upsertOne(
                    state,
                    {
                        id: action.payload.app,
                        items: [...state.entities[action.payload.app].items.map(el => el.id === action.payload.id ? action.payload : el)]
                    }
                )
                state.actionsIsLoading = false

            })
            .addCase(requestTargetContract.rejected, (state, action) => {
                state.actionsIsLoading = false
                state.error = action.error
            })


        Object.keys(actionHandlersMap).forEach(key => {
            const f = actionHandlersMap[key]
            if (key !== "delete" && key !== "request_contract" && f) {
                builder.addCase(f.pending, state => { state.actionsIsLoading = true })
                builder.addCase(f.fulfilled, (state, action) => {
                    state.actionsIsLoading = false
                    defaultEntityAdapter.upsertOne(
                        state,
                        {
                            id: action.payload.app,
                            items: [...state.entities[action.payload.app].items.map(el => el.id === action.payload.id ? action.payload : el)]
                        }
                    )
                })
                builder.addCase(f.rejected, (state, action) => {
                    state.error = action.error
                    state.actionsIsLoading = false
                })
            }
        })
    }
})

export const entrantApplicationSelectors = defaultEntityAdapter.getSelectors(x => x.entrantApplications.apps)

export const { addNewEntrantApplicationItem, deleteNewEntrantApplicationItem, setCurrentAppItem, resetCurrentAppItem } = entrantApplicationSlice.actions

export default entrantApplicationSlice.reducer