import { Autocomplete, Box, Button, Checkbox, FormControlLabel, TextField, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Spinner from "../../../components/Spinner";
import toastWrapper from "../../../components/toastWrapper";
import useCitizenships from "../../../hooks/useCitizenships";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import useLevels from "../../../hooks/useLevels";
import usePrevEduInstitutions from "../../../hooks/usePrevEduInstitutions";
import { createPrevEduInfo, getPrevEduInfo, updatePrevEduInfo } from "../../../redux/entrantForm/prevEduInfoSlice";
import { getPrevEduOriginal } from "../../../redux/entrantForm/prevEduOriginalSlice";
import { getProfileInfo } from "../../../redux/userSlice";
import { getCurrentISODate } from "../../../utils";
import useEduDocTypes from "../hooks/useEduDocTypes";
import useOksoSpecs from "../hooks/useOksoSpecs";
import useRegions from "../hooks/useRegions";


const checkYear = value => {
    const year = String(value)
    if (year.length > 4) return String(year).slice(0, 4)
    return value
}

const checkMark = mark => {
    if (mark > 5) return 5
    if (mark < 1) return 1
    return mark
}

const PrevEduInfo = ({ userInfo, profileInfo, }) => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (userInfo?.id) {
            dispatch(getPrevEduInfo(userInfo.id))
            dispatch(getPrevEduOriginal(userInfo.id))
        }
    }, [dispatch, userInfo])

    const { regionOptions, regionLookups, regionsIsLoading } = useRegions()

    const { data, isLoading } = useSelector(x => x.entrantForm.prevEduInfo)

    const defaultCitizenship = parseInt(window._env_.DEFAULT_CITIZENSHIP)

    const onSubmit = values => {
        const newValues = { ...values, user: userInfo.id }
        if (!isHigherEduWithSpo) {
            delete (newValues.diploma_mark)
            delete (newValues.spec)
        }
        if (values.level === 3) { delete (newValues.diploma_mark) }
        if (newValues?.diploma_mark === "") newValues.diploma_mark = null
        if (newValues?.average_mark === "") newValues.average_mark = null

        dispatch(values.id === null ? createPrevEduInfo(newValues) : updatePrevEduInfo(newValues)).unwrap()
            .then(() => {
                toastWrapper("Изменения сохранены", "success")
                dispatch(getProfileInfo(userInfo?.id))
            })
            .catch(e => {
                dispatch(getProfileInfo(userInfo?.id))
                console.error(e)
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
            })
    }

    const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
        enableReinitialize: true,
        // потому что A component is changing a controlled input to be uncontrolled.
        initialValues: { ...data, average_mark: data.average_mark ?? "" },
        onSubmit: onSubmit,
        validationSchema: yup.object({
            level: yup.number().required(),
            with_honors: yup.bool(),
            institution: yup.string().required(),
            series: yup.string().max(20),
            number: yup.string().max(20).required(),
            reg_number: yup.string(),
            issue_date: yup.date().required().min(new Date("1970-01-01")).max(getCurrentISODate()),
            average_mark: yup.string().required().test("is_valid", "Неверная оценка", value => Number(value) >= 1 && Number(value) <= 5)
                .when("level", { is: 1, then: () => submittingToSpo ? undefined : yup.string().nullable() })
                .when("level", { is: 2, then: () => submittingToSpo ? undefined : yup.string().nullable() })
                .when("level", { is: 3, then: () => yup.string().nullable() }),
            diploma_mark: yup.string().required().test("is_valid", "Неверная оценка", value => Number(value) >= 1 && Number(value) <= 5)
                .when("level", { is: 1, then: () => yup.mixed().nullable() })
                .when("level", { is: 2, then: () => yup.mixed().nullable() })
                .when("level", { is: 3, then: () => yup.mixed().nullable() }),
            doc_type: yup.number().required(),
            citizen: yup.number().required(),
            region: yup.number().nullable().when("citizen", {
                is: defaultCitizenship,
                then: () => yup.number().required()
            }),
            year_end: yup.number().required()
                .test("len", "Введен недопустимый год", val => val && val.toString().length === 4)
                .min(1900, "Введен недопустимый год").max(2050, "Введен недопустимый год")
        })
    })

    const { prevEduInstitutionOptions, prevEduInstitutionsIsLoading } = usePrevEduInstitutions()
    const { oksoSpecOptions, oksoSpecLookups, oksoSpecsIsLoading } = useOksoSpecs(values?.level)
    const { eduDocTypeOptions, eduDocTypeLookups, eduDocTypesIsLoading } = useEduDocTypes()
    const { citizenshipOptions, citizenshipLookups, citizenshipsIsLoading } = useCitizenships()
    const { levelOptions, levelLookups, levelsIsLoading, } = useLevels()

    const submittingToSpo = React.useMemo(() => profileInfo?.levels?.includes(3), [profileInfo])
    const isHigherEdu = React.useMemo(() => [4, 5, 6, 7].includes(values?.level), [values.level])
    const isHigherEduWithSpo = React.useMemo(() => [3, 4, 5, 6, 7].includes(values?.level), [values.level])

    const isSmallScreen = useIsSmallScreen()

    const calculateComponentHeight = () => {
        let height = isSmallScreen ? 550 : 440
        if (isHigherEduWithSpo) height += 50
        // eslint-disable-next-line eqeqeq
        if (isSmallScreen && values.citizen == defaultCitizenship) {
            height += 50
        }
        return height
    }

    const componentHeight = calculateComponentHeight()

    if (isLoading || levelsIsLoading || citizenshipsIsLoading) return <Spinner height={componentHeight} />

    return <Box sx={{ height: componentHeight }}>
        <Autocomplete
            options={levelOptions}
            getOptionLabel={option => levelLookups[option]?.name ?? ""}
            value={values.level}
            onChange={(_, value) => {
                setFieldValue("level", value)
                setFieldValue("doc_type", null)
            }}
            renderInput={props => < TextField
                label="Уровень образования"
                required
                error={touched.level && Boolean(errors.level)}
                {...props}
            />}
        />

        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Autocomplete
                options={prevEduInstitutionOptions}
                freeSolo
                value={values.institution}
                loading={prevEduInstitutionsIsLoading}
                onChange={(_, value) => setFieldValue("institution", value)}
                renderInput={props => <TextField
                    id="institution"
                    name="institution"
                    label="Образовательное учреждение"
                    required
                    onChange={handleChange}
                    error={touched.institution && Boolean(errors.institution)}
                    {...props}
                />}
            />

            {isHigherEduWithSpo && <Autocomplete
                options={oksoSpecOptions}
                getOptionLabel={option => {
                    const spec = oksoSpecLookups[option]
                    return spec ? `${spec.code} ${spec.name}` : ""
                }}
                loading={oksoSpecsIsLoading}
                disabled={!values.level}
                value={values.spec}
                onChange={(_, value) => setFieldValue("spec", value)}
                renderInput={props => <TextField
                    id="spec"
                    name="spec"
                    label="Направление подготовки"
                    {...props}
                />}
            />}

            <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
                <Autocomplete
                    options={eduDocTypeOptions}
                    loading={eduDocTypesIsLoading}
                    getOptionLabel={option => eduDocTypeLookups[option]?.name ?? ""}
                    filterOptions={options => options.filter(el => eduDocTypeLookups[el]?.level === values?.level)}
                    value={values.doc_type}
                    onChange={(_, value) => setFieldValue("doc_type", value)}
                    sx={{ width: isSmallScreen ? "100%" : "49%" }}
                    renderInput={props => <TextField
                        id="doc_type"
                        name="doc_type"
                        label="Тип документа о пред. образовании"
                        required
                        error={touched.doc_type && Boolean(errors.doc_type)}
                        {...props}
                    />}
                />
                <TextField
                    id="reg_number"
                    name="reg_number"
                    label="Регистрационный номер"
                    value={values.reg_number}
                    onChange={handleChange}
                    error={touched.reg_number && Boolean(errors.reg_number)}
                    sx={{ width: isSmallScreen ? "100%" : "49%" }}
                />
            </Box>

            <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
                <Autocomplete
                    options={citizenshipOptions}
                    value={values.citizen}
                    getOptionLabel={option => citizenshipLookups[option]?.sname ?? ""}
                    onChange={(_, value) => {
                        setFieldValue("citizen", value)
                        setFieldValue("region", null)
                    }}
                    fullWidth
                    sx={{
                        // eslint-disable-next-line eqeqeq
                        width: !isSmallScreen && values.citizen == defaultCitizenship ? "49%" : "100%"
                    }}
                    renderInput={props => <TextField
                        label="Страна, в который док-т был выдан"
                        required
                        error={touched.citizen && Boolean(errors.citizen)}
                        {...props}
                    />}
                />

                { // eslint-disable-next-line eqeqeq
                    values.citizen == defaultCitizenship && <Autocomplete
                        options={regionOptions}
                        value={values.region}
                        getOptionLabel={option => regionLookups[option]?.name ?? ""}
                        onChange={(_, value) => setFieldValue("region", value)}
                        fullWidth
                        sx={{ width: isSmallScreen ? "100%" : "49%" }}
                        loading={regionsIsLoading}
                        renderInput={props => <TextField
                            label="Регион"
                            error={touched.region && Boolean(errors.region)}
                            required
                            {...props}
                        />}
                    />
                }
            </Box>

            <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
                <TextField
                    id="issue_date"
                    name="issue_date"
                    label="Дата выдачи"
                    value={values.issue_date}
                    onChange={handleChange}
                    type="date"
                    required
                    error={touched.issue_date && Boolean(errors.issue_date)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: isSmallScreen ? "100%" : "49%" }}
                />
                <TextField
                    id="year_end"
                    name="year_end"
                    label="Год окончания"
                    type="number"
                    value={values.year_end}
                    onChange={e => setFieldValue("year_end", checkYear(e.target.value))}
                    required
                    inputProps={{ min: 1990, max: 2050, maxLength: 4 }}
                    sx={{ width: isSmallScreen ? "100%" : "49%" }}
                />
            </Box>

            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                <TextField
                    id="series"
                    name="series"
                    label="Серия"
                    value={values.series}
                    onChange={handleChange}
                    sx={{ width: "38%" }}
                />
                <TextField
                    id="number"
                    name="number"
                    label="Номер"
                    required
                    error={touched.number && Boolean(errors.number)}
                    value={values.number}
                    onChange={handleChange}
                    sx={{ width: "60%" }}
                />
            </Box>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                {(isHigherEduWithSpo || submittingToSpo) && <TextField
                    id="average_mark"
                    name="average_mark"
                    label="Средний балл"
                    type="number"
                    step="0.01"
                    value={values.average_mark}
                    onChange={handleChange}
                    inputProps={{ min: 1, max: 5, step: 0.01 }}
                    sx={{ width: "49%" }}
                    required={(isHigherEduWithSpo || submittingToSpo)}
                    error={touched.average_mark && Boolean(errors.average_mark)}
                />}
                {isHigherEdu && <TextField
                    id="diploma_mark"
                    name="diploma_mark"
                    label="Оценка ВКР"
                    type="number"
                    step="0.01"
                    value={values.diploma_mark}
                    onChange={e => setFieldValue("diploma_mark", checkMark(e.target.value))}
                    inputProps={{ min: 1, max: 5 }}
                    sx={{ width: "49%" }}
                    error={touched.diploma_mark && Boolean(errors.diploma_mark)}
                />}
            </Box>

            <FormControlLabel
                label="С отличием"
                control={<Checkbox
                    checked={values.with_honors}
                    onChange={e => setFieldValue("with_honors", e.target.checked)}
                />}
            />

            <Tooltip title={!values?.can_edit_and_delete ? "Вы не можете редактировать данный раздел анкеты, когда есть поданные заявления" : ""}><span>
                <Button
                    disabled={!values?.can_edit_and_delete}
                    sx={{ mt: 1 }}
                    variant="contained"
                    onClick={handleSubmit}
                    fullWidth
                >
                    Сохранить
                </Button>
            </span></Tooltip>
        </Box >
    </Box>
}

export default PrevEduInfo