import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";
import { entrantApplicationService } from "../../services/entrantService";

export const getAvailableTestSubjects = createCustomAsyncThunk(
    "entrantApplications/availableTestSubjects/get",
    async app => await entrantApplicationService.getAvailableTestSubjects(app)
)


const availableTestSubjectsSlice = createSlice({
    name: "entrantApplications/availableTestSubjects",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getAvailableTestSubjects.pending, state => { state.isLoading = true })
            .addCase(getAvailableTestSubjects.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getAvailableTestSubjects.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const availableTestSubjectSelectors = defaultEntityAdapter.getSelectors(x => x.entrantApplications.availableTestSubjects)

export default availableTestSubjectsSlice.reducer