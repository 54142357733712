import { Box, Button, Divider, Paper, Typography } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import routes from "../../components/routes"
import toastWrapper from "../../components/toastWrapper"
import { changeCampaignYear, changePasswordByOldPassword, logout, updateUserInfo } from "../../redux/userSlice"
import { backendUrl } from "../../services/api"
import ChangePasswordForm from "./ChangePasswordForm"
import UserInfo from "./UserInfo"
import PermissionList from "./PermissionList"
import ChangeCampaignYearForm from "./ChangeCampaignYearForm"


const ProfilePage = () => {

    const userInfo = useSelector(x => x.user.userInfo)

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const handleChangeUserInfo = e => { dispatch(updateUserInfo({ ...userInfo, allow_email_notifications: e.target.checked })) }

    const handleChangeCampaignYear = year => {
        dispatch(changeCampaignYear({ campaign_year: year })).unwrap()
            .catch(() => { toastWrapper("Не удалось изменить текущую приемную кампанию", "error") })
    }

    const handleExit = () => {
        dispatch(logout())
        navigate(routes.login.path)
    }

    const handleChangePassword = values => {
        dispatch(changePasswordByOldPassword({ userId: userInfo.id, ...values })).unwrap()
            .then(() => {
                toastWrapper("Пароль успешно изменен", "success")
            })
            .catch(e => {
                toastWrapper(e.getUserMessage ? e.getUserMessage() : "Не удалось изменить пароль")
                console.error(e)
            })
    }

    const uid = userInfo?.uid?.split("-")

    const isDictEditor = userInfo?.accesses?.find(el => el.role.id === 3) ?? false
    const isAdmin = userInfo?.accesses?.find(el => el.role.id === 4) ?? false

    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 650, minWidth: 280, margin: "auto" }}>

        <Box sx={{
            m: 1,
            p: 2,
            borderRadius: 1,
            display: "flex",
            backgroundColor: "primary.main",
        }}>
            <Typography sx={{ ml: 2 }} variant="font2" color="white">Профиль</Typography>
        </Box>

        <Paper sx={{ m: 1, p: 2 }}>

            <UserInfo
                userInfo={userInfo}
                uid={uid}
                onChange={handleChangeUserInfo}
            />

            <PermissionList userInfo={userInfo} />

            {isAdmin && <ChangeCampaignYearForm
                value={userInfo?.campaign_year}
                onChange={handleChangeCampaignYear}
            />}

            <Box sx={{ minWidth: 280, display: "flex", flexDirection: "column" }}>

                {isDictEditor && <Button
                    href={backendUrl + "/dict/competitive_group/"}
                    variant="contained"
                    sx={{ mb: 1 }}
                >
                    Справочники
                </Button>}

                <ChangePasswordForm onChange={handleChangePassword} />

                <Divider sx={{ mt: 2, mb: 1 }} />

                <Button
                    sx={{ my: 1 }}
                    color="error"
                    variant="contained"
                    onClick={handleExit}
                >
                    Выйти
                </Button>
            </Box>

        </Paper>
    </Box>
}

export default ProfilePage