import { createSvgIcon } from "@mui/material";


const Unchecked = createSvgIcon(
    <svg viewBox="-2 -2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#6C7180" />
    </svg>,
    "Unchecked"
)

export default Unchecked