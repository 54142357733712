import React from "react"
import { useDispatch } from "react-redux"
import routes from "../../../components/routes"
import toastWrapper from "../../../components/toastWrapper"
import { dialogTypes } from "../../../constants"
import { actionMessagesMap } from "../../../redux/entrantApplications/appsSlice"
import { resetNotifications } from "../../../redux/entrantNotificationsSlice"
import { closeDialogAndResetState, setAllDialogStates } from "../../../redux/uiSlice"
import { entrantApplicationService } from "../../../services/entrantService"
import { getMessageFromObject } from "../../../services/errors"


const useCompetitiveGroupActionHandlers = (
    tableRef,
    currentCG, onSetCurrentSG, onResetCurrentSG,
    handleDefaultMenuClick, handleMenuClose,
) => {

    const [actionsIsLoading, setActionsIsLoading] = React.useState(false)

    const dispatch = useDispatch()

    const handleIntent = (type) => { dispatch(setAllDialogStates({ dialogType: type })) }

    const handleReworkOrRejectActions = (f, data, messages) => {
        setActionsIsLoading(true)
        f({ ...data, id: currentCG.id }).then(() => {
            toastWrapper(messages.success, "success")
            tableRef.current.onQueryChange()
            onResetCurrentSG()
            handleMenuClose()
            handleDialogClose()
            setActionsIsLoading(false)
        }).catch(e => {
            console.error(e)
            if (e?.response?.status === 400) {
                toastWrapper(getMessageFromObject(e?.response?.data) ?? messages.error, "error")
            } else {
                toastWrapper(messages.error, "error")
            }
            tableRef.current.onQueryChange()
            onResetCurrentSG()
            handleMenuClose()
            setActionsIsLoading(false)
        })
    }

    const handleAction = (f, messages) => {
        setActionsIsLoading(true)
        f(currentCG).then(() => {
            toastWrapper(messages.success, "success")
            tableRef.current.onQueryChange()
            onResetCurrentSG()
            handleMenuClose()
            setActionsIsLoading(false)
        }).catch(e => {
            console.error(e)
            if (e?.response?.status === 400) {
                toastWrapper(getMessageFromObject(e?.response?.data) ?? messages.error, "error")
            } else {
                toastWrapper(messages.error, "error")
            }
            tableRef.current.onQueryChange()
            onResetCurrentSG()
            handleMenuClose()
            setActionsIsLoading(false)
        })
    }

    const handleMenuCloseAndResetState = () => {
        handleMenuClose()
        onResetCurrentSG()
    }

    const handleMenuOpen = (event, data) => {
        onSetCurrentSG(data)
        handleDefaultMenuClick(event)
    }

    const handleDialogClose = () => {
        handleMenuClose()
        onResetCurrentSG()
        dispatch(closeDialogAndResetState())
    }

    const handleCompetitiveGroupHistoryDialogOpen = data => {
        onSetCurrentSG(data)
        dispatch(setAllDialogStates({ dialogType: dialogTypes.ENTRANT_APPLICATION_COMPETITIVE_GROUP_HISTORY }))
    }

    const handleHideColumnsDialogOpen = () => {
        dispatch(setAllDialogStates({ dialogType: dialogTypes.HIDE_COLUMNS }))
    }

    const handleHideColumnsDialogClose = () => {
        tableRef.current.onQueryChange()
        dispatch(closeDialogAndResetState())
    }

    const handleCompetitiveGroupHistoryDialogClose = () => {
        dispatch(closeDialogAndResetState())
        onResetCurrentSG()
        dispatch(resetNotifications())
    }

    const handleRequestContract = () => {
        setActionsIsLoading(true)
        entrantApplicationService.requestContract(currentCG).then(() =>
            entrantApplicationService.getContract(currentCG))
            .then(() => {
                toastWrapper(actionMessagesMap.request_contract.success, "success")
                tableRef.current.onQueryChange()
                onResetCurrentSG()
                handleMenuClose()
                setActionsIsLoading(false)
            })
            .catch(e => {
                console.error(e)
                if (e?.response?.status === 400) {
                    toastWrapper(getMessageFromObject(e?.response?.data) ?? actionMessagesMap.request_contract.error, "error")
                } else {
                    toastWrapper(actionMessagesMap.request_contract.error, "error")
                }
                tableRef.current.onQueryChange()
                onResetCurrentSG()
                handleMenuClose()
                setActionsIsLoading(false)
            })
    }

    const handleNavigateToAllInfo = () => {
        window.open(routes.entrantAllInfo.makePath(currentCG.user), "_blank")
    }

    const handleNavigateToApplications = () => {
        window.open(routes.entrantApplicationsOperator.makePath(currentCG.user), "_blank")
    }

    const handleNavigateToForm = () => {
        window.open(routes.entrantFormOperator.makePath(currentCG.user), "_blank")
    }

    const handleNavigateToUnprocessedDocs = () => {
        window.open(routes.entrantUnprocessedDocs.makePath(currentCG.user), "_blank")
    }

    const handleNavigateToAchievements = () => {
        window.open(routes.competitiveGroupAchievements.makePath(currentCG.id), "_blank")
    }

    const handleSetTestStatus = () => {
        setActionsIsLoading(true)
        entrantApplicationService.setTestStatus({
            app_item: currentCG.id,
            without_entrance_tests: !currentCG.without_entrance_tests
        }).then(() => {
            toastWrapper("Изменения сохранены", "success")
            tableRef.current.onQueryChange()
            onResetCurrentSG()
            handleMenuClose()
            setActionsIsLoading(false)
        }).catch(e => {
            console.error(e)
            if (e?.response?.status === 400) {
                toastWrapper(getMessageFromObject(e?.response?.data) ?? "Не удалось сохранить изменения", "error")
            } else {
                toastWrapper("Не удалось сохранить изменения", "error")
            }
            tableRef.current.onQueryChange()
            onResetCurrentSG()
            handleMenuClose()
            setActionsIsLoading(false)
        })
    }

    const handleEditDepCodeIntent = () => {
        dispatch(setAllDialogStates({ dialogType: dialogTypes.ENTRANT_APPLICATION_DEP_CODE }))
    }

    const handleEditDepCode = data => {
        setActionsIsLoading(true)
        entrantApplicationService.patchCGDepCode(data)
            .then(() => {
                dispatch(closeDialogAndResetState())
                handleMenuClose()
                toastWrapper("Код подразделения изменен", "success")
                tableRef.current.onQueryChange()
                onResetCurrentSG()
                setActionsIsLoading(false)
            })
            .catch(e => {
                dispatch(closeDialogAndResetState())
                handleMenuClose()
                console.error(e)
                if (e?.response?.status === 400) {
                    toastWrapper(getMessageFromObject(e?.response?.data) ?? "Не удалось изменить код подразделения", "error")
                } else {
                    toastWrapper("Не удалось изменить код подразделения", "error")
                }
                tableRef.current.onQueryChange()
                onResetCurrentSG()
                setActionsIsLoading(false)
            })
    }

    const actionHandlersMap = {
        submit: () => handleAction(entrantApplicationService.submit, actionMessagesMap.submit),
        delete: () => handleAction(entrantApplicationService.delete, actionMessagesMap.delete),
        add_confirmation: () => handleAction(entrantApplicationService.add_confirmation, actionMessagesMap.add_confirmation),
        take_for_review: () => handleAction(entrantApplicationService.take_for_review, actionMessagesMap.take_for_review),
        accept: () => handleAction(entrantApplicationService.accept, actionMessagesMap.accept),
        perform_reject: data => handleReworkOrRejectActions(entrantApplicationService.reject, data, actionMessagesMap.reject),
        reject: () => handleIntent(dialogTypes.ENTRANT_APPLICATION_REJECT),
        perform_to_rework: data => handleReworkOrRejectActions(entrantApplicationService.to_rework, data, actionMessagesMap.to_rework),
        to_rework: () => handleIntent(dialogTypes.ENTRANT_APPLICATION_REWORK),
        to_competitive: () => handleAction(entrantApplicationService.to_competitive, actionMessagesMap.to_competitive),
        withdraw: () => handleAction(entrantApplicationService.withdraw, actionMessagesMap.withdraw),
        to_order: () => handleAction(entrantApplicationService.to_order, actionMessagesMap.to_order),
        to_fail_competition: () => handleIntent(dialogTypes.ENTRANT_APPLICATION_TO_FAIL_COMPETITION),
        perform_to_fail_competition: data => handleReworkOrRejectActions(entrantApplicationService.to_fail_competition, data, actionMessagesMap.to_fail_competition),
        refuse_enroll: () => handleAction(entrantApplicationService.refuse_enroll, actionMessagesMap.refuse_enroll),
        enroll: () => handleAction(entrantApplicationService.enroll, actionMessagesMap.enroll),
        withdraw_confirmation: () => handleAction(entrantApplicationService.withdraw_confirmation, actionMessagesMap.withdraw_confirmation),
        request_contract: handleRequestContract,
        accept_docs: handleNavigateToAllInfo,
        create_app: handleNavigateToApplications,
        edit_profile: handleNavigateToForm,
        check_epgu_docs: handleNavigateToUnprocessedDocs,
        set_test_status: handleSetTestStatus,
        check_achievements: handleNavigateToAchievements,
    }

    return {
        actionsIsLoading, actionHandlersMap, handleAction,
        handleMenuOpen, handleMenuCloseAndResetState, handleDialogClose,
        handleCompetitiveGroupHistoryDialogOpen, handleCompetitiveGroupHistoryDialogClose,
        handleHideColumnsDialogOpen, handleHideColumnsDialogClose,
        handleEditDepCodeIntent, handleEditDepCode
    }
}

export default useCompetitiveGroupActionHandlers