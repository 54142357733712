import { useSelector } from "react-redux"
import { resetUserSearchValue, searchUsers, setUserSearchValue, userSearchSelectors } from "../redux/userSearchSlice"
import { Autocomplete, TextField } from "@mui/material"
import { useDispatch } from "react-redux"
import { useDebouncedCallback } from "use-debounce"
import React from "react"

const UserSearch = ({ initialSearch, initialValue, onChange }) => {

    const options = useSelector(userSearchSelectors.selectIds)
    const lookups = useSelector(userSearchSelectors.selectEntities)
    const { value, isLoading } = useSelector(x => x.userSearch)

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (initialSearch) {
            dispatch(searchUsers(initialSearch)).unwrap().then(res => {
                const initialUser = res.results.find(el => el.id === initialValue)
                if (initialUser) (dispatch(setUserSearchValue(initialUser.id)))
            })
        }
    }, [dispatch, initialSearch, initialValue])

    React.useEffect(() => () => { dispatch(resetUserSearchValue()) }, [dispatch])

    const debouncedSearch = useDebouncedCallback(event => {
        if (event.target.value?.length > 2) { dispatch(searchUsers(event.target.value)) }
    }, 1000)


    const handleChange = (_, value) => {
        dispatch(setUserSearchValue(value))
        onChange(value ? {
            user_fio: lookups[value].full_name,
            user: value
        } : null)
    }

    return <Autocomplete
        options={options}
        value={value}
        getOptionLabel={option => {
            const user = lookups[option]
            if (!user) return ""
            return `${user.full_name} ${user.short_uid}`
        }}
        loading={isLoading}
        //onInputChange={debouncedSearch}
        onChange={handleChange}
        renderInput={props => < TextField
            helperText="Для поиска начните вводить ФИО абитуриента"
            onChange={debouncedSearch}
            {...props}
        />}
    />
}

export default UserSearch