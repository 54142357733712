import { Box, Drawer, IconButton, Typography } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import EntrantNotifications from "../../../components/EntrantNotifications"
import { closeNotificationsDrawer } from "../../../redux/uiSlice"
import { Close } from "@mui/icons-material"
import useIsSmallScreen from "../../../hooks/useIsSmallScreen"

const EntrantNotificationsDrawer = () => {

    const notificationsDrawerOpen = useSelector(x => x.ui.notificationsDrawerOpen)

    const dispatch = useDispatch()

    const handleDrawerClose = () => dispatch(closeNotificationsDrawer())

    const isSmallScreen = useIsSmallScreen()

    return <Drawer
        open={notificationsDrawerOpen}
        onClose={handleDrawerClose}
        anchor="right"
        PaperProps={{
            sx: { width: isSmallScreen ? 300 : 500, borderRadius: 0 }
        }}
    >
        <Box sx={{ p: 1, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6" sx={{ ml: 1 }}>
                Уведомления
            </Typography>
            <IconButton onClick={handleDrawerClose}>
                <Close />
            </IconButton>
        </Box>


        <EntrantNotifications />
    </Drawer>
}

export default EntrantNotificationsDrawer