import { entranceTestsService } from "../../services/operatorService"


const useEntranceTestRegistrationTableProps = (subjectLookups) => {

    const title = "Запись на вступительные испытания"

    const data = query => new Promise((resolve, reject) => {

        const params = {
            page_size: query.pageSize,
            page: query.page + 1,
            search: query.search,
        }

        query.filters.forEach(filter => {
            // Если boolean
            if (filter.column.type === "boolean") {
                params[filter.column.field] = filter.value === "checked"
            } else {
                params[filter.column.field] = filter.value
            }
        })

        entranceTestsService.getRegistrations(params).then(res => {
            resolve({
                data: res.results,
                totalCount: res.count,
                page: query.page
            })
        }).catch(e => {
            console.error(e)
            reject("Произошла ошибка")
        })
    })

    const columns = [
        { title: "UID", field: "short_uid" },
        { title: "ФИО", field: "full_name" },
        { title: "№ заявл.", field: "app_number" },
        { title: "Предмет", field: "subject", lookup: subjectLookups },
        { title: "Дата проведения", field: "date", type: "date" },
    ]

    const style = {
        width: "100%",
        margin: "20px",
    }

    const options = {
        pageSize: 25,
        pageSizeOptions: [10, 25, 50, 100],
        padding: "dense",
        maxBodyHeight: "75vh",
        minBodyHeight: "75vh",
        headerStyle: { position: "sticky", top: 0, zIndex: 1 },
        maxColumnSort: 0,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
    }


    return {
        title,
        data,
        columns,
        style,
        options
    }
}

export default useEntranceTestRegistrationTableProps