import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useSelector } from "react-redux"
import { dialogTypes } from "../../constants"
import { useFormik } from "formik"
import React from "react"
import * as yup from "yup"

const ReworkDialgog = ({
    open, onClose, onSave,
    noticeTypeOptions, noticeTypeLookups, noticeTypesIsLoading
}) => {

    const dialogType = useSelector(x => x.ui.dialogType)

    const { values, errors, touched, setFieldValue, handleChange, handleSubmit, resetForm } = useFormik({
        initialValues: {
            notice_type: null,
            comment: "",
        },
        validationSchema: yup.object({
            notice_type: yup.number().required(),
            comment: yup.string().required()
        }),
        onSubmit: onSave,
    })

    const dataMap = {
        [dialogTypes.ENTRANT_APPLICATION_REWORK]: { id: 5, title: "Отправка на доработку" },
        [dialogTypes.ENTRANT_APPLICATION_REJECT]: { id: 8, title: "Отклонение" },
        [dialogTypes.ENTRANT_APPLICATION_TO_FAIL_COMPETITION]: { id: 11, title: 'Перевод КГ в статус "не прошла по конкурсу"' },
    }

    React.useEffect(() => { if (!open) resetForm() }, [resetForm, open])

    const options = React.useMemo(() => {
        const statusToFilter = dataMap[dialogType]?.id ?? null
        if (!statusToFilter) return []
        return noticeTypeOptions?.filter(el => noticeTypeLookups[el].status === statusToFilter)
        // eslint-disable-next-line
    }, [noticeTypeOptions, noticeTypeLookups, dialogType])

    return <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>

        <DialogTitle>
            {dataMap[dialogType]?.title ?? ""}
        </DialogTitle>

        <DialogContent>
            <Autocomplete
                options={options}
                value={values.notice_type}
                onChange={(_, value) => setFieldValue("notice_type", value)}
                loading={noticeTypesIsLoading}
                getOptionLabel={option => noticeTypeLookups[option]?.name ?? ""}
                renderInput={props => <TextField
                    label="Причина"
                    required
                    error={touched.notice_type && Boolean(errors.notice_type)}
                    {...props}
                />}
            />

            <TextField
                id="comment"
                name="comment"
                label="Комментарий"
                value={values.comment}
                onChange={handleChange}
                error={touched.comment && Boolean(errors.comment)}
                multiline
                fullWidth
                minRows={4}
                maxRows={8}
                required
            />

        </DialogContent>

        <DialogActions>
            <Button
                variant="contained"
                onClick={handleSubmit}
            >
                {dialogType === dialogTypes.ENTRANT_APPLICATION_REWORK ? "Отправить на доработку" :
                    dialogType === dialogTypes.ENTRANT_APPLICATION_REJECT ? "Отклонить" : "Сохранить"}
            </Button>
            <Button
                variant="contained"
                onClick={onClose}
                color="error"
            >
                Отмена
            </Button>
        </DialogActions>
    </Dialog>
}

export default ReworkDialgog