import { createSlice } from "@reduxjs/toolkit";
import { entranceTestsService } from "../../services/operatorService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getCurrentScorecard = createCustomAsyncThunk(
    "entranceTests/scorecards/getCurrent",
    async id => await entranceTestsService.getScorecard(id)
)

const scorecardsSlice = createSlice({
    name: "entranceTests/scorecards",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
        current: null,
    }),
    reducers: {
        setCurrentScorecard: (state, action) => { state.current = action.payload },
        resetCurrentScorecard: state => { state.current = null }
    },
    extraReducers: builder => {
        builder
            .addCase(getCurrentScorecard.pending, state => { state.isLoading = true })
            .addCase(getCurrentScorecard.fulfilled, (state, action) => {
                state.current = action.payload
                state.isLoading = false
            })
            .addCase(getCurrentScorecard.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
    }
})

export const { setCurrentScorecard, resetCurrentScorecard } = scorecardsSlice.reducer

export default scorecardsSlice.reducer