import { Delete } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"
import useIsSmallScreen from "../../../../hooks/useIsSmallScreen"


const EntrantApplicationCG = ({ item, onDelete }) => {

    const isSmallScreen = useIsSmallScreen()

    const canDelete = Boolean(item?.available_actions?.includes("delete"))

    return <Box sx={{
        my: 1, p: 1,
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        justifyContent: isSmallScreen ? undefined : "space-between",
        alignItems: "center",
        borderRadius: 2,
        backgroundColor: "primary.lightBlue"
    }}>

        <Box>
            <Typography variant="font5">
                {`${item.spec_code} - ${item.spec_name}`}
            </Typography>

            <Typography>
                Профиль: {item.subspec_name}
            </Typography>

            <Typography>
                {item.university_name}, {item.edu_form_name}, {item.edu_type_name}
            </Typography>
        </Box>

        <IconButton disabled={!canDelete} size="small" onClick={onDelete}>
            <Delete color={canDelete ? "error" : "disabled"} />
        </IconButton>
    </Box>
}

export default EntrantApplicationCG