export const queryParams = params => {
    if (!params) return
    return Object.keys(params)
        .map(key => {
            if (Array.isArray(params[key])) {
                return `${params[key].map(el => `${encodeURIComponent(key)}=${encodeURIComponent(el)}`).join("&")}`
            } else {
                return params[key] !== undefined ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}` : ""
            }
        })
        .join("&")
}

export const transformTimeToString = time => {
    const minutes = String(Math.floor(time / 60000))
    let seconds = String(Math.floor((time % 60000) / 1000))
    if (seconds.length === 1) seconds = "0" + seconds
    return `${minutes}:${seconds}`
}

export const getInitials = (names, separator = ". ") => names.filter(el => el).map(el => el?.[0] ?? "").join(separator)

export const getCurrentISODate = () => new Date().toISOString().split("T")[0]

export const stringToBoolean = str => {
    if (str?.toLowerCase() === "true") return true
    if (str?.toLowerCase() === "false") return false
    return undefined
}

export const validateSnils = value => {
    let result = null
    if (value) {
        const snils = value.replaceAll(/\D+/g, "")
        if (snils.length !== 11) {
            result = "СНИЛС может состоять только из 11 цифр"
        } else {
            let sum = 0
            for (let i = 0; i < 9; i++) {
                sum += parseInt(snils[i]) * (9 - i)
            }
            let checkDigit = 0
            if (sum < 100) {
                checkDigit = sum
            } else if (sum > 101) {
                checkDigit = parseInt(sum % 101)
                if (checkDigit === 100) checkDigit = 0
            }
            if (checkDigit !== parseInt(snils.slice(-2))) result = "Неправильное контрольное число";
        }
    }
    return result
}

export const getLookups = (values, field = 'name', separator = ', ', identifier = 'id') => {
    if (Array.isArray(field)) {
        return Object.fromEntries(values.map(el => [el[identifier], field.map(f => el[f]).join(separator)]))
    }
    return Object.fromEntries(values.map(el => [el[identifier], el[field]]))
}

export const getOptions = (values, field = 'id') => values.map(el => el[field])

export const getYearsList = (from, to = Number(new Date().getFullYear())) => {
    const result = [from]
    for (let year = from + 1; year <= to; year += 1) {
        result.push(year)
    }
    return result
}
