import { useDispatch } from "react-redux"
import React from "react"
import { availableTestSubjectSelectors, getAvailableTestSubjects } from "../../../redux/entrantApplications/availableTestSubjectsSlice"
import { useSelector } from "react-redux"

const useAvailableTestSubjects = (appId) => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (appId) dispatch(getAvailableTestSubjects(appId))
    }, [dispatch, appId])

    const availableTestSubjectOptions = useSelector(availableTestSubjectSelectors.selectIds)
    const availableTestSubjectLookups = useSelector(availableTestSubjectSelectors.selectEntities)
    const availableTestSubjectsIsLoading = useSelector(x => x.entrantApplications.availableTestSubjects.isLoading)

    return { availableTestSubjectOptions, availableTestSubjectLookups, availableTestSubjectsIsLoading }
}

export default useAvailableTestSubjects