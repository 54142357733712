import { Box } from "@mui/material"
import MaterialTableWrapper from "../../components/MaterialTableWrapper"
import useLevels from "../../hooks/useLevels"
import useStaticRatingsTableProps from "./useStaticRatingsTableProps"
import { getLookups } from "../../utils"
import useDialogProps from "../../hooks/useDialogProps"
import AlertDialog from "../../components/AlertDialog"
import { dialogModes, dialogTypes } from "../../constants"
import React from "react"
import useStaticRatingsActionHandlers from "./useStaticRatingsActionHandlers"
import StaticRatingForm from "./StaticRatingForm"

const StaticRatingList = () => {

    const { levels, universityLevelOptions } = useLevels()

    const universityLevelLookups = getLookups(levels)

    const tableRef = React.useRef()

    const { dialogData, dialogMode, dialogType, dialogIsOpen, openDialog, closeDialog } = useDialogProps()
    
    const {
        handleCreateRatingIntent, handleCreateRating,
        handleDeleteRatingIntent, handleDeleteRating,
        handleNavigateToRating
    } = useStaticRatingsActionHandlers(tableRef, openDialog, closeDialog, dialogData)

    const tableProps = useStaticRatingsTableProps(universityLevelLookups, handleCreateRatingIntent, handleDeleteRatingIntent, handleNavigateToRating)

    const createDialogIsOpen = dialogIsOpen && dialogMode === dialogModes.CREATE && dialogType === dialogTypes.RATING
    const deleteDialogIsOpen = dialogIsOpen && dialogMode === dialogModes.DELETE && dialogType === dialogTypes.RATING

    return <Box sx={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
        <MaterialTableWrapper

            tableRef={tableRef}

            {...tableProps}
        />

        <StaticRatingForm
            open={createDialogIsOpen}
            onClose={closeDialog}
            data={dialogData}
            onSave={handleCreateRating}
            levelOptions={universityLevelOptions}
            levelLookups={universityLevelLookups}
        />

        <AlertDialog
            open={deleteDialogIsOpen}
            title="Удаление рейтинг-листа"
            description="Вы уверены, что хотите удалить рейтинг-лист?"
            onOk={handleDeleteRating}
            onCancel={closeDialog}
        />
    </Box>

}

export default StaticRatingList