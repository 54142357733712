import { Box, Button, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import useCountDown from "react-countdown-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import PhoneField from "../../components/PhoneField";
import routes from "../../components/routes";
import toastWrapper from "../../components/toastWrapper";
import useRedirectToProfile from "../../hooks/useRedirectToProfile";
import { changePasswordByPhone, sendConfirmPhoneMessage } from "../../redux/userSlice";
import { transformTimeToString } from "../../utils";
import Info from "../../components/Icons/Info";
import VisibleOff from "../../components/Icons/VisibleOff";
import VisibleOn from "../../components/Icons/VisibleOn";


const RestorePasswordPage = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useRedirectToProfile()

    const [timeLeft, { start, reset }] = useCountDown(2 * 60 * 1000, 1000)

    const { sessionToken } = useSelector(x => x.user)


    const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
        onSubmit: values => {
            dispatch(changePasswordByPhone({ ...values, session_token: sessionToken })).unwrap()
                .then(() => {
                    toastWrapper("Пароль успешно  изменен", "success")
                    navigate(routes.login.path)
                })
                .catch(e => {
                    console.error(e)
                    toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сменить пароль", "error")
                })
        },
        initialValues: {
            phone_number: "",
            new_password: "",
            new_password_repeat: "",
            security_code: "",
        },
        validationSchema: yup.object({
            security_code: yup.string().required(),
            phone_number: yup.string().required("Это поле необходимо заполнить")
                .matches(
                    //eslint-disable-next-line
                    /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/,
                    "Введен неверный номер телефона"
                ),
            new_password: yup.string().required("Это поле необходимо заполнить").matches(
                //eslint-disable-next-line
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
                "Пароль должен содержать не менее шести знаков, включать цифры и латинские буквы в разных регистрах"
            ),
            new_password_repeat: yup.string().required("Это поле необходимо заполнить")
                .oneOf([yup.ref("new_password"), null], "Пароли должны совпадать"),
        })
    })

    const handlePhoneSubmit = () => {
        reset()
        dispatch(sendConfirmPhoneMessage({ phone_number: values.phone_number })).unwrap().then(() => {
            start()
            toastWrapper("Сообщение с кодом отправлено", "success")
        }).catch(e => {
            start()
            console.error(e)
            toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось отправить сообщение с кодом", "error")
        })
    }

    const [showPassword, setShowPassword] = React.useState(false)

    const [showHint, setShowHint] = React.useState(false)

    return <Box sx={{
        m: "auto",
        height: "100%",
        maxWidth: "480px",
    }}>
        <Box sx={{
            minHeight: 72,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1,
            mb: 1
        }}>
            <img
                src={`${process.env.PUBLIC_URL}/icons/logoWithText.svg`}
                alt="БГТУ"
                style={{ height: "40px" }}
            />
            <Typography sx={{ pl: 2 }} color="font.main" variant="font2">
                Личный кабинет абитуриента
            </Typography>
        </Box>

        <Paper component="form" sx={{ p: 4, pb: 3, mb: 1 }}>
            <Typography component="h1" variant="h5" align="center" sx={{ mb: 3 }}>
                Восстановление пароля
            </Typography>
            <Typography variant="body1" >
                Для смены пароля введите номер телефона, на который будет отправлен код подтверждения
            </Typography>
            <PhoneField
                id="phone_number"
                name="phone_number"
                placeholder="Номер телефона"
                type="tel"
                value={values.phone_number}
                onChange={value => setFieldValue("phone_number", value)}
                error={touched.phone_number && Boolean(errors.phone_number)}
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
                margin="normal"
            />
            <Button
                variant="contained"
                fullWidth
                onClick={() => handlePhoneSubmit(values.phone_number)}
                sx={{ mt: 1 }}
                disabled={timeLeft > 0 || values.phone_number === "" || (touched.phone_number && Boolean(errors.phone_number))}
            >
                {timeLeft > 0 ? `Выслать код повторно ${transformTimeToString(timeLeft)}` : "Выслать код"}
            </Button>

            <TextField
                id="security_code"
                name="security_code"
                placeholder="Введите полученный код"
                value={values.security_code}
                onChange={handleChange}
                error={touched.security_code && Boolean(errors.security_code)}
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
                margin="normal"
            />
            <TextField
                id="new_password"
                name="new_password"
                placeholder="Новый пароль"
                type={showPassword ? "text" : "password"}
                inputProps={{ autoComplete: "new_password", form: { autoComplete: "off" } }}
                value={values.new_password}
                onChange={handleChange}
                error={touched.new_password && Boolean(errors.new_password)}
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
                margin="normal"
                InputProps={{
                    endAdornment: <InputAdornment position="end">

                        <Tooltip
                            title="Пароль должен содержать не менее шести знаков, включать цифры и латинские буквы в разных регистрах"
                            open={showHint}
                        >
                            <IconButton onClick={() => setShowHint(!showHint)} >
                                <Info fontSize="small" color={showHint ? "primary" : undefined} />
                            </IconButton>
                        </Tooltip>

                        <IconButton
                            aria-label="Показать пароль"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end"
                        >
                            {showPassword ? <VisibleOff fontSize="small" /> : <VisibleOn fontSize="small" />}
                        </IconButton>

                    </InputAdornment>
                }}
            />
            <TextField
                id="new_password_repeat"
                name="new_password_repeat"
                placeholder="Повторите новый пароль"
                type={showPassword ? "text" : "password"}
                inputProps={{ autoComplete: "new_password", form: { autoComplete: "off" } }}
                value={values.new_password_repeat}
                onChange={handleChange}
                error={touched.new_password_repeat && Boolean(errors.new_password_repeat)}
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
                margin="normal"
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="Показать пароль"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end"
                        >
                            {showPassword ? <VisibleOff fontSize="small" /> : <VisibleOn fontSize="small" />}
                        </IconButton>
                    </InputAdornment>
                }}
            />
        </Paper>

        <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            sx={{ mt: 1 }}
        >
            Сменить пароль
        </Button>
    </Box>
}

export default RestorePasswordPage