import { createSlice } from "@reduxjs/toolkit";
import { entrantFormService } from "../../services/entrantService";
import { createCustomAsyncThunk } from "../utils";

export const getPassportInfo = createCustomAsyncThunk(
    "entrant/passportInfo/get",
    async userId => await entrantFormService.getPassportInfo(userId)
)

export const createPassportInfo = createCustomAsyncThunk(
    "entrant/passportInfo/create",
    async data => await entrantFormService.postPassportInfo(data)
)

export const updatePassportInfo = createCustomAsyncThunk(
    "entrant/passportInfo/update",
    async data => await entrantFormService.patchPassportInfo(data)
)

export const getOldPassportInfo = createCustomAsyncThunk(
    "entrant/oldPassportInfo/get",
    async userId => await entrantFormService.getOldPassportInfo(userId)
)

export const createOldPassportInfo = createCustomAsyncThunk(
    "entrant/oldPassportInfo/create",
    async data => await entrantFormService.postOldPassportInfo(data)
)

export const updateOldPassportInfo = createCustomAsyncThunk(
    "entrant/oldPassportInfo/update",
    async data => await entrantFormService.patchOldPassportInfo(data)
)

export const deleteOldPassportInfo = createCustomAsyncThunk(
    "entrant/oldPassportInfo/delete",
    async data => await entrantFormService.deleteOldPassportInfo(data)
)

const initialValues = {
    id: null,
    doc_type: parseInt(window._env_?.DEFAULT_PASSPORT) ?? null,
    series: "",
    number: "",
    issue_dep: "",
    issue_dep_code: "",
    issue_date: "",
    user: 0,
    can_edit_and_delete: true,
}

const passportInfoSlice = createSlice({
    name: "entrant/passportInfo",
    initialState: {
        passport: initialValues,
        passportIsLoading: true,

        oldPassport: null,
        oldPassportIsLoading: true,

        error: null,
    },
    extraReducers: builder => {
        builder
            .addCase(getPassportInfo.pending, state => { state.passportIsLoading = true })
            .addCase(getPassportInfo.fulfilled, (state, action) => {
                state.passportIsLoading = false
                state.passport = action.payload.length > 0 ? action.payload[0] : initialValues
            })
            .addCase(getPassportInfo.rejected, (state, action) => {
                state.passportIsLoading = false
                state.error = action.error
            })

            .addCase(createPassportInfo.pending, state => { state.passportIsLoading = true })
            .addCase(createPassportInfo.fulfilled, (state, action) => {
                state.passportIsLoading = false
                state.passport = action.payload
            })
            .addCase(createPassportInfo.rejected, (state, action) => {
                state.passportIsLoading = false
                state.error = action.error
            })

            .addCase(updatePassportInfo.pending, state => { state.passportIsLoading = true })
            .addCase(updatePassportInfo.fulfilled, (state, action) => {
                state.passportIsLoading = false
                state.passport = action.payload
            })
            .addCase(updatePassportInfo.rejected, (state, action) => {
                state.passportIsLoading = false
                state.error = action.error
            })

            .addCase(getOldPassportInfo.pending, state => { state.oldPassportIsLoading = true })
            .addCase(getOldPassportInfo.fulfilled, (state, action) => {
                state.oldPassportIsLoading = false
                state.oldPassport = action.payload.length > 0 ? action.payload[0] : initialValues
            })
            .addCase(getOldPassportInfo.rejected, (state, action) => {
                state.oldPassportIsLoading = false
                state.error = action.error
            })

            .addCase(createOldPassportInfo.pending, state => { state.oldPassportIsLoading = true })
            .addCase(createOldPassportInfo.fulfilled, (state, action) => {
                state.oldPassportIsLoading = false
                state.oldPassport = action.payload
            })
            .addCase(createOldPassportInfo.rejected, (state, action) => {
                state.oldPassportIsLoading = false
                state.error = action.error
            })

            .addCase(updateOldPassportInfo.pending, state => { state.oldPassportIsLoading = true })
            .addCase(updateOldPassportInfo.fulfilled, (state, action) => {
                state.oldPassportIsLoading = false
                state.oldPassport = action.payload
            })
            .addCase(updateOldPassportInfo.rejected, (state, action) => {
                state.oldPassportIsLoading = false
                state.error = action.error
            })

            .addCase(deleteOldPassportInfo.fulfilled, state => { state.oldPassport = initialValues })
    }
})

export default passportInfoSlice.reducer