import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useFormik } from "formik"
import * as yup from "yup"
import PhoneField from "../../components/PhoneField"
import { dialogModes } from "../../constants"

const getTitle = formMode => {
    switch (formMode) {
        case dialogModes.CREATE:
            return "Создание пользователя"
        case dialogModes.UPDATE:
            return "Редактирование пользователя"
        default: return "Просмотр пользователя"
    }
}

const UserForm = ({
    data,
    open,
    onClose,
    dialogMode,
    onSave = () => null
}) => {

    const { values, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik({
        initialValues: data ? { ...data, patronymic: data?.patronymic ?? "" } : null,
        enableReinitialize: true,
        validationSchema: yup.object({
            surname: yup.string().required("Это поле необходимо заполнить"),
            name: yup.string().required("Это поле необходимо заполнить"),
            patronymic: yup.string().nullable(true),
            email: yup.string().required("Это поле необходимо заполнить")
                .matches(
                    //eslint-disable-next-line
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    "Введен неверный адрес электронной почты"
                ),
            phone: yup.string().required("Это поле необходимо заполнить")
                .matches(
                    //eslint-disable-next-line
                    /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/,
                    "Введен неверный номер телефона"
                ),
        }),
        onSubmit: onSave
    })

    if (!open || !values) return null

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>{getTitle(dialogMode)}</DialogTitle>
        <DialogContent>
            <TextField
                id="surname"
                name="surname"
                autoComplete="surname"
                autoFocus
                required
                label="Фамилия"
                value={values.surname}
                onChange={handleChange}
                error={touched.surname && Boolean(errors.surname)}
                fullWidth
            />
            <TextField
                id="name"
                name="name"
                autoComplete="name"
                required
                label="Имя"
                value={values.name}
                onChange={handleChange}
                error={touched.name && Boolean(errors.name)}
                fullWidth
            />
            <TextField
                id="patronymic"
                name="patronymic"
                autoComplete="patronymic"
                label="Отчество (при наличии)"
                value={values.patronymic}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                id="email"
                name="email"
                autoComplete="email"
                required
                label="Эл. почта"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                fullWidth
            />
            <PhoneField
                id="phone"
                name="phone"
                required
                label="Номер телефона"
                value={values.phone}
                onChange={value => setFieldValue("phone", value)}
                error={touched.phone && Boolean(errors.phone)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSubmit}>
                Сохранить
            </Button>
            <Button color="error" onClick={onClose}>
                Отмена
            </Button>
        </DialogActions>
    </Dialog>

}

export default UserForm
