import { useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/system"
import routes from "../routes"
import DesktopNavigation from "./DesktopNavigation"
import MobileNavigation from "./MobileNavigation"
import { useSelector } from "react-redux"
import React from "react"
import { operatorRoles } from "../../constants"


const OperatorLayout = ({ children }) => {

    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const userInfo = useSelector(x => x.user.userInfo)

    const menuItemsForMobile = [
        routes.userList,
        routes.competitiveGroupList,
        routes.operatorOtherServices,
        routes.operatorFeedback,
        routes.profile,
    ]

    const menuItemsForDesktop = React.useMemo(() => {

        const isComissionMember = Boolean(userInfo?.accesses?.find(el => el.role.id === operatorRoles.COMMISSION_MEMBER))
        const isAdmin = Boolean(userInfo?.accesses?.find(el => el.role.id === operatorRoles.ADMIN))
        const isDocumentEditor = Boolean(userInfo.accesses.find(el => el.role.id === operatorRoles.DOC_EDITOR))
        const isSatExamsChecker = Boolean(userInfo?.accesses?.find(el => el.role.id === operatorRoles.CHECK_SAT_EXAM))
        const isRatingEditor = Boolean(userInfo?.accesses?.find(el => el.role.id === operatorRoles.RATINGS))

        return [
            routes.userList,
            routes.competitiveGroupList,
            isDocumentEditor && routes.scorecards,
            (isComissionMember || isAdmin) && routes.reports,
            isSatExamsChecker && routes.satExams,
            (isComissionMember || isAdmin) && routes.entranceTestRegistrations,
            routes.operatorFeedback,
            (isAdmin || isDocumentEditor) && routes.orderList,
            (isAdmin || isRatingEditor) && routes.ratings,
            routes.profile,
        ].filter(el => el) // Убираем все falsy значения
    }, [userInfo])

    return isSmallScreen ? (
        <MobileNavigation menuItems={menuItemsForMobile}>
            {children}
        </MobileNavigation>
    ) : (
        <DesktopNavigation menuItems={menuItemsForDesktop}>
            {children}
        </DesktopNavigation>
    )
}

export default OperatorLayout