import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { getYearsList } from "../../utils"

const ChangeCampaignYearForm = ({ onChange, value }) => {

    const years = getYearsList(2023)

    return <Box sx={{ mt: 1, mb: 2 }}>
        <FormControl fullWidth variant="outlined" margin="none">
            <InputLabel id="campaign_year_label">Текущая приемная кампания</InputLabel>
            <Select
                id="campaign_year"
                labelId="campaign_year_label"
                variant="outlined"
                value={value}
                label="Текущая приемная кампания"
                onChange={e => onChange(e.target.value)}
            >
                {years.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}

            </Select>
        </FormControl>
    </Box >
}

export default ChangeCampaignYearForm