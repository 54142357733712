import { getLookups } from "../../utils"

const regionTypes = [
    { name: "Автономный округ", sname: "АО" },
    { name: "Автономная область", sname: "Аобл" },
    { name: "Город", sname: "г" },
    { name: "Край", sname: "край" },
    { name: "Область", sname: "обл" },
    { name: "Республика", sname: "Респ" },
    { name: "Округ", sname: "округ" },
    { name: "Город федерального значения", sname: "г.ф.з." },
    { name: "Чувашия", sname: "Чувашия" }, // Считается отдельным типом почему-то
]

export const regionTypeOptions = regionTypes.map(el => el.name)
export const regionTypeLookups = getLookups(regionTypes, ["sname"], ", ", "name")

const settlementTypes = [
    { name: "Населенный пункт", sname: "нп" },
    { name: "Поселок городского типа", sname: "пгт" },
    { name: "Село", sname: "c" },
    { name: "Рабочий поселок", sname: "рп" },
    { name: "Курортный поселок", sname: "кп" },
    { name: "Дачный поселок", sname: "дп" },
    { name: "Сельсовет", sname: "с/с" },
    { name: "Сельская администрация", sname: "с/а" },
    { name: "Сельский округ", sname: "с/о" },
    { name: "Волость", sname: "волость" },
    { name: "Почтовое отделение", sname: "п/о" },
    { name: "Территория", sname: "тер" },
    { name: "Сельское поселение", sname: "с/п" },
    { name: "Массив", sname: "массив" },

]

export const settlementTypeOptions = settlementTypes.map(el => el.name)
export const settlementTypeLookups = getLookups(settlementTypes, ["sname"], ", ", "name")