import { configureStore } from "@reduxjs/toolkit"
import logger from "redux-logger"
import competitiveGroupAchievementsReducer from "./competitiveGroupAchievementsSlice"
import dictsReducer from "./dicts"
import entranceTestsReducer from "./entranceTests"
import entrantAllInfoReducer from "./entrantAllInfoSlice"
import entrantApplicationReducer from "./entrantApplications"
import entrantFormReducer from "./entrantForm"
import entrantNotificationsReducer from "./entrantNotificationsSlice"
import entrantSurveyReducer from "./entrantSurveySlice"
import entrantUnprocessedDocsReducer from "./entrantUnprocessedDocsSlice"
import ordersReducer from "./orders"
import ratingsReducer from "./ratingsSlice"
import reportsReducer from "./reports"
import satExamsCheckReducer from "./satExamsCheckSlice"
import uiReducer from "./uiSlice"
import userSearchReducer from "./userSearchSlice"
import userReducer from "./userSlice"

const preloadedState = {}

export const store = configureStore({
    middleware: getDefaultMiddleware => process.env.NODE_ENV === "development" ? (
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }).concat(logger)
    ) : (
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        })
    ),
    reducer: {
        user: userReducer,
        ui: uiReducer,
        dicts: dictsReducer,
        entrantNotifications: entrantNotificationsReducer,
        entrantForm: entrantFormReducer,
        entrantSurvey: entrantSurveyReducer,
        entrantApplications: entrantApplicationReducer,
        entrantAllInfo: entrantAllInfoReducer,
        satExamsCheck: satExamsCheckReducer,
        reports: reportsReducer,
        entranceTests: entranceTestsReducer,
        entrantUnprocessedDocs: entrantUnprocessedDocsReducer,
        userSearch: userSearchReducer,
        ratings: ratingsReducer,
        orders: ordersReducer,
        competitiveGroupAchievements: competitiveGroupAchievementsReducer,
    },
    devTools: process.env.NODE_ENV !== "production",
    preloadedState,
})