import useIsSmallScreen from "../../hooks/useIsSmallScreen"
import { stringToBoolean } from "../../utils"
import routes from "../routes"
import DesktopNavigation from "./DesktopNavigation"
import MobileNavigation from "./MobileNavigation"

const EntrantLayout = ({ children }) => {

    const isSmallScreen = useIsSmallScreen()

    const menuItems = [
        stringToBoolean(window._env_?.ENTRANT_SURVEY_ENABLED) && routes.entrantSurvey,
        stringToBoolean(window._env_?.ENTRANT_FORM_ENABLED) && routes.form,
        stringToBoolean(window._env_?.ENTRANT_APPLICATIONS_ENABLED) && routes.entrantApplications,
        routes.entrantFeedback,
        routes.profile,
    ].filter(el => el)

    return isSmallScreen ? (
        <MobileNavigation menuItems={menuItems}>
            {children}
        </MobileNavigation>
    ) : (
        <DesktopNavigation menuItems={menuItems}>
            {children}
        </DesktopNavigation>
    )

}

export default EntrantLayout