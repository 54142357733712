import { Box, Typography } from "@mui/material"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"


const EntrantApplicationPrioritiesList = ({ priorities, onSwap, droppableId }) => {

    const onDragEnd = result => {
        if (!result.destination) return
        onSwap(result.source.index, result.destination.index)
    }

    return <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {priorities.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                    my: 1, p: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    borderRadius: 2,
                                    backgroundColor: "primary.lightBlue"
                                }}>

                                <Typography sx={{ p: 1, pr: 2, fontSize: 24 }} variant="font3">
                                    {index + 1}
                                </Typography>
                                <Box>
                                    <Typography variant="font5">
                                        {`${item.info.spec_code} - ${item.info.spec_name}`}
                                    </Typography>

                                    <Typography>
                                        Профили: {item.subspecs.join(", ")}
                                    </Typography>

                                    <Typography>
                                        {item.info.university}, {item.info.edu_form}
                                    </Typography>
                                </Box>
                            </Box>}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext>

}

export default EntrantApplicationPrioritiesList