import { Autocomplete, Box, Button, Checkbox, FormControlLabel, TextField } from "@mui/material"
import { useFormik } from "formik"
import * as yup from "yup"

const EntrantApplicationFormStep1 = ({ levelsToAdd, levelLookups, levelsIsLoading, onSave, onClose }) => {

    const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
        initialValues: {
            level: null,
            is_budget: true,
        },
        validationSchema: yup.object({
            level: yup.number().required(),
            is_budget: yup.bool().required()
        }),
        onSubmit: onSave
    })

    return <Box sx={{ px: 2 }}>
        <Box>
            <Autocomplete
                options={levelsToAdd ?? []}
                getOptionLabel={option => option === 4 ? "Бакалавриат / Специалитет" : levelLookups[option]?.name ?? ""}
                value={values.level}
                loading={levelsIsLoading}
                onChange={(_, value) => setFieldValue("level", value)}
                renderInput={props => <TextField
                    label="Уровень образования"
                    required
                    error={touched.level && Boolean(errors.level)}
                    {...props}
                />}
            />

            <FormControlLabel
                label="Бюджетная основа обучения"
                control={<Checkbox
                    checked={values.is_budget}
                    onChange={(_, value) => setFieldValue("is_budget", value)}
                />}
            />
        </Box>

        <Box sx={{ mt: 1, display: "flex", justifyContent: "flex-end", p: 1 }}>
            <Button variant="contained" sx={{ mr: 2 }} onClick={handleSubmit}>
                Создать заявление
            </Button>
            <Button variant="contained" color="error" onClick={onClose}>
                Закрыть
            </Button>
        </Box>
    </Box>
}

export default EntrantApplicationFormStep1