import { MTableToolbar } from "@material-table/core"
import { Autocomplete, Box, TextField, Typography } from "@mui/material"
import FileDownloadButton from "../../components/FileDownloadButton"
import { levelShortNames } from "../../constants"
import { apiUrl } from "../../services/api"
import { queryParams } from "../../utils"


const CompetitiveGroupListTableToolbar = ({
    tProps, tableRef, displayedColums,
    showOnlyMaxPriority,

    displayedLevels, onDisplayedLevelsChange,
    universityLevelOptions, universityLevelLookups,

    displayedSpecs, onDisplayedSpecsChange,
    specOptions, specLookups,

    displayedSubspecs, onDisplayedSubspecsChange,
    subspecOptions, subspecLookups

}) => {

    const getUrl = (fields, filters) => {
        const params = { fields: fields.join(",") }
        if (displayedLevels.length > 0) params.level = displayedLevels
        if (showOnlyMaxPriority) params.is_max_priority = showOnlyMaxPriority
        if (displayedSpecs.length > 0) params.spec = displayedSpecs
        if (displayedSubspecs.length > 0) params.subspec = displayedSubspecs
        filters.forEach(f => {
            params[f.column.field] = f.value
        })
        return `${apiUrl}/operator/applications_xlsx/?${queryParams(params)}`
    }

    return <div>
        <MTableToolbar {...tProps} />
        <Box sx={{ mx: 2, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>

            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                <Autocomplete
                    value={displayedLevels}
                    options={universityLevelOptions.filter(el => el !== 5)}
                    getOptionLabel={option => universityLevelLookups[option]}
                    onChange={(_, value) => onDisplayedLevelsChange(value)}
                    sx={{ minWidth: 250, maxWidth: 250, mr: 2 }}
                    multiple
                    renderTags={value => <Typography sx={{ maxWidth: 150 }} fontSize={12} noWrap>
                        {value.map(el => levelShortNames[el]).join(", ")}
                    </Typography>}
                    renderInput={props => <TextField
                        label="Уровни образования"
                        variant="standard"
                        margin="none"
                        {...props}
                    />}
                />

                <Autocomplete
                    value={displayedSpecs}
                    options={specOptions}
                    getOptionLabel={option => specLookups[option]}
                    onChange={(_, value) => onDisplayedSpecsChange(value)}
                    sx={{ minWidth: 300, maxWidth: 300, mr: 2 }}
                    multiple
                    renderTags={value => <Typography sx={{ maxWidth: 200 }} fontSize={12} noWrap>
                        {value.map(el => specLookups[el]).join(", ")}
                    </Typography>}
                    renderInput={props => <TextField
                        label="Направления"
                        variant="standard"
                        margin="none"
                        {...props}
                    />}
                />

                <Autocomplete
                    value={displayedSubspecs}
                    options={subspecOptions}
                    getOptionLabel={option => subspecLookups[option]?.name ?? ""}
                    onChange={(_, value) => onDisplayedSubspecsChange(value)}
                    sx={{ minWidth: 300, maxWidth: 300, mr: 2 }}
                    multiple
                    filterOptions={options => displayedSpecs.length > 0 ? options.filter(el => displayedSpecs.includes(subspecLookups[el].spec)) : options}
                    renderTags={value => <Typography sx={{ maxWidth: 200 }} fontSize={12} noWrap>
                        {value.map(el => subspecLookups[el]?.name ?? "").join(", ")}
                    </Typography>}
                    renderInput={props => <TextField
                        label="Профили"
                        variant="standard"
                        margin="none"
                        {...props}
                    />}
                />
            </Box>

            <FileDownloadButton
                component="link"
                url={getUrl(displayedColums, tableRef.current.state.query.filters)}
                name="Выгрузка с текущими фильтрами"
            />
        </Box>
    </div >
}

export default CompetitiveGroupListTableToolbar