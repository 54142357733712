import { LoadingButton } from "@mui/lab"
import { Autocomplete, Box, IconButton, Paper, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import routes from "../../components/routes"
import toastWrapper from "../../components/toastWrapper"
import useLevels from "../../hooks/useLevels"
import { api } from "../../services/api"
import { KeyboardBackspace } from "@mui/icons-material"
import useUniversities from "../../hooks/useUniversities"


const RegionReport = () => {

    const navigate = useNavigate()

    const navigateToReports = () => navigate(routes.reports.path)

    const [isLoading, setIsLoading] = React.useState(false)

    const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
        initialValues: {
            level: null,
            university: null,
        },
        validationSchema: yup.object({
            level: yup.number().required(),
            university: yup.number().required(),
        }),
        enableReinitialize: true,
        onSubmit: values => {
            setIsLoading(true)
            const link = document.createElement("a")
            link.target = "_blank"
            api.get(`/operator/reports/enrolled_by_regions_xlsx/`, { responseType: "blob", params: values })
                .then((res) => {
                    link.href = URL.createObjectURL(new Blob([res.data], { type: res.data.type }))
                    link.click()
                    setIsLoading(false)
                })
                .catch(e => {
                    console.error(e)
                    toastWrapper('Не удалось загрузить файл', 'error')
                    setIsLoading(false)
                })
        }
    })

    const { universityLevelOptions, levelLookups, } = useLevels()
    const { universityOptions, universityLookups } = useUniversities()

    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 700, minWidth: 280, margin: "auto" }}>

        <Box sx={{
            m: 1,
            p: 2,
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
            backgroundColor: "secondary.main",
            height: 56,
        }}>
            <IconButton onClick={navigateToReports} size="small">
                <KeyboardBackspace sx={{ color: "white" }} />
            </IconButton>
            <Typography sx={{ ml: 2 }} variant="font2" color="white">
                Отчет по регионам
            </Typography>
        </Box>

        <Paper sx={{
            display: "flex",
            flexDirection: "column",
            m: 1, mb: 3, p: 2,
            minHeight: 160,
            maxWidth: 700,
            overflow: "auto"
        }}>
            <Autocomplete
                options={universityLevelOptions}
                value={values.level}
                onChange={(_, value) => { setFieldValue("level", value) }}
                getOptionLabel={option => levelLookups[option]?.name ?? ""}
                renderInput={props => <TextField
                    id="level"
                    name="level"
                    label="Уровень образования"
                    margin="normal"
                    required
                    error={touched.level && Boolean(errors.level)}
                    {...props}
                />}
            />
            <Autocomplete
                options={universityOptions}
                value={values.university}
                onChange={(_, value) => setFieldValue("university", value)}
                getOptionLabel={option => universityLookups[option]?.sname ?? ""}
                renderInput={props => <TextField
                    label="Универститет"
                    margin="normal"
                    required
                    error={touched.university && Boolean(errors.university)}
                    {...props}
                />}
            />

            <LoadingButton loading={isLoading} variant="contained" onClick={handleSubmit} sx={{ mt: 1 }}>
                Скачать отчет
            </LoadingButton>
        </Paper>
    </Box>
}

export default RegionReport