import routes from "../../../components/routes"
import toastWrapper from "../../../components/toastWrapper"
import { dialogModes, dialogTypes } from "../../../constants"
import { getMessageFromObject } from "../../../services/errors"
import { ordersService } from "../../../services/operatorService"
import { getCurrentISODate } from "../../../utils"

const orderInitialValues = {
    number: "",
    date: getCurrentISODate(),
    category: 1,
    comment: "",
    university: null,
}

const useOrdersTableActionHandlers = (
    tableRef,
    dialogData, openDialog, closeDialog,
) => {

    const handleIntent = (dialogType, dialogMode, dialogData) => openDialog({ dialogType, dialogMode, dialogData })

    const handleAction = (f, data, successMessage, errorMessage) => {
        f(data).then(() => {
            toastWrapper(successMessage, "success")
            tableRef.current.onQueryChange()
            closeDialog()
        }).catch(e => {
            console.error(e)
            toastWrapper(getMessageFromObject(e?.response?.data) ?? errorMessage, "error")
            tableRef.current.onQueryChange()
        })
    }

    const handleCreateOrderIntent = () => handleIntent(dialogTypes.ORDER, dialogModes.CREATE, orderInitialValues)

    const handleCreateOrder = values => handleAction(ordersService.post, values, "Приказ создан", "Не удалось создать приказ")

    const handleEditOrderIntent = (_, data) => handleIntent(dialogTypes.ORDER, dialogModes.UPDATE, data)

    const handleEditOrder = values => handleAction(ordersService.patch, values, "Приказ изменен", "Не удалось изменить приказ")

    const handleDeleteOrderIntent = (_, data) => handleIntent(dialogTypes.ORDER, dialogModes.DELETE, data)

    const handleDeleteOrder = () => handleAction(ordersService.delete, dialogData, "Приказ создан", "Не удалось создать приказ")

    const handleOpenOrder = (_, data) => handleAction(ordersService.open, data, "Приказ открыт", "Не удалось открыть приказ")

    const handleCloseOrderIntent = (_, data) => handleIntent(dialogTypes.ORDER_CLOSE, null, data)

    const handleCloseOrder = () => handleAction(ordersService.close, dialogData, "Приказ закрыт", "Не удалось закрыть приказ")

    const handleNavigateToOrder = (_, data) => {
        window.open(routes.order.makePath(data.id), "_blank")
    }

    return {
        handleCreateOrderIntent, handleCreateOrder,
        handleEditOrderIntent, handleEditOrder,
        handleDeleteOrderIntent, handleDeleteOrder,
        handleCloseOrderIntent, handleCloseOrder,
        handleOpenOrder,
        handleNavigateToOrder
    }
}

export default useOrdersTableActionHandlers