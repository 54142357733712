import { createSlice } from "@reduxjs/toolkit";
import { dictService } from "../../services/dictService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getLanguages = createCustomAsyncThunk(
    "dicts/languages/get",
    async () => await dictService.getLanguages()
)

const languagesSlice = createSlice({
    name: "dicts/languages",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: true,
        error: null
    }),
    extraReducers: builder => {
        builder
            .addCase(getLanguages.pending, state => { state.isLoading = true })
            .addCase(getLanguages.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getLanguages.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const languageSelectors = defaultEntityAdapter.getSelectors(x => x.dicts.languages)

export default languagesSlice.reducer