import Box from "@mui/material/Box";
import React from "react";

const MainLayout = ({ children }) => <Box sx={{
	p: 1,
	minHeight: "100vh",
	display: "flex",
	justifyContent: "center"
}}>
	{children}
</Box>

export default MainLayout
