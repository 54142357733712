import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDisplayedUserInfo, setDisplayedUserInfo } from "../redux/userSlice"
import { useNavigate } from "react-router"
import routes from "../components/routes"
import toastWrapper from "../components/toastWrapper"


const useDisplayedUserInfo = id => {

    const dispatch = useDispatch()

    const { displayedUserInfo, displayedUserInfoIsLoading, userInfo } = useSelector(x => x.user)

    const navigate = useNavigate()

    React.useEffect(() => () => {
        dispatch(setDisplayedUserInfo(userInfo))
        // eslint-disable-next-line
    }, [dispatch])

    React.useEffect(() => {
        if (id) {
            dispatch(getDisplayedUserInfo(id)).unwrap().catch(e => {
                console.error(e)
                toastWrapper("Пользователь не найден", "error")
                navigate(routes.competitiveGroupList.path)
            })
        }
    }, [dispatch, navigate, id])

    const hasLoaded = displayedUserInfo !== null && !displayedUserInfoIsLoading && userInfo?.id !== displayedUserInfo?.id
    
    return hasLoaded
}

export default useDisplayedUserInfo