import { Autocomplete, Box, Button, ButtonGroup, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from 'yup';
import { olympResults } from "../../../constants";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import { createOlympInfo, deleteOlympInfo, getOlympInfo, updateOlympInfo } from "../../../redux/entrantForm/olympInfoSlice";
import { getLookups, getOptions } from "../../../utils";
import toastWrapper from "../../../components/toastWrapper";

const OlympForm = ({ userInfo }) => {

    const dispatch = useDispatch()

    const { data } = useSelector(x => x.entrantForm.olymp)

    const [isOlympPresent, setIsOlympPresent] = React.useState(false)

    React.useEffect(() => {
        if (userInfo?.id) dispatch(getOlympInfo(userInfo.id))
    }, [dispatch, userInfo])

    React.useEffect(() => { if (data?.id) setIsOlympPresent(true) }, [data])

    const handleDelete = () => {
        if (data.id) dispatch(deleteOlympInfo(data))
        setIsOlympPresent(false)
    }

    const resultsOptions = getOptions(olympResults)
    const resultsLookups = getLookups(olympResults)

    const isSmallScreen = useIsSmallScreen()

    const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
        initialValues: data,
        validationSchema: yup.object({
            name: yup.string().required(),
            result: yup.number().required()
        }),
        onSubmit: values => {
            const newValues = { ...values, user: userInfo.id }
            dispatch(newValues.id ? updateOlympInfo(newValues) : createOlympInfo(newValues)).unwrap()
            .then(() => { toastWrapper("Изменения сохранены", "success") })
            .catch(e => {
                console.error(e)
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
            })
        },
        enableReinitialize: true
    })

    return <Box>
        <FormControlLabel
            label="Олимпиада, подтверждаемая баллами ЕГЭ по соответствующему предмету"
            disabled={!data?.can_edit_and_delete}
            //sx={{ mb: isOlympPresent ? 0 : 1 }}
            control={<Checkbox
                disabled={!data?.can_edit_and_delete}
                checked={isOlympPresent}
                onChange={e => setIsOlympPresent(e.target.checked)}
            />}
        />
        {isOlympPresent && <Box sx={{ width: '100%' }}>

            <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'space-between' }}>
                <TextField
                    id="name"
                    name="name"
                    label="Наименование олимпиады"
                    value={values.name}
                    onChange={handleChange}
                    sx={{ width: isSmallScreen ? '100%' : '60%' }}
                    error={touched.name && Boolean(errors.name)}
                    fullWidth
                    required
                />

                <Autocomplete
                    options={resultsOptions}
                    getOptionLabel={option => resultsLookups[option] ?? ''}
                    value={values.result}
                    onChange={(_, value) => setFieldValue('result', value)}
                    sx={{ width: isSmallScreen ? '100%' : '38%' }}
                    renderInput={props => <TextField
                        id="result"
                        name="result"
                        label="Результат"
                        error={touched.result && Boolean(errors.result)}
                        required
                        {...props}
                    />
                    }
                />
            </Box>

            <ButtonGroup sx={{ width: '100%', mt: 1, mb: 1 }}>
                <Button
                    variant="contained"
                    color="error"
                    disabled={!data.can_edit_and_delete}
                    onClick={handleDelete}
                    fullWidth
                >
                    Удалить
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    fullWidth
                    disabled={!data.can_edit_and_delete}
                >
                    Сохранить
                </Button>
            </ButtonGroup>
        </Box>}
    </Box>
}

export default OlympForm