import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSpecialConditions, specialConditionSelectors } from "../redux/dicts/specialConditionsSlice"

const useSpecialConditions = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(getSpecialConditions())
    }, [dispatch])

    const specialConditionOptions = useSelector(specialConditionSelectors.selectIds)
    const specialConditionLookups = useSelector(specialConditionSelectors.selectEntities)
    const specialConditionsIsLoading = useSelector(x => x.dicts.specialConditions.isLoading)

    return { specialConditionOptions, specialConditionLookups, specialConditionsIsLoading }
}

export default useSpecialConditions