import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSubjects, subjectSelectors } from "../redux/dicts/subjectsSlice"

const useSubjects = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(getSubjects())
    }, [dispatch])

    const subjects = useSelector(subjectSelectors.selectAll)
    const subjectOptions = useSelector(subjectSelectors.selectIds)
    const subjectLookups = useSelector(subjectSelectors.selectEntities)
    const subjectsIsLoading = useSelector(x => x.dicts.subjects.isLoading)

    return { subjects, subjectOptions, subjectLookups, subjectsIsLoading, }
}

export default useSubjects