import { Delete } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Link, Step, StepButton, StepContent, Stepper, Typography } from "@mui/material";
import React from "react";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import FileDownloadButton from "../../../components/FileDownloadButton";
import FileIcon from "../../../components/Icons/FileIcon";
import toastWrapper from "../../../components/toastWrapper";
import { addAcceptedFiles, createSupFiles, deleteAcceptedFile, deleteSupFile, getSupFiles, resetAcceptedFiles, resetCurrentFileFormStep, resetTempFiles, saveTempFiles, setCurrentFileFormStep } from "../../../redux/entrantForm/supDocsSlice";


const SupDocs = ({ userInfo }) => {

    const dispatch = useDispatch()

    const { files, tempFiles, acceptedFiles, currentStep } = useSelector(x => x.entrantForm.supDocs)

    React.useEffect(() => {
        if (userInfo?.id) dispatch(getSupFiles(userInfo.id))
    }, [dispatch, userInfo])

    // При выходе удаляем временные файлы и уже загруженные файлы
    React.useEffect(() => () => {
        dispatch(resetCurrentFileFormStep())
        dispatch(resetTempFiles())
        dispatch(resetAcceptedFiles())
    }, [dispatch])

    const onDrop = f => {
        dispatch(addAcceptedFiles(f))
        dispatch(saveTempFiles(f))
    }

    const handleChangeActiveStep = index => {
        dispatch(setCurrentFileFormStep(index))
        dispatch(resetTempFiles())
        dispatch(resetAcceptedFiles())
    }

    const handleRemoveAcceptedFile = index => { dispatch(deleteAcceptedFile(index)) }

    const handleSave = index => {
        const data = { ...files[index], files: tempFiles.map(el => el.id), userId: userInfo.id }
        dispatch(createSupFiles(data)).unwrap()
            .then(() => {
                dispatch(resetAcceptedFiles([]))
                dispatch(resetTempFiles())
                toastWrapper("Изменения сохранены", "success")
            })
            .catch(e => {
                console.error(e)
                dispatch(resetAcceptedFiles([]))
                dispatch(resetTempFiles())
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
            })
    }

    const handleDelete = (fileIndex, id) => {
        dispatch(deleteSupFile({ fileIndex, id })).unwrap()
            .then(() => {
                toastWrapper("Изменения сохранены", "success")
            })
            .catch(e => {
                console.error(e)
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
            })
    }

    return <Stepper orientation="vertical" nonLinear activeStep={currentStep} sx={{ mb: 1 }}>

        {files?.map((file, fileIndex) => <Step key={fileIndex} completed={file.files?.length > 0}>

            <StepButton onClick={() => handleChangeActiveStep(fileIndex)}>
                <Typography color="black">
                    {file.name}
                </Typography>
            </StepButton>

            <StepContent sx={{ mt: 1 }}>
                {file?.template_link && <FileDownloadButton
                    url={file.template_link}
                    name={file.name}
                    sx={{ width: 300, m: "auto" }}
                    //fullWidth
                    buttonLabel="Скачать шаблон документа"
                    variant="contained"
                />}

                <Dropzone
                    onDrop={onDrop}
                    maxSize={1024 * 1024 * 5}
                    accept={{
                        "image/jpeg": [],
                        "image/png": [],
                        "application/pdf": [],
                    }}
                    noDragEventsBubbling
                >
                    {({ getRootProps, getInputProps }) => {

                        const { onClick, ...rootProps } = getRootProps()

                        return <Box
                            {...rootProps}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "#E8F2FF",
                                borderRadius: "5px",
                                borderColor: "primary",
                                mt: 2, mb: 2, p: 1,
                                minHeight: 120
                            }}
                        >
                            <Box
                                sx={{
                                    p: 1,
                                    display: "flex",
                                    flexWrap: "wrap",
                                }}
                            >
                                {acceptedFiles.length === 0 ? (
                                    <Typography align="center" sx={{ m: 1 }}>
                                        Перетащите файлы сюда или загрузите вручную. Допустимые форматы: pdf, png и jpg, размером не более 5Мб
                                    </Typography>
                                ) : acceptedFiles.map((acceptedFile, acceptedFileIndex) => <FileIcon
                                    key={acceptedFileIndex}
                                    name={acceptedFile.name}
                                    type={acceptedFile.type}
                                    onDelete={() => handleRemoveAcceptedFile(acceptedFileIndex)}
                                />)
                                }
                            </Box>

                            <Button sx={{ height: 35 }} variant="contained" onClick={onClick}>
                                Добавить файлы
                            </Button>

                            <input {...getInputProps()} />
                        </Box>
                    }}
                </Dropzone>

                {file.files.length > 0 && <>
                    <Typography>Сохраненные файлы</Typography>
                    <Divider sx={{ mb: 1 }} />
                    {file.files.map((savedFile, savedFileIndex) => <Box key={savedFileIndex} sx={{ display: "flex", alignItems: "center" }}>
                        <Link
                            key={savedFileIndex}
                            style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "block"
                            }}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={savedFile.link}
                        >
                            {savedFile.name}
                        </Link>
                        <IconButton disabled={!file.can_edit_and_delete} sx={{ ml: 1 }} onClick={() => handleDelete(fileIndex, savedFile.id)} >
                            <Delete color={!file.can_edit_and_delete ? "disabled" : "error"} size="small" />
                        </IconButton>
                    </Box>)}
                    <Divider sx={{ mt: 1, mb: 1 }} />
                </>}

                <span><Button
                    fullWidth
                    onClick={() => handleSave(fileIndex)}
                    variant="contained"
                    sx={{ mb: 1 }}
                    disabled={tempFiles?.length === 0}
                >
                    Сохранить
                </Button></span>

            </StepContent>
        </Step>)
        }
    </Stepper >

}

export default SupDocs