import { createSlice } from "@reduxjs/toolkit";
import { entrantNotificationsService } from "../services/entrantService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "./utils";

export const getEntrantNotifications = createCustomAsyncThunk(
    "entrant/notifications/get",
    async userId => await entrantNotificationsService.getNotifications(userId)
)

export const getCGStatusHistory = createCustomAsyncThunk(
    "entrant/notifications/getCGStatusHistory",
    async cgId => await entrantNotificationsService.getStatusHistory(cgId)
)

export const getCGPriorityHistory = createCustomAsyncThunk(
    "entrant/notifications/getCGPriorityHistory",
    async cgId => await entrantNotificationsService.getPriorityHistory(cgId)
)

export const getCGOriginalHistory = createCustomAsyncThunk(
    "entrant/notifications/getCGOriginalHistory",
    async userId => await entrantNotificationsService.getOriginalHistory(userId)
)

const entrantNotificationsSlice = createSlice({
    name: "entrant/notifications",
    initialState: {
        status: defaultEntityAdapter.getInitialState({
            isLoading: false,
        }),
        priority: defaultEntityAdapter.getInitialState({
            isLoading: false,
        }),
        original: defaultEntityAdapter.getInitialState({
            isLoading: false,
        }),
        error: null,
    },
    reducers: {
        resetNotifications: state => { defaultEntityAdapter.removeAll(state) }
    },
    extraReducers: builder => {
        builder
            .addCase(getEntrantNotifications.pending, state => { state.status.isLoading = true })
            .addCase(getEntrantNotifications.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state.status, action.payload)
                state.status.isLoading = false
            })
            .addCase(getEntrantNotifications.rejected, (state, action) => {
                state.error = action.error
                state.status.isLoading = false
            })

            .addCase(getCGStatusHistory.pending, state => { state.status.isLoading = true })
            .addCase(getCGStatusHistory.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state.status, action.payload)
                state.status.isLoading = false
            })
            .addCase(getCGStatusHistory.rejected, (state, action) => {
                state.error = action.error
                state.status.isLoading = false
            })
        
            .addCase(getCGPriorityHistory.pending, state => { state.priority.isLoading = true })
            .addCase(getCGPriorityHistory.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state.priority, action.payload)
                state.priority.isLoading = false
            })
            .addCase(getCGPriorityHistory.rejected, (state, action) => {
                state.error = action.error
                state.status.isLoading = false
            })
        
            .addCase(getCGOriginalHistory.pending, state => { state.original.isLoading = true })
            .addCase(getCGOriginalHistory.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state.original, action.payload)
                state.original.isLoading = false
            })
            .addCase(getCGOriginalHistory.rejected, (state, action) => {
                state.error = action.error
                state.original.isLoading = false
            })
    }

})

export default entrantNotificationsSlice.reducer

export const { resetNotifications } = entrantNotificationsSlice.actions

export const entrantStatusHistorySelectors = defaultEntityAdapter.getSelectors(x => x.entrantNotifications.status)
export const entrantPriorityHistorySelectors = defaultEntityAdapter.getSelectors(x => x.entrantNotifications.priority)
export const entrantOriginalHistorySelectors = defaultEntityAdapter.getSelectors(x => x.entrantNotifications.original)