import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOksoSpecs, oksoSpecSelectors } from "../../../redux/dicts/oksoSpecsSlice";

const useOksoSpecs = (level) => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (level) {
            dispatch(getOksoSpecs({ level }))
        }
    }, [dispatch, level])

    const oksoSpecOptions = useSelector(oksoSpecSelectors.selectIds)
    const oksoSpecLookups = useSelector(oksoSpecSelectors.selectEntities)
    const oksoSpecsIsLoading = useSelector(x => x.dicts.oksoSpecs.isLoading)

    return { oksoSpecOptions, oksoSpecLookups, oksoSpecsIsLoading }
}

export default useOksoSpecs