import { combineReducers } from "@reduxjs/toolkit";
import appsReducer from "./appsSlice";
import appPrioritiesReducer from "./appPrioritiesSlice";
import availableTestSubjectsReducer from "./availableTestSubjectsSlice";
import entranceTestRegistrationReducer from "./entranceTestRegistrationSlice";

export default combineReducers({
    apps: appsReducer,
    appPriorities: appPrioritiesReducer,
    availableTestSubjects: availableTestSubjectsReducer,
    entranceTestRegistration: entranceTestRegistrationReducer,
})