import { createSlice } from "@reduxjs/toolkit";
import { getCurrentISODate } from "../../utils";
import { entrantFormService } from "../../services/entrantService";
import { createCustomAsyncThunk } from "../utils";

export const getMilitaryDocInfo = createCustomAsyncThunk(
    "entrantForm/militaryDocInfo/get",
    async userId => await entrantFormService.getMilitaryDoc(userId)
)

export const createMilitaryDocInfo = createCustomAsyncThunk(
    "entrantForm/militaryDocInfo/create",
    async data => await entrantFormService.postMilitaryDoc(data)
)

export const updateMilitaryDocInfo = createCustomAsyncThunk(
    "entrantForm/militaryDocInfo/update",
    async data => await entrantFormService.patchMilitaryDoc(data)
)

const initialValues = {
    type: 1,
    series: "",
    number: "",
    issue_mil_office: "",
    issue_date: getCurrentISODate(),
    category: "А",
    can_edit_and_delete: true,
}

const militaryDocInfoSlice = createSlice({
    name: "entrantForm/militaryDocInfo",
    initialState: {
        data: initialValues,
        isLoading: false,
        error: null
    },
    extraReducers: builder => {
        builder
            .addCase(getMilitaryDocInfo.pending, state => { state.isLoading = true })
            .addCase(getMilitaryDocInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload.length > 0 ? action.payload[0] : initialValues
            })
            .addCase(getMilitaryDocInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
        
            .addCase(createMilitaryDocInfo.pending, state => { state.isLoading = true })
            .addCase(createMilitaryDocInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(createMilitaryDocInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
        
            .addCase(updateMilitaryDocInfo.pending, state => { state.isLoading = true })
            .addCase(updateMilitaryDocInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(updateMilitaryDocInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
    }
})

export default militaryDocInfoSlice.reducer