import { combineReducers } from "@reduxjs/toolkit";
import currentOrderReducer from "./currentOrderSlice";
import orderAvailableAppsSlice from "./orderAvailableAppsSlice";


const ordersReducer = combineReducers({
    current: currentOrderReducer,
    availableApps: orderAvailableAppsSlice
})

export default ordersReducer