import { Box, Dialog, DialogContent, DialogTitle, Step, StepButton, Stepper } from "@mui/material"
import { useSelector } from "react-redux"
import useUniversities from "../../../../hooks/useUniversities"
import useProfileInfo from "../../../EntrantForm/hooks/useProfileInfo"
import useEntrantApplicationFormActionHandlers from "../../hooks/useEntrantApplicationFormActionHandlers"
import useEntrantApplicationPriorities from "../../hooks/useEntrantApplicationPriorities"
import EntrantApplicationFormStep1 from "./EntrantApplicationFormStep1"
import EntrantApplicationFormStep2 from "./EntrantApplicationFormStep2"
import EntrantApplicationFormStep3 from "./EntrantApplicationFormStep3"
import EntrantApplicationFormStep4 from "./EntrantApplicationFormStep4"
import useAvailableTestSubjects from "../../hooks/useAvailableTestSubjects"
import useEntranceTestRegistration from "../../hooks/useEntranceTestRegistration"


const EntrantApplicationForm = ({ userInfo, open, onClose, levelLookups, levelsIsLoading }) => {

    const { profileInfo } = useProfileInfo(userInfo?.id)

    const { universityOptions, universityLookups, universitiesIsLoading } = useUniversities()

    const { currentApplicationStep } = useSelector(x => x.ui)

    const dialogData = useSelector(x => x.ui.dialogData)

    const { commonPriorities, targetPriorities, isLoading } = useEntrantApplicationPriorities(currentApplicationStep, dialogData?.id)

    const { availableTestSubjectOptions, availableTestSubjectLookups, availableTestSubjectsIsLoading } = useAvailableTestSubjects(dialogData?.id)

    const { entranceTestRegistrations, entranceTestRegistrationsIsLoading } = useEntranceTestRegistration(dialogData?.id)

    const {
        handleCreateApplication,
        handleCreateAppItem, handleCloseEditAppItem, handleDeleteAppItem, handleAddNewAppItem,
        handleSwapCommonPriorities, handleSwapTargetPriorities, handleSavePriorities,
        handleToStep2, handleToStep3, 
        handleAddEntranceTestRegistration, handleCreateEntranceTestRegistration,
        handleDeleteEntranceTestRegistration, handleDeleteNewEntranceTestRegistration,
    } = useEntrantApplicationFormActionHandlers(userInfo, commonPriorities, targetPriorities, onClose, entranceTestRegistrations)

    const levelsToAdd = [...new Set(profileInfo?.levels?.map(level => level === 4 || level === 5 ? 4 : level))]

    const steps = [
        {
            component: EntrantApplicationFormStep1,
            props: {
                levelsToAdd, levelLookups, levelsIsLoading,
                onSave: handleCreateApplication,
                onClose,
            },
        },
        {
            component: EntrantApplicationFormStep2,
            props: {
                universityOptions, universityLookups, universitiesIsLoading,
                data: dialogData,
                onSave: handleCreateAppItem,
                onClose,
                onCloseEdit: handleCloseEditAppItem,
                onDelete: handleDeleteAppItem,
                onAddNew: handleAddNewAppItem,
                onNextStep: handleToStep3,
            },
        },
        {
            component: EntrantApplicationFormStep3,
            props: {
                onPrevStep: handleToStep2,
                onSave: handleSavePriorities,
                onClose,
                onSwapCommonPriorities: handleSwapCommonPriorities,
                onSwapTargetPriorities: handleSwapTargetPriorities,
                commonPriorities, targetPriorities, isLoading,
            },
        },
        {
            component: EntrantApplicationFormStep4,
            props: {
                onClose,
                availableTestSubjectOptions, availableTestSubjectLookups, availableTestSubjectsIsLoading,
                entranceTestRegistrations, entranceTestRegistrationsIsLoading,
                onAdd: handleAddEntranceTestRegistration,
                onSave: handleCreateEntranceTestRegistration,
                onDelete: handleDeleteEntranceTestRegistration,
                onDeleteNew: handleDeleteNewEntranceTestRegistration,
            }
        }
    ]

    const currentStep = steps[currentApplicationStep]

    return <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">

        <DialogTitle>{`Заявление ${dialogData?.number ?? ""}`}</DialogTitle>

        <DialogContent sx={{ p: 0, pb: 1 }}>

            <Stepper activeStep={currentApplicationStep} sx={{ pl: 1, pr: 1, my: 4, }}>
                {steps.map((step, index) => <Step sx={{ display: "flex" }} key={index}>
                    <StepButton
                        onClick={index === 1 ? handleToStep2 : index === 2 ? handleToStep3 : undefined}
                        sx={{ height: 15, p: 2, }} color="inherit" />
                </Step>)}
            </Stepper>


            <Box sx={{ height: "65vh" }}>
                <currentStep.component {...currentStep.props} />
            </Box>

        </DialogContent>
    </Dialog>
}

export default EntrantApplicationForm