import { Box, Link as MuiLink, Paper, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import routes from "../components/routes"
import { operatorRoles } from "../constants"

const OperatorOtherServicesPage = () => {

    const userInfo = useSelector(x => x.user.userInfo)

    const isComissionMember = Boolean(userInfo.accesses.find(el => el.role.id === operatorRoles.COMMISSION_MEMBER))
    const isDocEditor = Boolean(userInfo.accesses.find(el => el.role.id === operatorRoles.DOC_EDITOR))
    const isSatExamsChecker = Boolean(userInfo?.accesses?.find(el => el.role.id === operatorRoles.CHECK_SAT_EXAM))
    const isAdmin = Boolean(userInfo?.accesses?.find(el => el.role.id === operatorRoles.ADMIN))

    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 650, minWidth: 280, margin: "auto" }}>

        <Box sx={{
            m: 1,
            p: 2,
            borderRadius: 1,
            display: "flex",
            backgroundColor: "secondary.main",
        }}>
            <Typography sx={{ ml: 2 }} variant="font2" color="white">
                Прочее
            </Typography>
        </Box>

        <Paper sx={{ display: "flex", flexDirection: "column", m: 1, p: 2, minHeight: 300 }}>
            <MuiLink sx={{ mr: 1 }} component={Link} to={routes.reports.path}>Отчеты</MuiLink>
            {isAdmin && <MuiLink sx={{ mr: 1 }} component={Link} to={routes.ratings.path}>Рейтинг-листы</MuiLink>}
            {isDocEditor && <MuiLink sx={{ mr: 1 }} component={Link} to={routes.scorecards.path}>Экзаменационные ведомости</MuiLink>}
            {(isDocEditor || isAdmin) && <MuiLink sx={{ mr: 1 }} component={Link} to={routes.orderList.path}>Приказы</MuiLink>}
            {(isSatExamsChecker || isAdmin) && <MuiLink sx={{ mr: 1 }} component={Link} to={routes.satExams.path}>Проверка ЕГЭ</MuiLink>}
        </Paper>
    </Box>
}
export default OperatorOtherServicesPage