import { combineReducers } from "redux";
import scorecardResultsReducer from "./scorecardResultsSlice";
import scorecardReducer from "./sсorecardSlice";


const entranceTestsReducer = combineReducers({
    scorecard: scorecardReducer,
    scorecardResults: scorecardResultsReducer,
})

export default entranceTestsReducer