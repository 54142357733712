import { useDispatch } from "react-redux"
import { createScorecardResult, deleteScorecardResult, updateScorecardResult } from "../../../redux/entranceTests/scorecardResultsSlice"
import { useNavigate } from "react-router"
import routes from "../../../components/routes"


const useScorecardResultsActionHandlers = current => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const handleCreate = data => dispatch(createScorecardResult({ scorecard: current?.id, ...data })).unwrap()
    const handleUpdate = data => dispatch(updateScorecardResult({ scorecard: current?.id, ...data })).unwrap()
    const handleDelete = data => dispatch(deleteScorecardResult({ ...data })).unwrap()

    const handleNavigateToScorecardList = () => navigate(routes.scorecards.path)

    return { handleCreate, handleUpdate, handleDelete, handleNavigateToScorecardList }
}

export default useScorecardResultsActionHandlers