import { createSvgIcon } from "@mui/material";

const Form = createSvgIcon(
    <svg viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.8572 3.50458H19.3327V1.5487C19.3327 0.982523 18.8694 0.519287 18.3033 0.519287H9.70196C9.13579 0.519287 8.67255 0.982523 8.67255 1.5487V6.96454C8.67255 7.53073 9.13579 7.99396 9.70196 7.99396H16.0614C16.6277 7.99396 17.0908 7.53073 17.0908 6.96454C17.0908 6.39837 16.6277 5.93514 16.0614 5.93514H10.7314V2.57811H17.2795V4.53399C17.2795 5.10016 17.7428 5.56341 18.3089 5.56341H23.8335V26.4605H4.17173V5.56341H5.74445C6.31062 5.56341 6.77386 5.10016 6.77386 4.53399C6.77386 3.96782 6.31062 3.50458 5.74445 3.50458H3.14232C2.57615 3.50458 2.11292 3.96782 2.11292 4.53399V27.4899C2.11292 28.056 2.57615 28.5193 3.14232 28.5193H24.8572C25.4233 28.5193 25.8866 28.056 25.8866 27.4899V4.53399C25.8866 3.96782 25.4233 3.50458 24.8572 3.50458Z" />
        <path d="M18.744 12.9295C18.3436 12.5235 17.6917 12.5235 17.2857 12.9238L12.4874 17.682L10.7317 15.9148C10.3314 15.5088 9.67944 15.5088 9.2734 15.9091C8.86735 16.3095 8.86735 16.9614 9.26767 17.3674L11.7497 19.8666C11.9499 20.0668 12.2129 20.1697 12.4817 20.1697C12.7449 20.1697 13.0079 20.0725 13.208 19.8723L18.7383 14.3879C19.1443 13.9875 19.1442 13.3356 18.744 12.9295Z" />
    </svg>,
    "Checked"
)

export default Form