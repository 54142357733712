import { Box, Link as MuiLink, Paper, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import routes from "../../components/routes"


const RatingsPage = () => {


    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 650, minWidth: 280, margin: "auto" }}>

        <Box sx={{
            m: 1,
            p: 2,
            borderRadius: 1,
            display: "flex",
            backgroundColor: "secondary.main",
        }}>
            <Typography sx={{ ml: 2 }} variant="font2" color="white">
                Рейтинг-листы
            </Typography>
        </Box>

        <Paper sx={{ display: "flex", flexDirection: "column", m: 1, p: 2, minHeight: 300 }}>
            <MuiLink sx={{ mr: 1 }} component={Link} to={routes.staticRatingList.path}>Статические рейтинг-листы</MuiLink>
            <MuiLink sx={{ mr: 1 }} component={Link} to={routes.dynamicRatingCGSelect.path}>Динамический рейтинг-лист</MuiLink>
        </Paper>
    </Box>
}

export default RatingsPage