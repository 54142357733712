import React from "react"
import { exemptionSelectors, getExemptions, updateExemptions } from "../../../redux/entrantForm/exemptionSlice"
import { useDispatch, useSelector } from "react-redux"
import { exemptionDocsSelectors, getExemptionDocs } from "../../../redux/dicts/exemptionDocsSlice"
import { Autocomplete, Box, Button, Divider, IconButton, TextField, Tooltip } from "@mui/material"
import { Add, Delete } from "@mui/icons-material"
import { useFormik } from "formik"
import * as yup from "yup"
import { exemptionDocCategoriesSelectors, getExemptionDocsCategories } from "../../../redux/dicts/exemptionDocCategoriesSlice"
import toastWrapper from "../../../components/toastWrapper"
import useIsSmallScreen from "../../../hooks/useIsSmallScreen"
import { getCurrentISODate, getLookups, getOptions } from "../../../utils"
import { invalidTypes } from "../../../constants"

const exemptionInitialValues = {
    is_init_values: true,
    can_edit_and_delete: true,

    doc_type: null,
    doc_type_category: null,
    name: "",
    series: "",
    number: "",
    issue_date: getCurrentISODate(),
    organization: "",
    invalid_group: "",
    original_date: getCurrentISODate(),
}


const ExemptionsInfo = ({ userInfo }) => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(getExemptionDocs())
        dispatch(getExemptionDocsCategories())
    }, [dispatch])

    React.useEffect(() => {
        if (userInfo) dispatch(getExemptions(userInfo.id))
    }, [userInfo, dispatch])

    const exemptions = useSelector(exemptionSelectors.selectAll)

    const exemptionDocOptions = useSelector(exemptionDocsSelectors.selectIds)
    const exemptionDocLookups = useSelector(exemptionDocsSelectors.selectEntities)

    const exemptionDocCategoryOptions = useSelector(exemptionDocCategoriesSelectors.selectIds)
    const exemptionDocCategoryLookups = useSelector(exemptionDocCategoriesSelectors.selectEntities)

    const invalidTypeOptions = getOptions(invalidTypes)
    const invalidTypeLookups = getLookups(invalidTypes)

    const { values, handleSubmit, errors, touched, setValues, handleChange, setFieldValue } = useFormik({
        initialValues: exemptions,
        enableReinitialize: true,
        onSubmit: values => {
            const newExemptions = values.map(value => ({ ...value, user: userInfo.id }))
            const created = newExemptions.filter(el => el.is_init_values)
            const updated = newExemptions.filter(el => !el.is_init_values && el.id)
            const deleted = exemptions.filter(el => !newExemptions.map(el => el.id).includes(el.id))
            dispatch(updateExemptions({ created, updated, deleted })).unwrap()
                .then(() => {
                    dispatch(getExemptions(userInfo.id))
                    toastWrapper("Изменения сохранены", "success")
                })
                .catch(e => {
                    dispatch(getExemptions(userInfo.id))
                    console.error(e)
                    toastWrapper("Не удалось сохранить изменения", "error")
                })
        },
        validationSchema: yup.array().of(yup.object({
            doc_type: yup.number().required(),
            doc_type_category: yup.number().required(),
            name: yup.string().when("doc_type_category", { is: 1, then: () => yup.string().required(), }),
            series: yup.string().when("doc_type_category", { is: 2, then: () => yup.string().required() }),
            number: yup.string().when("doc_type_category", { is: 2, then: () => yup.string().required() }),
            issue_date: yup.date().required(),
            organization: yup.string().when("doc_type_category", { is: value => value !== 2, then: () => yup.string().required() }),
            invalid_group: yup.string().nullable(true).when("doc_type_category", { is: 2, then: () => yup.string().required() }),
            original_date: yup.date(),
        }))
    })

    const isSmallScreen = useIsSmallScreen()

    return <>
        {values.map((value, index) => <React.Fragment key={index}>

            {index !== 0 && <Divider sx={{ mt: 1, mb: 1 }} />}

            <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>

                <Autocomplete
                    value={value.doc_type_category}
                    options={exemptionDocCategoryOptions}
                    getOptionLabel={option => exemptionDocCategoryLookups[option].name ?? ""}
                    disabled={!value.can_edit_and_delete}
                    fullWidth
                    sx={{ mr: 1 }}
                    onChange={(_, value) => {
                        setFieldValue(`[${index}].doc_type_category`, value)
                        setFieldValue(`[${index}].invalid_group`, "")
                        setFieldValue(`[${index}].doc_type`, null)
                    }}
                    renderInput={props => <TextField
                        label="Тип документа, подтверждающего наличие льготы"
                        required
                        error={errors[index]?.doc_type_category && Boolean(touched[index]?.doc_type_category)}
                        {...props}
                    />}
                />

                <Tooltip title={value.can_edit_and_delete ? "Удалить особое или преимущественное право" : "Удалить особое или преимущественное право нельзя"}>
                    <span>
                        <IconButton
                            disabled={!value.can_edit_and_delete}
                            onClick={() => setValues(values.filter((_, ind) => index !== ind))}
                        >
                            <Delete color={value.can_edit_and_delete ? "error" : "disabled"} />
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>

            <Autocomplete
                options={exemptionDocOptions}
                getOptionLabel={option => exemptionDocLookups[option]?.name ?? ""}
                value={value.doc_type}
                disabled={!value.can_edit_and_delete || !values[index]?.doc_type_category}
                filterOptions={options => options.filter(option => exemptionDocLookups[option]?.category === value.doc_type_category)}
                onChange={(_, value) => {
                    setFieldValue(`[${index}].doc_type`, value)
                    setFieldValue(`[${index}].invalid_group`, "")
                }}
                renderInput={props => <TextField
                    label="Категория льготы"
                    required
                    error={errors[index]?.doc_type && Boolean(touched[index]?.doc_type)}
                    {...props}
                />}
            />

            {value.doc_type_category === 2 && <Autocomplete
                options={invalidTypeOptions}
                getOptionLabel={option => invalidTypeLookups[option] ?? ""}
                value={value.invalid_group}
                disabled={!value.can_edit_and_delete}
                onChange={(_, value) => { setFieldValue(`[${index}].invalid_group`, value) }}
                renderInput={props => <TextField
                    label="Группа инвалидности"
                    required={value.doc_type_category === 2}
                    error={errors[index]?.invalid_group && Boolean(touched[index]?.invalid_group)}
                    {...props}
                />}
            />}

            <TextField
                id={`[${index}].organization`}
                name={`[${index}].organization`}
                label="Наименование организации, выдавшей документ"
                value={value.organization}
                disabled={!value.can_edit_and_delete}
                onChange={handleChange}
                fullWidth
                required={value.doc_type_category !== 2}
                error={errors[index]?.organization && Boolean(touched[index]?.organization)}
            />

            <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>

                <TextField
                    id={`[${index}].name`}
                    name={`[${index}].name`}
                    label="Название документа"
                    value={value.name}
                    disabled={!value.can_edit_and_delete}
                    onChange={handleChange}
                    sx={{ width: isSmallScreen ? "100%" : "49%" }}
                    required={value.doc_type_category === 1}
                    error={errors[index]?.name && Boolean(touched[index]?.name)}
                />
                <TextField
                    id={`[${index}].series`}
                    name={`[${index}].series`}
                    label="Серия документа"
                    value={value.series}
                    disabled={!value.can_edit_and_delete}
                    onChange={handleChange}
                    sx={{ width: isSmallScreen ? "100%" : "20%" }}
                    required={value.doc_type_category === 2}
                    error={errors[index]?.series && Boolean(touched[index]?.series)}
                />
                <TextField
                    id={`[${index}].number`}
                    name={`[${index}].number`}
                    label="Номер документа"
                    value={value.number}
                    disabled={!value.can_edit_and_delete}
                    onChange={handleChange}
                    sx={{ width: isSmallScreen ? "100%" : "27%" }}
                    required={value.doc_type_category === 2}
                    error={errors[index]?.number && Boolean(touched[index]?.number)}
                />
            </Box>

            <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>

                <TextField
                    id={`[${index}].issue_date`}
                    name={`[${index}].issue_date`}
                    label="Дата выдачи документа"
                    value={value.issue_date}
                    disabled={!value.can_edit_and_delete}
                    onChange={handleChange}
                    sx={{ width: isSmallScreen ? "100%" : "49%" }}
                    type="date"
                    error={errors[index]?.issue_date && Boolean(touched[index]?.issue_date)}
                />
                <TextField
                    id={`[${index}].original_date`}
                    name={`[${index}].original_date`}
                    label="Дата предоставления оригинала"
                    value={value.original_date}
                    disabled={!value.can_edit_and_delete}
                    onChange={handleChange}
                    sx={{ width: isSmallScreen ? "100%" : "49%" }}
                    type="date"
                    error={errors[index]?.original_date && Boolean(touched[index]?.original_date)}
                />
            </Box>
        </React.Fragment>)}
        <Button
            fullWidth
            onClick={() => setValues([...values, exemptionInitialValues])}
            sx={{ mb: 1, mt: 1 }}
        >
            <Add color="primary" />
        </Button>
        <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            sx={{ mt: 1 }}
        >
            Сохранить
        </Button>
    </>

}

export default ExemptionsInfo