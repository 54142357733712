import { useDispatch, useSelector } from "react-redux"
import React from "react"
import { getUniversities, universitySelectors } from "../redux/dicts/universitiesSlice"



const useUniversities = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(getUniversities())
    }, [dispatch])

    const universities = useSelector(universitySelectors.selectAll)
    const universityOptions = useSelector(universitySelectors.selectIds)
    const universityLookups = useSelector(universitySelectors.selectEntities)
    const universitiesIsLoading = useSelector(x => x.dicts.universities.isLoading)

    return { universities, universityOptions, universityLookups, universitiesIsLoading }
}

export default useUniversities