import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getRegions, regionSelectors } from "../../../redux/dicts/regionsSlice"


const useRegions = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(getRegions())
    }, [dispatch])

    const regions = useSelector(regionSelectors.selectAll)
    const regionOptions = useSelector(regionSelectors.selectIds)
    const regionLookups = useSelector(regionSelectors.selectEntities)
    const regionsIsLoading = useSelector(x => x.dicts.regions.isLoading)

    return { regions, regionOptions, regionLookups, regionsIsLoading }
}

export default useRegions