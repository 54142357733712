import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from "@mui/material"
import { dialogModes } from "../../constants"
import { useFormik } from "formik"
import * as  yup from "yup"

const getTitle = mode => {
    switch (mode) {
        case dialogModes.CREATE:
            return "Создание экзаменационной ведомости"
        case dialogModes.UPDATE:
            return "Редактирование экзаменационной ведомости"
        default: return "Просмотр экзаменационной ведомости"
    }
}

const ScorecardForm = ({
    data, dialogMode, open, onClose, onSave,
    specLookups, specsIsLoading,
    subspecOptions, subspecLookups, subspecsIsLoading,
    universityLookups, universitiesIsLoading,
    subjectOptions, subjectLookups, subjectsIsLoading,
    allowedUniversities
}) => {

    const { values, errors, touched, setFieldValue, handleChange, handleSubmit } = useFormik({
        initialValues: data,
        enableReinitialize: true,
        validationSchema: yup.object({
            is_add_entrants: yup.boolean().required(),
            test_date: yup.date().required(),
            university: yup.number().required(),
            subject: yup.number().required(),
            subspec: yup.number().nullable(),
        }),
        onSubmit: onSave
    })

    if (!open || !values) return null

    return <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>

        <DialogTitle>
            {getTitle(dialogMode)}
        </DialogTitle>

        <DialogContent>
            <Autocomplete
                value={values.university}
                options={allowedUniversities}
                getOptionLabel={option => universityLookups[option] ?? ""}
                onChange={(_, value) => setFieldValue("university", value)}
                loading={universitiesIsLoading}
                renderInput={props => <TextField
                    label="Университет"
                    required
                    error={touched.university && Boolean(errors.university)}
                    {...props}
                />}
            />

            <Autocomplete
                value={values.subject}
                options={subjectOptions}
                getOptionLabel={option => subjectLookups[option] ?? ""}
                onChange={(_, value) => setFieldValue("subject", value)}
                loading={subjectsIsLoading}
                renderInput={props => <TextField
                    label="Предмет"
                    required
                    error={touched.subject && Boolean(errors.subject)}
                    {...props}
                />}
            />

            <TextField
                id="test_date"
                name="test_date"
                value={values.test_date}
                type="date"
                onChange={handleChange}
                label="Дата проведения"
                error={touched.test_date && Boolean(errors.test_date)}
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
            />

            <FormControlLabel
                label="Автоматически добавить в ведомость записавшихся абитуриентов"
                control={<Checkbox
                    checked={values.is_add_entrants}
                    onChange={(_, value) => setFieldValue("is_add_entrants", value)}
                />}
            />

            <Autocomplete
                value={values.subspec}
                options={subspecOptions}
                getOptionLabel={option => {
                    const subspec = subspecLookups[option]
                    const spec = specLookups[subspec.spec]
                    if (!subspec || !spec) {
                        return ""
                    }
                    return `${spec.code} - ${spec.name}, профиль ${subspec.name}`
                }}
                onChange={(_, value) => setFieldValue("subspec", value)}
                loading={specsIsLoading || subspecsIsLoading}
                renderInput={props => <TextField
                    label="Профиль"
                    error={touched.subspec && Boolean(errors.subspec)}
                    {...props}
                />}
            />
        </DialogContent>

        <DialogActions>
            <Button variant="contained" onClick={handleSubmit}>
                Сохранить
            </Button>
            <Button variant="contained" color="error" onClick={onClose}>
                Закрыть
            </Button>
        </DialogActions>

    </Dialog>
}

export default ScorecardForm