import { Button, Link, Paper, Typography } from "@mui/material"
import React from "react"


const CookiesNotification = () => {

    const [isNotified, setIsNotified] = React.useState(localStorage.getItem("cookies_agreement") ?? false)

    return isNotified ? null : (
        <Paper sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            p: 2,
            display: "flex",
            flexDirection: "column",
            zIndex: 1300,
            borderTop: 1,
            borderRadius: 0,
            borderColor: "background.default"
        }}>
            <Typography align="center">
                Данный сайт использует файлы cookies.
                Продолжая работу в системе, вы подтверждаете, что даёте согласие на их использование.
                Вы можете отключить использование файлов cookies в настройках Вашего браузера. <Link href="https://www.bstu.ru/pd">
                    Узнать больше
                </Link>
            </Typography>
            <Button
                sx={{ alignSelf: "center", width: 250, mt: 1 }}
                variant="contained"
                onClick={() => {
                    localStorage.setItem("cookies_agreement", true)
                    setIsNotified(true)
                }}
            >
                Ок
            </Button>
        </Paper>
    )
}

export default CookiesNotification