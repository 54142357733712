import { createSlice } from "@reduxjs/toolkit";
import { entrantFormService } from "../../services/entrantService";
import { createCustomAsyncThunk } from "../utils";

export const getAchievementsInfo = createCustomAsyncThunk(
    "entrantForm/achievementsInfo/get",
    async userId => await entrantFormService.getAchievements(userId)
)

export const updateAchievementsInfo = createCustomAsyncThunk(
    "entrantForm/achievementsInfo/update",
    async data => {
        const requests = []
        data.created.forEach(el => requests.push(entrantFormService.postAchievement(el)))
        data.updated.forEach(el => requests.push(entrantFormService.patchAchievement(el)))
        data.deleted.forEach(el => requests.push(entrantFormService.deleteAchievement(el)))
        return Promise.all(requests).then(() => entrantFormService.getAchievements(data.user))
    }
)

const achievementsInfoSlice = createSlice({
    name: "entrantForm/achievementsInfo",
    initialState: {
        data: [],
        isLoading: true,
        error: null,
    },
    extraReducers: builder => {
        builder
            .addCase(getAchievementsInfo.pending, state => { state.isLoading = true })
            .addCase(getAchievementsInfo.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(getAchievementsInfo.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
        
            .addCase(updateAchievementsInfo.pending, state => { state.isLoading = true })
            .addCase(updateAchievementsInfo.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false
            })
            .addCase(updateAchievementsInfo.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export default achievementsInfoSlice.reducer