import { Done } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { SmartCaptcha } from '@yandex/smart-captcha';
import { useFormik } from "formik";
import React from "react";
import useCountDown from "react-countdown-hook";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from 'yup';
import Info from "../../components/Icons/Info";
import VisibleOff from "../../components/Icons/VisibleOff";
import VisibleOn from "../../components/Icons/VisibleOn";
import PhoneField from "../../components/PhoneField";
import routes from "../../components/routes";
import Spinner from "../../components/Spinner";
import toastWrapper from "../../components/toastWrapper";
import { dialogTypes } from "../../constants";
import { closeDialogAndResetState, setAllDialogStates } from "../../redux/uiSlice";
import { confirmPhone, register, sendConfirmPhoneMessage } from "../../redux/userSlice";
import ConfirmationDialog from "./ConfirmationDialog";
import PhoneConfirmationDialog from "./PhoneConfirmationDialog";

const RegistrationPage = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const sessionToken = useSelector(x => x.user.sessionToken)

    const { handleSubmit, handleChange, values, setFieldValue, errors, touched } = useFormik({
        initialValues: {
            surname: '',
            name: '',
            patronymic: '',
            email: '',
            phone: '',
            // confirmedPhone: '',
            captchaToken: '',
            password: '',
            repeatPassword: '',
            personalDataConfirmation: false,
        },
        validationSchema: yup.object({
            surname: yup.string().required().matches(/^[\u0400-\u0484\u0487-\u052F\u1C80-\u1C88\u1D2B\u1D78\u2DE0-\u2DFF\uA640-\uA69F\uFE2E\uFE2F\s]*$/),
            name: yup.string().required().matches(/^[\u0400-\u0484\u0487-\u052F\u1C80-\u1C88\u1D2B\u1D78\u2DE0-\u2DFF\uA640-\uA69F\uFE2E\uFE2F\s]*$/),
            patronymic: yup.string().matches(/^[\u0400-\u0484\u0487-\u052F\u1C80-\u1C88\u1D2B\u1D78\u2DE0-\u2DFF\uA640-\uA69F\uFE2E\uFE2F\s]*$/),
            email: yup.string().required()
                //eslint-disable-next-line
                .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
            //eslint-disable-next-line
            phone: yup.string().required().matches(/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/),
            // confirmedPhone: yup.string().required().oneOf([yup.ref('phone'), null], 'Телефоны должны совпадать'),
            captchaToken: yup.string().required(),
            //eslint-disable-next-line
            password: yup.string().required().matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/),
            repeatPassword: yup.string().required('Это поле необходимо заполнить').oneOf([yup.ref('password'), null], 'Пароли должны совпадать'),
            personalDataConfirmation: yup.bool().oneOf([true])
        }),
        initialErrors: {
            password: 'Это поле необходимо заполнить',
            repeatPassword: 'Это поле необходимо заполнить',
            phone: 'Это поле необходимо заполнить',
        },
        // initialTouched: { confirmedPhone: false },
        onSubmit: values => {
            dispatch(register(values)).unwrap()
                .then(() => navigate(routes.profile.path))
                .catch(e => {
                    console.error(e)
                    toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось зарегистрироваться")
                })
        },
    })


    const [timeLeft, { start, reset }] = useCountDown(2 * 60 * 1000, 1000);

    const { dialogType, dialogIsOpen } = useSelector(x => x.ui)

    const confirmationDialogIsOpen = dialogIsOpen && dialogType === dialogTypes.PERSONAL_DATA_CONFIRMATION
    const phoneDialogIsOpen = dialogIsOpen && dialogType === dialogTypes.PHONE_CONFIRMATION

    const handleCloseDialog = () => { dispatch(closeDialogAndResetState()) }

    const handleSendConfirmPhoneMessage = () => dispatch(sendConfirmPhoneMessage({ phone_number: values.phone })).unwrap()

    const handleConfirmPhoneIntent = () => {
        handleSendConfirmPhoneMessage().then(() => {
            start()
            dispatch(setAllDialogStates({ dialogType: dialogTypes.PHONE_CONFIRMATION, dialogData: values.phone }))
        }).catch(e => {
            console.error(e)
            toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось отправить сообщение")
        })
    }

    const handleConfirmPhone = code => {
        dispatch(confirmPhone({
            phone_number: values.phone,
            session_token: sessionToken,
            security_code: code
        })).unwrap().then(() => {
            handleCloseDialog()
            toastWrapper("Телефон подтвержден", "success")
            reset()
            setFieldValue("confirmedPhone", values.phone)
        }).catch(e => {
            console.error(e)
            toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Неверный код подтверждения")
        })
    }

    const handleConfirmPersonalDataIntent = () => {
        dispatch(setAllDialogStates({ dialogType: dialogTypes.PERSONAL_DATA_CONFIRMATION }))
    }

    const handleConfirmPersonalData = () => {
        handleCloseDialog()
        setFieldValue("personalDataConfirmation", true)
    }

    const userInfoIsLoading = useSelector(x => x.user.isLoading)

    const [showPassword, setShowPassword] = React.useState(false)

    const [showHint, setShowHint] = React.useState(false)

    if (userInfoIsLoading) return <Spinner height="100vh" />

    return <Box sx={{
        m: "auto",
        height: "100%",
        maxWidth: "480px",
    }}>

        <Box sx={{
            minHeight: 72,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1,
            mb: 1
        }}>
            <img
                src={`${process.env.PUBLIC_URL}/icons/logoWithText.svg`}
                alt="БГТУ"
                style={{ height: "40px" }}
            />
            <Typography sx={{ pl: 2 }} color="font.main" variant="font2">
                Личный кабинет абитуриента
            </Typography>
        </Box>

        <Paper sx={{ p: 4, pb: 3, mb: 1 }} component="form">

            <Typography variant="font2" sx={{ color: "font.main" }}>
                Регистрация
            </Typography>

            <TextField
                id="surname"
                name="surname"
                autoFocus
                required
                placeholder="Фамилия"
                value={values.surname}
                onChange={handleChange}
                error={touched.surname && Boolean(errors.surname)}
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
                margin="normal"
            />

            <TextField
                id="name"
                name="name"
                required
                placeholder="Имя"
                value={values.name}
                onChange={handleChange}
                error={touched.name && Boolean(errors.name)}
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
                margin="normal"
            />

            <TextField
                id="patronymic"
                name="patronymic"
                placeholder="Отчество (при наличии)"
                value={values.patronymic}
                onChange={handleChange}
                error={touched.patronymic && Boolean(errors.patronymic)}
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
                margin="normal"
            />

            <TextField
                id="email"
                name="email"
                autoComplete="email"
                required
                placeholder="Эл. почта"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
                margin="normal"
            />

            <TextField
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="password"
                required
                placeholder="Пароль"
                value={values.password}
                onChange={handleChange}
                error={touched.password && Boolean(errors.password)}
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
                margin="normal"
                InputProps={{
                    endAdornment: <InputAdornment position="end">

                        <Tooltip
                            title="Пароль должен содержать не менее шести знаков, включать цифры и латинские буквы в разных регистрах"
                            open={showHint}
                        >
                            <IconButton onClick={() => setShowHint(!showHint)} >
                                <Info fontSize="small" color={showHint ? "primary" : undefined} />
                            </IconButton>
                        </Tooltip>

                        <IconButton
                            aria-label="Показать пароль"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end"
                        >
                            {showPassword ? <VisibleOff fontSize="small" /> : <VisibleOn fontSize="small" />}
                        </IconButton>

                    </InputAdornment>
                }}
            />

            <TextField
                id="repeatPassword"
                name="repeatPassword"
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                required
                placeholder="Повторите пароль"
                value={values.repeatPassword}
                onChange={handleChange}
                error={touched.repeatPassword && Boolean(errors.repeatPassword)}
                helperText={touched.repeatPassword && errors.repeatPassword}
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
                margin="normal"
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="Показать пароль"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end"
                        >
                            {showPassword ? <VisibleOff fontSize="small" /> : <VisibleOn fontSize="small" />}
                        </IconButton>
                    </InputAdornment>
                }}
            />

            <PhoneField
                id="phone"
                name="phone"
                autoComplete="phone"
                required
                placeholder="Номер телефона"
                value={values.phone}
                onChange={value => setFieldValue('phone', value)}
                error={(touched.phone && Boolean(errors.phone)) || (touched.confirmedPhone && Boolean(errors.confirmedPhone))}
                sx={{ "& .MuiInputBase-root": { height: 48 }, "& .MuiInputBase-input": { height: 30 } }}
                margin="normal"
                InputProps={Boolean(errors.phone) || Boolean(errors.confirmedPhone) ? null : {
                    endAdornment: (
                        <InputAdornment position="end">
                            <Done color="primary" />
                        </InputAdornment>
                    )
                }}
            />

            {/* <Tooltip title={Boolean(errors.phone) ? "Введите корректный номер телефона" : ""}><span>
                <Button
                    variant="contained"
                    fullWidth
                    sx={{ mt: 1, mb: 1 }}
                    onClick={handleConfirmPhoneIntent}
                    disabled={
                        values.phone === values.confirmedPhone || Boolean(errors.phone) || timeLeft > 0 ||
                        values.name.length === 0 || values.surname.length === 0 || values.email.length === 0
                    }
                >
                    {`Подтвердить номер телефона ${timeLeft > 0 ? transformTimeToString(timeLeft) : ''}`}
                </Button>
            </span></Tooltip> */}

            <FormControl
                required
                error={touched.personalDataConfirmation && Boolean(errors.personalDataConfirmation)}
                color="error"
            >
                <FormControlLabel
                    control={<Checkbox
                        checked={values.personalDataConfirmation}
                        onChange={e => e.target.checked ?
                            handleConfirmPersonalDataIntent() : setFieldValue("personalDataConfirmation", false)
                        }
                    />}
                    label={<>
                        <Typography variant="font3" component="span">
                            {"Настоящим подтверждаю, что я ознакомлен с условиями "}
                        </Typography>
                        <Typography variant="font3" color="primary" component="span">
                            Политики обработки персональных данных и даю согласие на обработку персональных данных
                        </Typography>
                    </>}
                />
            </FormControl>

            <Box py={1}>
                <SmartCaptcha
                    sitekey="ysc1_wEMSwkCN7Bw1xHB18JfrPkjy9Wmx4ZfYs1SWkPaca28ec191"
                    onSuccess={value => {
                        console.log(value)
                        setFieldValue("captchaToken", value)
                    }}
                />
            </Box>
        </Paper >

        <Button
            sx={{ mt: 1, mb: 1 }}
            fullWidth
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            disabled={!values.personalDataConfirmation || !values.captchaToken}
        >
            Зарегистрироваться
        </Button>

        <Typography align="center" variant="subtitle1">
            <Link to={routes.login.path}>
                Уже есть аккаунт? Авторизация
            </Link>
        </Typography>

        <ConfirmationDialog
            open={confirmationDialogIsOpen}
            onOk={handleConfirmPersonalData}
            onClose={handleCloseDialog}
        />

        <PhoneConfirmationDialog
            timeLeft={timeLeft}
            start={start}
            reset={reset}
            open={phoneDialogIsOpen}
            phone={values.phone}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmPhone}
            onResend={handleSendConfirmPhoneMessage}
        />

    </Box>
}

export default RegistrationPage