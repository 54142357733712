import { Box, Button, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import * as yup from "yup"
import Spinner from "../../../components/Spinner"
import useIsSmallScreen from "../../../hooks/useIsSmallScreen"
import { createDirectionEduMinistryInfo, getDirectionEduMinistryInfo, updateDirectionEduMinistryInfo } from "../../../redux/entrantForm/directionEduMinistrySlice"
import toastWrapper from "../../../components/toastWrapper"

const DirectionEduMinistry = ({ userInfo }) => {

    const { data, isLoading } = useSelector(x => x.entrantForm.directionEduMinistry)

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (userInfo?.id) dispatch(getDirectionEduMinistryInfo(userInfo.id))
    }, [dispatch, userInfo])

    const { touched, errors, handleSubmit, handleChange, values } = useFormik({
        enableReinitialize: true,
        initialValues: data,
        onSubmit: values => {
            const newValues = { ...values, user: userInfo.id }
            dispatch(values.id === null ? createDirectionEduMinistryInfo(newValues) : updateDirectionEduMinistryInfo(newValues)).unwrap()
            .then(() => { toastWrapper("Изменения сохранены", "success") })
            .catch(e => {
                console.error(e)
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
            })
        },
        validationSchema: yup.object({
            series: yup.string(),
            number: yup.string().required(),
            issue_date: yup.date().required(),
            issue_dep: yup.string().required()
        })
    })

    const isSmallScreen = useIsSmallScreen()

    const componentHeight = isSmallScreen ? 310 : 190

    if (isLoading) return <Spinner height={componentHeight} />

    return <Box sx={{ display: "flex", flexDirection: "column", height: componentHeight }}>
        <Typography sx={{ pt: 1 }} variant="subtitle2">
            Заполняется поступающими сверх контрольных цифр приема
        </Typography>

        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextField
                id="series"
                name="series"
                value={values.series}
                onChange={handleChange}
                label="Серия документа"
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                error={touched.series && Boolean(errors.series)}
            />
            <TextField
                id="number"
                name="number"
                value={values.number}
                onChange={handleChange}
                label="Номер документа"
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                error={touched.number && Boolean(errors.number)}
                required
            />
        </Box>
        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextField
                id="issue_date"
                name="issue_date"
                value={values.issue_date}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                label="Дата выдачи"
                type="date"
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                error={touched.issue_date && Boolean(errors.issue_date)}
                required
            />
            <TextField
                id="issue_dep"
                name="issue_dep"
                value={values.issue_dep}
                onChange={handleChange}
                label="Кем выдан"
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                error={touched.issue_dep && Boolean(errors.issue_dep)}
                required
            />
        </Box>
        <Button
            disabled={!values.can_edit_and_delete}
            sx={{ mt: 1 }}
            variant="contained"
            onClick={handleSubmit}
            fullWidth
        >
            Сохранить
        </Button>
    </Box >
}

export default DirectionEduMinistry