import { HelpOutlineOutlined } from "@mui/icons-material"
import { Box, Checkbox, FormControlLabel, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import React from "react"
import { useParams } from "react-router-dom"
import Spinner from "../../components/Spinner"
import useRatingCurrentCG from "./useRatingCurrentCG"
import useRatings from "./useRatings"
import { useDispatch } from "react-redux"
import { setRatingParams } from "../../redux/ratingsSlice"

const RatingList = ({ type }) => {

    const { id, cgId } = useParams()

    const { currentCompetitiveGroup, currentCompetitiveGroupIsLoading } = useRatingCurrentCG(cgId)

    const isSpo = currentCompetitiveGroup?.level === 3

    const { ratings, ratingsIsLoading, params } = useRatings(currentCompetitiveGroup, id, type)

    const isLoading = currentCompetitiveGroupIsLoading || ratingsIsLoading

    const isBudget = currentCompetitiveGroup?.edu_type !== 2

    const dispatch = useDispatch()

    const setParams = params => dispatch(setRatingParams(params))

    const isStatic = type === "static"

    return <Box sx={{ display: "flex", flexDirection: "column", width: "100%", margin: "auto", p: 1 }}>

        <Box sx={{ width: "calc(100% - 24px)", minWidth: 1500 }}>
            <Box sx={{
                m: 1,
                borderRadius: 1,
                width: "100%",
                display: "flex",
                backgroundColor: "secondary.main",
            }}>
                <Typography sx={{ ml: 2, p: 2 }} variant="font2" color="white">
                    {isLoading ? "Загрузка..." : currentCompetitiveGroup?.name ?? ""}
                </Typography>
            </Box>

            {isStatic && <Paper sx={{ m: 1, p: 1, pl: 2, width: "100%" }}>
                <FormControlLabel
                    control={<Checkbox
                        disabled={ratingsIsLoading}
                        checked={params.is_original}
                        onChange={(_, value) => setParams({ ...params, is_original: value })}
                    />}
                    label="Только с оригиналами"
                />
                <FormControlLabel
                    control={<Checkbox
                        disabled={ratingsIsLoading}
                        checked={params.is_max_priority}
                        onChange={(_, value) => setParams({ ...params, is_max_priority: value })}
                    />}
                    label="Только с наивысшим приоритетом"
                />
            </Paper>}

            {isLoading ? <Spinner height={280} /> : <Table
                stickyHeader
                sx={{
                    m: 1,
                    height: "100%",
                    overflow: "auto",
                    width: "100%",
                    backgroundColor: "#fff",
                    boxShadow: "0 0 2px #C4C4C4",
                    borderRadius: "4px"
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell aling="center">№</TableCell>
                        <TableCell aling="center">UID</TableCell>
                        <TableCell aling="center">СНИЛС</TableCell>
                        <TableCell aling="center">ФИО</TableCell>
                        {!isSpo && <TableCell aling="center">Баллы по ВИ</TableCell>}
                        {isSpo && <TableCell aling="center">Средний балл аттестата</TableCell>}
                        <TableCell aling="center">Достижения</TableCell>
                        {!isSpo && <TableCell aling="center">Всего баллов</TableCell>}
                        {!isSpo && <TableCell aling="center">Максимальный приоритет</TableCell>}
                        <TableCell aling="center">Статус</TableCell>
                        {!isSpo && <TableCell aling="center">Приоритет</TableCell>}
                        <TableCell aling="center">{isBudget ? "Оригинал" : "Согласие"}</TableCell>
                        {isSpo && <TableCell aling="center">Зачисление в первоочередном порядке</TableCell>}
                        <TableCell aling="center">Комментарий</TableCell>
                        {isStatic && <TableCell aling="center">Зачислен</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ratings.map((rating, index) => <TableRow
                        key={index}
                        sx={{ borderBottom: index + 1 === currentCompetitiveGroup.count_place ? "2px solid" : undefined }}
                    >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{rating.user_uid}</TableCell>
                        <TableCell>{rating.snils}</TableCell>
                        <TableCell>{rating.user_fio}</TableCell>
                        {isSpo ? (
                            <TableCell align="center">{rating.diploma_average_mark_ball}</TableCell>
                        ) : (
                            <TableCell align="center">
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Typography>
                                        {rating.total_exams_ball}
                                    </Typography>
                                    {rating.total_exams_ball > 0 && <Tooltip
                                        title={<Box>
                                            {rating.entrance_exams.map((exam, examIndex) => <Typography key={examIndex}>
                                                {exam.subject_name}: {exam.ball} ({exam.type})
                                            </Typography>)}
                                        </Box>}
                                    >
                                        <HelpOutlineOutlined sx={{ ml: 1 }} fontSize="small" color="primary" />
                                    </Tooltip>}
                                </Box>
                            </TableCell>
                        )}
                        <TableCell align="center">{rating.achievement}</TableCell>
                        {!isSpo && <TableCell align="center">{rating.total_ball}</TableCell>}
                        {!isSpo && <TableCell align="center">{rating.is_max_priority ? "+" : "-"}</TableCell>}
                        <TableCell>{rating.status}</TableCell>
                        {!isSpo && <TableCell align="center">{rating.priority}</TableCell>}
                        <TableCell>{isBudget ? rating.is_original ? "Подан" : "Не подан" : rating.is_confirmation ? "Подано" : "Не подано"}</TableCell>
                        {isSpo && <TableCell>{rating.is_parent_svo_member ? "Да" : "Нет"}</TableCell>}
                        <TableCell>{rating.comment}</TableCell>
                        {isStatic && <TableCell>{rating.enroll_competitive_group}</TableCell>}
                    </TableRow>)}
                </TableBody>
            </Table>}
        </Box>
    </Box>
}

export default RatingList