import { ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import Spinner from "../../components/Spinner"
import { applicationStatusLookups } from "../../constants"
import useEntrantNotifications from "./useEntrantNotifications"


const EntrantNotifications = () => {

    const userInfo = useSelector(x => x.user.displayedUserInfo)

    const {
        notifications, notificationsIsLoading,
        noticeTypeLookups, noticeTypesIsLoading
    } = useEntrantNotifications(userInfo)

    const filteredNotifications = React.useMemo(() => notifications.filter(el => el.status !== 1), [notifications])

    const isLoading = notificationsIsLoading || noticeTypesIsLoading

    const hasAdditionalInfo = n => n.notice_type !== null || (n.comment && n.comment !== "")

    return <Box sx={{ display: "flex", flexDirection: "column" }}>

        {isLoading ? <Spinner height="80vh" /> : filteredNotifications.length !== 0  && filteredNotifications.map(n => <Accordion
            disableGutters
            key={n.id}
        >
            <AccordionSummary expandIcon={hasAdditionalInfo(n) ? <ExpandMore /> : undefined}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>{n.spec_name}</Typography>
                    <Typography>Заявление {n.app_name}</Typography>
                    <Typography variant="subtitle2" color="grey">Статус изменен на "{applicationStatusLookups[n.status]}"</Typography>
                </Box>
            </AccordionSummary>
            {hasAdditionalInfo(n) && <AccordionDetails sx={{ pt: 0 }}>
                {n.notice_type && <Box sx={{ mt: 1 }}>
                    <Typography variant="subtitle2">
                        Причина
                    </Typography>
                    <Typography variant="subtitle2" color="grey">
                        {noticeTypeLookups[n.notice_type]?.name ?? ""}
                    </Typography>
                </Box>}
                {n.comment && n.comment !== "" && <Box sx={{ mt: 1 }}>
                    <Typography variant="subtitle2">
                        Комментарий отборочной комиссии
                    </Typography>
                    <Typography variant="subtitle2" color="grey">
                        {n.comment}
                    </Typography>
                </Box>}
            </AccordionDetails>}
        </Accordion>)}
    </Box>
}
export default EntrantNotifications