import { Box, Button, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import * as yup from "yup"
import CustomDadataSuggestions from "../../../components/DadataSuggestions/CustomDadataSuggestions"
import PhoneField from "../../../components/PhoneField"
import Spinner from "../../../components/Spinner"
import useIsSmallScreen from "../../../hooks/useIsSmallScreen"
import { createTargetContractInfo, getTargetContractInfo, updateTargetContractInfo } from "../../../redux/entrantForm/targetContrantInfoSlice"
import toastWrapper from "../../../components/toastWrapper"

const TargetContractInfo = ({ userInfo }) => {

    const { data, isLoading } = useSelector(x => x.entrantForm.targetContract)

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (userInfo?.id) dispatch(getTargetContractInfo(userInfo.id))
    }, [dispatch, userInfo])


    const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
        initialValues: data,
        enableReinitialize: true,
        validationSchema: yup.object({
            organization: yup.object({
                name: yup.string().required(),
                sname: yup.string(),
                ogrn: yup.string().required(),
                kpp: yup.string().required(),
                inn: yup.string().required(),
                address: yup.string(),
                phone: yup.string(),
                email: yup.string(),
                head_name: yup.string(),
            }),
            number: yup.string(),
            date: yup.date().required(),
        }),
        onSubmit: values => {
            const newValues = { ...values, user: userInfo.id }
            dispatch(values.id ? updateTargetContractInfo(newValues) : createTargetContractInfo(newValues)).unwrap()
                .then(() => { toastWrapper("Изменения сохранены", "success") })
                .catch(e => {
                    console.error(e)
                    toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
                })
        }
    })

    const isSmallScreen = useIsSmallScreen()

    const componentHeight = isSmallScreen ? 770 : 460

    if (isLoading) return <Spinner height={componentHeight} />

    return <Box sx={{ height: componentHeight }}>
        <Typography sx={{ pt: 1 }} variant="body1">
            Информация об организации, предоставляющей право на целевое обучение
        </Typography>
        <CustomDadataSuggestions
            id="org_party"
            name="org_party"
            label="Поиск организации"
            disabled={!values.can_edit_and_delete}
            sx={{ mb: isSmallScreen ? 4 : 3 }}
            fullWidth
            type="party"
            onChange={value => {
                setFieldValue("organization.name", value.value ?? "")
                setFieldValue("organization.sname", value.data?.name?.short ?? value.value)
                setFieldValue("organization.head_name", value.data?.management?.name ?? "")
                setFieldValue("organization.ogrn", value.data?.ogrn ?? "")
                setFieldValue("organization.kpp", value.data?.kpp ?? "")
                setFieldValue("organization.inn", value.data?.inn ?? "")
                setFieldValue("organization.address", value.data?.address?.value ?? "")
            }}
            required
            helperText="Для поиска организации введите ее название"
        />
        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextField
                id="organization.name"
                name="organization.name"
                label="Сокращенное название"
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                disabled={!values.can_edit_and_delete}
                value={values.organization?.name}
                error={errors.organization?.name && Boolean(touched.organization?.name)}
                onChange={handleChange}
            />
            <TextField
                id="organization.sname"
                name="organization.sname"
                label="Сокращенное название"
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                disabled={!values.can_edit_and_delete}
                value={values.organization?.sname}
                error={errors.organization?.sname && Boolean(touched.organization?.sname)}
                onChange={handleChange}
            />
        </Box>
        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between", mt: isSmallScreen ? 0 : 1 }}>
            <TextField
                id="organization.head_name"
                name="organization.head_name"
                label="ФИО главы организации"
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                disabled={!values.can_edit_and_delete}
                value={values.organization.head_name}
                error={errors.organization?.head_name && Boolean(touched.organization?.head_name)}
                onChange={handleChange}
            />
            <TextField
                id="organization.ogrn"
                name="organization.ogrn"
                label="ОГРН"
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                disabled={!values.can_edit_and_delete}
                value={values.organization?.ogrn}
                error={errors.organization?.ogrn && Boolean(touched.organization?.ogrn)}
                required
                onChange={handleChange}
            />
        </Box>
        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextField
                id="organization.kpp"
                name="organization.kpp"
                label="КПП"
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                disabled={!values.can_edit_and_delete}
                value={values.organization?.kpp}
                error={errors.organization?.kpp && Boolean(touched.organization?.kpp)}
                required
                onChange={handleChange}
            />
            <TextField
                id="organization.inn"
                name="organization.inn"
                label="ИНН"
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                disabled={!values.can_edit_and_delete}
                value={values.organization?.inn}
                error={errors.organization?.inn && Boolean(touched.organization?.inn)}
                required
                onChange={handleChange}
            />
        </Box>
        <TextField
            id="organization.address"
            name="organization.address"
            label="Адрес"
            disabled={!values.can_edit_and_delete}
            value={values.organization?.address}
            error={errors.organization?.address && Boolean(touched.organization?.address)}
            required
            fullWidth
            onChange={handleChange}
        />
        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <PhoneField
                id="organization.phone"
                name="organization.phone"
                label="Номер телефона"
                disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                value={values.organization.phone}
                error={errors?.organization?.phone && Boolean(touched?.organization?.phone)}
                onChange={value => setFieldValue("organization.phone", value)}
            />
            <TextField
                id="organization.email"
                name="organization.email"
                label="Адрес эл. почты"
                disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                value={values.organization.email}
                error={errors?.organization?.email && Boolean(touched?.organization?.email)}
                onChange={e => setFieldValue("organization.email", e.target.value)}
            />
        </Box>
        <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextField
                id="number"
                name="number"
                label="№ договора о целевом обучении"
                disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                value={values.number}
                error={errors.number && Boolean(touched.number)}
                onChange={handleChange}
            />
            <TextField
                id="date"
                name="date"
                label="Дата подписания договора"
                type="date"
                disabled={!values.can_edit_and_delete}
                sx={{ width: isSmallScreen ? "100%" : "49%" }}
                value={values.date}
                error={errors.date && Boolean(touched.date)}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                required
            />
        </Box>
        <Button
            disabled={!values.can_edit_and_delete}
            variant="contained"
            fullWidth
            sx={{ mt: 1 }}
            onClick={handleSubmit}
        >
            Сохранить
        </Button>
    </Box>
}

export default TargetContractInfo