import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPersonalDocTypes, personalDocTypeSelectors } from "../../../redux/dicts/personalDocTypesSlice"

const usePersonalDocTypes = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (personalDocTypeOptions.length === 0) { dispatch(getPersonalDocTypes()) }
        //eslint-disable-next-line
    }, [dispatch])

    const personalDocTypeOptions = useSelector(personalDocTypeSelectors.selectIds)
    const personalDocTypeLookups = useSelector(personalDocTypeSelectors.selectEntities)
    const personalDocTypesIsLoading = useSelector(x => x.dicts.personalDocTypes.isLoading)

    return { personalDocTypeOptions, personalDocTypeLookups, personalDocTypesIsLoading }
}

export default usePersonalDocTypes