import { Menu, MenuItem } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import FileDownloadButton from "../../components/FileDownloadButton"
import MaterialTableWrapper from "../../components/MaterialTableWrapper"
import { dialogModes, dialogTypes } from "../../constants"
import useDialogProps from "../../hooks/useDialogProps"
import useMenu from "../../hooks/useMenu"
import { apiUrl } from "../../services/api"
import UserForm from "./UserForm"
import useUserListActionHandlers from "./useUserListActionHandlers"
import useUserListTableProps from "./useUserListTableProps"

const UserListPage = () => {

    const tableRef = React.useRef(null)

    const { displayedUserInfo } = useSelector(x => x.user)

    const { anchorEl, open, handleDefaultMenuClick, handleMenuClose } = useMenu()

    const { dialogData, dialogMode, dialogType, dialogIsOpen, openDialog, closeDialog } = useDialogProps()

    const {
        handleOpenUserMenu, handleCloseUserMenu,
        handleCreateUserIntent, handleCreateUser, handleUpdateUser,
        handleCloseUserForm, 
        actionHandlersMap, actionNamesMap,
    } = useUserListActionHandlers(
        tableRef, displayedUserInfo,
        handleDefaultMenuClick, handleMenuClose,
        openDialog, closeDialog,
    )

    const tableProps = useUserListTableProps(handleOpenUserMenu, handleCreateUserIntent)

    const userFormIsOpen = dialogIsOpen && dialogType === dialogTypes.USER_FORM

    return <>

        <MaterialTableWrapper
            tableRef={tableRef}
            {...tableProps}
        />

        <Menu
            id="user-actions"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseUserMenu}
        >
            {displayedUserInfo?.available_actions?.map((action) => action === "download_voucher" ? (
                <FileDownloadButton
                    key={action}
                    component="menuItem"
                    url={`${apiUrl}/entrant/voucher_download/${displayedUserInfo?.id}/`}
                    name={actionNamesMap["download_voucher"]}
                />) : (
                <MenuItem
                    key={action}
                    onClick={actionHandlersMap?.[action] ?? null}
                    disabled={!actionHandlersMap?.[action]}
                >
                    {actionNamesMap[action] ?? ""}
                </MenuItem>
            ))}
        </Menu>

        <UserForm
            data={dialogData}
            open={userFormIsOpen}
            onClose={handleCloseUserForm}
            dialogMode={dialogMode}
            onSave={dialogMode === dialogModes.CREATE ? handleCreateUser : handleUpdateUser}
        />

    </>
}

export default UserListPage