import { useDispatch } from "react-redux"
import React from "react"
import { entrantStatusHistorySelectors, getEntrantNotifications } from "../../redux/entrantNotificationsSlice"
import { useSelector } from "react-redux"
import { getNoticeTypes, noticeTypeSelectors } from "../../redux/dicts/noticeTypesSlice"

const useEntrantNotifications = (userInfo) => {

    const dispatch = useDispatch()

    React.useEffect(() => { dispatch(getNoticeTypes()) }, [dispatch])

    const noticeTypeLookups = useSelector(noticeTypeSelectors.selectEntities)
    const noticeTypesIsLoading = useSelector(x => x.dicts.noticeTypes.isLoading)

    React.useEffect(() => {
        if (userInfo?.id) dispatch(getEntrantNotifications(userInfo.id))
    }, [userInfo, dispatch])

    const notifications = useSelector(entrantStatusHistorySelectors.selectAll)
    const notificationsIsLoading = useSelector(x => x.entrantNotifications.status.isLoading)

    return { notifications, notificationsIsLoading, noticeTypeLookups, noticeTypesIsLoading }
}

export default useEntrantNotifications