import { createSvgIcon } from "@mui/material";

const Info = createSvgIcon(
    <svg viewBox="-3 -3 22 22" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 4.59375C8.34518 4.59375 8.62499 4.31393 8.62499 3.96875C8.62499 3.62357 8.34518 3.34375 8 3.34375C7.65481 3.34375 7.375 3.62357 7.375 3.96875C7.375 4.31393 7.65481 4.59375 8 4.59375Z" />
        <path d="M13.6569 2.34313C12.1459 0.832156 10.1369 0 8 0C5.86313 0 3.85416 0.832156 2.34313 2.34313C0.832156 3.85416 0 5.86313 0 8C0 10.1369 0.832156 12.1459 2.34313 13.6569C3.85416 15.1678 5.86313 16 8 16C9.46293 16 10.8943 15.6013 12.1394 14.8471C12.4347 14.6682 12.529 14.2839 12.3501 13.9887C12.1713 13.6934 11.787 13.5991 11.4918 13.778C10.442 14.4139 9.23458 14.75 8 14.75C4.27804 14.75 1.25 11.722 1.25 8C1.25 4.27804 4.27804 1.25 8 1.25C11.722 1.25 14.75 4.27804 14.75 8C14.75 9.33093 14.3542 10.6264 13.6054 11.7464C13.4136 12.0333 13.4907 12.4215 13.7776 12.6133C14.0646 12.8052 14.4527 12.7281 14.6445 12.4412C15.5313 11.1148 16 9.57902 16 8C16 5.86313 15.1678 3.85416 13.6569 2.34313Z" />
        <path d="M8 5.84375C7.6548 5.84375 7.375 6.12356 7.375 6.46875V12.0313C7.375 12.3764 7.6548 12.6563 8 12.6563C8.34519 12.6563 8.62499 12.3764 8.62499 12.0313V6.46875C8.62499 6.12356 8.34519 5.84375 8 5.84375Z" />
    </svg>,
    "Info"
)

export default Info