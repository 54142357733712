import React from "react"
import CustomDadataSuggestions from "./CustomDadataSuggestions"
import { IconButton, InputAdornment } from "@mui/material"
import { Edit } from "@mui/icons-material"
import AddressForm from "./AddressForm"


const AddressSuggestions = ({ value, onChange, type, label, required, error, disabled, canAdd = true, ...rest }) => {

    const [editDialogIsOpen, setEditDialogIsOpen] = React.useState(false)

    return <>
        <CustomDadataSuggestions
            value={value}
            onChange={onChange}
            type="address"
            label={label}
            required={required}
            error={error}
            disabled={disabled}
            {...rest}
            InputProps={{
                endAdornment: canAdd && <InputAdornment position="end">
                    <IconButton disabled={disabled} onClick={() => setEditDialogIsOpen(true)} size="small">
                        <Edit color={disabled ? "disabled" : "primary"} fontSize="small" />
                    </IconButton>
                </InputAdornment>
            }}
        />
        <AddressForm
            open={editDialogIsOpen}
            onClose={() => setEditDialogIsOpen(false)}
            data={value}
            onSubmit={onChange}
        />
    </>
}

export default AddressSuggestions