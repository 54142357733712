import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getScorecardResults, scorecardResultsSelector } from "../../../redux/entranceTests/scorecardResultsSlice"

const useScorecardResults = current => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (current) {
            dispatch(getScorecardResults({ scorecard: current.id }))
        }
    }, [dispatch, current])

    const scorecardResults = useSelector(scorecardResultsSelector.selectAll)
    const scorecardResultsIsLoading = useSelector(x => x.entranceTests.scorecardResults.isLoading)

    return { scorecardResults, scorecardResultsIsLoading }
}

export default useScorecardResults