import { createSlice } from "@reduxjs/toolkit";
import { dictService } from "../../services/dictService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getUniversities = createCustomAsyncThunk(
    "dicts/universities/get",
    async () => await dictService.getUniversities()
)

const universitiesSlice = createSlice({
    name: "dicts/universities",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: true,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getUniversities.pending, state => { state.isLoading = true })
            .addCase(getUniversities.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getUniversities.rejected, (state, action) => {
                state.error = action.error
                state.isLoading = false
            })
    }
})

export const universitySelectors = defaultEntityAdapter.getSelectors(x => x.dicts.universities)

export default universitiesSlice.reducer