import { Autocomplete, Box, TextField, Typography } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "../../../../components/Spinner"
import toastWrapper from "../../../../components/toastWrapper"
import useCitizenships from "../../../../hooks/useCitizenships"
import useIsSmallScreen from "../../../../hooks/useIsSmallScreen"
import { createPayerInfo, createPayerOrgInfo, deletePayerInfo, deletePayerOrgInfo, getPayerInfo, getPayerOrgInfo, setPayerType, updatePayerInfo, updatePayerOrgInfo } from "../../../../redux/entrantForm/payerInfoSlice"
import usePersonalDocTypes from "../../hooks/usePersonalDocTypes"
import PayerForm from "./PayerForm"
import PayerOrganizationForm from "./PayerOrganizationForm"


const PayerInfo = ({ userInfo }) => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (userInfo?.id) {
            Promise.all([
                dispatch(getPayerInfo(userInfo.id)).unwrap(),
                dispatch(getPayerOrgInfo(userInfo.id)).unwrap(),
            ]).then(res => {
                // Ну эт вообще мда
                if (res[0]?.[0]?.id) {
                    dispatch(setPayerType("person"))
                } else if (res[1]?.[0]?.id) {
                    dispatch(setPayerType("organization"))
                }
            })
        }
    }, [dispatch, userInfo])

    const personalDocTypeProps = usePersonalDocTypes()

    const citizenshipProps = useCitizenships()

    const {
        payer, payerIsLoading,
        payerOrg, payerOrgIsLoading,
        payerType,
    } = useSelector(x => x.entrantForm.payer)


    const handleSavePayerInfo = values => {
        const newValues = { ...values, user: userInfo.id }
        dispatch(values?.id ? updatePayerInfo(newValues) : createPayerInfo(newValues)).unwrap()
            .then(() => {
                toastWrapper("Изменения сохранены", "success")
                if (payerOrg?.id) dispatch(deletePayerOrgInfo(payerOrg))
            })
            .catch(e => {
                console.error(e)
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
            })
    }

    const savePayerOrgInfo = values => {
        const newValues = { ...values, user: userInfo.id }
        dispatch(values?.id ? updatePayerOrgInfo(newValues) : createPayerOrgInfo(newValues)).unwrap()
            .then(() => {
                toastWrapper("Изменения сохранены", "success")
                if (payer?.id) dispatch(deletePayerInfo(payer))
            })
            .catch(e => {
                console.error(e)
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
            })
    }

    const isSmallScreen = useIsSmallScreen()

    const componentHeight = payerType === "person" ?
        isSmallScreen ? 1280 : 810 :
        isSmallScreen ? 770 : 520


    if (payerIsLoading || payerOrgIsLoading) return <Spinner height={componentHeight} />

    return <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: componentHeight }}>

        <Typography variant="h6">Реквизиты плательщика</Typography>
        <Typography variant="subtitle2">
            Заполняется для поступающих на платную форму обучения, в случае если абитуриент не является плательщиком по договору об оказании образовательных услуг
        </Typography>

        <Autocomplete
            value={payerType}
            options={["person", "organization"]}
            onChange={(_, value) => dispatch(setPayerType(value))}
            getOptionLabel={option => option === "person" ? "Физ. лицо" : "Юр. лицо"}
            renderInput={props => <TextField
                label="Оплачивать обучение будет"
                {...props}
            />}
        />

        {payerType === "person" && <PayerForm
            data={payer}
            onSubmit={handleSavePayerInfo}
            {...citizenshipProps}
            {...personalDocTypeProps}
        />}

        {payerType === "organization" && <PayerOrganizationForm
            data={payerOrg}
            onSubmit={savePayerOrgInfo}
        />}

    </Box>
}

export default PayerInfo