import axios from 'axios'
import { logout } from '../redux/userSlice'

// https://stackoverflow.com/questions/70122391/how-to-dispatch-action-outside-component-in-redux-toolkit-typescript
let store

export const injectStore = _store => {
    store = _store
}

export const backendUrl = window._env_?.BACKEND_URL ?? ""
export const apiUrl = backendUrl + "/api"

const instance = axios.create({
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: false, // TODO разобраться почему ругается на CORS
    paramsSerializer: {
        serialize: params => Object.keys(params)
            .map(key => {
                if (Array.isArray(params[key])) {
                    return `${params[key].map(el => `${encodeURIComponent(key)}=${encodeURIComponent(el)}`).join("&")}`
                } else {
                    return params[key] !== undefined ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}` : ""
                }
            })
            .join("&")
    }
})

const publicInstance = axios.create({
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/json",
    },
    paramsSerializer: {
        serialize: params => Object.keys(params)
            .map(key => {
                if (Array.isArray(params[key])) {
                    return `${params[key].map(el => `${encodeURIComponent(key)}=${encodeURIComponent(el)}`).join("&")}`
                } else {
                    return params[key] !== undefined ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}` : ""
                }
            })
            .join("&")
    }
})

instance.interceptors.request.use(
    config => {
        const accessToken = localStorage.getItem('access')
        if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`
        else return Promise.reject(new Error())
        return config
    },
    error => Promise.reject(error)
)

instance.interceptors.response.use(
    response => response,
    async error => {
        if (
            !axios.isAxiosError(error) ||
            error?.code === "ERR_NETWORK" ||
            error.response.status !== 401 ||                // 401 -> обновить токен
            error.config.url === '/auth/token/' ||
            error.response?.data?.code === 'user_not_found' // Если пользователь был удален, но токены в localStorage остались
        ) return Promise.reject(error)

        const originalRequest = error.config
        let refreshToken = localStorage.getItem('refresh')
        return publicInstance
            .post(`/auth/token/refresh/`, { refresh: refreshToken })
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem('access', res.data.access)

                    const config = {
                        ...originalRequest,
                        headers: {
                            ...originalRequest.headers,
                            "Authorization": `Bearer ${res.data.access}`,
                            //"Content-Type": "application/json",
                        }
                    }

                    return instance(config)
                } else {
                    const refreshTokenError = new Error("Не удалось обновить refresh-токен")
                    refreshTokenError.originalError = error
                    localStorage.removeItem('refresh')
                    localStorage.removeItem('access')
                }
            })
            .catch(e => {
                if (e.response.status === 401) {
                    const refreshTokenError = new Error("Не удалось обновить refresh-токен")
                    refreshTokenError.originalError = error
                    localStorage.removeItem('refresh')
                    localStorage.removeItem('access')
                    const { dispatch } = store
                    dispatch(logout())
                }
                return Promise.reject(e)
            })
    }
)

export { instance as api, publicInstance as publicApi }
