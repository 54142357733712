import { combineReducers } from "@reduxjs/toolkit";
import achievementsInfoReducer from "./achievementsInfoSlice";
import directionEduMinistryReducer from "./directionEduMinistrySlice";
import exemptionReducer from "./exemptionSlice";
import militaryDocInfoReducer from "./militaryDocInfoSlice";
import olympInfoReducer from "./olympInfoSlice";
import optionsInfoReducer from "./optionsInfoSlice";
import passportInfoReducer from "./passportInfoSlice";
import payerInfoReducer from "./payerInfoSlice";
import personalInfoReducer from "./personalInfoSlice";
import prevEduInfoReducer from "./prevEduInfoSlice";
import satExamsInfoReducer from "./satExamsInfoSlice";
import supDocsReducer from "./supDocsSlice";
import targetContrantInfoReducer from "./targetContrantInfoSlice";
import prevEduOriginalReducer from "../entrantForm/prevEduOriginalSlice";

const entrantFormSlice = combineReducers({
    personalInfo: personalInfoReducer,
    passportInfo: passportInfoReducer,
    prevEduInfo: prevEduInfoReducer,
    prevEduOriginal: prevEduOriginalReducer,
    options: optionsInfoReducer,
    satExams: satExamsInfoReducer,
    olymp: olympInfoReducer,
    achievements: achievementsInfoReducer,
    militaryDocInfo: militaryDocInfoReducer,
    exemptions: exemptionReducer,
    payer: payerInfoReducer,
    targetContract: targetContrantInfoReducer,
    directionEduMinistry: directionEduMinistryReducer,
    supDocs: supDocsReducer,
})

export default entrantFormSlice