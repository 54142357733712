import { createSlice } from "@reduxjs/toolkit";
import { operatorService } from "../services/operatorService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "./utils";

export const searchUsers = createCustomAsyncThunk(
    "userSearch/get",
    async search => await operatorService.searchUsers(search)
)

const userSearchSlice = createSlice({
    name: "userSearch",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
        value: null,
    }),
    reducers: {
        setUserSearchValue: (state, action) => { state.value = action.payload },
        resetUserSearchValue: state => { state.value = null },
    },
    extraReducers: builder => {
        builder
            .addCase(searchUsers.pending, state => { state.isLoading = true })
            .addCase(searchUsers.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload.results)
                state.isLoading = false
            })
            .addCase(searchUsers.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
    }
})

export const { setUserSearchValue, resetUserSearchValue } = userSearchSlice.actions

export const userSearchSelectors = defaultEntityAdapter.getSelectors(x => x.userSearch)

export default userSearchSlice.reducer