import React from "react"
import { useDispatch } from "react-redux"
import { enentrantUnprocessedDocSelectors, getEntrantUnprocessedDocs } from "../../redux/entrantUnprocessedDocsSlice"
import toastWrapper from "../../components/toastWrapper"
import { useNavigate } from "react-router-dom"
import routes from "../../components/routes"
import { useSelector } from "react-redux"

const useEntrantUnprocessedDocsData = id => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    React.useEffect(() => {
        if (id) {
            dispatch(getEntrantUnprocessedDocs(id)).unwrap().catch(e => {
                console.error(e)
                toastWrapper("Не удалось найти необработанные документы из ЕПГУ пользователя", "error")
                navigate(routes.competitiveGroupList.path)
            })
        }
    }, [navigate, dispatch, id])

    const data = useSelector(enentrantUnprocessedDocSelectors.selectAll)
    const isLoading = useSelector(x => x.entrantUnprocessedDocs.isLoading)

    return { data, isLoading }
}

export default useEntrantUnprocessedDocsData