import { Autocomplete, Box, Button, Checkbox, FormControlLabel, TextField } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import * as yup from "yup"
import Spinner from "../../../components/Spinner"
import toastWrapper from "../../../components/toastWrapper"
import { submitDocWays } from "../../../constants"
import { createOptionsInfo, getOptionsInfo, updateOptionsInfo } from "../../../redux/entrantForm/optionsInfoSlice"
import { getLookups, getOptions } from "../../../utils"
import useSpecialConditions from "../../../hooks/useSpecialConditions"


const OptionsInfo = ({ userInfo }) => {

    const dispatch = useDispatch()

    const { data, isLoading } = useSelector(x => x.entrantForm.options)

    React.useEffect(() => {
        if (userInfo?.id) dispatch(getOptionsInfo(userInfo.id))
    }, [dispatch, userInfo])

    const submitDocWayOptions = React.useMemo(() => getOptions(submitDocWays), [])
    const submitDocWaylookups = React.useMemo(() => getLookups(submitDocWays), [])

    const { specialConditionOptions, specialConditionLookups, specialConditionsIsLoading } = useSpecialConditions()

    const { handleSubmit, values, errors, touched, setFieldValue } = useFormik({
        initialValues: data,
        enableReinitialize: true,
        onSubmit: values => {
            const newValues = { ...values, user: userInfo.id }
            dispatch(newValues.id ? updateOptionsInfo(newValues) : createOptionsInfo(newValues)).unwrap()
                .then(() => { toastWrapper("Изменения сохранены", "success") })
                .catch(e => {
                    console.error(e)
                    toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
                })
        },
        validationSchema: yup.object({
            submit_docs_way: yup.number().nullable(),
            return_docs_way: yup.number().nullable(),
            special_conditions_entrance_test: yup.number().nullable(),
            is_needed_hostel: yup.bool(),
        })
    })

    const componentHeight = 240

    if (isLoading) return <Spinner height={componentHeight} />

    return <Box sx={{ height: componentHeight }}>
        <Autocomplete
            value={values.submit_docs_way}
            options={submitDocWayOptions}
            getOptionLabel={option => submitDocWaylookups[option ?? ""]}
            onChange={(_, value) => setFieldValue("submit_docs_way", value)}
            disableClearable
            renderInput={props => <TextField
                label="Способ подачи документов"
                error={errors.submit_docs_way && Boolean(touched.submit_docs_way)}
                {...props}
            />}
        />
        <Autocomplete
            value={values.return_docs_way}
            options={submitDocWayOptions}
            getOptionLabel={option => submitDocWaylookups[option ?? ""]}
            onChange={(_, value) => setFieldValue("return_docs_way", value)}
            disableClearable
            renderInput={props => <TextField
                label="Способ возврата документов"
                error={errors.return_docs_way && Boolean(touched.return_docs_way)}
                {...props}
            />}
        />
        <Autocomplete
            value={values.special_conditions_entrance_test}
            options={specialConditionOptions}
            getOptionLabel={option => specialConditionLookups[option]?.name ?? ""}
            onChange={(_, value) => setFieldValue("special_conditions_entrance_test", value)}
            loading={specialConditionsIsLoading}
            renderInput={props => <TextField
                label="Специальные условия при проведении вступительных испытаний"
                error={errors.special_conditions_entrance_test && Boolean(touched.special_conditions_entrance_test)}
                {...props}
            />}
        />
        <FormControlLabel
            label="Необходимо общежитие"
            control={<Checkbox
                checked={values.is_need_hostel}
                onChange={e => setFieldValue("is_need_hostel", e.target.checked)}
            />}
        />
        <Button
            sx={{ mt: 1 }}
            variant="contained"
            fullWidth
            onClick={handleSubmit}
        >
            Сохранить
        </Button>
    </Box>
}

export default OptionsInfo