import { Box, Paper, Typography } from "@mui/material"


const FeedbackPage = ({ isOperator }) => {


    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 650, minWidth: 280, margin: "auto", height: "100%" }}>

        <Box sx={{
            m: 1,
            p: 2,
            borderRadius: 1,
            display: "flex",
            backgroundColor: "primary.main",
        }}>
            <Typography sx={{ ml: 2 }} variant="font2" color="white">
                Обратная связь
            </Typography>
        </Box>

        <Paper sx={{ m: 1, p: 2, height: 540, overflow: "auto" }}>
            {isOperator ? <>
                <script src="https://yastatic.net/s3/frontend/forms/_/embed.js"></script>
                <iframe
                    title="operator-feedback-yaform"
                    src="https://forms.yandex.ru/cloud/649448f45d2a062ce38dc67b/?iframe=1"
                    name="ya-form-649448f45d2a062ce38dc67b"
                    width="100%"
                    height="500"
                    frameBorder="0"
                />
            </> : <>
                <script src="https://yastatic.net/s3/frontend/forms/_/embed.js" />
                <iframe
                    title="entrant-feedback-yaform"
                    src="https://forms.yandex.ru/cloud/64941bb73e9d0823cbbe195b/?iframe=1"
                    name="ya-form-64941bb73e9d0823cbbe195b"
                    width="100%"
                    height="500"
                    frameBorder="0"
                />
            </>}

        </Paper>

    </Box>
}

export default FeedbackPage