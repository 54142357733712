import { MTableToolbar } from "@material-table/core"
import { KeyboardBackspace } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"
import FileDownloadButton from "../../../components/FileDownloadButton"
import { LookupFilter } from "../../../components/MaterialTableWrapper/components"
import { apiUrl } from "../../../services/api"
import { ordersService } from "../../../services/operatorService"
import AvailableOrderAppSearch from "../AvailableOrderAppSearch"


const useOrderItemsTableProps = (id, current, isLoading, levelLookups, universityLookups, specLookups, onNavigate) => {

    const title = <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton sx={{ mr: 1 }} size="small" onClick={onNavigate}>
            <KeyboardBackspace color="secondary" />
        </IconButton>
        <Typography variant="font1" noWrap>
            {isLoading || !current ? "Загрузка..." : `
            Приказ ${current.number} от ${new Date(current.date).toLocaleDateString()} 
            (${universityLookups[current.university]?.sname}, ${levelLookups[current.level]?.name})
            `}
        </Typography>
    </Box>

    const data = query => new Promise((resolve, reject) => {

        const params = { order: id }

        query.filters.forEach(filter => { params[filter.column.field] = filter.value })

        return ordersService.getItems(params).then(res => {
            resolve({
                data: res,
                page: query.page,
                totalCount: res.length,
            })
        }).catch(e => {
            console.error(e)
            reject("Произошла ошибка при загрузке приказа")
        })
    })

    const columns = [
        { field: "app_number", title: "Номер заявления", editComponent: () => null, filtering: false, },
        {
            field: "fio",
            title: "ФИО",
            editComponent: props => <AvailableOrderAppSearch {...props} current={current} />,
            filtering: false,
        },
        { field: "spec", title: "Направление", lookup: specLookups, editComponent: () => null, filterComponent: LookupFilter },
        { field: "modified_at", title: "Время последнего изменения", type: "datetime", editComponent: () => null, filtering: false, },
    ]

    const editable = {
        onRowAdd: current?.is_open ? ordersService.createItem : undefined,
        onRowDelete: current?.is_open ? ordersService.deleteItem : undefined,
    }

    const options = {
        pageSize: 25,
        pageSizeOptions: [10, 25, 50, 100],
        padding: "dense",
        maxBodyHeight: "calc(100vh - 141px)",
        minBodyHeight: "calc(100vh - 141px)",
        headerStyle: { position: "sticky", top: 0, zIndex: 1 },
        maxColumnSort: 0,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        search: false,
        filtering: true,
    }

    const components = {
        Toolbar: props => <div>
            <MTableToolbar {...props} />
            <FileDownloadButton
                sx={{ ml: 2 }}
                component="link"
                url={`${apiUrl}/orders/order_xls/?order=${current?.id}`}
                name="Скачать печатную форму приказа"
            />
            <FileDownloadButton
                sx={{ ml: 2 }}
                component="link"
                url={`${apiUrl}/orders/${current?.id}/act_doc_students/`}
                name="Скачать акт о передаче и приёме личных дел студентов "
            />
            <FileDownloadButton
                sx={{ ml: 2 }}
                component="link"
                url={`${apiUrl}/orders/${current?.id}/extract_order_all/`}
                name="Скачать выписки из приказа "
            />
            <FileDownloadButton
                sx={{ ml: 2 }}
                component="link"
                url={`${apiUrl}/orders/${current?.id}/descript_pers_file_all/`}
                name="Скачать описи личных дел "
            />
            <FileDownloadButton
                sx={{ ml: 2 }}
                component="link"
                url={`${apiUrl}/orders/${current?.id}/wrappers_pdf/`}
                name="Скачать обёртки личных дел "
            />
        </div>
    }

    return {
        title,
        data,
        columns,
        editable,
        options,
        components
    }
}

export default useOrderItemsTableProps