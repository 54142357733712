import { api, publicApi } from "./api"

export const userService = {
    login: data => publicApi.post(`/auth/token/`, data).then(res => {
        localStorage.setItem("access", res.data.access)
        localStorage.setItem("refresh", res.data.refresh)
    }),
    logout: () => {
        localStorage.removeItem("access")
        localStorage.removeItem("refresh")
    },
    register: data => publicApi.post(`/auth/users/`, data),
    getUserInfo: () => api.get(`/auth/current_user/`),
    getUserInfoById: id => api.get(`/auth/users/${id}/`),
    updateUserInfo: data => api.put(`/auth/users/${data.id}/`, data),

    getProfileInfo: userId => api.get(`/entrant/entrant_profile_info/${userId}/`),
    updateProfileInfo: ({ userId, ...rest }) => api.patch(`/entrant/entrant_profile_info/${userId}/`, rest),

    sendConfirmPhoneMessage: data => publicApi.post(`/auth/phone/register/`, data),
    verifyPhone: data => publicApi.post("/auth/phone/verify/", data),

    changePassword: ({ userId, ...rest }) => api.post(`/auth/users/${userId}/change_password_by_old_password/`, rest),
    changePasswordByPhone: data => publicApi.post(`/auth/users/change_password_by_phone_code/`, data),

    changeCampaignYear: data => api.post("/auth/change_year/", data),
}