import { getCurrentScorecard } from "../../../redux/entranceTests/sсorecardSlice"
import toastWrapper from "../../../components/toastWrapper"
import routes from "../../../components/routes"
import React from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"

const useCurrentScorecard = id => {

    const current = useSelector(x => x.entranceTests.scorecard.current)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (id && current?.id !== Number(id)) {
            dispatch(getCurrentScorecard(id)).unwrap()
                .catch(e => {
                    console.error(e)
                    toastWrapper("Экзаменационная ведомость не найдена", "error")
                    navigate(routes.scorecards.path)
                })
        }
    }, [dispatch, navigate, id, current])

    return current
}

export default useCurrentScorecard