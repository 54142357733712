import { createSlice } from "@reduxjs/toolkit";
import { dictService } from "../../services/dictService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getLevels = createCustomAsyncThunk(
    "dicts/levels/get",
    async () => await dictService.getLevels()
)

const levelsSlice = createSlice({
    name: "dicts/levels",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null
    }),
    extraReducers: builder => {
        builder
            .addCase(getLevels.pending, state => { state.isLoading = true })
            .addCase(getLevels.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getLevels.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
    }
})

export const levelSelectors = defaultEntityAdapter.getSelectors(x => x.dicts.levels)

export default levelsSlice.reducer