import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
    name: "ui",
    initialState: {
        dialogMode: null,
        dialogType: null,
        dialogIsOpen: false,
        dialogData: null,

        currentFormStep: 0,
        currentApplicationStep: 0,
        currentApplicationEditItem: null,

        notificationsDrawerOpen: false,
    },
    reducers: {
        setDialogMode: (state, action) => { state.dialogMode = action.payload },
        setDialogType: (state, action) => { state.dialogType = action.payload },
        setDialogIsOpen: (state, action) => { state.dialogIsOpen = action.payload },
        setDialogData: (state, action) => { state.dialogData = action.payload },
        closeDialogAndResetState: state => {
            state.dialogMode = null
            state.dialogType = null
            state.dialogIsOpen = false
            state.dialogData = null
        },
        setAllDialogStates: (state, action) => {
            state.dialogMode = action.payload.dialogMode
            state.dialogType = action.payload.dialogType
            state.dialogData = action.payload.dialogData
            state.dialogIsOpen = true
        },
        setCurrentFormStep: (state, action) => { state.currentFormStep = action.payload },
        setCurrentApplicationStep: (state, action) => { state.currentApplicationStep = action.payload },
        setCurrentApplicationEditItem: (state, action) => { state.currentApplicationEditItem = action.payload },
        openNotificationsDrawer: state => { state.notificationsDrawerOpen = true },
        closeNotificationsDrawer: state => { state.notificationsDrawerOpen = false }
    }
})

export const {
    setAllDialogStates, setDialogMode,
    setDialogType, setDialogIsOpen, setDialogData,
    closeDialogAndResetState,
    setCurrentFormStep,
    setCurrentApplicationStep,
    setCurrentApplicationEditItem,
    openNotificationsDrawer,
    closeNotificationsDrawer,
} = uiSlice.actions

export default uiSlice.reducer
