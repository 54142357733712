import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPrevEduInstitutions, prevEduInstitutionSelectors } from "../redux/dicts/prevEduInstitutionsSlice"

const usePrevEduInstitutions = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(getPrevEduInstitutions())
    }, [dispatch])

    const prevEduInstitutions = useSelector(prevEduInstitutionSelectors.selectAll)
    const prevEduInstitutionOptions = React.useMemo(() => prevEduInstitutions.map(el => el.sname), [prevEduInstitutions])
    const prevEduInstitutionsIsLoading = useSelector(x => x.dicts.prevEduInstitutions.isLoading)

    return { prevEduInstitutionOptions, prevEduInstitutionsIsLoading }
}

export default usePrevEduInstitutions