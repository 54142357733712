import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from "@mui/material"


const ConfirmationDialog = ({
    open, onClose, onOk
}) => {

    return (
        <Dialog
            fullWidth
            maxWidth="xl"
            open={open}
        >
            <DialogTitle>Согласие на обработку персональных данных</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle2" >
                    Пользователь веб-ресурсов (далее - Пользователь), оставляя заявку на сайте
                    <Link href="https://abiturient.bstu.ru/" sx={{ ml: 0.5, mr: 0.5 }}>
                        https://abiturient.bstu.ru/
                    </Link>
                    (далее - сайт) принимает настоящее Согласие на обработку персональных данных.
                    <br />
                    <br />
                    Пользователь, действуя свободно, своей волей и в своём интересе, подтверждая свою дееспособность,
                    даёт своё согласие:
                    <br />
                    <Typography variant="subtitle2" component="strong" sx={{ fontWeight: 'bolder' }}>
                        Федеральному государственному бюджетному образовательному учреждению высшего образования
                        «Белгородский государственный технологический университет им. В.Г. Шухова», находящемуся по адресу:
                        308012, г. Белгород, ул. Костюкова, д. 46 ИНН: 3123017793 КПП: 312301001 ОГРН: 1023101659481
                    </Typography>
                    <br />
                    на обработку своих персональных данных для целей заключения, исполнения и прекращения
                    гражданско-правовых договоров, а так же для обработки входящих запросов, консультирования,
                    направления комментариев. При этом:
                    <br />
                    <br />
                    1. Согласие предоставлено для использования моих следующих персональных данных:
                    фамилия, имя, отчество; электронная почта; номер телефона; год, месяц, дата рождения;
                    реквизиты документа, удостоверяющего личность (вид, серия, номер документа, дата выдачи,
                    наименование органа, выдавшего его); сведения о гражданстве; адрес места регистрации и проживания;
                    сведения об образовании (уровень образования, наименование образовательной организации,
                    номер/серия документов об образовании, год окончания); СНИЛС; сведения об инвалидности;
                    сведения, содержащиеся в документах воинского учета для военнообязанных лиц и лиц,
                    подлежащих призыву на военную службу и реквизиты документов воинского учёта;
                    информация о прохождении учебного процесса.
                    <br />
                    <br />
                    2. Обработка моих персональных данных может включать следующие действия:
                    сбор, запись, систематизацию, накопление, хранение, извлечение, использование,
                    передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение.
                    <br />
                    <br />
                    По вопросам обработки либо уничтожения персональных данных пользователей веб-ресурсов
                    необходимо обратиться лично по адресу г. Белгород, ул. Костюкова, 46, ГУК, каб. 421.
                    За разъяснениями по вопросам, касающимся обработки персональных данных пользователей,
                    можно обратиться по адресу электронной почты pd@bstu.ru.
                    Уничтожение персональных данных, предоставляемых данным согласием,
                    будет произведено уполномоченной комиссией в течении 30 дней с момента достижения целей обработки.
                    <br />
                    <br />
                    Настоящим подтверждаю, что ознакомлен и согласен с положениями Политики БГТУ им. В.Г. Шухова
                    в отношении обработки персональных данных, расположенной в сети Интернет по адресу

                    <Link href="https://bstu.ru/pd" sx={{ ml: 0.5, mr: 0.5 }}>bstu.ru/pd</Link>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onOk}>
                    Подтвердить
                </Button>
                <Button color="error" onClick={onClose}>
                    Отклонить
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default ConfirmationDialog