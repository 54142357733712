const OtherFileIcon = () => {
    return <svg width="72" height="80" viewBox="0 0 72 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.75" y="0.75" width="70.5" height="78.5" rx="3.25" fill="white" />
        <g opacity="0.32">
            <path d="M44.985 12H15.963C15.155 12 14.5 12.655 14.5 13.926V67C14.5 67.345 15.155 68 15.963 68H56.037C56.845 68 57.5 67.345 57.5 67V24.978C57.5 24.282 57.407 24.058 57.243 23.893L45.607 12.257C45.442 12.093 45.218 12 44.985 12Z" fill="#E9E9E0" />
            <path d="M45.5 12.1509V23.9999H57.349L45.5 12.1509Z" fill="#D9D7CA" />
            <path d="M56.037 68H15.963C15.155 68 14.5 67.345 14.5 66.537V51H57.5V66.537C57.5 67.345 56.845 68 56.037 68Z" fill="green" />
        </g>
        <rect x="0.75" y="0.75" width="70.5" height="78.5" rx="3.25" stroke="#122D52" strokeWidth="1.5" strokeDasharray="8 8" />
    </svg>
}

export default OtherFileIcon