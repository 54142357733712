import { useDispatch, useSelector } from "react-redux"
import toastWrapper from "../../../components/toastWrapper"
import { saveCommonPriorities, saveTargetPriorities, swapCommonPriorities, swapTargetPriorities } from "../../../redux/entrantApplications/appPrioritiesSlice"
import { addNewEntrantApplicationItem, createEntrantApplication, createEntrantApplicationItem, deleteEntrantApplicationItem, deleteNewEntrantApplicationItem, entrantApplicationSelectors, getEntrantApplications } from "../../../redux/entrantApplications/appsSlice"
import { addNewEntranceTestRegistrationItem, createEntranceTestRegistration, deleteEntranceTestRegistration, deleteNewEntranceTestRegistrationItem } from "../../../redux/entrantApplications/entranceTestRegistrationSlice"
import { store } from "../../../redux/store"
import { setCurrentApplicationEditItem, setCurrentApplicationStep, setDialogData } from "../../../redux/uiSlice"
import { dictService } from "../../../services/dictService"
import { getAvailableTestSubjects } from "../../../redux/entrantApplications/availableTestSubjectsSlice"

const appItemInitialValues = {

    university: null,
    edu_form: null,
    is_english_program: false,
    is_foreign_with_direction: false,

    quota_types: [],
    without_quota_types: true,
    spec: null,
    subspec: null,

    competitive_group: null,
}

const useEntrantApplicationFormActionHandlers = (userInfo, commonPriorities, targetPriorities) => {

    const dispatch = useDispatch()

    const handleCreateApplication = values => {
        dispatch(createEntrantApplication({ ...values, user: userInfo.id })).unwrap()
            .then(res => {
                dispatch(setDialogData(res))
                dispatch(setCurrentApplicationStep(1))
            })
            .catch(e => {
                console.error(e)
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось создать заявление")
            })
    }

    const handleCreateAppItem = (app, item) => {
        const params = {
            university: item.university,
            is_foreign_with_direction: item.is_foreign_with_direction,
            level: app.level,
            spec: item.spec,
            edu_form: item.edu_form,
            subspec: item.subspec,
            is_english_program: item.is_english_program,
            is_budget: app.is_budget,
            without_quota_types: item.without_quota_types
        }
        if (!item.without_quota_types) {
            params.quota_types = item.quota_types
        }
        dictService.getCompetitiveGroups(params)
            .then(({ data }) => {
                if (data.length === 1) {
                    const dataToSave = { ...item, competitive_group: data[0].id }
                    dispatch(createEntrantApplicationItem(dataToSave)).unwrap()
                        .then(() => {
                            toastWrapper("Конкурсная группа добавлена", "success")
                            handleCloseEditAppItem(item)
                            dispatch(getEntrantApplications(userInfo.id))
                        })
                        .catch(e => {
                            console.error(e)
                            toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось добавить конкурсную группу")
                            dispatch(getEntrantApplications(userInfo.id))
                        })
                } else {
                    toastWrapper("Не удалось найти конкурсную группу", "error")
                    console.error("Не нашлась КГ", { app, item, data })
                }
            })
    }

    const dialogData = useSelector(x => x.ui.dialogData)

    const handleCloseEditAppItem = item => {
        dispatch(setCurrentApplicationEditItem(null))
        if (!item?.id) {
            dispatch(deleteNewEntrantApplicationItem(item))
            const currentApp = entrantApplicationSelectors.selectById(store.getState(), dialogData.id)
            dispatch(setDialogData(currentApp))
        }
    }

    const handleDeleteAppItem = item => {
        if (item.id) {
            dispatch(deleteEntrantApplicationItem(item)).then(() => {
                const currentApp = entrantApplicationSelectors.selectById(store.getState(), dialogData.id)
                dispatch(setDialogData(currentApp))
                toastWrapper("Конкурсная группа удалена", "success")
            })
        } else {
            const currentApp = entrantApplicationSelectors.selectById(store.getState(), dialogData.id)
            dispatch(deleteNewEntrantApplicationItem(item))
            dispatch(setDialogData(currentApp))
        }
    }

    const handleAddNewAppItem = app => {
        dispatch(addNewEntrantApplicationItem({
            app: app.id,
            id: null,
            ...appItemInitialValues,
        }))
        const currentApp = entrantApplicationSelectors.selectById(store.getState(), dialogData.id)
        dispatch(setDialogData(currentApp))
        dispatch(setCurrentApplicationEditItem(currentApp.items.length - 1))
    }

    const handleSwapCommonPriorities = (src, dst) => {
        dispatch(swapCommonPriorities({ src, dst }))
    }

    const handleSwapTargetPriorities = (src, dst) => {
        dispatch(swapTargetPriorities({ src, dst }))
    }

    const handleSavePriorities = () => {
        const newCommon = {
            app: dialogData.id,
            is_target: false,
            groups: commonPriorities.map((el, index) => ({ priority: index + 1, app_items: el.app_items }))
        }
        const newTarget = {
            app: dialogData.id,
            is_target: true,
            groups: targetPriorities.map((el, index) => ({ priority: index + 1, app_items: el.app_items }))
        }

        Promise.all([
            dispatch(saveCommonPriorities(newCommon)).unwrap(),
            dispatch(saveTargetPriorities(newTarget)).unwrap()
        ]).then(() => {
            toastWrapper("Приоритеты сохранены", "success")
            dispatch(getEntrantApplications(userInfo.id))
            dispatch(setCurrentApplicationStep(3))
            //onClose()
        }).catch(e => {
            console.error(e)
            toastWrapper("не удалось сохранить приоритеты", "error")
            dispatch(getEntrantApplications(userInfo.id))
        })

    }

    const handleAddEntranceTestRegistration = () => {
        dispatch(addNewEntranceTestRegistrationItem({ id: null, location: null, subject: null }))
    }

    const handleDeleteNewEntranceTestRegistration = () => {
        dispatch(deleteNewEntranceTestRegistrationItem())
    }

    const handleCreateEntranceTestRegistration = data => {
        dispatch(createEntranceTestRegistration({ ...data, app: dialogData?.id })).unwrap()
            .then(() => {
                toastWrapper("Запись на вступительное испытание добавлена", "success")
                dispatch(getAvailableTestSubjects(dialogData.id))
            })
            .catch(e => {
                console.error(e)
                toastWrapper("Не удалось добавить запись на вступительное испытание", "error")
                dispatch(getAvailableTestSubjects(dialogData.id))
            })
    }

    const handleDeleteEntranceTestRegistration = data => {
        dispatch(deleteEntranceTestRegistration(data)).unwrap()
            .then(() => {
                toastWrapper("Запись на вступительное испытание удалена", "success")
                dispatch(getAvailableTestSubjects(dialogData.id))
            })
            .catch(e => {
                console.error(e)
                toastWrapper("Не удалось удалить запись на вступительное испытание", "error")
                dispatch(getAvailableTestSubjects(dialogData.id))
            })
    }

    const handleToStep2 = () => {
        dispatch(setCurrentApplicationStep(1))
    }

    const handleToStep3 = () => {
        dispatch(setCurrentApplicationStep(2))
    }

    return {
        handleCreateApplication,
        handleCreateAppItem, handleCloseEditAppItem, handleDeleteAppItem, handleAddNewAppItem,
        handleSwapCommonPriorities, handleSwapTargetPriorities, handleSavePriorities,
        handleToStep2, handleToStep3,

        handleAddEntranceTestRegistration, handleCreateEntranceTestRegistration,
        handleDeleteEntranceTestRegistration, handleDeleteNewEntranceTestRegistration,
    }
}

export default useEntrantApplicationFormActionHandlers