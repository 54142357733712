import { CheckCircle, Info } from "@mui/icons-material"
import { Avatar, Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material"
import Person from "../../components/Icons/Person"
import useIsSmallScreen from "../../hooks/useIsSmallScreen"


const UserInfo = ({ userInfo, uid, onChange }) => {

    const isSmallScreen = useIsSmallScreen()


    return <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", mb: 3 }}>

        <Avatar
            sx={{
                alignSelf: "center",
                backgroundColor: "#E8F2FF",
                height: 150,
                width: 150,
                m: 1,
                mb: isSmallScreen ? 2 : 1,
                mr: isSmallScreen ? 1 : 2
            }}
        >
            <Person sx={{ height: 80, width: 80, color: "background.default" }} />
        </Avatar>


        <Stack direction="column" spacing={2} sx={{ maxWidth: 450, overflow: "hidden" }} >

            <Typography variant="font2" sx={{ alignSelf: "center", }}>
                {`${userInfo?.surname ?? ""} ${userInfo?.name ?? ""} ${userInfo?.patronymic ?? ""}`}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center" }}>

                <Typography variant="font3" sx={{ width: 160 }}>
                    Номер телефона
                </Typography>

                <Typography variant="font3">
                    {userInfo?.phone}
                </Typography>

                <Box sx={{ display: "flex", ml: 1 }}>
                    {userInfo?.is_check_phone ? (<>
                        <CheckCircle sx={{ color: "#219653", mr: 0.5 }} fontSize="small" />
                        {!isSmallScreen && <Typography color="#219653" noWrap>
                            Подтвержден
                        </Typography>}
                    </>) : (<>
                        <Info sx={{ mr: 0.5 }} color="warning" fontSize="small" />
                        {!isSmallScreen && <Typography color="warning.main">
                            Не подтвержден
                        </Typography>}
                    </>)}
                </Box>

            </Box>


            <Box sx={{ display: "flex" }}>
                <Typography variant="font3" sx={{ width: 160 }}>Идентификатор</Typography>
                <Typography>{uid?.[uid.length - 1]}</Typography>
            </Box>

            <Box sx={{ display: "flex" }}>
                <Typography variant="font3" sx={{ width: 160 }}>Электронная почта</Typography>
                <Typography variant="font3" noWrap sx={{ maxWidth: "calc(100% - 160px)" }}>
                    {userInfo?.email}
                </Typography>
            </Box>

            <FormControlLabel
                control={<Checkbox checked={userInfo?.allow_email_notifications ?? false} onClick={onChange} />}
                label="Я хочу получать информационные уведомления от БГТУ им. В.Г. Шухова на указанный e-mail"
                componentsProps={{
                    typography: { fontSize: 14 }
                }}
            />

        </Stack>

    </Box>
}

export default UserInfo