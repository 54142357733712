import { createSlice } from "@reduxjs/toolkit";
import { userService } from "../services/userService";
import { createCustomAsyncThunk } from "./utils";

export const login = createCustomAsyncThunk(
    "user/login",
    async data => await userService.login(data)
)

export const getUserInfo = createCustomAsyncThunk(
    "user/userInfo/get",
    async () => await userService.getUserInfo()
)

export const getDisplayedUserInfo = createCustomAsyncThunk(
    "user/displayedUserInfo/get",
    async id => await userService.getUserInfoById(id)
)

export const updateUserInfo = createCustomAsyncThunk(
    "user/userInfo/update",
    async data => await userService.updateUserInfo(data)
)

export const register = createCustomAsyncThunk(
    "user/register",
    async data => await userService.register(data)
)

export const changePasswordByPhone = createCustomAsyncThunk(
    "user/changePassword/phone",
    async data => await userService.changePasswordByPhone(data)
)

export const changePasswordByOldPassword = createCustomAsyncThunk(
    "user/changePassword/password",
    async data => await userService.changePassword(data)
)

export const sendConfirmPhoneMessage = createCustomAsyncThunk(
    "user/phone/message",
    async data => await userService.sendConfirmPhoneMessage(data)
)

export const confirmPhone = createCustomAsyncThunk(
    "user/phone/confirm",
    async data => await userService.verifyPhone(data)
)

export const getProfileInfo = createCustomAsyncThunk(
    "user/profileInfo/get",
    async userId => await userService.getProfileInfo(userId)
)

export const updateProfileInfo = createCustomAsyncThunk(
    "user/profileInfo/update",
    async data => await userService.updateProfileInfo(data)
)

export const changeCampaignYear = createCustomAsyncThunk(
    "user/userInfo/changeCurrentCampaign",
    async data => await userService.changeCampaignYear(data)
)

const userSlice = createSlice({
    name: "user",
    initialState: {
        isLoading: true,
        isAuthenticated: false,
        error: null,

        userInfo: null,
        displayedUserInfo: null,

        displayedUserInfoIsLoading: false,

        profileInfo: null,
        profileInfoIsLoading: false,
        sessionToken: null,
    },
    reducers: {
        logout: state => {
            userService.logout()
            state.isAuthenticated = false
            state.isLoading = false
        },
        setDisplayedUserInfo: (state, action) => { state.displayedUserInfo = action.payload },
        resetDisplayedUserInfo: state => { state.displayedUserInfo = null }
    },
    extraReducers: builder => {
        builder
            .addCase(login.pending, state => {
                state.isAuthenticated = false
                state.isLoading = true
            })
            .addCase(login.fulfilled, state => {
                state.isAuthenticated = true
                state.isLoading = false
            })
            .addCase(login.rejected, (state, action) => {
                state.isAuthenticated = false
                state.isLoading = false
                state.error = action.error
            })

            .addCase(getUserInfo.pending, state => { state.isLoading = true })
            .addCase(getUserInfo.fulfilled, (state, action) => {
                state.userInfo = action.payload
                state.displayedUserInfo = action.payload
                state.isAuthenticated = true
                state.isLoading = false
            })
            .addCase(getUserInfo.rejected, (state, action) => {
                state.userInfo = null
                state.isLoading = false
                state.isAuthenticated = false
                state.error = action.error
            })

            .addCase(getDisplayedUserInfo.pending, state => { state.displayedUserInfoIsLoading = true })
            .addCase(getDisplayedUserInfo.fulfilled, (state, action) => {
                state.displayedUserInfoIsLoading = false
                state.displayedUserInfo = action.payload
            })
            .addCase(getDisplayedUserInfo.rejected, (state, action) => {
                state.displayedUserInfo = null
                state.error = action.error
                state.displayedUserInfoIsLoading = false
            })

            .addCase(updateUserInfo.fulfilled, (state, action) => {
                state.userInfo = { ...state.userInfo, ...action.payload }
                state.displayedUserInfo = { ...state.displayedUserInfo, ...action.payload }
            })

            .addCase(getProfileInfo.pending, state => { state.profileInfoIsLoading = true })
            .addCase(getProfileInfo.fulfilled, (state, action) => {
                state.profileInfo = action.payload
                state.profileInfoIsLoading = false
            })
            .addCase(getProfileInfo.rejected, (state, action) => {
                state.profileInfo = null
                state.profileInfoIsLoading = false
                state.error = action.error
            })

            .addCase(updateProfileInfo.fulfilled, (state, action) => {
                state.profileInfo = action.payload
            })

            .addCase(sendConfirmPhoneMessage.pending, state => {
                state.sessionToken = null
            })
            .addCase(sendConfirmPhoneMessage.fulfilled, (state, action) => {
                state.sessionToken = action.payload.session_token
            })
            .addCase(sendConfirmPhoneMessage.rejected, state => {
                state.sessionToken = null
            })

            .addCase(confirmPhone.fulfilled, state => {
                state.sessionToken = null
            })

            .addCase(changeCampaignYear.fulfilled, (state, action) => {
                state.userInfo = { ...state.userInfo, campaign_year: action.payload }
            })
    }
})

export const { logout, setDisplayedUserInfo, resetDisplayedUserInfo } = userSlice.actions

export default userSlice.reducer