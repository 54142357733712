import { Box, Button, Paper, Typography } from "@mui/material"
import React from "react"
import Dropzone from "react-dropzone"
import FileDownloadButton from "../../components/FileDownloadButton"
import FileIcon from "../../components/Icons/FileIcon"
import Spinner from "../../components/Spinner"
import { apiUrl } from "../../services/api"
import useSatExamsCheck from "./useSatExamsCheck"

const SatExamsPage = () => {

    const { acceptedFiles, isLoading, handleSelectFile, handleSendFile, handleDeleteFile } = useSatExamsCheck()

    return <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 650, minWidth: 280, margin: "auto" }}>

        <Box sx={{
            m: 1,
            p: 2,
            borderRadius: 1,
            display: "flex",
            backgroundColor: "secondary.main",
        }}>
            <Typography sx={{ ml: 2 }} variant="font2" color="white">
                Проверка ЕГЭ
            </Typography>
        </Box>

        <Paper sx={{ display: "flex", flexDirection: "column", m: 1, p: 2, minHeight: 300 }}>

            <FileDownloadButton
                component="link"
                url={`${apiUrl}/ege/get_file/`}
                name="Скачать файл для проверки"
            />

            {isLoading ? <Spinner height={250} /> : (
                <>
                    <Dropzone
                        onDrop={handleSelectFile}
                        accept={{
                            "text/csv": [],
                        }}
                        noDragEventsBubbling
                        maxFiles={1}
                    >
                        {({ getRootProps, getInputProps }) => {

                            const { onClick, ...rootProps } = getRootProps()

                            return <Box
                                {...rootProps}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: "#E8F2FF",
                                    borderRadius: "5px",
                                    borderColor: "primary",
                                    mt: 2, mb: 2, p: 1,
                                    minHeight: 165
                                }}
                            >
                                <Box
                                    sx={{
                                        p: 1,
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {acceptedFiles.length === 0 ? (


                                        <Typography align="center" sx={{ m: 1 }}>
                                            Загрузить проверенный в ФИС файл
                                        </Typography>

                                    ) : acceptedFiles.map((acceptedFile, acceptedFileIndex) => <FileIcon
                                        key={acceptedFileIndex}
                                        name={acceptedFile.name}
                                        type={acceptedFile.type}
                                        onDelete={handleDeleteFile}
                                    />)
                                    }
                                </Box>

                                <Button
                                    sx={{ height: 35 }} variant="contained" onClick={onClick}
                                >
                                    Выбрать файл
                                </Button>

                                <input {...getInputProps()} />
                            </Box>
                        }}
                    </Dropzone>

                    <Button
                        variant="contained"
                        onClick={handleSendFile}
                        disabled={acceptedFiles.length !== 1}
                    >
                        Отправить
                    </Button>
                </>
            )}
        </Paper>
    </Box>
}
export default SatExamsPage