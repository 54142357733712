import { useDispatch, useSelector } from "react-redux"
import toastWrapper from "../../../components/toastWrapper"
import { dialogModes, dialogTypes } from "../../../constants"
import { actionHandlersMap, actionMessagesMap, actionNamesMap, deleteEntrantApplication, deleteNewEntrantApplicationItem, resetCurrentAppItem, setCurrentAppItem } from "../../../redux/entrantApplications/appsSlice"
import { deleteNewEntranceTestRegistrationItem } from "../../../redux/entrantApplications/entranceTestRegistrationSlice"
import { closeDialogAndResetState, openNotificationsDrawer, setAllDialogStates, setCurrentApplicationEditItem, setCurrentApplicationStep } from "../../../redux/uiSlice"


const useEntrantApplicationActionHandlers = (onOpenMenu, onCloseMenu, currentAppItem) => {

    const dispatch = useDispatch()

    const handleCreateApplicationIntent = () => {
        dispatch(setAllDialogStates({
            dialogMode: dialogModes.CREATE,
            dialogType: dialogTypes.ENTRANT_APPLICATION,
        }))
        dispatch(setCurrentApplicationStep(0))
    }

    const handleEditApplication = dialogData => {
        dispatch(setAllDialogStates({
            dialogMode: dialogModes.UPDATE,
            dialogType: dialogTypes.ENTRANT_APPLICATION,
            dialogData: dialogData.items?.length ? dialogData : { ...dialogData, items: [] },
        }))
        dispatch(setCurrentApplicationStep(1))
    }

    const handleDeleteApplicationIntent = dialogData => {
        dispatch(setAllDialogStates({
            dialogMode: dialogModes.DELETE,
            dialogType: dialogTypes.ENTRANT_APPLICATION,
            dialogData,
        }))
    }

    const dialogData = useSelector(x => x.ui.dialogData)

    const handleDeleteApplication = () => {
        dispatch(deleteEntrantApplication(dialogData)).unwrap()
            .then(() => {
                toastWrapper("Заявление удалено", "success")
                handleClose(false)
            })
            .catch(e => {
                console.error(e)
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось удалить заявление")
            })
    }

    const handleClose = (deleteEmpty = true) => {
        if (deleteEmpty && dialogData?.id) {
            dispatch(deleteNewEntrantApplicationItem({ app: dialogData.id }))
        }
        dispatch(deleteNewEntranceTestRegistrationItem())
        dispatch(setCurrentApplicationEditItem(null))
        dispatch(closeDialogAndResetState())
    }

    const handleOpenMenu = (e, item) => {
        dispatch(setCurrentAppItem(item))
        onOpenMenu(e)
    }

    const handleCloseMenu = () => {
        onCloseMenu()
        // TODO здесь бы debounce, перерендер уж больно долгий
        dispatch(resetCurrentAppItem())
    }

    const handleAction = action => {
        dispatch(actionHandlersMap[action](currentAppItem)).unwrap()
            .then(() => {
                toastWrapper(actionMessagesMap[action]?.success ?? "Изменения сохранены", "success")
                handleCloseMenu()
            })
            .catch(e => {
                console.error(e)
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : actionMessagesMap[action]?.error ?? "Не удалось выполнить действие")
            })
    }

    const handleOpenEntrantNotificationsDrawer = () => { dispatch(openNotificationsDrawer()) }

    return {
        handleCreateApplicationIntent,
        handleEditApplication,
        handleDeleteApplicationIntent, handleDeleteApplication,
        handleClose,

        handleOpenMenu, handleCloseMenu,
        actionNamesMap, handleAction,

        handleOpenEntrantNotificationsDrawer
    }
}

export default useEntrantApplicationActionHandlers