import { createSlice } from "@reduxjs/toolkit";
import { dictService } from "../../services/dictService";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";

export const getPersonalDocTypes = createCustomAsyncThunk(
    "dicts/personalDocTypes",
    async () => await dictService.getPersonalIdentityDocTypes()
)

const personalDocTypesSlice = createSlice({
    name: "dicts/personalDocTypes",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: true,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getPersonalDocTypes.pending, state => { state.isLoading = true })
            .addCase(getPersonalDocTypes.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getPersonalDocTypes.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
    }
})

export const personalDocTypeSelectors = defaultEntityAdapter.getSelectors(x => x.dicts.personalDocTypes)

export default personalDocTypesSlice.reducer