
const palette = {
    primary: {
        main: "#0989BB", // bstu-color-4
        grey: "#6C7180", // bstu-color-7
        lightBlue: "#E8F2FF" // bstu-color-3
    },
    secondary: {
        main: "#122D52", // bstu-footer-color-6
    },
    font: {
        main: "#314867" // bstu-font-color-5
    },
    error: {
        main: "#B3282D",
    },
    warning: {
        main: "#FFD554"
    },
    background: {
        default: "#F6F7FB", // bstu-background-color-1
    },
}

export default palette