import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import toastWrapper from "../../../../components/toastWrapper";
import { createOldPassportInfo, createPassportInfo, deleteOldPassportInfo, getOldPassportInfo, getPassportInfo, updateOldPassportInfo, updatePassportInfo } from "../../../../redux/entrantForm/passportInfoSlice";
import usePersonalDocTypes from "../../hooks/usePersonalDocTypes";
import PassportForm from "./PassportForm";
import OldPassportForm from "./OldPassportForm";
import { getProfileInfo } from "../../../../redux/userSlice";

const PassportInfo = ({ userInfo, profileInfo }) => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (userInfo?.id) dispatch(getPassportInfo(userInfo.id))
        if (userInfo?.id) dispatch(getOldPassportInfo(userInfo.id))
    }, [dispatch, userInfo])


    const { personalDocTypeOptions, personalDocTypeLookups } = usePersonalDocTypes()

    const { passport, passportIsLoading, oldPassport, oldPassportIsLoading } = useSelector(x => x.entrantForm.passportInfo)

    const onSubmitPassport = values => {
        const newValues = { ...values, user: userInfo.id }
        dispatch(values.id !== null ? updatePassportInfo(newValues) : createPassportInfo(newValues)).unwrap()
            .then(() => {
                toastWrapper("Изменения сохранены", "success")
                dispatch(getProfileInfo(userInfo?.id))
            })
            .catch(e => {
                dispatch(getProfileInfo(userInfo?.id))
                console.error(e)
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
            })
    }

    const onSubmitOldPassport = values => {
        const newValues = { ...values, user: userInfo.id }
        dispatch(values.id !== null ? updateOldPassportInfo(newValues) : createOldPassportInfo(newValues)).unwrap()
            .then(() => { toastWrapper("Изменения сохранены", "success") })
            .catch(e => {
                console.error(e)
                toastWrapper(e?.getUserMessage ? e.getUserMessage() : "Не удалось сохранить изменения")
            })
    }

    const [isPresentOldPassport, setIsPresentOldPassport] = React.useState(false)

    React.useEffect(() => {
        if (oldPassport?.id) setIsPresentOldPassport(true)
    }, [oldPassport])

    const handleChangeOldPassPresence = checked => {
        if (!checked && oldPassport?.id) {
            dispatch(deleteOldPassportInfo(oldPassport))
        }
        setIsPresentOldPassport(checked)
    }

    return <Box>
        <PassportForm
            data={passport}
            docTypeOptions={personalDocTypeOptions}
            docTypeLookups={personalDocTypeLookups}
            onSubmit={onSubmitPassport}
            profileInfo={profileInfo}
            isLoading={passportIsLoading}
        />
        <FormControlLabel
            sx={{ mt: 2 }}
            label="Я сдавал ЕГЭ по другому документу"
            control={<Checkbox
                checked={isPresentOldPassport}
                onChange={e => handleChangeOldPassPresence(e.target.checked)}
            />}
        />
        {isPresentOldPassport && <OldPassportForm
            data={oldPassport}
            onSubmit={onSubmitOldPassport}
            profileInfo={profileInfo}
            isLoading={oldPassportIsLoading}
        />}
    </Box>
}

export default PassportInfo