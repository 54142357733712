import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { useFormik } from "formik"
import React from "react"
import * as yup from "yup"
import { transformTimeToString } from "../../utils"


const PhoneConfirmationDialog = ({ open, onClose, onConfirm, onResend, timeLeft, start, reset }) => {

    const { values, setFieldValue, handleChange, handleSubmit } = useFormik({
        initialValues: { code: "" },
        onSubmit: values => { onConfirm(values.code) },
        validationSchema: yup.object({
            code: yup.string().required()
        })
    })

    const handleResend = () => {
        reset()
        start()
        setFieldValue("code", "")
        onResend()
    }

    React.useEffect(() => {
        if (!open) setFieldValue("code", "")
    }, [open, setFieldValue])

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs">
            <DialogTitle
                sx={{ mt: 2, mb: 2, display: "flex", alignItems: "center", justifyContent: "center" }}
                variant="font2"
                color="font.main"
            >
                Подтверждение телефона
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    На ваш номер телефона было отправлено SMS с кодом подтверждения. Введите этот код в поле ниже.
                </DialogContentText>
                <Box component="form">
                    <TextField
                        id="code"
                        name="code"
                        placeholder="Код из SMS"
                        fullWidth
                        autoFocus
                        value={values.code}
                        onChange={handleChange}
                    />
                    <Button sx={{ mt: 2 }} type="submit" variant="contained" fullWidth onClick={handleSubmit}>
                        Отправить
                    </Button>

                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleResend}
                        disabled={timeLeft > 0}
                        sx={{ mt: 2 }}
                    >
                        {`Выслать код повторно ${timeLeft > 0 ? transformTimeToString(timeLeft) : ''}`}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default PhoneConfirmationDialog