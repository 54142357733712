import { Box, List, ListItemText, Typography, capitalize } from "@mui/material"


const PermissionList = ({ userInfo }) => {

    if (!userInfo?.is_operator) return null

    return <Box>
        <Typography variant="h6">Права доступа</Typography>
        <List>
            {userInfo?.accesses?.map((access, index) => <ListItemText key={index}
                primary={access.role?.name ?? ""}
                secondary={access.dep?.name ?? access.university?.name ?? ""}
            />)}
        </List>

    </Box>
}

export default PermissionList