import { BottomNavigation, BottomNavigationAction, Box, Paper, useTheme } from "@mui/material"
import { useNavigate, useLocation } from "react-router-dom"

const MobileNavigation = ({ menuItems, children }) => {

    const navigate = useNavigate()

    const handleSelect = path => { navigate(path) }

    const location = useLocation()

    const isCurrentPage = (path) => location.pathname.includes(path)

    const theme = useTheme()

    return <>
        <Box sx={{
            height: 'calc(100% - 56px)',
            overflow: 'auto',
        }}>
            {children}
        </Box>
        <Box sx={{ height: 56 }} />
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2 }} elevation={3} >
            <BottomNavigation showLabels >
                {menuItems.map((route, index) => <BottomNavigationAction
                    key={index}
                    label={route.name}
                    icon={<route.icon
                        sx={{ color: isCurrentPage(route.path) ? theme.palette.primary.main : theme.palette.font.main }}
                    />}
                    onClick={() => handleSelect(route.path)}
                />
                )}
            </BottomNavigation>
        </Paper>
    </>
}

export default MobileNavigation