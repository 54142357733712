import { ruRU } from "@mui/material/locale"
import { createTheme } from "@mui/material/styles"
import Checked from "../components/Icons/Checked"
import Unchecked from "../components/Icons/Unchecked"
import { MuiTypography } from "./components"
import palette from "./palette"


const theme = createTheme(
    {
        palette,
        typography: {
            button: { textTransform: "none" }
        },
        components: {
            MuiTypography,
            MuiTextField: {
                defaultProps: {
                    variant: "outlined",
                    margin: "dense",
                    size: "small"
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: "standard",
                    margin: "dense",
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow: "0px 0px 2px #C4C4C4",
                        borderRadius: "4px"
                    }
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        height: "40px",
                        boxShadow: "none",
                        ...MuiTypography.variants[3].style
                    },
                },
                defaultProps: { disableElevation: true }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        color: palette.primary.grey,
                        ...MuiTypography.variants[2].style
                    }
                }
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: "#E6F7FF",
                        color: palette.font.main,
                        opacity: 0.8,
                        boxShadow: "0px 0px 2px #0989BB",
                        borderRadius: "4px",
                    }
                }
            },
            MuiCheckbox: {
                defaultProps: {
                    icon: <Unchecked />,
                    checkedIcon: <Checked />
                },
            },
        }
    },
    ruRU
)

export const drawerWidth = 230

export default theme