import { useMediaQuery, useTheme } from "@mui/material"

const useIsSmallScreen = () => {

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down("sm"))
    
    return matches
}

export default useIsSmallScreen