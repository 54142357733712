import React from "react"
import { useDispatch } from "react-redux"
import { getRatingCurrentCG, resetRatings } from "../../redux/ratingsSlice"
import { useSelector } from "react-redux"
import toastWrapper from "../../components/toastWrapper"
import { useNavigate } from "react-router-dom"
import routes from "../../components/routes"

const useRatingCurrentCG = id => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    React.useEffect(() => {
        if (id) dispatch(getRatingCurrentCG(id)).unwrap().catch(e => {
            toastWrapper("Конкурсная группа не найдена", "error")
            navigate(routes.dynamicRatingCGSelect.path)
        })
    }, [dispatch, navigate, id])

    React.useEffect(() => () => { dispatch(resetRatings()) }, [dispatch])

    const { currentCompetitiveGroup, currentCompetitiveGroupIsLoading } = useSelector(x => x.ratings)

    return { currentCompetitiveGroup, currentCompetitiveGroupIsLoading }
}

export default useRatingCurrentCG