import { useDispatch } from "react-redux"
import React from "react"
import toastWrapper from "../../../components/toastWrapper"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import routes from "../../../components/routes"
import { getCurrentOrder } from "../../../redux/orders/currentOrderSlice"

const useCurrentOrder = id => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { current, isLoading } = useSelector(x => x.orders.current)

    React.useEffect(() => {
        if (id) {
            dispatch(getCurrentOrder(id)).unwrap().catch(e => {
                toastWrapper("Приказ не найден", "error")
                console.error(e)
                navigate(routes.orderList.path)
            })
        }
    }, [dispatch, navigate, id])

    return { current, isLoading }
}

export default useCurrentOrder