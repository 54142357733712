import { createSlice } from "@reduxjs/toolkit";
import { createCustomAsyncThunk, defaultEntityAdapter } from "../utils";
import { entrantFormService } from "../../services/entrantService";

export const getPrevEduOriginal = createCustomAsyncThunk(
    "entrantForm/prevEduOriginal/get",
    async user => await entrantFormService.getPrevEduOriginal(user)
)

export const createPrevEduOriginal = createCustomAsyncThunk(
    "entrantForm/prevEduOriginal/create",
    async data => await entrantFormService.postPrevEduOriginal(data)
)

const prevEduOriginalSlice = createSlice({
    name: "entrantForm/prevEduOriginal",
    initialState: defaultEntityAdapter.getInitialState({
        isLoading: false,
        error: null,
    }),
    extraReducers: builder => {
        builder
            .addCase(getPrevEduOriginal.pending, state => { state.isLoading = true })
            .addCase(getPrevEduOriginal.fulfilled, (state, action) => {
                defaultEntityAdapter.setAll(state, action.payload)
                state.isLoading = false
            })
            .addCase(getPrevEduOriginal.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
        
            .addCase(createPrevEduOriginal.pending, state => { state.isLoading = true })
            .addCase(createPrevEduOriginal.fulfilled, (state, action) => {
                defaultEntityAdapter.addOne(state, action.payload)
                state.isLoading = false
            })
            .addCase(createPrevEduOriginal.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error
            })
    }
})

export const prevEduOriginalSelectors = defaultEntityAdapter.getSelectors(x => x.entrantForm.prevEduOriginal)

export default prevEduOriginalSlice.reducer